import React from "react";
import { createRoot } from "react-dom/client";
import Animation from "./topic/Animation";
import TabbedMap from "./maps/TabbedMap";
import LoadingAnimation from "./LoadingAnimation";
import ContextSearch from "./ContextSearch";
import TopicPage from "./topic/Topic";
import MapGradientLegend from "./maps/MapGradientLegend";
import FuzzySearch from "components/FuzzySearch/FuzzySearch";
import VaccinationStory from "../components/stories/Vaccination";
import StoryTitle from "components/stories/StoryTitle";
import StoryTabbedMap from "./maps/StoryTabbedMap";
import LineChart from "components/stories/Charts/LineChart";
import TimeBasedNote from "components/TimeBasedNote";

const components = {
  Animation,
  TabbedMap,
  LoadingAnimation,
  ContextSearch,
  TopicPage,
  MapGradientLegend,
  FuzzySearch,
  VaccinationStory,
  StoryTitle,
  StoryTabbedMap,
  LineChart,
  TimeBasedNote
};

const renderComponent = function (root, compType, props) {
  const Component = components[compType];
  if (Component) {
    const _root = createRoot(root);
    _root.render(<Component {...props} />);
  } else {
    throw "unknown component type " + compType;
  }
};

export default renderComponent;
