import { Controller } from "stimulus";
import renderChart from "../../charts/renderChart";
import { valueFormatter, percentageFormatter, barTooltipOpts } from "../../charts/utils";
import { colorConfig } from "../../charts/config";
import { ColumnData } from "../../charts/types";

type ChartConfig = {
  title: string;
  yAxisTitleText: string;
  yAxisLabelFormat: string;
  yAxisMax: number | null;
  xAxisTitleText: string;
  categories: string[];
  min: number | null;
  showsPercentages: boolean;
  precision: number | null;
  series: Array<{
    slug: string;
    name: string;
    data: ColumnData[];
    borderColor: string;
    color: string;
  }>;
  startDate: [number, number, number];
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;
  declare colors: string[];

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({
    series,
    title,
    yAxisTitleText,
    yAxisLabelFormat = "{value}",
    xAxisTitleText = "",
    yAxisMax = null,
    min = null,
    showsPercentages = false,
    precision = null,
    categories
  }: ChartConfig) {
    const { element } = this;
    const colors = colorConfig.bars;
    renderChart({
      title: {
        text: title
      },
      colors: colors.map(({ fill }) => fill),
      chart: {
        renderTo: element as HTMLElement
      },
      legend: {
        enabled: series.length > 1,
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 15,
        symbolWidth: 30,
        align: "left",
        x: 50
      },
      yAxis: {
        max: yAxisMax,
        title: {
          text: yAxisTitleText
        },
        labels: {
          format: yAxisLabelFormat,
          formatter() {
            return this.value;
          }
        }
      },
      xAxis: {
        categories,
        title: {
          text: xAxisTitleText ?? undefined,
        },
      },
      tooltip: barTooltipOpts(
        min,
        showsPercentages,
        series.map(({ data }) => data),
        precision
      ),
      series: series.map(({ data, name }, i) => ({
        name,
        type: "column",
        showInLegend: series.length > 1,
        data: data.map((datum: ColumnData) => {
          let formatterOptions;

          if (min) {
            formatterOptions = valueFormatter(datum.value, min);
          } else if (showsPercentages) {
            formatterOptions = percentageFormatter(datum.value);
          } else {
            return +datum.value;
          }

          return {
            y: formatterOptions.value,
            borderColor: colors[i].border,
            dataLabels: {
              showInLegend: true,
              enabled: formatterOptions.showLabel,
              format: formatterOptions.label,
              color: colors[i].fill,
              y: -5,
              style: {
                fontSize: 11
              }
            }
          };
        })
      }))
    });
  }
}
