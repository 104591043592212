import React, { Component } from "react";
import classnames from "classnames";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show
    });
    this.hide();
  }

  componentDidMount() {
    this.hide();
  }

  hide() {
    setTimeout(() => {
      this.setState({ show: false });
    }, this.props.hideDelay);
  }

  render() {
    const { show } = this.state;
    const classNames = classnames("c-more-below", { hidden: !show });
    return (
      <div className={classNames}>
        <span>Scroll to see additional charts</span>
        <i className="fas fa-arrow-down c-more-below__icon" />
      </div>
    );
  }
}
