// There seems to be a fair amount of inconsistency with the methods we call
// and the methods in the leaflet types. Ignoring this file for now until
// we have time to fully type things.
// @ts-nocheck

import DynamicContentCardController from "../dynamic-content-card-controller";
import { Browser, control, DomUtil, geoJSON, Layer, LayerGroup, LeafletEvent, Map } from "leaflet";
import { renderLoader } from "../../charts/utils";
import { renderBaseMap, currentLocationGeoJson, choroplethLegend } from "./utils";
import { getColor } from "../../javascript/utils/color";
import { dateToEndpoint } from "javascript/utils/date";
import { GeoJSON, ChoroplethConfig, MapData, ColorCalibration } from "./types";
import { Feature } from "geojson";
import { gaMapActions, sendGAEvent } from "../../javascript/utils/google_analytics";
import renderComponent from "javascript/renderComponent";

export default class extends DynamicContentCardController {
  static targets = ["content"];
  declare contentTarget: HTMLElement;
  declare map: Map;
  declare mapTarget: HTMLElement;
  declare rangeSelectorTarget: HTMLElement;
  declare locationLayer: Layer;
  declare geoJson: GeoJSON;
  declare layerIds: { [key: string]: string };
  declare colorCalibration: ColorCalibration;
  declare info: object;
  declare legend: object;
  declare activeIdentifier: string;
  declare config: MapData;
  declare initialized: boolean;

  initialize() {
    renderLoader(this.contentTarget);
    this.fetchInitialData();
    this.activeIdentifier = this.identifier;
  }

  fetchInitialData() {
    const { endpoint, identifier, date = "", comparisonIdentifier = null } = this;
    let comparisonEndpoint = "";
    if (comparisonIdentifier) {
      comparisonEndpoint = `/compare/${comparisonIdentifier}`;
    }
    if (endpoint && identifier) {
      this.fetch(
        `${endpoint}${identifier}${dateToEndpoint(date)}${comparisonEndpoint}`,
        this.contentTarget,
        "json"
      );
    } else {
      this.handleError();
    }
  }

  handleLoad(payload: ChoroplethConfig, _contentContainer?: HTMLElement) {
    this.buildMap(payload);
  }

  buildMap(config: ChoroplethConfig) {
    this.config = config;
    const loaderEl = this.contentTarget.querySelector(".c-loading-animation");
    loaderEl?.remove();
    const { props, geoJson } = config;
    const { polygons } = geoJson;
    const { colorCalibration, locationSlug, locationGeoJson, token, unitsAffix } = props;
    this.colorCalibration = colorCalibration;

    this.map = renderBaseMap(this.contentTarget as HTMLElement, token, {
      zoomControl: true,
      doubleClickZoom: true,
      touchZoom: true,
      scrollWheelZoom: false,
      dragging: !Browser.mobile
    });

    if (polygons) {
      this.geoJson = geoJSON(polygons, {
        style: this.getStyle.bind(this),
        onEachFeature: this.onEachFeature.bind(this)
      }).addTo(this.map);
      this.layerIds = {};
      this.geoJson.getLayers().map((l: LayerGroup) => {
        this.layerIds[l.feature.properties.locationName] = l._leaflet_id;
      });
    }

    if (locationGeoJson) {
      this.locationLayer?.remove();
      this.locationLayer = currentLocationGeoJson(locationGeoJson);
      this.map.fitBounds(this.locationLayer.getBounds());
      locationSlug !== "state" && this.locationLayer.addTo(this.map);
      this.locationLayer.bringToFront();
    }

    // Add Box to upper right that presents data for locations
    this.addInfoBox();
    if (this.showLegend && colorCalibration?.colors?.length) {
      this.addLegend(unitsAffix, colorCalibration);
    }

    // Highlight current layer if on page of location layer
    const currentLocationLayer = this.geoJson.getLayers().find((l) => {
      return l.feature.properties.locationSlug === locationSlug;
    });
    currentLocationLayer && this.highlightFeature(currentLocationLayer);
  }

  onEachFeature(feature: Feature, layer: Layer) {
    layer.on({
      mouseover: (event: LeafletEvent, _layer = null) => {
        const layer = !event ? _layer : event.target;
        if (this.config.callbacks?.mouseover) {
          this.config.callbacks.mouseover(layer);
        }
        this.highlightFeature(layer);
      },
      mouseout: () => {
        if (this.config.callbacks?.mouseout) {
          this.config.callbacks.mouseout();
          return;
        }
        this.resetHighlight.bind(this);
      },
      click: (event: LeafletEvent, _layer = null) => {
        const layer = !event ? _layer : event.target;
        if (this.config.callbacks?.click) {
          this.config.callbacks.click(layer);
        } else if (Browser.mobile) {
          this.highlightFeature(layer);
        } else {
          this.goToLocation(layer.feature.properties);
        }
      }
    });
  }

  getStyle(feature: Feature) {
    const { values, color = null } = feature.properties;
    const { value = null } = (values && values.first()) || {};
    const { colorCalibration } = this;
    const getPoloygonColor = (d: string) => {
      if (d === null) return "#CCCCCC";
      if (!colorCalibration.colors?.length) return "#CCCCCC";
      return getColor(colorCalibration, d) as string;
    };
    return {
      fillColor: color || getPoloygonColor(value),
      weight: 1,
      opacity: 1,
      color: colorCalibration.borderColor,
      dashArray: "",
      fillOpacity: value === null ? 0 : 1
    };
  }

  addInfoBox() {
    this.info?.remove();
    this.info = control();

    const humanDate = "";
    this.info.onAdd = function () {
      this._div = DomUtil.create("div", "c-map-info");
      this.update({ humanDate });
      return this._div;
    };

    this.info.update = function (props) {
      if (props.locationName) {
        let values = [];
        if (props.values && props.values.length) {
          values = props.values.map(({ value: _value = null, label, unit, valuePresented }) => {
            const valueToShow = (() => {
              if (valuePresented !== undefined && valuePresented !== null) {
                return valuePresented;
              }
              if (_value !== undefined && _value !== null) {
                return _value;
              }
              return "Data not reported";
            })()
            const _label = label ? `${label}: ` : "";
            const unitText = unit;
            let _unit = "";
            if (unitText && _value != null) {
              _unit = ` <span class="c-map-info__value-unit"> ${unitText} </span> `;
            }
            return `
              <div class="c-map-info__value-container">
                <span class="c-map-info__value-label">${_label}</span>
                <div>
                  <span class="c-map-info__value-number">
                    ${valueToShow}
                  </span>
                  ${_unit}
                </div>
              </div>
            `;
          });
        }
        this._div.innerHTML = `
          <div class="c-map-info__value-wrapper">
            <a href="${props.path}" class="c-map-info__title-link">
              <h4 class="c-map-info__title">${props.locationName}</h4>
            </a>
            <h5 class="c-map-info__date">${props.humanDate || props.title || ""}</h5>
            <div class="c-map-info__values">${values.length ? values.join(" ") : ""}</div>
            <a href="${props.path}" class="c-map-info__link"> Go to this report </a>
          </div>
        `;
      } else {
        const action = Browser.mobile ? "Tap on" : "Hover over";
        this._div.innerHTML = `
          <div class="c-map-info__value-wrapper">
            <span class="c-map-info__teaser">${action} a region to see more info</span>
          </div>
        `;
      }
    };

    this.info.addTo(this.map);
  }

  addLegend(unitsAffix = "", _colorCalibration?: ColorCalibration = null) {
    const colorCalibration = _colorCalibration || this.colorCalibration;
    this.legend?.remove();
    this.legend = choroplethLegend(colorCalibration, unitsAffix);
    this.legend.addTo(this.map);
  }

  goToLocation(props: object) {
    if (!props.path) return;
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: "Maps",
      action: gaMapActions.polygonClick,
      label: props.locationName || props.path,
      link_path: props.path
    }});
    window.top.location.href = props.path;
  }

  highlightFeature(layer: Layer) {
    this.highlightedFeature && this.resetHighlight(null, this.highlightedFeature);
    if (!layer) return;
    const activeLayerStyle = {
      weight: 3,
      color: "#8B0505",
      fillOpacity: 1
    };
    layer.setStyle(activeLayerStyle);
    this.info?.update(layer.feature.properties);
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    this.highlightedFeature = layer;
  }

  resetHighlight(e: Event, feature: Feature = null) {
    this.geoJson.resetStyle(e ? e.target : feature);
    this.locationLayer.bringToFront();
    this.info && this.info.update({ humanDate: "" });
  }

  get endpoint() {
    return this.data.get("endpoint");
  }

  get identifier() {
    return this.data.get("identifier") as string;
  }

  get comparisonIdentifier() {
    return this.data.get("comparison-identifier") as string;
  }

  get showLegend() {
    let show = true;
    if (this.data.get("hide-legend")) {
      show = false;
    }
    return show;
  }

  get date() {
    return this.data.get("date");
  }

  set identifier(identifier) {
    this.data.set("identifier", identifier);
  }
}
