import React, { Fragment, useState } from "react";
import { createUseStyles } from "react-jss";
import { tickInterval, dateTimeLabelFormats } from "charts/config";
import { chartTextStyle, labels } from "../Charts/config";
import MemoizedLineChart from "../Charts/MemoizedLineChart";
import Scene from "../Scene/Scene";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import { getCreditById } from "./copy";

import { textContainer, chartTitleHighlighted } from "../styles/typography";
import { StepData, ChartInstanceProps } from "../types";

const useStyles = createUseStyles({
  textContainer,
  chartTitleHighlighted
});

const opacityForStep = (activeIndices: number[], activeIndex: number) => {
  const condition = activeIndices.includes(activeIndex);
  return {
    opacity: condition ? 1 : 0
    // zIndex: condition ? 100 : 1,
  };
};

const VaccinationRateChart = () => {
  const plotLineDate = "April 6, 2021";
  const options = {
    title: { text: null },
    yAxis: {
      title: {
        text: "Rate per 10,000 population (14-day moving average)"
      },
      max: 155,
      labels
    },
    xAxis: {
      title: {
        text: "Date",
        style: chartTextStyle
      },
      type: "datetime",
      labels: {
        style: chartTextStyle
      },
      dateTimeLabelFormats,
      minTickInterval: tickInterval("daily"),
      plotLines: [
        {
          color: "#666666",
          dashStyle: "Dot",
          id: "vaccination-phase-change",
          label: {
            align: "left",
            rotation: 0,
            useHTML: true,
            text: `Vaccination eligibility opened to 16+ <br/> (${plotLineDate})`,
            style: {
              padding: "10px",
              background: "rgba(255,255,255,.5)",
              ...chartTextStyle
            },
            x: 5,
            y: 40
          },
          width: 2,
          value: Date.parse(plotLineDate),
          y: -50,
          zIndex: 3
        }
      ]
    },
    plotOptions: {
      series: {
        marker: { enabled: false },
        showInLegend: true,
        lineWidth: 3
      }
    },
    legend: {
      title: {
        style: chartTextStyle
      }
    }
  };

  const classes = useStyles();
  const baseTitle = (subTitle: string) => (
    <>
      Daily rate of new COVID-19 vaccinations by Hispanic origin, Delaware, 2021 -{" "}
      <span className={classes.chartTitleHighlighted}>{subTitle}</span>
    </>
  );
  const chartInstances = [
    {
      id: "vax-rates",
      jsonFile: "stratified_covid_vaccination_rates",
      title: baseTitle("All Ages"),
      subTitle: "January 2021 to October 2021",
      options,
      activeIndices: [0, 1],
      source: getCreditById("ddhss", "sources")
    },
    {
      id: "vax-rates-50-puls",
      jsonFile: "stratified_covid_vaccination_rates_50_plus",
      title: baseTitle("Age 50 and Older"),
      subTitle: "January 2021 to October 2021",
      options,
      activeIndices: [2, 3, 4],
      source: getCreditById("ddhss", "sources")
    }
  ];

  const text = [
    <>
      <p>
        Despite early distribution efforts, including onsite clinics at poultry facilities, Hispanic
        Delawareans across the state were vaccinated at a slower pace than non-Hispanics during the
        first months of vaccine availability...
      </p>
    </>,
    <div className={classes.textContainer}>
      <p>
        ...until early April 2021—when all residents 16 years and older became eligible for
        vaccination.
      </p>
      <p>At that point, the tables turned.</p>
      <p>
        Going forward and into fall, the daily portion of Hispanic Delawareans getting vaccinated
        exceeded that of non-Hispanic residents nearly every day.
      </p>
    </div>,
    <div className={classes.textContainer}>
      <p>
        The trend appears particularly pronounced among older Delawareans. As under-50 year-olds
        became eligible, Hispanic persons 50 and older continued seeking vaccinations at rates
        higher than non-Hispanics of the same age.
      </p>
    </div>,
    <div className={classes.textContainer}>
      <p style={{ marginBottom: 32 }}>
        The belated surge in Hispanic vaccination rates is consistent with an observation from
        health officials during the first six months of vaccine availability: Hispanic Delawareans
        were arriving at vaccination sites not just individually, but as households.
      </p>
      <p style={{ fontWeight: 700 }}> Some possible explanations? </p>
      <p>
        Close-knit families may have preferred getting vaccinated together rather than according to
        the age groupings of the eligibility schedule.
      </p>
      <p>
        Older generations possibly postponed until younger, English-fluent relatives could accompany
        them to clinics.
      </p>
      <p>
        A single, all-family appointment may simply have been the easiest logistical solution for a
        large household juggling childcare and frontline or other jobs.
      </p>
    </div>,
    "filler-1x"
  ];
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const onStepEnter = ({ data }: StepData) => setCurrentStepIndex(data);
  return (
    <Scene reverseContent>
      <SceneFeaturedContent>
        {chartInstances.map((instance: ChartInstanceProps, i: number) => (
          <div style={opacityForStep(instance.activeIndices, currentStepIndex)} key={i}>
            <MemoizedLineChart {...instance} />
          </div>
        ))}
      </SceneFeaturedContent>
      <SceneScrollContainer acts={text} onStepEnter={onStepEnter} />
    </Scene>
  );
};

export default VaccinationRateChart;
