import React, { Component } from "react";
import classnames from "classnames";
import LoadingAnimation from "../LoadingAnimation";
import MetricCard from "./MetricCard";
import MetricCardAverageTrend from "./MetricCardAverageTrend";
import CardInfoWarning from "../topic/CardInfoWarning";
import { fetch } from "whatwg-fetch";
import compact from "lodash/compact";

export default class extends Component {
  constructor(props) {
    super(props);
    this.options = Object.assign(
      {
        objectMargin: 6
      },
      props.options || {}
    );
    this.cardComponents = {
      "multi-metric": MetricCard,
      "avg-trend": MetricCardAverageTrend
    };
    this.state = {
      comparisonLocationId: props.geographicComparison ? props.geographicComparison.id : null,
      data: this.props.data || [],
      headingTop: 0,
      error: false
    };
  }

  componentDidMount() {
    if (!this.state.data) {
      this.fetchData();
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    if (
      this.state.comparisonLocationId === nextState.comparisonLocationId &&
      this.state.loading === nextState.loading
    )
      return false;
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (
      this.state.loading === nextState.loading &&
      this.props.geographicComparison.id === nextProps.geographicComparison.id
    )
      return false;
    this.fetchData(nextProps.geographicComparison.id);
  }

  updateData(data, comparisonLocationId, error = false) {
    // Delay state change in case response is quick so user knows there was an
    // update
    setTimeout(() => {
      this.setState({
        loading: false,
        data,
        error,
        comparisonLocationId
      });
    }, 500);
  }

  fetchData(comparisonLocationId = null) {
    const _id = comparisonLocationId || this.props.geographicComparison.id;
    this.setState({ loading: true });
    fetch(`${window.location.href.split("?")[0]}.json?comparison_slug=${_id}`, {
      headers: { "Content-Type": "application/json" }
    })
      .then((response) => response.json())
      .then((data) => this.updateData(data, _id))
      .catch((error) => {
        this.updateData([], _id, error);
      });
  }

  categoryMetrics({ category_name, category_topics, category_path }) {
    return (
      <section className="c-metric-card-group" key={category_name}>
        <h3 className="c-metric-card-group__heading" style={{ top: `${this.state.headingTop}px` }}>
          {category_name}
        </h3>
        <div>
          {category_topics.map((c, i) => {
            return (
              <MetricCard
                key={`metric-card-${i}`}
                data={c}
                location={this.props.location}
                comparisonLocationId={this.state.comparisonLocationId}
                showInfoMessage={this.props.showInfoMessagePerCard}
              />
            );
          })}
        </div>
        {category_path && (
          <a href={category_path} className="c-metric-card-link">
            <span>View full </span>
            <strong>{category_name}</strong>
            <span> report</span>
          </a>
        )}
      </section>
    );
  }

  cardsByType() {
    return this.state.data.map((c, i) => {
      const Component = this.cardComponents[this.props.cardType];
      return (
        <Component
          key={`metric-card-${i}`}
          data={c}
          location={this.props.location}
          comparisonLocationId={this.state.comparisonLocationId}
          showInfoMessage={this.props.showInfoMessagePerCard}
        />
      );
    });
  }

  cards() {
    const { data } = this.state;
    // Handle grouped metrics -- if data contains multiple metrics treat as
    // Category
    if (data.length && data[0].category_topics) {
      return data.map(this.categoryMetrics.bind(this));
    } else if (data.length) {
      const classNames = classnames(
        { "o-container--constrained": this.props.cardType === "multi-metric" },
        { "o-container--main": this.props.cardType !== "multi-metric" },
        { row: this.props.cardType !== "multi-metric" }
      );
      return <div className={classNames}>{this.cardsByType()}</div>;
    }
  }

  globalInfoWarning() {
    const { location } = this.props;
    if (!this.props.showGlobalInfoWarning) return null;
    const dataLocations = compact(this.state.data.map((d) => d.location === this.props.location));
    // Show info warning if all slats' resolved location does not match
    // location
    if (this.state.data.length && !dataLocations.length) {
      return (
        <CardInfoWarning
          wrapperClassNames="rounded"
          primaryLocation={this.state.data[0].primary.location.name}
          location={location}
        />
      );
    }
  }

  render() {
    const { cardType } = this.props;
    const { data, loading, error } = this.state;
    let classNames = classnames(
      "o-topic__content o-topic__cards o-metric-cards",
      { "o-metric-cards--stretch-right": cardType === "avg-trend" },
      { "o-topic__cards--grouped": data.length && data[0].category_topics }
    );
    classNames += ` mb-${this.options.objectMargin}`;
    return (
      <div>
        {this.globalInfoWarning()}
        <div className={classNames} data-spy="scroll" data-target=".c-topic-nav" data-offset="0">
          {loading && <LoadingAnimation veil={true} />}
          {this.cards()}
          {error && <p>Where having troubling finding data</p>}
        </div>
      </div>
    );
  }
}
