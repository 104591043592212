import React, { Component, Fragment } from "react";
import Charts from "./Charts.js";
import Maps from "./Maps";
import Resources from "./Resources";
import AboutData from "./AboutData";
import classnames from "classnames";
import { sendGAEvent } from "../utils/google_analytics";

export default class TopicContext extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: "" };
    this.primaryContextCategories = {
      maps: { props: null, Component: Maps, icon: "map" },
      charts: { props: null, Component: Charts, icon: "chart-line" }
    };
    this.tabbedContextCategories = {
      resources: { props: null, Component: Resources, icon: "info-circle" },
      "about-the-data": { props: null, Component: AboutData, icon: "table" }
    };
    this.buildProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.geographicComparison &&
      this.props.geographicComparison.id === nextProps.geographicComparison.id
    )
      return false;
    this.buildProps(nextProps);
  }

  handleTabSwitch(tab) {
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: "Indicator",
      action: "Tab Click",
      label: tab
    }});
  }

  hasContextType(type = null, data = {}) {
    let hasType = 0;
    switch (type) {
      case "maps":
        hasType =
          (data.animations && data.animations.length) || (data.maps && data.maps.length) ? 1 : 0;
        break;
      case "charts":
        if (data.chart && data.chart.sets) hasType = 1;
        else hasType = 0;
        break;
      case "resources":
        hasType = data.resources && data.resources.length ? 1 : 0;
        break;
      case "about-the-data":
        hasType = data.about;
        break;
      default:
        hasType = 0;
    }
    return hasType;
  }

  buildProps({ data, geographicComparison }) {
    if (this.hasContextType("charts", data)) {
      data.chart.geographicComparison = geographicComparison;
      data.chart.location = this.props.location || "";
      this.primaryContextCategories.charts.props = data.chart;
    }
    if (this.hasContextType("maps", data)) {
      this.primaryContextCategories.maps.props = { maps: data.maps };
    }
    if (this.hasContextType("resources", data)) {
      this.tabbedContextCategories.resources.props = { data: data.resources };
    }
    if (this.hasContextType("about-the-data", data)) {
      this.tabbedContextCategories["about-the-data"].props = { content: data.about };
    }
  }

  activeClasses() {
    return {
      baseTab: "c-tab__link nav-link ",
      baseContent: "tab-pane fade ",
      resources: classnames({ "show active": true }),
      "about-the-data": classnames({ "show active": !this.tabbedContextCategories.resources.props })
    };
  }

  getTabName(id) {
    return `context-${id}-tabContent`;
  }

  tabs(panes) {
    return this.panesWithContent(panes, "tabbedContextCategories").map(({ categoryName }, i) => {
      const classNames = classnames("c-tab__link", "nav-link", { active: i === 0 });
      return (
        <li key={`${categoryName}-tab`} className="c-tab nav-item" role="presentation">
          <button
            id={`context-${categoryName}-tab`}
            className={classNames}
            data-bs-toggle="tab"
            data-bs-target={`#${this.getTabName(categoryName)}`}
            role="tab"
            aria-selected="false"
            onClick={() => this.handleTabSwitch(categoryName)}
          >
            {categoryName.replace(/-/g, " ").capitalize()}
          </button>
        </li>
      );
    });
  }

  panesWithContent(panes, contextCategoryKey = "tabbedContextCategories") {
    return Object.keys(panes)
      .map((categoryName) => ({
        ...this[contextCategoryKey][categoryName],
        categoryName
      }))
      .filter(({ props }, i) => {
        const { sets = null, data = null, content = null, maps = null } = props || {};
        return <Fragment key={i}>{sets || data || content || maps}</Fragment>;
      });
  }

  panes(categoryPanes, isTab = false) {
    const tabPanesCategory = isTab ? "tabbedContextCategories" : "primaryContextCategories";
    const tabPanes = this.panesWithContent(categoryPanes, tabPanesCategory);
    return tabPanes.map(({ props = {}, Component, categoryName }, i) => {
      const classes = classnames({ "show active": i === 0 && isTab }, { "show active": !isTab });
      return (
        <section
          key={`${categoryName}-tabContent`}
          id={this.getTabName(categoryName)}
          className={`tab-pane fade ${classes} pb-4`}
          role={isTab ? "tabpanel" : ""}
          aria-labelledby={`context-${categoryName}-tab`}
        >
          {Component && <Component {...props} />}
        </section>
      );
    });
  }

  render() {
    return (
      <>
        {this.panes(this.primaryContextCategories)}
        <section className="card pt-2">
          <ul id="context-tabs" className="o-tabs nav nav-tabs" role="tablist">
            {this.tabs(this.tabbedContextCategories)}
          </ul>
          <div id="context-tabContent" className="tab-content">
            {this.panes(this.tabbedContextCategories, true)}
          </div>
        </section>
      </>
    );
  }
}
