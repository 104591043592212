import React, { Component } from "react";

export default class extends Component {
  detailItems() {
    return this.props.items.map((i) => {
      return (
        <div className="c-tooltip-chart__value-wrapper">
          <h4 className="c-tooltip-chart__title">{i.title}</h4>
          <span className="c-tooltip-chart__value">{Number(i.value).toFixed(2)}</span>
          {i.unit ? <span className="c-tooltip-chart__unit">{i.unit}</span> : null}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="pl-4 pr-4" style={{ flex: "0 0 25%" }}>
        <div className="c-tooltip-chart">{this.detailItems()}</div>
      </div>
    );
  }
}
