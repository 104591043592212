// Import and initialize scroll polyfill for legacy browsers
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

window.App = window.App || {};
App.polyfills = App.polyfills || {};

import objectFit from "./object_fit";
App.polyfills.objectFit = objectFit;

// window.fetch related polyfills
import "whatwg-fetch";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";

// Add forEach method to NodeList
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Add last method to NodeList
if (window.NodeList && !NodeList.prototype.last) {
  NodeList.prototype.last = Array.prototype.last;
}

// Add first method to NodeList
if (window.NodeList && !NodeList.prototype.first) {
  NodeList.prototype.first = Array.prototype.first;
}

import "./closest";
