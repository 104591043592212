import breakpoints from "../../styles/breakpoints";

export const figureTitle = {
  flex: 0,
  fontFamily: "open sans",
  fontSize: 16,
  lineHeight: "22px",
  fontWeight: "600",
  letterSpacing: 0.72,
  margin: 0,
  minHeight: 80,
  "& > p": {
    margin: 0
  },
  [breakpoints.up("md")]: {
    fontSize: 20,
    lineHeight: "26px",
    padding: [0, 50],
    marginBottom: 20
  }
};

export const figureSource = {
  fontSize: 14,
  lineHeight: "18px",
  paddingLeft: 25,
  marginTop: 20,
  marginBottom: 0,
  minHeight: 35,
  [breakpoints.up("md")]: {
    fontSize: 18,
    lineHeight: "22px",
    paddingLeft: 50
  }
};
