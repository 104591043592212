import renderComponent from "../javascript/renderComponent";
import { numberFormat, PointOptionsType, setOptions } from "highcharts";
import { ColumnData } from "./types";

export const setGlobalOptions = () => {
  const globalOpts = {
    lang: {
      thousandsSep: ",",
      numericSymbolMagnitude: 1000
    }
  };
  setOptions(globalOpts);
};

export const valueFormatter = (value: number | string) => {
  const label = value;

  return {
    showLabel: typeof value === "string" || +value === 0,
    label: typeof value === "string" ? value : label,
    value: typeof value === "string" ? 0 : value
  };
};

export const percentageFormatter = (value: number) => {
  return {
    showLabel: !!value,
    label: `${value}%`,
    value: +value
  };
};

export const barTooltipOpts = (
  min: number | null,
  showsPercentages = false,
  originalSeriesData: PointOptionsType[],
  precision: number = 0
) => ({
  pointFormatter() {
    const { y, x, series } = this as PointOptionsType;
    let value;
    let additionalValues = "";

    setGlobalOptions();

    if (min) {
      let original_y: number | string | ColumnData = originalSeriesData[series.index][x];

      if (typeof original_y === "object") {
        original_y = original_y.value;
      }

      value = valueFormatter(original_y).label;
    } else if (showsPercentages) {
      value = percentageFormatter(y).label;
    } else {
      value = numberFormat(y as number, precision);
    }
    if (showsPercentages) {
      const { numerator, denominator } = originalSeriesData[series.index][x];
      if (numerator) additionalValues += `<br>Numerator: <b>${numerator}</b>`;
      if (denominator) additionalValues += `  |  Denominator: <b>${denominator}</b>`;
    }
    return `
      <div>
        <div><span style="color: ${series.color}; margin-right: 5px;">•</span></div>
        <div> ${series.name}: <b>${value}</b></div>
        ${additionalValues}
      </div>
    `;
  }
});

export const renderLoader = (container: HTMLElement, options = {}) => {
  const { zIndex = 100, veilColor = null } = options;
  const newContainer = document.createElement("div");
  newContainer.className = "loading-animation";
  container.appendChild(newContainer);
  renderComponent(newContainer, "LoadingAnimation", {
    veil: true,
    veilColor,
    zIndex,
    withBG: false,
    colors: { bg: "#fde89b", active: "#1E90FF" }
  });
};

export const removeLoader = (container: HTMLElement) => {
  container?.querySelector(".loading-animation")?.remove();
};
