// The charts we have at the bottom of indicator pages.

import Chart from "./chart";
import LevelReference from "./level_reference";
import { line, areaSplineRange } from "billboard.js";

import { colors } from "./options";
import moment from "moment";
import { removeLeadingZero, roundToDigits } from "../utils/number";
import { getDatesInRange } from "../utils/date";
import { querySelectorAll } from "../utils/polyfills";
import { cullingMaxBasedOnWidth } from "./utils";
import compact from "lodash/compact";

export default class extends Chart {
  constructor(props, containerEl, options = {}) {
    const _options = Object.assign(
      {
        showLegend: true,
        showLoader: true,
        yAxis: { min: null },
        defaultAxisMin: 0
      },
      options
    );
    // Make min and max 0 and 100 when the unit is percent
    if (_options.data_unit === "percent") {
      _options.yAxis = { min: 0, max: 100 };
    }
    super(props, containerEl, _options);
    this.updateDateFormat();
    this.granularity = this.props.options.granularity || "year";
  }

  _generate() {
    const chart = super._generate();
    this.addLevelRef();
    this._addAdditionalUi();
    return chart;
  }

  _onRendered(chart) {
    super._onRendered(chart, true);
    if (this.granularity === "month") {
      querySelectorAll(".bb-axis-x-label").forEach((l) => l.setAttribute("dy", "5.25em"));
    }
  }

  _buildOptions() {
    // Build basic options for line chart
    // Copy data columns array for labels and points
    const self = this;
    const columns = this._prepareColumns();
    const dataColumns = [...columns];
    const { height, labels } = this.props;
    // Remove x axis labels (first element in array)
    dataColumns.shift();

    // Add padding to left and right of ticks to prevent clipping
    // Bassed on datetime
    const dateFormat = this.dateFormat.chart;
    let tickPadding = 10000000000;
    this._options = Object.assign(
      {
        size: { height: height || 450 },
        legend: { show: false },
        padding: this._getChartPadding(),
        color: { pattern: colors() },
        data: {
          x: "x",
          xFormat: dateFormat,
          columns,
          names: this._getColumnNames(),
          colors: this._getColors(columns),
          type: line()
        },
        line: {
          connectNull: this.connectNullPoints()
        },
        axis: {
          x: {
            type: "timeseries",
            padding: { right: tickPadding, left: tickPadding },
            label: {
              text: labels.x || "Date",
              position: "outer-center"
            },
            tick: this.buildXAxisTick()
          },
          y: {
            padding: { top: 0, bottom: 0 },
            label: {
              text: this._getYAxisLabel(),
              position: "outer-middle"
            },
            tick: {
              fit: true,
              format: removeLeadingZero
            },
            max: this.options.yAxis.max ? this.options.yAxis.max : this._getMaxPoint().toFixed(2),
            min:
              this.options.defaultAxisMin !== undefined
                ? this.options.defaultAxisMin
                : this.options.yAxis.min
          },
          y2: {
            tick: {
              format: removeLeadingZero
            }
          }
        },
        grid: {
          x: { show: false },
          y: { show: true }
        },
        point: { pattern: this._getVisualPoints(dataColumns) },
        bindto: this.containerEl,
        tooltip: { contents: this._tooltip.bind(this) },
        onrendered() {
          self._onRendered(this);
        },
        onresize() {
          self._onResize(this);
        }
      },
      this.options
    );
  }

  annualizeWord(set) {
    if (set.annualized) {
      return "Annualized";
    }
    return "";
  }

  _tooltip(data) {
    const levelReference = this.levelReference;
    const { showConfidence } = this.props;
    const values = compact(data).map((d, i) => {
      let showTooltipItem = true;
      if (showConfidence) showTooltipItem = compact(d.value).length;
      else showTooltipItem = d.value !== null;
      if (!showTooltipItem) return "";
      let warningStyle = "";
      let levelWarning = "";
      let warning = "";
      let context = "";
      const set = this._getDataSets()[i];
      const { location, maxValue, nameAddendum, relatedData } = set;
      const date = moment(d.x).format(set.granularity === "month" ? "MMMM, YYYY" : "YYYY");
      if (maxValue && d.value >= +maxValue) {
        warning = `
          <span class='c-tooltip-chart__warning'>
            <i class='fa fa-warning'></i>
            <span>Above or at maximum contaminant level (${maxValue}${this._getUnit()})</span>
          </span>
        `;
      }
      if (this.hasLevelRefRanges() && levelReference.rangeDataAvailable(d.id)) {
        levelWarning = levelReference.getLegend(d);
        warningStyle = 'style="color: #000000 !important;"';
      }
      if (relatedData) {
        const text = this.props.data.relatedDataText ? `${this.props.data.relatedDataText}:` : "";
        const primarySet = primary.name
          ? primary
          : sets[0] ||
            {}`
          <span class='mb-3 mt-2 c-tooltip-chart__context d-block'>
            <span>${text}</span>
            <span class='c-metric-date'>${relatedData[moment(d.x).format("YYYY-MM-DD")]}</span>
          </span>
        `;
      }
      let value = `<span ${warningStyle} class='c-tooltip-chart__value c-tooltip-chart__value--${
        i + 1
      } d-inline'>${d.value}</span>`;
      if (this.props.showConfidence) {
        const low = d.value[2] ? roundToDigits(d.value[2], null, null, 1) : "NA";
        const high = d.value[0] ? roundToDigits(d.value[0], null, null, 1) : "NA";
        value = `
          <span ${warningStyle} class='c-tooltip-chart__value c-tooltip-chart__value--${
          i + 1
        } d-inline'>
            ${d.value[1] || d.value}
          </span>
          <span class='c-tooltip-chart__value--secondary'>
            (${low}, ${high})
          </span>
        `;
      }
      return `
          <div class='c-tooltip-chart__value-wrapper'>
            <span class='c-tooltip-chart__location'>${location ? location : ''}</span>
            <h4 class='c-tooltip-chart__title'>${d.name}${nameAddendum ? '*' : ''}</h4>
            <span class='c-tooltip-chart__date'>
              ${date}
            </span>
            <h4 class='c-tooltip-chart__title'>${d.name}${nameAddendum ? "*" : ""}</h4>
            <span class='c-tooltip-chart__location mb-2'>${location ? location : ""}</span>
            <span class='c-tooltip-chart__value-container'>
              ${value}
              <span class='c-tooltip-chart__value-point c-chart-legend__visual c-chart-legend__visual--${
                i + 1
              } c-chart-legend__visual--point'> </span>
            </span>
            <span class='c-tooltip-chart__unit'>${this._getUnit()}</span>
            ${this.annualizeWord(set)}
            ${context}
            <span class='c-tooltip-chart__addendum d-flex mt-2'>
              ${nameAddendum ? `<span>*${nameAddendum}</span>` : ""}
            </span>
            ${warning}
            ${levelWarning}
          </div>
        `;
    });
    return `
      <div class='c-tooltip-chart'>
        ${values.join("")}
      </div>
    `;
  }

  _additionalLegendUpdates() {
    super._additionalLegendUpdates();
    if (this.hasLevelRefRanges() && this.levelReference) {
      this.levelReference.updateLegendItem(this.id);
      this.levelReference.addLevelRefLegend(this.id);
    }
  }

  _addAdditionalUi() {
    super._addAdditionalUi();
    const set = this._getDataSets()[0];
    if (set && set.maxValue) this.addMaxLevel();
  }

  _onResize(chart) {
    super._onResize(chart);
    if (this.hasLevelRefRanges()) {
      this.levelReference.updateLineAndDots(chart);
    }
  }

  update(props) {
    this._updateLoadingState(true);
    this.props = props;
    this.updateDateFormat();
    const columns = this._prepareColumns();
    const sets = this._getDataSets();
    const comparisonsAreSubsets = compact(sets.map((s) => s.subset)).length;
    const showSecondaryAxis =
      this.props.comparison && this.props.comparison.data && !comparisonsAreSubsets;
    const setIds = columns.map((c) => c[0]);
    // Update column type if confidence interval is selected
    if (this.props.showConfidence) {
      this._options.data.types = {};
      columns.forEach((c) => {
        const colName = c[0];
        if (colName !== "x") this._options.data.types[colName] = areaSplineRange();
      });
    } else if (this._options.data.types) {
      delete this._options.data.types;
    }
    let axes = {};
    axes[setIds[1]] = "y";
    if (showSecondaryAxis) {
      axes[setIds[setIds.length - 1]] = "y2";
    }
    const newData = {
      columns,
      names: this._getColumnNames(),
      colors: this._getColors(columns),
      axes
    };
    this._options.padding = this._getChartPadding();

    // Update y axis label text
    this._options.axis.y.label.text = this._getYAxisLabel();

    this._options.point = { pattern: this._getVisualPoints(columns) };

    // Add secondary axis if comparison
    if (showSecondaryAxis) {
      const comparisonSet = props.comparison;
      this._options.axis.y2 = {
        show: true,
        label: {
          text:
            comparisonSet.name +
            (comparisonSet.title_addendum ? "*" : "") +
            (comparisonSet.unit ? ` ${comparisonSet.unit}` : ""),
          position: "outer-middle"
        },
        tick: {
          format: removeLeadingZero,
          fit: true
        }
      };
    } else {
      this._options.axis.y2 = {
        show: false,
        tick: {
          format: removeLeadingZero,
          fit: true
        }
      };
    }
    // Update tick values that are shown based on daterange
    this._options.axis.x.tick = this.buildXAxisTick();

    // Update y-axis max/min
    // NOTE: Currently we round the min in hopes of setting the bottom number to
    //       0 in cases of set being rates
    // TODO: Better handle all rounding
    const dataSetToGet = showSecondaryAxis || sets.length > 1 ? null : 0;
    this._options.axis.y.max =
      this.props.options.data_unit === "percent"
        ? 100
        : +this._getMaxPoint(dataSetToGet).toFixed(2);
    let defaultMin = null;
    defaultMin = this.options.defaultAxisMin !== null ? this.options.defaultAxisMin : null;
    defaultMin = this.options.yAxis.min !== null ? this.options.yAxis.min : defaultMin;
    this._options.axis.y.min =
      defaultMin !== null ? defaultMin : +this._getMinPoint(dataSetToGet).toFixed(2);

    if (showSecondaryAxis) {
      this._options.axis.y2.max = +this._getMaxPoint(sets.length - 1).toFixed(2);
      this._options.axis.y2.min = +this._getMinPoint(sets.length - 1).toFixed(2);
    }

    // Combine new data with existing data
    this._options.data = Object.assign(this._options.data, newData);

    // Update whether to connect null points based on granularity of sets
    this._options.line = { connectNull: this.connectNullPoints() };

    setTimeout(() => {
      this.chart.destroy();
      this._generate();
      this.updateLegendPadding();
    }, this.LOAD_DELAY);
  }

  updateLegendPadding() {
    if (this.props.comparison) {
      // Add padding to the right of the legend to counteract width of y2 axis
      const chart = document.querySelector(".c-chart");
      if (chart) {
        const legend = chart.querySelector(".c-chart-legend");
        if (legend) legend.setAttribute("style", "padding-left: 75px; padding-right: 75px;");
      }
    }
  }

  addMaxLevel() {
    // Add grid line for maximum value if one is given
    const set = this._getDataSets()[0];
    if ((this.props.max && this.props.max.value) || set.maxValue) {
      const value = (this.props.max ? this.props.max.value : null) || set.maxValue;
      this.chart.ygrids([
        {
          value,
          text:
            (this.props.max ? this.props.max.label : null) ||
            `Maximum Contaminant Level: ${value} ${this._getUnit()}`,
          class: "c-chart__max-value",
          position: "start"
        }
      ]);
      // Only increase max value of axis if greater than maximum point value
      // across all sets
      if (this._getMaxPoint() < value) {
        this.chart.axis.max({
          y: +value
        });
      }
    }
  }

  addLevelRef() {
    const hasLevelRefRanges = this.hasLevelRefRanges();
    if (hasLevelRefRanges) {
      const levelRefInstace = new LevelReference(
        {
          sets: this._getDataSets(),
          comparison: this.props.comparison
        },
        this.chart,
        { showFullRange: true }
      );
      this.levelReference = levelRefInstace;
      levelRefInstace
        .add(this.chart, this._getMaxMinPoint(null, 0))
        .then(() => {
          levelRefInstace.updateLineAndDots(this.chart);
          levelRefInstace.updateLegendItem(this.id);
          levelRefInstace.addLevelRefLegend(this.id);
          this._updateLoadingState(false);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
    } else {
      this.levelRefRanges = null;
      this._updateLoadingState(false);
    }
  }

  hasLevelRefRanges() {
    const sets = this._getDataSets()[0] || {};
    return sets.levelRefRanges && sets.levelRefRanges.length;
  }

  updateDateFormat() {
    const hasMonthlyData = this.setsIncludeGranularity("month");
    let chartDateFormat = "%b %d, %Y";
    let mommentFormat = "MMMM D YYYY";
    if (hasMonthlyData && this.props.dateFormat == "month_year") {
      chartDateFormat = "%b %Y";
      mommentFormat = "MMMM YYYY";
    } else {
      chartDateFormat = "%Y";
      mommentFormat = "YYYY";
    }
    this.dateFormat = {
      chart: chartDateFormat,
      moment: mommentFormat,
      rotate: hasMonthlyData
    };
  }

  getPrimarySets() {
    let _sets = this._getDataSets();
    if (_sets.primary) _sets = _sets.primary;
    return _sets;
  }

  getDateRange() {
    const { dateRange } = this.props;
    if (this.setsIncludeGranularity("month")) {
      return dateRange;
    }
    return getDatesInRange("year", [
      dateRange[0].getFullYear(),
      dateRange[dateRange.length - 1].getFullYear()
    ]);
  }

  connectNullPoints() {
    const { connectNull } = this.options;
    return connectNull ? connectNull : this.setsIncludeGranularity("year");
  }

  setsIncludeGranularity(granularity) {
    return this._getDataSets()
      .map((s) => s.granularity)
      .includes(granularity);
  }

  buildXAxisTick() {
    let rotate = this.options.rotate || 0;
    if (this.options.rotate) {
      rotate = 35;
    } else if (this.dateFormat.rotate) {
      rotate = this.dateFormat.rotate;
    }

    // Establish culling max based on screen width
    // Billboard will remove labels from the series in hopes
    // of narrowing to this value

    return {
      format: this.dateFormat.chart,
      outer: false,
      centered: true,
      rotate,
      fit: true,
      culling: {
        max: cullingMaxBasedOnWidth()
      }
    };
  }
}
