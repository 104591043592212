import React, { Component } from "react";
import classnames from "classnames";
import SVGSymbol from "../SVGSymbol";

export default class MetricCardMetric extends Component {
  componentWillReceiveProps(nextProps) {
    const { comparisonLocation } = this.props.locations;
    const nextComparisonLocation = nextProps.locations.comparisonLocation;
    if (comparisonLocation.name === nextComparisonLocation.name) return false;
  }

  buildProps(props = this.props) {
    let primary = null;
    let comparison = null;
    const {
      title,
      titleAddendum,
      tooltip,
      metricValue,
      timeframe,
      unit,
      comparisonLocResolutionChanged,
      showPrimaryTooltip = true
    } = props;
    const { locations } = this.props;
    if (metricValue) {
      primary = metricValue.primary;
      comparison = metricValue.comparison;
    }
    const { primaryLocation, comparisonLocation } = locations;
    const _titleAddendum = titleAddendum ? titleAddendum : "";
    const id = `label-metric-${title.replace(/ /g, "-").toLowerCase()}`;
    let comparisonIcon = "";
    let comparisonTooltip = `<h4 class='mb-0'>${title} (${comparisonLocation.name})</h4>`;
    if (typeof timeframe == "object" && timeframe.comparison != timeframe.primary) {
      comparisonTooltip = `<h4 class='mb-0'>${title} (${comparisonLocation.name} in ${timeframe.comparison}) </h4><p>Most recent data available</p>`;
      comparisonIcon = (
        <span style={{ marginLeft: "5px" }}>
          <SVGSymbol name="info" color="primary" dimensions={{ height: 12, width: 12 }} />
        </span>
      );
    }
    if (comparisonLocResolutionChanged) {
      comparisonIcon = (
        <span style={{ marginLeft: "5px" }}>
          <SVGSymbol name="info" color="primary" dimensions={{ height: 12, width: 12 }} />
        </span>
      );
      comparisonTooltip += `<div class='c-tooltip__info-message'><i class='fas fa-info-circle'></i><span>Statistics shown are calculated from data for ${comparisonLocation.name} because there are insufficient data for the selected comparison location</span></div>`;
    }

    return (
      <div className="c-metric-card__group-item" key={title.replace(" ", "-")}>
        <div
          className="c-metric-card__group-title"
          aria-labelledby={_titleAddendum ? "label-metric-addendum" : ""}
        >
          <span id={id}>
            {title} {_titleAddendum}
          </span>
          {tooltip && (
            <span
              className="c-tooltip-icon"
              title={tooltip || ""}
              data-bs-toggle="tooltip"
              data-bs-html="true"
            >
              <SVGSymbol name="info" />
            </span>
          )}
        </div>
        <div className="c-metric-pair">
          {primary !== null && (
            <span
              className="c-metric-value c-metric-value--primary"
              aria-describedby={id}
              aria-label={`${primaryLocation.name} (primary location)`}
              data-bs-toggle={showPrimaryTooltip ? "tooltip" : ""}
              data-bs-html="true"
              title={
                showPrimaryTooltip ? `<h4 class='mb-0'>${title} (${primaryLocation.name})</h4>` : ""
              }
            >
              {primary}
              {unit && <span>{unit}</span>}
            </span>
          )}
          {comparison && comparison !== null && (
            <span
              className="c-metric-value c-metric-value--comparison"
              aria-describedby={id}
              aria-label={`${comparisonLocation.name} (comparison location)`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title={comparisonTooltip}
              data-original-title={comparisonTooltip}
            >
              {comparison}
              {unit && <span className="c-metric__unit--comparison">{unit}</span>}
              {comparisonIcon}
            </span>
          )}
        </div>
      </div>
    );
  }

  values() {
    const { metrics } = this.props;
    if (metrics && metrics.length) {
      return metrics.map(this.buildProps.bind(this));
    }
    return this.buildProps();
  }

  render() {
    const { baseClasses, metricValue, metrics } = this.props;
    let disabled = true;
    if (metricValue) {
      disabled = metricValue.primary === null;
    }
    if (metrics && metrics.length) {
      disabled = metrics[0].metricValue.primary === null;
    }
    const classNames = classnames(baseClasses, { "c-metric-card__group--disabled": disabled });
    return <div className={classNames}>{this.values()}</div>;
  }
}
