import { Controller } from "stimulus";
import renderComponent from "javascript/renderComponent";

export default class extends Controller {
  static values = { name: String, props: String };
  declare nameValue: string;
  declare propsValue: string;

  connect() {
    const { element, nameValue, propsValue } = this;
    if (!nameValue) throw "Component was not given";
    renderComponent(element, nameValue, JSON.parse(propsValue || "{}"));
  }
}
