// https://leafletjs.com/examples/geojson/
// https://leafletjs.com/examples/quick-start/
// https://account.mapbox.com/
// https://github.com/domoritz/leaflet-maskcanvas

import 'leaflet'
import 'leaflet-maskcanvas'
import { getColor } from '../utils/color'
import { renderBaseMap, choroplethLegend } from '../../controllers/maps/utils'
import { gaMapActions, sendGAEvent } from '../utils/google_analytics'
import defaultsDeep from 'lodash/defaultsDeep'
import { addTrailingZeros } from '../utils/number'

export default class Choropleth {
  constructor(props) {
    this.props = defaultsDeep(props, {
      renderLegend: true,
      addTooltip: false,
      callbacks: {
        mouseover: () => {return},
        mouseout: () => {return},
      }
    })
    this.accessToken = props.accessToken
    this.htmlId = props.htmlId
    this.identifier = props.identifier
    this.locationSlug = props.locationSlug
    this.locationGeoJson = props.locationGeoJson
    this.year = props.year
    this.date = new Date(props.date) // Date in UTC timezone
    this.humanDate = props.year || this.date.toLocaleDateString("en-US", { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC' })
    this.variant = props.variant || 'default'
    this.data = props.data
    this.colorCalibration = props.colorCalibration || {colors: [], low: 0, high: 100}
    this.legendHtml = props.legendHtml
    this.unitsAffix = props.unitsAffix || '%'
  }

  buildMap() {
    const { polygons } = this.data
    this.map = renderBaseMap(document.getElementById(this.htmlId), this.props.accessToken, {
      zoomControl: true,
      doubleClickZoom: true,
      touchZoom: true,
      scrollWheelZoom: false,
      dragging: !L.Browser.mobile,
    })
    this.geoJson = L.geoJSON(polygons, {
      style: this.getStyle.bind(this),
      onEachFeature: this.onEachFeature.bind(this)
    }).addTo(this.map)
    this.layerIds = {}
    this.geoJson.getLayers().forEach((l) => {
      this.layerIds[l.feature.properties.locationName] = l._leaflet_id
    })
    this.locationLayer = L.geoJSON(this.locationGeoJson, {
      interactive: false,
      style: {
        weight: 0,
        color: '#000000',
        fillOpacity: 0,
      },
    })
    this.map.fitBounds(this.locationLayer.getBounds())
    this.locationLayer.addTo(this.map)
    this.addInfoBox(this)
    this.addLegend(this)
  }

  // Update geoJSON of map
  // expects a data object that contains `polygons`
  updateData(data) {
    if (this.geoJson) {
      this.map.removeLayer(this.geoJson)
    }
    const { identifier, unitsAffix, polygons } = data
    this.identifier = identifier
    this.unitsAffix = unitsAffix
    this.geoJson = L.geoJSON(polygons, {
      style: this.getStyle.bind(this),
      onEachFeature: this.onEachFeature.bind(this)
    }).addTo(this.map)
  }

  addInfoBox(choropleth) {
    choropleth.info = L.control();

    const humanDate = choropleth.humanDate
    choropleth.info.onAdd = function() {
      this._div = L.DomUtil.create('div', 'c-map-info')
      this.update({ humanDate: humanDate })
      return this._div
    };

    choropleth.info.update = function(props) {
      if (props.locationName) {
        let values = []
        let date = ""
        let unit = ""
        if (props.values && props.values.length) {
          values = props.values.map((value) => {
            const label = value.label ? `${value.label}: ` : ''
            return `
              <span class="c-map-info__value ${value.inline ? 'd-inline' : ''}">
                ${label}<strong>${addTrailingZeros(value.valuePresented) || addTrailingZeros(value.value) || 'Data not reported'}</strong>
              </span>
            `
          })
        }
        if (props.humanDate) {
          date = `<h4 class="c-map-info__date">${props.humanDate}</h4>`
        }
        if (props.unit) {
          unit = `<h5 class="c-map-info__unit">${props.unit}</h5>`
        }
        this._div.innerHTML = `
          <div class="c-map-info__value-wrapper">
            ${date}
            <a href="/${props.path}">
              <span class="c-map-info__location">${props.locationName || ''}</span>
            </a>
            ${ values.length ? values.join(' ') : '' }
            ${ unit }
          </div>
        `
      } else {
        const action = L.Browser.mobile ? 'Tap on' : 'Hover over'
        this._div.innerHTML = `
          <div class="c-map-info__value-wrapper">
            <span class="c-map-info__title">${action} a region to see more info</span>
          </div>
        `
      }
    }

    choropleth.info.addTo(choropleth.map)
  }

  addLegend(choropleth) {
    const { map, colorCalibration } = choropleth
    const legend = choroplethLegend(colorCalibration, this.unitsAffix)
    if (legend) {
      legend.addTo(map)
    }
  }

  onEachFeature(feature, layer) {
    if (this.props.addTooltip) {
      const marker = L.marker(
        feature.properties.centroid,
        {title: feature.properties.tooltip}
      ).addTo(this.map)
      marker.bindTooltip(feature.properties.tooltip).openTooltip()
    }

    layer.on({
      click: this.handleClick.bind(this),
      mouseover: this.handleMouseover.bind(this),
      mouseout: this.resetHighlight.bind(this),
    })
  }

  getStyle(feature) {
    const { values, color=null } = feature.properties
    const { value=null } = values && values.first() || {}
    return {
      fillColor: color || this.getColor(value),
      weight: 1,
      opacity: 1,
      color: this.colorCalibration.borderColor,
      dashArray: '',
      fillOpacity: value === null ? 0 : 1
    }
  }

  getColor(d) {
    if (d === null) return '#CCCCCC'
    return getColor(this.colorCalibration, d)
  }

  addLocationMask() {
    if (this.locationGeoJson && L.TileLayer.maskCanvas) {
      // https://github.com/domoritz/leaflet-maskcanvas
      const mask = L.TileLayer.maskCanvas({
        color: '#f9f5f0',  // the color of the layer
        opacity: 0.75,  // opacity of the not covered area
        noMask: false,  // true results in normal (filled) circled, instead masked circles
        lineColor: this.colorCalibration.colors.last()   // color of the circle outline if noMask is true
      })
      mask.setData(this.locationGeoJson.coordinates)
      this.map.addLayer(mask)
    }
  }

  handleClick(e, _layer=null) {
    const layer = !e ? _layer : e.target
    const { properties } = layer.feature
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: this.gaCategory(),
      action: gaMapActions.polygonClick,
      label: properties.locationName
    }})
    if (L.Browser.mobile) {
      this.highlightFeature(layer)
    } else if (this.props.callbacks && this.props.callbacks.click) {
      this.callback('click', layer.feature.properties)
    } else {
      this.goToLocation(properties)
    }
  }

  handleMouseover(e, _layer=null) {
    const layer = !e ? _layer : e.target
    const { properties } = layer.feature
    this.highlightFeature(layer)
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: this.gaCategory(),
      action: gaMapActions.polygonClick,
      label: properties.locationName
    }})
  }

  goToLocation(props) {
    if (props.path)
      window.top.location.href = props.path
  }

  highlightFeature(layer) {
    this.highlightedFeature && this.resetHighlight(null, this.highlightedFeature)
    if (!layer) return
    layer.setStyle({
      weight: 2,
      color: '#8B0505',
      fillOpacity: 1
    })
    const { properties } = layer.feature
    this.info.update(properties)
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront()
    }
    this.highlightedFeature = layer
    this.callback('mouseover', properties)
  }

  resetHighlight(e, feature=null) {
    this.geoJson.resetStyle(e ? e.target : feature)
    this.locationLayer.resetStyle()
    this.locationLayer.bringToFront()
    this.info && this.info.update({humanDate: this.humanDate})
    this.callback('mouseout', {})
  }

  gaCategory() {
    let uiLocation = ''
    if (this.props.uiLocation) {
      uiLocation = `[${this.props.uiLocation}]`
    }
    return `Maps ${uiLocation}`.trim()
  }

  callback(name, properties) {
    if (this.props.callbacks[name]) {
      this.props.callbacks[name](properties)
    }
  }
}
