import { querySelectorAll } from "./utils/polyfills";
import { scrollOptions } from "./_init/ui/config";

export const initScrollLinks = (root = null) => {
  let headerHeight = 0;
  const { duration, padding } = scrollOptions;
  const header =
    document.getElementById("mhc-header") ||
    document.querySelector(".o-topic__header") ||
    document.querySelector(".c-page-navbar");
  if (header) {
    headerHeight = header.clientHeight;
  }
  querySelectorAll(".j-smooth-scroll", root).forEach((link) => {
    link.addEventListener("click", (event) => {
      const $linkContent = $(link.hash);
      if ($linkContent.length) {
        event.preventDefault();
        event.currentTarget.blur();
        $("html, body").animate(
          {
            scrollTop: $linkContent.position().top - headerHeight - padding
          },
          duration
        );
      }
    });
  });
};

export const scrollAnchorIntoView = () => {
  if (window.location.hash) {
    const anchoredEl = document.getElementById(window.location.hash.substring(1));
    if (!anchoredEl) return;
    let offset = 0;
    const stuckHeader = document.querySelector('[data-controller="sticky-element"]');
    if (stuckHeader) {
      offset += stuckHeader.clientHeight;
    }
    const top = anchoredEl.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scroll({ top, behavior: "smooth" });
  }
};
