import React, { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";

import { Credit } from "./types";
import { linkStyle } from "./styles/typography";

type InlineCreditProps = {
  insideContent: Credit;
  children?: ComponentChildren;
  linkVariant: "primary" | "light" | "dark";
  credit: Credit;
};

const useStyles = createUseStyles({
  popoverWrapper: {
    zIndex: "1000",
    borderRadius: 3
  },
  popoverLink: {
    display: "inline",
    cursor: "pointer",
    textDecoration: "underline"
  },
  "popoverLink--primary": {
    color: "#1576D7",
    "&:hover, &:focus": {
      color: "#2669AA"
    }
  },
  "popoverLink--light": {
    color: "white",
    "&:hover, &:focus": {
      color: "rgba(255,255,255,.75)"
    }
  },
  "popoverLink--dark": {
    color: "black",
    "&:hover, &:focus": {
      color: "rgba(0,0,0,.75)"
    }
  },
  popoverArrow: {},
  popoverContent: {
    color: "black",
    background: "rgba(250,250,250,.95)",
    padding: 15,
    boxShadow: "0 5px 14px 2px rgb(0 0 0 / 10%)",
    fontSize: 16,
    lineHeight: "20px",
    fontFamily: "open sans",
    maxWidth: "90vw",
    "& a": linkStyle,
    [breakpoints.up("sm")]: {
      maxWidth: "60vw"
    },
    [breakpoints.up("lg")]: {
      maxWidth: "40vw"
    }
  },
  popoverLinkToInstance: {
    display: "block",
    marginTop: 15,
    textAlign: "right"
  }
});

const InlineCredit: React.FC<InlineCreditProps> = ({
  credit,
  children,
  linkVariant = "primary"
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const classes = useStyles();
  const popoverLinkClass = [classes.popoverLink, classes[`popoverLink--${linkVariant}`]];
  return (
    <Popover
      isOpen={isPopoverOpen}
      containerClassName={classes.popoverWrapper}
      onClickOutside={() => setIsPopoverOpen(false)}
      reposition={true}
      boundaryInset={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"rgba(250,250,250,.95)"}
          arrowSize={6}
          arrowStyle={{ opacity: 0.95 }}
          arrowClassName={classes.popoverArrow}
        >
          <div className={classes.popoverContent}>
            {credit.text}
            <a href="#footnotes" className={classes.popoverLinkToInstance}>
              View in footnotes
            </a>
          </div>
        </ArrowContainer>
      )}
    >
      <span className={popoverLinkClass.join(" ")} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        {children || (credit.superscript && <sup>{credit.superscript}</sup>)}
      </span>
    </Popover>
  );
};

export default InlineCredit;
