import { Controller } from "stimulus";

/*
To make remote-loaded views more cachable, the link targets are
adjusted to be relative to the current window path
*/

export default class extends Controller {
  static values = { slug: String };
  declare slugValue: string;

  connect() {
    const { element, slugValue } = this;
    const newUrl = window.location.pathname.replace(
      /^\/locations\/[^/]+/,
      `/locations/${slugValue}`
    );
    (element as HTMLAnchorElement).href = newUrl;
  }
}
