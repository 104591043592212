import React from "react";
import { stateSvg } from "javascript/svgs";
import { uniqeId } from "javascript/utils";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  loadingAnimation: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    transition: ".45s opacity ease-in-out",
    zIndex: 100,
    pointerEvents: "none"
  },
  loadingAnimationIndicator: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "rem(14px)",
    fontWeight: "bold",
    zIndex: 120,
    color: "blue",
    "& svg": {
      height: 100,
      marginBottom: 10
    },
    "& span": {
      marginLeft: 10,
      textAlign: "center"
    }
  },
  loadingAnimationBg: {
    width: "100%",
    height: "100%",
    animation: "loading",
    animationDuration: "3s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out"
  }
});

type LoadingAnimationProps = {
  height?: number;
};

const LoadingAnimation = ({ height = 200 }: LoadingAnimationProps) => {
  const classes = useStyles();
  const clipPathId = `loading-indicator-clip-path_${uniqeId()}`;
  const colors = {
    bg: "#A0DEE7",
    active: "#1E90FF"
  };
  return (
    <div className={classes.loadingAnimation}>
      <div className={classes.loadingAnimationIndicator}>
        <svg style={{ height }} viewBox="0 0 14 32">
          <clipPath id={clipPathId}>
            <rect className={classes.loadingAnimationBg} />
          </clipPath>
          {stateSvg(colors.bg)}
          {stateSvg(colors.active, `url(#${clipPathId})`)}
        </svg>
      </div>
    </div>
  );
};

export default LoadingAnimation;
