import React, { Component } from "react";
import TimeSeriesChart from "./TimeSeriesChart";
import InvestigationChart from "./InvestigationChart";
import TopicContextFilters from "./TopicContextFilters";
import MoreBelow from "../MoreBelow";
import LoadingAnimation from "../LoadingAnimation";
import { getMaxMinDatesOfSets } from "../utils/date";
import { fetch } from "whatwg-fetch";
import isEmpty from "lodash/isEmpty";

export default class extends Component {
  constructor(props) {
    super(props);

    this.options = Object.assign(
      {
        showInvestigationChart: false
      },
      props.options || {}
    );

    const { sets = {} } = props;
    const { geographic_comparison = [] } = sets;
    this.state = {
      dates: getMaxMinDatesOfSets((sets && sets.primary) || sets),
      primary: sets && sets.primary ? sets.primary[0] : {},
      geographicComparisonChoices: geographic_comparison,
      geographicComparison: geographic_comparison.length ? geographic_comparison[0] : {},
      investigationIdentifier: null,
      comparison: null,
      loading: false,
      error: false,
      showConfidence: false
    };
    this.hasConfidenceData = this.hasConfidenceData.bind(this);
  }

  componentDidMount() {
    const { sets = {}, geographicComparison } = this.props;
    const geographicComparisonSet = sets.geographic_comparison;
    // TODO: Establish a better way to determine if there is a geographic comparison
    if (
      geographicComparisonSet &&
      !geographicComparisonSet.length &&
      geographicComparison &&
      geographicComparison.id
    ) {
      this.fetchComparisonData(geographicComparison.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    // Update geogrpahic comparison data if
    if (
      this.props.geographicComparison &&
      this.props.geographicComparison.id !== nextProps.geographicComparison.id
    ) {
      this.fetchComparisonData(nextProps.geographicComparison.id);
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (!this.state.comparison && nextState.comparison) {
      this.handleClearInvestigation();
    }
  }

  updateComparisonData(data) {
    let error = "";
    if (typeof data !== "object") {
      data = [];
      error = data;
    }
    this.props.sets.geographic_comparison = data;
    // Delay state change in case response is quick so user knows there was an
    // update
    setTimeout(() => {
      this.setState({
        geographicComparison: data.find((d) => d.identifier === this.state.primary.identifier),
        geographicComparisonChoices: data,
        loading: false,
        error
      });
    }, 500);
  }

  fetchComparisonData(geographicComparisonId) {
    const statIdentifiers = this.props.sets.primary.map((s) => s.identifier).join(",");
    this.setState({ loading: true });
    fetch(`/locations/${geographicComparisonId}/stats.json?stat_identifiers=${statIdentifiers}`)
      .then((response) => response.json())
      .then(this.updateComparisonData.bind(this))
      .catch((error) => {
        console.error(error);
        this.updateComparisonData.bind(this);
      });
  }

  handleFilterChange(filters, filter) {
    const newState = {};
    newState[filter] = filters;
    if (filter === "comparison")
      newState.investigationIdentifier = filters ? filters.identifier : "";
    this.setState(newState);
  }

  handleClearInvestigation() {
    document.dispatchEvent(new CustomEvent("chart:clear-investigation"));
  }

  getFilters() {
    const props = this.props;
    const { sets = {}, primarySetSelection } = props;
    const { comparison } = sets;
    // Build comparison choices in case some are depednent on the selected
    // primary data set if there is no `associated_primary_identifier`
    // always include the choice
    let comparisonChoices = [];
    const selectedPrimary = this.state.primary;
    const primaryHasComparison = (choice) => {
      return choice.identifier.includes(selectedPrimary.identifier);
    };
    if (comparison && comparison.length) {
      comparisonChoices = comparison.filter((choice) => {
        if (choice.associated_primary_identifier)
          return choice.identifier && primaryHasComparison(choice);
        return true;
      });
    }
    return {
      dateFormat: props.startYear ? "year" : null,
      dates: this.state.dates,
      primaryChoices: primarySetSelection ? sets.primary : [],
      geographicComparisonChoices: this.state.geographicComparisonChoices,
      comparisonChoices,
      handleFilterChange: this.handleFilterChange.bind(this),
      showConfidence: this.state.showConfidence,
      hasConfidenceData: this.hasConfidenceData(),
      handleConfidenceToggle: this.handleConfidenceToggle.bind(this)
    };
  }

  /**
   * getProps - Prepare props for individual charts
   *
   * @return {Object}  Props
   */
  getProps() {
    const {
      dates,
      primary,
      comparison,
      geographicComparison,
      investigationIdentifier,
      showConfidence
    } = this.state;

    return {
      dates,
      primary,
      geographicComparison,
      investigationIdentifier,
      comparison,
      showConfidence,
      data: this.props
    };
  }

  comparisonEnabled() {
    return false;
  }

  showInvestigationChart() {
    return;
  }

  handleConfidenceToggle() {
    this.setState({ showConfidence: !this.state.showConfidence });
  }

  hasConfidenceData() {
    // Get primary data set from state if available
    let { primary = {} } = this.state;
    const { sets = [] } = this.props;
    // If no dataset in state check the sets in the props
    // The sets of DetailedContaminats are not store in the charts' state (primary prop)
    if (!Object.keys(primary).length) primary = sets[0] || {};
    // If a primary dataset exists check for the confidence info and return true if both
    // lower and upper props have values
    if (primary) {
      const info = primary.confidence_info;
      if (!info || isEmpty(info)) return false;
      const { upper_confidence, lower_confidence } = info[Object.keys(info).first()];
      return upper_confidence && lower_confidence;
    }
    return false;
  }

  render() {
    const { title } = this.props;
    const { primary = {}, loading = false } = this.state;
    const showInvestigationChart = this.showInvestigationChart();
    const { description } = primary;

    return (
      <div className="mb-6" aria-label="Charts">
        <div className="c-dashboard-title c-dashboard-title--h2 c-dashboard-title--line mb-4">
          <h2>Charts</h2>
          {title && <span>({title})</span>}
        </div>
        <div className="card">
          {loading && <LoadingAnimation veil={true} />}
          <TopicContextFilters {...this.getFilters()} />
          {description && <p className="o-topic-context__description">{description}</p>}
          <TimeSeriesChart {...this.getProps()} />
          {showInvestigationChart && <MoreBelow show={true} hideDelay={5000} />}
          {showInvestigationChart && (
            <div ref={(el) => (this.investigationChart = el)}>
              <InvestigationChart {...this.getProps()} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
