import React from "react";
import { createRoot } from "react-dom/client";

import chromeIcon from "../../images/browsers/chrome.svg";
import edgeIcon from "../../images/browsers/edge.svg";
import firefoxIcon from "../../images/browsers/firefox.svg";
import safariIcon from "../../images/browsers/safari.svg";

export const deviceIsMobile = () => {
  let isMobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      isMobile = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return isMobile;
};

import { detect } from "detect-browser";

export const browserIsOld = () => {
  return browserIs("ie");
};

export const deviceIsIpad = () => {
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
  return (
    navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2
  );
};

export const browserIsMobileSafari = () => {
  return browserIs("ios") || deviceIsIpad();
};

export const browserIs = (browserId = "") => {
  const detectedBrowser = detect();
  if (detectedBrowser && detectedBrowser.name) {
    return detectedBrowser.name === browserId;
  } else {
    false;
  }
};

const browsers = {
  chrome: {
    url: "https://www.google.com/chrome/",
    icon: chromeIcon
  },
  firefox: {
    url: "https://www.mozilla.org/en-US/firefox/new/",
    icon: firefoxIcon
  },
  edge: {
    url: "https://www.microsoft.com/en-us/edge",
    icon: edgeIcon
  },
  safari: {
    url: "https://support.apple.com/downloads/safari",
    icon: safariIcon
  }
};

const BrowserBanner = () => {
  const browsersElements = Object.keys(browsers).map((b) => (
    <div className="ml-1">
      <a
        title={b}
        target="_blank"
        className="browser-link"
        href={browsers[b] ? browsers[b].url : "#"}
        rel="noreferrer"
      >
        <img height="30" alt={b} src={browsers[b] ? browsers[b].icon : ""} />
      </a>
    </div>
  ));

  return (
    <div className="c-app-alert c-app-alert--warning d-flex align-items-end flex-wrap justify-content-between">
      <div className="mr-4 my-1">
        <div className="c-app-alert__title mb-0">
          My Healthy Community is not fully supported in your web browser
        </div>
        <span>
          You may notice visual and performance issues while viewing pages with this browser.
        </span>
      </div>
      <div className="d-flex align-items-center my-1 flex-wrap pl-4">
        <a target="_blank" className="mr-4" href="https://browser-update.org/" rel="noreferrer">
          <span>Check your browser version here</span>
          <i className="fas fa-external-link-alt ml-1"></i>
        </a>
        <div className="d-flex align-items-center">
          <span className="mr-2">Download a new browser:</span>
          {browsersElements.map((b) => b)}
        </div>
      </div>
    </div>
  );
};

export const checkBrowserCompatibility = () => {
  if (browserIsOld()) {
    const div = document.createElement("DIV");
    const header = document.getElementById("mhc-nav");
    if (header) {
      header.parentNode.insertBefore(div, header);
    } else {
      document.body.prepend(div);
    }

    const root = createRoot(div);
    root.render(<BrowserBanner />);
  }
};
