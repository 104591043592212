import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Scene from "../Scene/Scene";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import StoryImage from "../StoryImage";
import UpTickingNumbers from "../UpTickingNumbers";
import breakpoints from "../../styles/breakpoints";
import { imageDictionary } from "./config";
import { getInlineCredit } from "./copy.tsx";
import { linkStyle, leadText } from "../styles/typography";

import { StepData } from "../types";

const useStyles = createUseStyles({
  poultryTextWrapper: {
    transition: ".2s background ease-in-out",
    "& > div": {
      padding: 0
    },
    "& p": {
      background: "none",
      color: "white",
      paddingLeft: "5vw",
      paddingRight: "5vw"
    },
    "& a": linkStyle
  },
  poultryNumbersWrapper: {
    display: "flex",
    minHeight: "100vh",
    fallbacks: {
      minHeight: "-webkit-fill-available"
    },
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    transition: ".2s ease-in-out background"
  },
  poultryStats: {
    transition: ".3s ease-in-out opacity",
    transitionDelay: ".05s",
    opacity: 0,
    zIndex: 10
  },
  poultryImage: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    margin: 0,
    transition: ".15s ease-in-out transform",
    "& img": {
      opacity: 1,
      transition: ".3s ease-in-out opacity",
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  },
  inBackground: {
    "& img": {
      opacity: 0.4
    }
  },
  shifted: {
    [breakpoints.up("md")]: {
      transform: "translateX(-50%)"
    }
  },
  poultrySupportingText: {
    background: "rgba(0,0,0,.85) !important",
    padding: [20, "5vw"]
  },
  floatingText: {
    zIndex: 20,
    position: "relative",
    textAlign: "center",
    "& span": {
      fontWeight: "normal"
    },
    "& span, & strong": {
      color: "white",
      ...leadText(1)
    },
    [breakpoints.up("md")]: {
      transform: "translateX(50%)"
    }
  }
});

const boilerCredit = getInlineCredit("poultry-figures");
const Poultry = () => {
  const classes = useStyles();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const onStepEnter = ({ data }: StepData) => setCurrentStepIndex(data);
  const imageStyle = {};
  const imageClassname = [classes.poultryImage];
  const text = [
    "filler-half",
    <p className={classes.floatingText}>
      <span style={{ display: "block", marginBottom: 10 }}>Another factor:</span>
      <strong>poultry processing facilities.</strong>
    </p>,
    <p className={classes.poultrySupportingText}>
      Delaware is home to some of the largest broiler chicken facilities on the Eastern Seaboard.
      {boilerCredit}
    </p>,
    "filler-half"
  ];

  if (currentStepIndex > 0) {
    imageClassname.push(classes.inBackground);
  }
  if (currentStepIndex < 2) {
    imageClassname.push(classes.shifted);
  }
  const populationNumbers = [
    {
      title: "Delaware Human Population",
      end: 957248
    },
    {
      title: "Chicken Population",
      end: 270000000
    }
  ];
  const economicNumbers = [
    {
      title: "Plant Jobs",
      end: 9000,
      suffix: "+"
    },
    {
      title: "State Economic Impact",
      end: 3600000000,
      prefix: "$"
    }
  ];
  const { chicken } = imageDictionary;
  return (
    <Scene reverseContent background="black">
      <SceneFeaturedContent>
        <div className={classes.poultryNumbersWrapper}>
          <StoryImage image={chicken} className={imageClassname.join(" ")} style={imageStyle} />
          <div className={classes.poultryStats} style={{ opacity: currentStepIndex >= 2 ? 1 : 0 }}>
            {currentStepIndex >= 2 && (
              <div style={{ marginBottom: 100 }}>
                <UpTickingNumbers numbers={populationNumbers} />
              </div>
            )}
            {currentStepIndex >= 2 && <UpTickingNumbers numbers={economicNumbers} />}
          </div>
        </div>
      </SceneFeaturedContent>
      <SceneScrollContainer
        acts={text}
        onStepEnter={onStepEnter}
        className={classes.poultryTextWrapper}
      />
    </Scene>
  );
};

export default Poultry;
