import Highcharts from "highcharts/highstock";
import { Controller } from "stimulus";

const OCCUPATIONS = {
  suicide: [
    ["Construction and Extraction", 19.1, "#1E90FF"],
    ["Installation, Maintenance, and Repair", 9.0, "#E5B303"],
    ["Sales and Related", 9.0, "#d6d6d6"],
    ["Transportation and Material Moving", 9.0, "#d6d6d6"],
    ["Office and Administrative Support", 7.9, "#d6d6d6"],
    ["Management", 5.6, "#d6d6d6"],
    ["Production", 5.6, "#d6d6d6"],
    ["Business and Financial Operations", 4.5, "#d6d6d6"],
    ["Food Preparation and Serving Related", 3.4, "#d6d6d6"],
    ["Military", 2.2, "#d6d6d6"],
    ["Building and Grounds Cleaning and Maintenance", 2.2, "#d6d6d6"],
    ["Personal Care and Service", 2.2, "#d6d6d6"],
    ["Protective Service", 2.2, "#d6d6d6"],
    ["Farming, Fishing, and Forestry", 2.2, "#d6d6d6"],
    ["Arts, Design, Entertainment, Sports, and Media", 2.2, "#d6d6d6"],
    ["Computer and Mathematical", 2.2, "#d6d6d6"],
    ["Healthcare", 2.2, "#d6d6d6"],
    ["All other", 9.0, "#d6d6d6"]
  ],
  population: [
    ["Management Occupations", 4.2, "#d6d6d6"],
    ["Business and Financial Operations Occupations", 6.3, "#d6d6d6"],
    ["Computer and Mathematical Occupations", 3.6, "#d6d6d6"],
    ["Architecture and Engineering Occupations", 1.3, "#d6d6d6"],
    ["Life, Physical, and Social Science Occupations", 1.3, "#d6d6d6"],
    ["Community and Social Service Occupations", 1.5, "#d6d6d6"],
    ["Legal Occupations", 1.1, "#d6d6d6"],
    ["Education, Training, and Library Occupations", 5.9, "#d6d6d6"],
    ["Arts, Design, Entertainment, Sports, and Media Occupations", 0.9, "#d6d6d6"],
    ["Healthcare Practitioners and Technical Occupations", 6.8, "#d6d6d6"],
    ["Healthcare Support Occupations", 2.9, "#d6d6d6"],
    ["Protective Service Occupations", 2.3, "#d6d6d6"],
    ["Food Preparation and Serving Related Occupations", 9.4, "#d6d6d6"],
    ["Building and Grounds Cleaning and Maintenance Occupations", 3.5, "#d6d6d6"],
    ["Personal Care and Service Occupations", 3.2, "#d6d6d6"],
    ["Sales and Related Occupations", 10.8, "#d6d6d6"],
    ["Office and Administrative Support Occupations", 16.5, "#d6d6d6"],
    ["Farming, Fishing, and Forestry Occupations", 0.2, "#d6d6d6"],
    ["Construction and Extraction Occupations", 3.5, "#1E90FF"],
    ["Installation, Maintenance, and Repair Occupations", 3.8, "#E5B303"],
    ["Production Occupations", 4.4, "#d6d6d6"],
    ["Transportation and Material Moving Occupations", 6.6, "#d6d6d6"]
  ]
};

export default class extends Controller {
  // This component is tied to the Ruby ViewComponent Charts::DonutComponent
  // so it follows a different pattern than other controllers here.

  static values = { categories: Array, colors: Array, innerSize: String };

  connect() {
    const chartId = this.element.id;
    const seriesStyle = {
      textOverflow: "none",
      whiteSpace: "nowrap",
      color: "#000000",
      fontWeight: "bold",
      background: "#f9f9f9",
      padding: "0",
      fontSize: "12px",
      crop: false,
      overflow: "allow"
    };
    const series = [
      OCCUPATIONS[chartId]
        .sort((a, b) => b[1] - a[1])
        .map(([label, value, color]) => {
          const enabled = label.match(/Construction|Installation/);
          return {
            label,
            value,
            color,
            dataLabels: {
              allowOverlap: true,
              crop: false,
              overflow: "allow",
              style: enabled
                ? seriesStyle
                : {
                    ...seriesStyle,
                    color: "#999999",
                    strokeWidth: 0,
                    fontWeight: "normal",
                    connectorColor: "#333333"
                  },
              enabled,
              format:
                '<span style="font-size: 115%; display: block">{y}%</span><span> {point.name}</span>',
              useHTML: true
            }
          };
        })
    ];
    Highcharts.chart({
      chart: {
        type: "pie",
        renderTo: this.element,
        backgroundColor: "transparent",
        margin: [10, 10, 10, 10]
      },
      title: { text: null },
      accessibility: {
        point: {
          valueSuffix: "%"
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          allowOverlap: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true
          },
          size: "50%",
          center: [chartId === "suicide" ? "25%" : "65%", "40%"]
        }
      },
      series: series.map((data) => {
        return {
          colorByPoint: true,
          name: this.title,
          innerSize: "65%",
          data: data.map((cat, i) => {
            return {
              name: cat.label,
              y: cat.value,
              color: cat.color || this.colorsValue[i],
              selected: cat.selected,
              dataLabels: cat.dataLabels
            };
          })
        };
      }),
      credits: {
        enabled: false
      }
    });
  }
}
