import { uniq } from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.labels = [];
    this.collectActiveLabels = this.collectActiveLabels.bind(this);
    this.select = this.element.querySelector(".dependent-selector");
    if (this.select) {
      $(this.select).on("change", this.collectActiveLabels);
      this.collectActiveLabels();
    }
  }

  collectActiveLabels() {
    this.labels.forEach((label) => {
      const conditionalElements = this.element.querySelectorAll(`.conditional-content-${label}`);
      conditionalElements.forEach((el) => {
        el.classList.remove("d-block");
        el.classList.add("d-none");
      });
    });
    const selected = [...this.select.querySelectorAll("option:checked")];
    const labels = uniq(
      selected.map((s) => {
        const parent = s.parentElement;
        return parent?.getAttribute("label");
      })
    );
    this.labels = labels;
    labels.forEach((label) => {
      const conditionalElements = this.element.querySelectorAll(`.conditional-content-${label}`);
      conditionalElements.forEach((el) => {
        el.classList.remove("d-none");
        el.classList.add("d-block");
      });
    });
  }
}
