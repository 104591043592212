import { Controller } from "stimulus";
import consumer from "../javascript/admin/channels/consumer";
import CableReady from "cable_ready";
import uuid from "uuid/v4";
import cloneDeep from "lodash/cloneDeep";

interface Operation {
  message: string;
  element: HTMLElement;
}
interface Operations {
  alert: Operation[];
  consoleLog: Operation[];
}

interface ReceivedData {
  cableReady: object;
  operations: Operations;
}

interface ResponseBody {
  render_id: string;
}

export default class extends Controller {
  static values = { renderId: String, url: String, fetchParams: Object };
  declare renderIdValue: string;
  declare subscription: object;
  declare urlValue: string;
  declare fetchParamsValue: ResponseBody;

  connect() {
    // set a unique id for this render
    this.renderIdValue = uuid();

    // subscribe to the channel and watch for updates from action_cable
    this.subscription = consumer.subscriptions.create(
      { channel: "BackgroundRenderChannel", id: this.renderIdValue },
      {
        connected: () => {
          this.fetch();
        },
        received: (data: ReceivedData) => {
          CableReady.perform(data.operations);
        },
      }
    );
  }

  fetch() {
    // send an ajax request to kick off the render job
    let csrfToken: string | null = "";
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
    if (csrfTokenElement) {
      csrfToken = csrfTokenElement.getAttribute("content") as string;
    }

    const body = cloneDeep(this.fetchParamsValue);
    body.render_id = this.renderIdValue;

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).catch(() => {
      window.alert(
        "Sorry, an error occurred while loading page content. Please refresh the page and try again."
      );
    });
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }
}
