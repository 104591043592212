import React, { Component } from "react";
import classnames from "classnames";

export default class SvgSymbol extends Component {
  constructor(props) {
    super(props);
  }

  dimensions() {
    let dimensions = {};
    if (!this.props.dimensions) return dimensions;
    const { height, width } = this.props.dimensions;
    if (height) {
      dimensions.height = `${height}px`;
    }
    if (width) {
      dimensions.width = `${width}px`;
    }
    return dimensions;
  }

  render() {
    const { name, color = "white", alt, style, classNames } = this.props;
    const _classNames = classnames(`fill--${color}`, classNames);
    return (
      <span className="icon-svg" style={{ ...this.dimensions(), ...style }}>
        <svg
          className={_classNames}
          style={{ ...this.dimensions(), ...style }}
          alt={alt ? alt : ""}
        >
          <use xlinkHref={`#icon-${name}`}></use>
        </svg>
      </span>
    );
  }
}
