import { Application } from "stimulus"
import { ContextSwitcherController, LeafletMapController } from '../types'
import { sendGAEvent, googleEvent, gaMapActions, GaEventType } from  '../../javascript/utils/google_analytics'

type contextSwitcherConfig = {
  layoutId: string;
  mapId: string;
  accordionId: string;
}

type SwitcherGaEvent = googleEvent & { category?: string }

interface ContextOptions {
  parentController?: string;
}

export const contextSwitcherConfig:Record<string, string> = {
  base: 'context-switcher',
  layout: 'layout',
  'location-selector': 'location-selector',
  map: 'map',
  accordion: 'accordion-list',
}

export const childComponentName = (childId:string):string => {
  return `${contextSwitcherConfig['base'] as string}--${contextSwitcherConfig[childId] as string}`
}

export const getMapController = (application:Application, siblingElement?:HTMLElement|null) => {
  const mapId = childComponentName('map')
  let mapEl
  if (siblingElement) {
    const parent = siblingElement.closest(".c-context-switcher__area")
    mapEl = parent?.querySelector('[data-controller="context-switcher--map"]')
  }
  const element = mapEl || document.getElementById(mapId)
  if (element) {
    return application.getControllerForElementAndIdentifier(
      element, mapId
    ) as LeafletMapController
  }
}

export const getContextController = (application:Application, options:ContextOptions) => {
  const { parentController = "layout" } = options || {}
  const layoutId = childComponentName(parentController || 'layout')
  const element = document.getElementById(layoutId)
  if (element) {
    return application.getControllerForElementAndIdentifier(
      element, layoutId
    ) as ContextSwitcherController
  }
}

export const gaEvent = ({
  eventType=null,
  category,
  action,
  ...parameters}:SwitcherGaEvent & {eventType: GaEventType|null}) => {
  const _eventType = eventType ||
    (action === "Switch" || action?.includes(gaMapActions.polygonClick) ? "navigation" : "user_interaction")
  sendGAEvent({eventType: _eventType, parameters: {
    category: 'Context Switcher',
    action,
    ...parameters
  }})
}
