import renderChart from "charts/renderChart";
import { colorConfig } from "charts/config";
import { SeriesOptionsType } from "highcharts";
import { Controller } from "stimulus";

type ChartConfig = {
  title: string;
  series: Array<{
    slug: string;
    name: string;
    data: number[];
  }>;
  startDate: [number, number, number];
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({ title, series, startDate: [year, month, day] }: ChartConfig) {
    const pointStart = Date.UTC(year, month - 1, day);
    const pointInterval = 24 * 3600 * 1000 * 7; // one week
    renderChart({
      title: {
        text: title
      },
      chart: {
        renderTo: this.element as HTMLElement,
        height: 500
      },
      yAxis: [
        {
          title: { text: "" },
          tickAmount: 6
        }
      ],
      xAxis: {
        type: "datetime",
        title: { text: "Week Ending Date" }
      },
      legend: {
        enabled: series.length > 1,
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 15,
        symbolWidth: 30,
        align: "left",
        x: 50,
        layout: "horizontal",
        useHTML: true,
        labelFormatter() {
          const { name } = this;
          return `
            <div class="highcharts-custom-legend">
              <p class='name'>${name}</p>
            </div>
          `;
        }
      },
      plotOptions: {
        column: {
          maxPointWidth: 0,
          groupPadding: 0.1
        }
      },
      rangeSelector: {
        enabled: true
      },
      series: series.map(
        ({ slug, data, name }): SeriesOptionsType => {
          switch (slug) {
            case "cli":
              return {
                type: "column",
                color: colorConfig.bars[0].fill,
                borderColor: colorConfig.bars[0].border,
                pointStart,
                pointInterval,
                data,
                name
              };
            case "ili":
              return {
                type: "column",
                color: colorConfig.bars[1].fill,
                borderColor: colorConfig.bars[1].border,
                pointStart,
                pointInterval,
                data,
                name
              };
            default:
              throw new Error(`unknown chart ${slug}`);
          }
        }
      )
    });
  }
}
