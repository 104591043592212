import React from "react";
import { createUseStyles } from "react-jss";
import AnimatedMap from "../Maps/AnimatedMap";
import mapFrame1 from "images/stories/vaccination/maps/map-1_1.jpg";
import mapFrame2 from "images/stories/vaccination/maps/map-1_2.jpg";
import mapFrame3 from "images/stories/vaccination/maps/map-1_3.jpg";
import SwatchLegend from "../Maps/SwatchLegend";
import { getCreditById } from "./copy";

import breakpoints from "../../styles/breakpoints";
import { AnimationFrame } from "../types";

const useStyles = createUseStyles({
  legend: {
    position: "absolute",
    right: 50,
    top: "20%",
    zIndex: 20,
    maxWidth: "40%",
    [breakpoints.up("md")]: {
      right: "5%",
      transform: "none",
      paddingRight: 50,
      maxWidth: "none"
    }
  }
});

const HispanicPopulationMap = () => {
  const deTitle = "Sussex County, Delaware";
  const title = (
    <p>
      Hispanic population by Census tract, <br />
      {deTitle}, 2019
    </p>
  );
  const textAndImages: AnimationFrame[] = [
    {
      title: deTitle,
      text: <p />,
      image: mapFrame1,
      altText: "Delaware with Sussex County highlighted"
    },
    {
      title: deTitle,
      text: (
        <p>
          The heart of the industry is the farmland of Sussex County, where processing plants from
          Milford to Georgetown to Selbyville can employ upwards of 1,000 people at a single
          facility.
        </p>
      ),
      image: mapFrame2,
      altText:
        "Delaware with Sussex County and cities where poultry processing plants can employ upwards of 1,000 people at a single facility highlighted"
    },
    {
      title,
      text: (
        <p>
          In recent decades, poultry plants have attracted significant numbers of Hispanic workers,
          including many immigrants from Guatemala and Mexico who settled in pockets of Sussex
          County.
        </p>
      ),
      image: mapFrame3,
      showLegend: true,
      altText: "Hispanic population by Census tract"
    },
    {
      title,
      text: "filler-1x",
      image: mapFrame3,
      showLegend: true,
      altText: "Hispanic population by Census tract"
    }
  ];

  const Legend: React.FC = () => {
    const classes = useStyles();
    const items = [
      { range: [0, 10], color: "#FFF5F0" },
      { range: [10, 20], color: "#FDCCB8" },
      { range: [20, 30], color: "#FC8F6F" },
      { range: [30, 40], color: "#F44D38" },
      { range: [40, 50], color: "#C5161C" },
      { range: [50], color: "#67000D" }
    ];
    return (
      <div className={classes.legend}>
        <SwatchLegend title="Percent Hispanic" items={items} suffix="%" openEnded />
      </div>
    );
  };

  return (
    <AnimatedMap
      Legend={Legend}
      textAndImages={textAndImages}
      title=""
      sourceShowIndices={[2, 3]}
      source={getCreditById("census-short", "sources").text}
    />
  );
};

export default HispanicPopulationMap;
