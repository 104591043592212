import React, { CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import { Scrollama, Step } from "react-scrollama";
import breakpoints from "../../styles/breakpoints";

import { leadText } from "../styles/typography";
import { Act, ActHeight } from "../types";

const useStyles = createUseStyles({
  scrollContainerWrapper: {
    flexBasis: "50%",
    zIndex: 150,
    width: "100%",
    position: "relative",
    transform: "translate3d(0,0,0)",
    [breakpoints.up("md")]: {
      width: "50%",
      position: "static"
    }
  },
  scrollContainerContent: {
    height: "100vh",
    fallbacks: {
      height: "-webkit-fill-available"
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 0,
    "& > p": {
      background: "rgba(255,255,255,.95)",
      padding: ["2.5vh", "8vw"],
      fontSize: 26,
      lineHeight: "34px",
      "& sup": {
        lineHeight: 1
      }
    },
    "& .lead": leadText(),
    "& .lead-2x": leadText(2),
    "& .centered": {
      textAlign: "center"
    },
    [breakpoints.up("md")]: {
      "& > p": {
        fontSize: 34,
        lineHeight: "44px",
        background: "none",
        padding: ["2.5vh", "5vw"]
      }
    }
  },
  "scrollContainerContent-filler-eighth": {
    height: "12.5vh !important"
  },
  "scrollContainerContent-filler-quarter": {
    height: "25vh !important"
  },
  "scrollContainerContent-filler-half": {
    height: "50vh !important"
  },
  "scrollContainerContent-filler-3-quarter": {
    height: "75vh !important"
  },
  "scrollContainerContent-filler-1x": {
    height: "100vh !important",
    fallbacks: {
      height: "-webkit-fill-available"
    }
  },
  "scrollContainerContent-filler-2x": {
    height: "200vh !important"
  },
  "scrollContainerContent-filler-no-padding": {
    padding: 0
  }
});

type SceneScrollContainerProps = {
  actClassName?: string;
  acts: Act[];
  className?: string;
  onStepEnter?: Function;
  onStepProgress?: Function;
  offSet?: number;
  style?: CSSProperties;
  height?: ActHeight;
};

const SceneScrollContainer = ({
  acts,
  onStepEnter,
  onStepProgress,
  offSet = 0.5,
  style = {},
  className = "",
  actClassName = ""
}: SceneScrollContainerProps) => {
  const classes = useStyles();
  const isFillerAct = (act: Act) => typeof act === "string" && act.includes("filler");
  const actClassNames = (act: Act | ActHeight) => {
    const classNames: string[] = [classes.scrollContainerContent];
    if (isFillerAct(act)) {
      classNames.push(classes[`scrollContainerContent-${act}`]);
    }
    return classNames.join(" ");
  };
  const renderedAct = (act: Act) => (isFillerAct(act) ? null : act);
  const wrapperClassName = [classes.scrollContainerWrapper, className];
  return (
    <div className={wrapperClassName.join(" ")} style={style}>
      <Scrollama onStepEnter={onStepEnter} onStepProgress={onStepProgress} offset={offSet}>
        {acts.map((act, stepIndex) => (
          <Step data={stepIndex} key={stepIndex}>
            <div className={[actClassNames(act), actClassName].join(" ")}>{renderedAct(act)}</div>
          </Step>
        ))}
      </Scrollama>
    </div>
  );
};

export default SceneScrollContainer;
