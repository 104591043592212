import React from "react";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";

import { Credit } from "./types";

const useStyles = createUseStyles({
  storyCreditsGroupWrapper: {
    marginBottom: 24
  },
  creditsWrapper: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    fontFamily: "open sans",
    columnCount: 1,
    columnGap: "5vw"
  },
  creditsWrapperColumnar: {
    columnCount: 1,
    columnGap: "5vw",
    [breakpoints.up("md")]: {
      columnCount: 2
    }
  },
  creditItem: {
    fontSize: 16,
    lineHeight: "20px",
    marginBottom: 16,
    display: "flex",
    "& span": {
      flex: "1 1 auto",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "block"
    },
    "& sup": {
      marginRight: 5
    }
  },
  creditNumber: {
    fontWeight: 700
  }
});

type StoryCreditsGroupProps = {
  credits: Credit[];
  title?: string;
};

const CreditItem: React.FC<Credit> = ({ text, source, superscript }) => {
  const classes = useStyles();
  return (
    <li className={classes.creditItem}>
      {superscript && <sup className={classes.creditNumber}>{superscript} </sup>}
      <span>
        {text}
        <span>
          {source && (
            <a href={source} target="_blank" rel="noreferrer">
              {source}
            </a>
          )}
        </span>
      </span>
    </li>
  );
};

const StoryCreditsGroup: React.FC<StoryCreditsGroupProps> = ({ credits, title }) => {
  const classes = useStyles();
  const classNames = [classes.creditsWrapper];
  if (credits.length > 1) {
    classNames.push(classes.creditsWrapperColumnar);
  }
  return (
    <section className={classes.storyCreditsGroupWrapper}>
      {title && <h2>{title}</h2>}
      <ul className={classNames.join(" ")}>
        {credits.map((credit, i) => (
          <CreditItem key={i} {...credit} />
        ))}
      </ul>
    </section>
  );
};

export default StoryCreditsGroup;
