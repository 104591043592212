import { Controller } from "stimulus";
import renderChart from "charts/renderChart";
import { valueFormatter, barTooltipOpts } from "charts/utils";
import { colorConfig } from "charts/config";

type ChartConfig = {
  title: string;
  yAxisTitleText: string;
  yAxisLabelFormat: string;
  categories: string[];
  min: number | null;
  series: Array<{
    slug: string;
    name: string;
    data: number | string[];
    borderColor: string;
    color: string;
  }>;
  startDate: [number, number, number];
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;
  declare colors: string[];

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({
    series,
    title,
    yAxisTitleText,
    yAxisLabelFormat = "{value}",
    min = null,
    categories
  }: ChartConfig) {
    const { element } = this;
    const colors = colorConfig.bars;
    renderChart({
      title: {
        text: title
      },
      colors: colors.map(({ fill }) => fill),
      chart: {
        renderTo: element as HTMLElement
      },
      legend: {
        enabled: series.length > 1,
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 15,
        symbolWidth: 30,
        align: "left",
        x: 50
      },
      yAxis: {
        title: {
          text: yAxisTitleText
        },
        labels: {
          format: yAxisLabelFormat
        }
      },
      xAxis: {
        categories
      },
      tooltip: barTooltipOpts(
        min,
        false,
        series.map(({ data }) => data)
      ),
      series: series.map(({ data, name }, i) => ({
        name,
        type: "bar",
        showInLegend: series.length > 1,
        data: data.map((y: number | string) => {
          const formatterOptions = valueFormatter(y, min);

          return {
            y: formatterOptions.value,
            borderColor: colors[i].border,
            dataLabels: {
              showInLegend: true,
              enabled: formatterOptions.showLabel,
              format: formatterOptions.label,
              color: colors[i].fill,
              style: {
                fontSize: 11
              }
            }
          };
        })
      }))
    });
  }
}
