import React, { useState } from "react";
import Scene from "../Scene/Scene";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import { createUseStyles } from "react-jss";
import { opacityForStep } from "../utils";
import { imageDictionary } from "./config";
import StoryImage from "../StoryImage";

import { Act } from "../types";

const useStyles = createUseStyles({
  introFigure: {
    minHeight: "100vh",
    fallbacks: {
      minHeight: "-webkit-fill-available"
    },
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transition: "opacity .5s ease-in-out",
    "& img": {
      position: "absolute",
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  },
  introductionContentWrapper: {}
});

const Introduction = () => {
  const classes = useStyles();
  const { hospital, jab, church } = imageDictionary;
  const textAndImages: Act[] = [
    {
      text: (
        <p>
          Hispanic Delawareans were hit hard during the pandemic's early months—and slow to be
          vaccinated.
        </p>
      ),
      image: hospital
    },
    {
      text: <p>Now they outpace the vaccination rates of non-Hispanic Delawareans.</p>,
      image: jab
    },
    {
      text: <p className="lead-2x centered">What happened?</p>,
      image: church
    }
  ];

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const onStepEnter = ({ data }) => setCurrentStepIndex(data);

  return (
    <Scene reverseContent>
      <SceneFeaturedContent className={classes.introFeatured}>
        {textAndImages.map(({ image }, i) => (
          <StoryImage
            image={image}
            key={i}
            style={opacityForStep(currentStepIndex, i)}
            className={classes.introFigure}
          />
        ))}
      </SceneFeaturedContent>
      <SceneScrollContainer
        acts={textAndImages.map(({ text }) => text)}
        onStepEnter={onStepEnter}
        className={classes.introductionContentWrapper}
      />
    </Scene>
  );
};

export default Introduction;
