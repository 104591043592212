import React, { Fragment, ReactNode, useState, useEffect } from "react";
import { SeriesLineOptions } from "highcharts";
import MemoizedLineChart from "./MemoizedLineChart";
import { truncatedArrayBasedOnPercentage, fetchChartData } from "./utils";
import LoadingAnimation from "../LoadingAnimation";
import cloneDeep from "lodash/cloneDeep";

import { ChartProps } from "../types";

interface AnimatedLineChartProps extends ChartProps {
  currentSceneIndex: number;
  totalNumberOfScenes: number;
  source: string | Node | Element | ReactNode;
}

const AnimatedLineChart: React.FC<AnimatedLineChartProps> = ({
  id,
  title,
  subTitle,
  currentSceneIndex,
  totalNumberOfScenes,
  jsonFile,
  source,
  options = {}
}) => {
  const [originalSeries, setSeries] = useState<SeriesLineOptions[] | "ERROR" | null>(null);
  useEffect(() => {
    async function _setSeries() {
      if (originalSeries === null) {
        const { series } = await fetchChartData(null, jsonFile);
        setSeries(series);
      }
    }
    _setSeries();
  }, [originalSeries, setSeries, jsonFile]);

  let currentSeries = null;
  let renderedPointIndices: number[] = [];

  if (originalSeries && originalSeries.length && typeof originalSeries === "object") {
    const scrollPercentage = (currentSceneIndex / totalNumberOfScenes) * 100;
    const newSeries = cloneDeep(originalSeries);
    const values = originalSeries[0].data.map((d: any, i: number) => i);
    renderedPointIndices = truncatedArrayBasedOnPercentage(values, scrollPercentage);
    originalSeries.forEach(({ data }, i: number) => {
      const newData = [...data];
      newSeries[i].data = newData.map(([d, v], dataIndex) => {
        return [d, renderedPointIndices[dataIndex] !== undefined ? v : null];
      });
      newSeries[i].percentage = scrollPercentage;
    });
    currentSeries = newSeries;
  } else {
    return (
      <>
        <LoadingAnimation />
      </>
    );
  }

  return (
    <MemoizedLineChart
      id={id}
      title={title}
      subTitle={subTitle}
      options={options}
      jsonFile={jsonFile}
      series={currentSeries}
      pointsToRender={renderedPointIndices.length}
      mutateSeriesCallback={(s) => s}
      source={source}
    />
  );
};

export default AnimatedLineChart;
