import { Controller } from "stimulus";
import { sendGAEvent } from "../javascript/utils/google_analytics";
import { querySelectorAll } from "../javascript/utils/polyfills";

export default class extends Controller {
  connect() {
    const gtmSelectCallback = (event) => {
      const { target } = event;
      const filterName = target.dataset.label;
      const selectedOption = target.options[target.selectedIndex];
      sendGAEvent({eventType: "user_interaction", parameters: {
        category: "Charts",
        action: `Filter Change${filterName ? `: ${filterName}` : ""}`,
        label: selectedOption ? selectedOption.text : ""
      }});
    };

    querySelectorAll("select", this.element).forEach((select) => {
      select.addEventListener("change", gtmSelectCallback, true);
    });
  }
}
