import renderChart from "charts/renderChart";
import { colorConfig } from "charts/config";
import { SeriesOptionsType } from "highcharts";
import { Controller } from "stimulus";

type ChartConfig = {
  title: string;
  series: Array<{
    slug: string;
    name: string;
    data: number[];
  }>;
  startDate: [number, number, number];
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({ title, series, startDate: [year, month, day] }: ChartConfig) {
    const pointStart = Date.UTC(year, month - 1, day);
    const pointInterval = 24 * 3600 * 1000; // one day
    renderChart({
      title: {
        text: title
      },
      chart: {
        renderTo: this.element as HTMLElement
      },
      yAxis: {
        title: { text: "Total Doses Administered" }
      },
      xAxis: {
        type: "datetime"
      },
      plotOptions: {
        column: {
          maxPointWidth: 0,
          groupPadding: 0.1
        }
      },
      rangeSelector: {
        enabled: true
      },
      series: series.map(
        ({ slug, data, name }): SeriesOptionsType => {
          switch (slug) {
            case "darkLine":
              return {
                type: "line",
                color: colorConfig.primary,
                pointStart,
                pointInterval,
                data,
                name
              };
            case "dottedLine":
              return {
                dashStyle: "ShortDot",
                color: colorConfig.trend,
                type: "line",
                pointStart,
                pointInterval,
                data,
                name,
                marker: { enabled: false }
              };
            case "lightColumns":
              return {
                type: "column",
                color: colorConfig.average,
                opacity: 0.8,
                pointStart,
                pointInterval,
                data,
                name
              };
            default:
              throw new Error(`unknown chart ${slug}`);
          }
        }
      )
    });
  }
}
