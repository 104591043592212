import React, { Fragment, useState } from "react";
import Scene from "../Scene/Scene";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import { createUseStyles } from "react-jss";
import Quote from "./Quote";
import { opacityForStep } from "../utils";
import { imageDictionary } from "./config";

import { getCreditById } from "./copy";
import { textStyle, textContainer } from "../styles/typography";

import { StepData } from "../types";
import StoryImage from "../StoryImage";

const useStyles = createUseStyles({
  scene: {
    display: "flex",
    justifyContent: "space-between"
  },
  featured: {
    position: "sticky",
    top: 0,
    height: "100vh",
    fallbacks: {
      height: "-webkit-fill-available"
    },
    marginLeft: "auto",
    flexBasis: "45%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  contextWrapper: {
    flexBasis: "55%"
  },
  context: {
    height: "100vh",
    fallbacks: {
      height: "-webkit-fill-available"
    },
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: [50, "5vw"],
    "& a": {
      color: "#1576D7"
    },
    "& a:hover, & a:focus": {
      color: "#2669AA"
    },
    "& p": {
      ...textStyle,
      padding: 0
    },
    "& .featured": {
      ...textStyle,
      fontSize: 52,
      fontWeight: 700,
      lineHeight: "58px"
    }
  },
  imageTextWrapper: {
    background: "white",
    opacity: 0,
    transition: "opacity .5s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  imageWrapper: {
    flex: "1 1 auto",
    overflow: "hidden",
    width: "100%",
    "& figure": {
      height: "100%"
    },
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  },
  textWrapper: {
    flex: "0 1 auto"
  },
  textContainer,
  conclusionContentWrapper: {
    "& > div": {
      height: "75vh"
    }
  }
});

const quotes = {
  rosa: getCreditById("rosa-people", "footnotes"),
  lisaHenry: getCreditById("lisa-henry", "footnotes")
};

const Conclusion = () => {
  const classes = useStyles();
  const { worship, child } = imageDictionary;
  const textAndImages = [
    {
      text: (
        <p className="featured">
          Health officials also saw community-level efforts making a difference.
        </p>
      ),
      image: worship,
      imageContext: <Quote credit={quotes.lisaHenry} />
    },
    {
      text: (
        <>
          <p>
            Faith and community figures relayed information from Lt. Gov. Bethany Hall-Long's
            "Latino Community Leaders" meetings, disseminating accurate, myth-busting vaccine
            information.
          </p>
        </>
      ),
      imageContext: <Quote credit={quotes.rosa} />
    },
    {
      text: (
        <>
          <p>
            Communication was two-way, with local leaders conveying to officials the barriers to
            successful testing and vaccination, including the imperative of providing services at
            locations convenient to, and already frequented by, Hispanic residents.
          </p>
        </>
      ),
      imageContext: <Quote credit={quotes.rosa} />
    },
    {
      text: (
        <div className={classes.textContainer}>
          <p>
            Despite some of the most troubling initial positive case rates of any demographic in
            Delaware, 65 percent of Hispanic residents had at least one COVID-19 vaccination dose by
            mid-January 2022, a rate exceeding non-Hispanics by roughly ten percentage points.
          </p>
          <p>
            Find the latest figures at the{" "}
            <a
              href="https://myhealthycommunity.dhss.delaware.gov/locations/state/vaccine-tracker"
              target="_blank"
              rel="noreferrer"
            >
              My Healthy Community - Coronavirus Vaccine Tracker.
            </a>
          </p>
        </div>
      ),
      image: child
    },
    {
      text: "filler-quarter"
    }
  ];

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const onStepEnter = ({ data }: StepData) => setCurrentStepIndex(data);
  return (
    <Scene reverseContent>
      <SceneFeaturedContent>
        {textAndImages.map(({ image, imageContext }, i: number) => (
          <div
            className={classes.imageTextWrapper}
            style={opacityForStep(currentStepIndex, i)}
            key={`text-and-image${i}`}
          >
            {image && (
              <div className={classes.imageWrapper}>
                <StoryImage image={image} />
              </div>
            )}
            {imageContext && <div className={classes.textWrapper}>{imageContext}</div>}
          </div>
        ))}
      </SceneFeaturedContent>
      <SceneScrollContainer
        acts={textAndImages.map(({ text }) => text)}
        onStepEnter={onStepEnter}
        className={classes.conclusionContentWrapper}
      />
    </Scene>
  );
};

export default Conclusion;
