import DynamicContentCardController from "./dynamic-content-card-controller";

export default class extends DynamicContentCardController {
  static targets = ["content", "toggler"];
  declare contentTarget: HTMLElement;
  declare togglerTargets: HTMLElement[];
  declare activeDataSet: string;

  connect() {
    this.activeDataSet = this.dataSets[0];
  }

  toggle(event: Event) {
    const target = event.currentTarget as HTMLElement;
    const { url } = target.dataset;
    if (url) {
      if (this.activeDataSet === url) return;
      this.activeDataSet = url;
      this.togglerTargets.forEach((el) => el.classList.remove("active"));
      target.classList.add("active");
      target.blur();
      this.renderLoader();
      this.fetch(url);
    }
  }

  get dataSets(): string[] {
    return this.togglerTargets.map((el) => {
      return el.dataset.endpoint || "";
    });
  }
}
