import React, { Component, Fragment } from "react";
import "leaflet";
import Choropleth from "./choropleth";
import { uniqeId } from "../utils/index";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import LoadingAnimation from "../LoadingAnimation";
import { getColor } from "../utils/color";
import { sendGAEvent } from "javascript/utils/google_analytics";

export default class TabbedMap extends Component {
  constructor(props) {
    super(props);
    this.mapsId = uniqeId();
    const { maps, colorCalibration } = props;
    this.dates = maps.map(({ year }) => year);
    this.labels = maps.map(({ label }) => label);
    this.confidenceKeys = ["lowerConfidence", "upperConfidence", "weightedFrequency"];
    this.colorCalibration = colorCalibration || this._defaultColorCalibration();
    this.state = {
      loadFailure: false,
      loading: true,
      activeMap: this.labels.first() || this.dates.last(),
      maps: props.maps,
      mapInstances: {}
    };
  }

  _defaultColorCalibration() {
    // Just enough to hopefully not break the page
    return { colors: [], low: 0, high: 100 };
  }

  componentDidMount() {
    this.initMaps()
      .then(({ mapData, mapInstances }) => {
        const _maps = [...this.state.maps];
        _maps.map((m) => (m.data = mapData[m.label || m.year]));
        this.setState({
          loading: false,
          maps: _maps,
          mapInstances
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Choropleth maps failed to load", error);
        this.setState({
          loadFailure: true,
          loading: false,
          maps: [],
          mapInstances: null
        });
      });
  }

  initMaps() {
    return new Promise((allMapsLoaded, loadFailure) => {
      const { maps, title } = this.props;
      let mapData = {};
      let mapInstances = {};
      const mapPromises = maps.map((map, i) => {
        return new Promise((mapLoaded, reject) => {
          fetch(
            `/locations/${map.location.slug}/choropleth_map/${map.identifier}/date/01-01-${map.year}`
          )
            .then((response) => response.json())
            .then((data) => {
              const { identifier, locationGeoJson, locationSlug, unitsAffix } = data.props;
              const { geoJson = {} } = data;
              mapData[map.label || map.year] = geoJson;
              const mapProps = {
                htmlId: `${this.mapsId}_${i}`,
                identifier,
                accessToken: this.props.token,
                locationSlug,
                locationGeoJson,
                year: map.year || 1985,
                colorCalibration: this.colorCalibration,
                data: geoJson,
                title,
                unitsAffix
              };
              const renderedMap = new Choropleth(mapProps);
              renderedMap.buildMap();
              mapInstances[map.label || map.year] = renderedMap;
              mapLoaded(i);
            })
            .catch(reject);
        });
      });
      Promise.all(mapPromises)
        .then(() => {
          allMapsLoaded({ mapData, mapInstances });
        })
        .catch(loadFailure);
    });
  }

  renderMap(map, i) {
    const classNames = classnames("c-tabbed-map__map", {
      active: this.state.activeMap === (map.label || map.year)
    });
    const id = `${this.mapsId}_${i}`
    return (
      <div className={classNames} key={id}>
        <div id={id} />
      </div>
    );
  }

  handleDateChange(activeMap, event) {
    event.preventDefault();
    const target = event?.target;
    target && target.blur()
    this.setState({ activeMap });
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: "Maps",
      action: "Tabbed Map Date/Data Change",
      label: activeMap
    }});
  }

  getActiveMapInstance() {
    const { mapInstances, activeMap } = this.state;
    return mapInstances[activeMap];
  }

  getActiveMapFeature(locationProps) {
    return this.getActiveMapInstance().geoJson.getLayer(
      this.getActiveMapInstance().layerIds[locationProps.locationName]
    );
  }

  highlightFeature(locationProps) {
    const mapInstance = this.getActiveMapInstance();
    mapInstance.highlightFeature(this.getActiveMapFeature(locationProps));
  }

  resetFeature(locationProps) {
    this.getActiveMapInstance().resetHighlight(null, this.getActiveMapFeature(locationProps));
  }

  showConfidenceItem(item, value, tooltip = true) {
    let unit = "";

    if (typeof value === "number" && item.includes("Confidence")) {
      unit = "%";
    }

    if (value) {
      return (
        <li
          key={item}
          className={`c-confidence-details__item ${item}`}
          title={tooltip ? item.toHuman() : ""}
          data-bs-toggle={tooltip ? "tooltip" : ""}
        >
          {value.toLocaleString()}
          {unit}
        </li>
      );
    }
    return null;
  }

  mapDetails() {
    const { maps, activeMap } = this.state;
    if (maps.length && !maps.last().data) return null;
    const locationDetails = (locationProps) => {
      const { values = [] } = locationProps;
      if (!values.length) return;
      const value = values.first();
      return (
        <div
          key={locationProps.locationName}
          className="c-tabbed-map__detail-list-sub-item"
          onFocus={(event) => this.highlightFeature(locationProps, event)}
          onBlur={(event) => this.resetFeature(locationProps, event)}
          onMouseOver={(event) => this.highlightFeature(locationProps, event)}
          onMouseOut={(event) => this.resetFeature(locationProps, event)}
        >
          <h4 className="mb-1">{locationProps.locationName}</h4>
          <div className="d-flex align-items-end">
            <span className="c-metric mr-2 mb-0 d-flex align-items-baseline">
              <span
                className="c-swatch c-swatch--lg mr-2"
                style={{ backgroundColor: getColor(this.colorCalibration, value.value) }}
              />
              <span className="c-metric__value">{value.valuePresented || value.value || ""}</span>
            </span>
            <ul className="c-confidence-details ml-auto">
              {this.confidenceKeys.map((k) => this.showConfidenceItem(k, locationProps[k]))}
            </ul>
          </div>
        </div>
      );
    };
    return maps.map((m, i) => {
      const { year, label, data } = m;
      const className = classnames("c-tabbed-map__detail-list-link ", {
        active: (label || year) === this.state.activeMap
      });
      return (
        <div className="c-tabbed-map__detail-list-item" key={`map-${i}`}>
          <button
            className={className}
            onClick={(event) => this.handleDateChange(label || year, event)}
          >
            <span className="mb-0"> {label || year} </span>
          </button>
          {activeMap === (label || year) && data ? (
            <div className="c-tabbed-map__detail-list-details">
              {data.polygons.features.map(({ properties }) => locationDetails(properties, year))}
            </div>
          ) : null}
        </div>
      );
    });
  }

  render() {
    const { maps=[] } = this.props;
    const { loading, loadFailure } = this.state;
    let title = ""
    let dateRange = ""
    if (maps.length > 0) {
      const { data:firstMapData="" } = maps[0] || {}
      firstMapData && (title = firstMapData.title)
      const years = maps.map(({year}) => year)
      years.length && (dateRange = `${years[0]}-${years[years.length - 1]}`)
    }

    return (
      <div className="c-tabbed-map">
        <div className="c-tabbed-map__wrapper">
          {(title || dateRange) && (
            <div className="d-flex px-4 py-3 align-items-center" style={{gap: "8px", borderBottom: "1px solid #e2e2e2" }}>
              {title && <h3 className="mb-0">{title}</h3>}
              {dateRange && <span className="c-metric-date">{dateRange}</span>}
            </div>
          )}
          <div className="c-tabbed-map__container">
            {loading && <LoadingAnimation veil={true} />}
            {loadFailure && (
              <div className="c-tabbed-map__error">
                {" "}
                Sorry, we&apos;re having trouble loading data to render these maps.
              </div>
            )}
            <div className="c-tabbed-map__details">
              <div className="c-tabbed-map__details-wrapper">{this.mapDetails()}</div>
              <ul className="c-confidence-details c-confidence-details--legend">
                {this.confidenceKeys.map((k, i) => (
                  <Fragment key={i}>{this.showConfidenceItem(k, k.toHuman(), false)}</Fragment>
                ))}
              </ul>
            </div>
            <div className="c-tabbed-map__map-wrapper">{maps.map(this.renderMap.bind(this))}</div>
          </div>
        </div>
      </div>
    );
  }
}
