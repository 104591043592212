import { Controller } from "stimulus";
import { getContextController, gaEvent } from "./config";
import { useClickOutside } from "stimulus-use";
import renderComponent from "../../javascript/renderComponent";
import { PreSearchController, ItemType } from "../types";

export default class extends Controller implements PreSearchController {
  static targets = [
    "searchForm",
    "searchToggle",
    "locationSearch",
    "topicSearch",
  ];
  declare active: Boolean;
  declare searchFormTarget: HTMLElement;
  declare searchToggleTarget: HTMLElement;
  declare locationSearchTarget: HTMLElement;
  declare topicSearchTarget: HTMLElement;
  declare currentSearchType: "locations" | "topics";
  declare location: ItemType;
  declare topic: ItemType;

  connect() {
    useClickOutside(this);
    this.active = false;
    this.topicSearchTarget.classList.add("d-none");
    this.initSearches();
    this.currentSearchType = "location";
    const currentTopic = JSON.parse(
      this.data.get("currentTopic") || "{}"
    ) as ItemType;
    const currentLocation = JSON.parse(
      this.data.get("currentLocation") || "{}"
    ) as ItemType;
    this.topic = {
      ...currentTopic,
      type: "topic",
    };
    this.location = {
      ...currentLocation,
      type: "location",
    };
  }

  handleSearchTypeChange(event: MouseEvent) {
    const btnElement = event.currentTarget as HTMLButtonElement;
    const type = btnElement.dataset.type as string;
    Object.values(this.inputWrapperTargets).forEach((el) =>
      el.classList.add("d-none")
    );
    this.inputWrapperTargets[type]?.classList.remove("d-none");
    btnElement.parentElement
      ?.querySelectorAll(".active")
      .forEach((el) => el.classList.remove("active"));
    btnElement.classList.add("active");
    this.getInputTarget(type)?.focus();
    this.currentSearchType = type;
    gaEvent({
      action: "Header Search: Change search type",
      label: type,
    });
  }

  initSearches() {
    [
      [
        this.locationSearchTarget,
        "location",
        this.locationSearchTarget.dataset.placeholder,
      ],
      [
        this.topicSearchTarget,
        "topic",
        this.topicSearchTarget.dataset.placeholder,
      ],
    ].forEach(([searchTarget, type, placeholder]) => {
      if (!searchTarget) return;
      renderComponent(searchTarget, "ContextSearch", {
        type,
        placeholder,
        showRecents: false,
        handleSearchResults: this.handleSearchResults.bind(this),
        handleUpdateSelectedItem: this.handleUpdateSelectedItem.bind(this),
        handleViewMoreResults: this.handleViewMoreResults.bind(this),
      });
    });
  }

  handleUpdateSelectedItem(event: MouseEvent, el: HTMLElement, item: ItemType) {
    if (item && this.contextController) {
      this.contextController[item.type] = item;
      this.contextController.switch(null, true);
    }
  }

  handleSearchResults(results: ItemType[]) {
    return;
  }

  handleViewMoreResults(event: MouseEvent) {
    const { type = "location" } = event.currentTarget.dataset;
    this.contextController?.changeContent(null, type);
    this.contextController?.toggle();
    this.clickOutside();
    gaEvent({
      action: "Header Search: View More Results",
      label: `${type}s`,
    });
  }

  show(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.currentTarget.blur();
    }
    this.element.classList.add("active");
    this.searchToggleTarget.classList.add("active");
    // Delay focus otherwise browser moves to input which is animating
    // in and causes the viewport to jitter
    setTimeout(() => this.getInputTarget(this.currentSearchType).focus(), 350);
    this.updateSiblings();
  }

  clickOutside(event: MouseEvent) {
    this.searchToggleTarget.classList.remove("active");
    this.element.classList.remove("active");
    this.updateSiblings("remove");
  }

  updateSiblings(method = "add") {
    const parentEl = this.element.parentElement;
    setTimeout(() => {
      [...parentEl.parentElement.querySelectorAll(".link")].forEach((el) =>
        el.classList[method]("hidden")
      );
    }, 250);
  }

  getInputTarget(type: string) {
    return this.inputWrapperTargets[type]?.querySelector("input");
  }

  get inputWrapperTargets() {
    return {
      location: this.locationSearchTarget,
      topic: this.topicSearchTarget,
    };
  }

  get contextController() {
    return getContextController(this.application);
  }
}
