import { Controller } from "stimulus";
import { map, control, tileLayer, Layer, Map, LeafletEvent, geoJSON } from "leaflet";
import { Feature } from "geojson";
import { LeafletMapController } from "../types";
import { getContextController, gaEvent } from "./config";
import { renderLoader, removeLoader } from "../../charts/utils";
import { polygonStyles } from "../maps/utils";
import { fetch } from "whatwg-fetch";

export default class extends Controller implements LeafletMapController {
  static targets = ["item"];

  declare map: Map;
  declare mapTarget: HTMLElement;
  declare mapLayers: Layer[];
  declare activeLayer: Layer;
  declare selectedFeature: Layer;
  declare loadedGeoJson: object;

  initialize() {
    this.initMap();
    document.getElementById("location-selector")?.addEventListener("show.bs.modal", () => {
      setTimeout(() => {
        this.map?.remove();
        this.initMap();
      }, 500);
    });
  }

  initMap() {
    if (this.isDisabled) return;
    this.mapLayers = [];
    this.loadedGeoJson = {};
    const geoJson = JSON.parse(this.data.get("geoJson") || "null");
    this.map = map(this.element as HTMLElement, this.options).setView([38.8172, -75.5639], 8);
    control.scale().addTo(this.map);

    // Add basemap
    tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: "abcd",
      maxZoom: 19
    }).addTo(this.map);

    geoJson && this.addLocations({ geoJson, clearLayers: false });
  }

  clearLayers() {
    if (this.isDisabled) return;
    this.mapLayers.forEach((l) => l.remove());
    this.mapLayers = [];
  }

  selectLocation(name: string) {
    if (this.isDisabled || !this.mapLayers?.length) return;
    this.selectedFeature?.setStyle(polygonStyles.default);
    this.selectedFeature = this.findLayer({ name });
    if (this.selectedFeature) {
      this.highlightLocation(null, this.selectedFeature, "selected");
      this.map.flyToBounds(this.selectedFeature.getBounds(), {
        duration: 0.75
      });
    }
  }

  findLayer(data: object) {
    return this.mapLayers
      .first()
      .getLayers()
      .find((layer: FeatureGroup) => {
        return layer?.feature.properties.NAME.match(data.name);
      });
  }

  highlightLocation(data: object, layer?: object, styleKey = "active") {
    if (!this.mapLayers?.length) return;
    this.activeLayer = layer || this.findLayer(data);
    this.activeLayer?.setStyle(polygonStyles[styleKey]);
  }

  unHighlightLocation() {
    this.activeLayer?.setStyle(polygonStyles.default);
  }

  addLocations(options) {
    if (this.isDisabled) return;
    const {
      searchableType,
      slugs = [],
      geoJson,
      clearLayers = true,
      callbacks = null,
      style = null
    } = options;

    if (clearLayers) {
      this.clearLayers();
    }

    const _style = style || polygonStyles.default;
    const onEachFeature = (feature: Feature, layer: Layer) => {
      const getProp = (e: LeafletEvent, key: string) => e.target.feature.properties[key];
      layer.on({
        click: (e) => {
          const { feature } = e.target;
          if (feature) {
            const { PATH, FEATURE_TYPE, NAME } = feature.properties;
            const item = {
              slug: PATH.replace("locations/", ""),
              name: NAME,
              type: FEATURE_TYPE
            };
            gaEvent({
              eventType: this.contextController?.visible ? "user_interaction" : "navigation",
              action: "Location polygon click",
              label: NAME,
              link_path: PATH,
            });
            this.contextController && this.contextController.updateItem(item);
            if (callbacks && callbacks.click) callbacks.click(item);
            e.target.setStyle(polygonStyles.selected);
            return item;
          }
          return e;
        },
        mouseover: (e) => {
          if (callbacks && callbacks.mouseover) callbacks.mouseover(getProp(e, "NAME"));
          e.target.setStyle({ color: "#8B0505", fillOpacity: 0.5 });
          gaEvent({
            action: "Location polygon mouseover",
            label: getProp(e, "NAME")
          });
        },
        mouseout: (e) => {
          if (callbacks && callbacks.mouseout) callbacks.mouseout(getProp(e, "NAME"));
          e.target.setStyle({ color: "#147BB1", fillOpacity: 0.2 });
        }
      });
    };
    const addLayers = (geoJson) => {
      const layer = geoJSON(geoJson, {
        style: _style,
        onEachFeature
      }).addTo(this.map);

      const baseZIndex = 1000;
      layer.getLayers().forEach((l, i) => l.setZIndexOffset && l.setZIndexOffset(baseZIndex + i));
      this.map.flyToBounds(layer.getBounds(), { duration: 0.75 });
      this.mapLayers.push(layer);
      if (clearLayers) {
        this.selectedFeature?.addTo(this.map);
      }
      return layer;
    };

    const parentElement = this.element.parentElement;
    if (geoJson) {
      addLayers(geoJson);
    } else if (searchableType) {
      if (this.loadedGeoJson[searchableType]) {
        addLayers(this.loadedGeoJson[searchableType]);
      } else if (searchableType || slugs.length) {
        let endpoint = "/context-switcher/locations-geo-json/";
        if (searchableType) {
          endpoint += `type/${searchableType as string}`;
        } else {
          endpoint += `slugs/${slugs.join("+") as string}`;
        }
        renderLoader(parentElement, {
          zIndex: 1000,
          veilColor: "rgba(255,255,255, .25)"
        });
        fetch(endpoint)
          .then((response) => response.json())
          .then(({ type, geoJson }) => {
            addLayers(geoJson);
            this.loadedGeoJson[type] = geoJson;
            removeLoader(parentElement);
          })
          .catch(() =>
            // eslint-disable-next-line no-console
            console.error(`We're having trouble loading these locations`)
          );
      }
    }
  }

  get isDisabled() {
    // Determine if map is visible — map is disabled if not
    // `offsetParent` will return null if
    // The element or its parent element has the display property set to none.
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
    return !this.element.offsetParent;
  }

  get parentController() {
    return this.data.get("parent-controller");
  }

  get options() {
    return JSON.parse(this.data.get("options")) || {};
  }

  get contextController() {
    return getContextController(this.application, { parentController: this.parentController });
  }
}
