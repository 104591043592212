import React, { Component } from "react";
import Dropdown from "../Dropdowns/Dropdown";
import TopicInvestigationSelection from "./TopicInvestigationSelection";
import { buildDropdownItem } from "../utils/dropdowns";
import Toggle from "react-toggle";

export default class TopicContextFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: this.props.animations ? this.props.animations[0] : null,
      comparison: null,
      geographicComparison: this.props.geographicComparisonChoices
        ? this.props.geographicComparisonChoices[0]
        : null,
      primary: this.props.primaryChoices ? this.props.primaryChoices[0] : null,
    };
  }

  componentDidMount() {
    document.addEventListener("chart:clear-investigation", this.handleClearComparison.bind(this));
  }

  handlePrimaryDataChange(event, value) {
    event.preventDefault();
    const selectedPrimary = this.selectedOption(value, this.props.primaryChoices);
    let selectedGeographicComparison = null;
    // If geographicComparisonChoices are available, find choice and add it todo
    // the primary set
    if (this.props.geographicComparisonChoices && this.props.geographicComparisonChoices.length) {
      selectedGeographicComparison = this.selectedOption(
        value,
        this.props.geographicComparisonChoices
      );
      this.props.handleFilterChange(selectedGeographicComparison, "geographicComparison");
    }
    this.setState({ dataSet: selectedPrimary, geographicComparison: selectedGeographicComparison });
    this.handleInvestigationChange(null, null);
    this.props.handleFilterChange(selectedPrimary, "primary");
  }

  /**
   * handleInvestigationChange - Handle change in investigation dropdown
   *
   * @param  {Object} event DOM event
   * @param  {String} value
   * @return NA
   */
  handleInvestigationChange(event, value) {
    const otherStateItems = {};
    event && event.preventDefault();
    const investigation = this.selectedOption(value, this.props.comparisonChoices);

    // Update state with investigation
    this.setState(Object.assign({ comparison: investigation }, otherStateItems));
    // Trigger change of state in parent Component - change comparison
    this.props.handleFilterChange(investigation, "comparison");
  }

  handleClearComparison() {
    this.setState({ comparison: null });
    this.props.handleFilterChange(null, "comparison");
  }

  handleAnimationChange(event) {
    event.preventDefault();
    const animationIndex = +event.target.value;
    const currentAnimation = { animation: animationIndex };
    this.setState(currentAnimation);
    this.props.handleFilterChange(animationIndex, "animation");
  }

  selectedOption(selectedValue, data) {
    return data.find((c) => c.identifier === selectedValue);
  }

  animationOptions() {
    return this.props.animations.map((a, i) => {
      return (
        <option key={i} value={i} selected={this.state.animation.title === a.title}>
          {a.title}
        </option>
      );
    });
  }

  dataSetsChoices(choices) {
    return choices.map((choice) => {
      const disabled = choice.disable_choice !== undefined ? choice.disable_choice : false;
      return buildDropdownItem(choice.identifier, choice.name, choice.sets, disabled);
    });
  }

  showInvestigation() {
    return this.props.comparisonChoices.filter((c) => c.identifier).length;
  }

  render() {
    const {
      primaryChoices,
      comparisonChoices,
      handleConfidenceToggle,
      showConfidence,
      hasConfidenceData
    } = this.props;

    return (
      <div className="c-filters" aria-label="Chart Filters">
        {primaryChoices && primaryChoices.length > 1 ? (
          <div className="c-filters__filter">
            <Dropdown
              items={this.dataSetsChoices(primaryChoices, "primary")}
              handler={this.handlePrimaryDataChange.bind(this)}
              label="Select data set to include in chart"
              ga={{ category: "Maps and Charts", action: "Primary Metric" }}
              actionLabel="Select data set"
              constrained={false}
            />
          </div>
        ) : (
          <span
            className="c-filters__title"
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          />
        )}

        {this.showInvestigation() ? (
          <div className="c-filters__filter">
            <TopicInvestigationSelection
              name="Investigate"
              groupItems={true}
              items={this.dataSetsChoices(comparisonChoices, "comparison", true)}
              handler={this.handleInvestigationChange.bind(this)}
              handleClearComparison={this.handleClearComparison.bind(this)}
              ga={{ category: "Maps and Charts", action: "Investigation" }}
            />
          </div>
        ) : null}

        {this.props.animations ? (
          <div className="c-filters__filter">
            <div className="c-filters__selector c-select">
              <select
                name="animation"
                rel={(el) => (this.investigationEl = el)}
                onChange={this.handleAnimationChange.bind(this)}
              >
                {this.animationOptions()}
              </select>
            </div>
          </div>
        ) : null}

        {hasConfidenceData ? (
          <div className="c-filters__filter c-filters__filter--toggle">
            <div className="c-filters__filter-wrapper">
              <label htmlFor="confidence-toggle">Show Confidence Intervals</label>
              <Toggle
                onChange={handleConfidenceToggle}
                defaultChecked={showConfidence}
                aria-label="Toggle confidence intervals in chart"
                id="confidence-toggle"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
