import React from "react";
import { createUseStyles } from "react-jss";
import breakpoints from "../../styles/breakpoints";

const useStyles = createUseStyles({
  sceneWrapper: {
    minHeight: "100vh",
    fallbacks: {
      minHeight: "-webkit-fill-available"
    },
    width: "100%",
    position: "relative",
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between"
    }
  },
  sceneWrapperReversed: {
    [breakpoints.up("md")]: {
      flexDirection: "row-reverse"
    }
  }
});

type SceneProps = {
  background?: "black" | "white";
  reverseContent?: boolean;
  id?: string;
  className?: string;
};

const Scene: React.FC<SceneProps> = ({
  background = "white",
  children,
  reverseContent = false,
  id,
  className
}) => {
  const classes = useStyles();
  const wrapperClasses = [classes.sceneWrapper, className];
  if (reverseContent) {
    wrapperClasses.push(classes.sceneWrapperReversed);
  }
  return (
    <div style={{ background }} className={wrapperClasses.join(" ")} id={id}>
      {children}
    </div>
  );
};

export default Scene;
