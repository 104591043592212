import Chart from "./chart";
import { bar, line, spline } from "billboard.js";
import { svgChartPoints, colors } from "./options";
import { roundToMultipleOf } from "../utils/number";
import { querySelectorAll } from "../utils/polyfills";
import { deepCopy } from "../utils/index";
import { ticks } from "d3";
import moment from "moment";
import compact from "lodash/compact";
import * as __min from "lodash/min";
import * as __max from "lodash/max";

export const typesAsModules = (types) => {
  const chartTypeDictionary = {
    bar: bar(),
    line: line(),
    spline: spline()
  };
  const _types = {};

  if (!types) return _types;
  Object.keys(types).forEach((k) => (_types[k] = chartTypeDictionary[types[k]]));
  return _types;
};

export default class SimpleLine extends Chart {
  constructor(props, containerEls, options = {}) {
    let _options = Object.assign(
      {
        names: null,
        types: {
          data1: line()
        },
        axes: {
          data1: "y"
        },
        showLegend: true,
        showLoader: true,
        unit: "percent",
        yAxis: {
          label: "",
          min: null,
          max: null,
          tick: {}
        },
        xAxis: {
          tick: {
            rotate: 0
          }
        },
        y2Axis: {}
      },
      options
    );
    super(props, containerEls, _options);
    this.updateXAxisLabels();
    this.xAxisLabelsRotated = this.hidePoints() || this.getPointCount() > 10;
    this.LABEL_ROTATION = 35;
    this.names = props.names;
    this.chart = this._init();
  }

  updateXAxisLabels() {
    this.categories = this.props.dates || this.props.categories;
    const format = this.options.dateFormat || this.props.dateFormat || "YYYY";
    if (this.categories) {
      this.categories = this.categories.map((cat) => {
        const date = moment(cat);
        if (date.isValid() && format) {
          return date.format(format);
        }
        // Return string if not a valid date
        return cat;
      });
    }
  }

  _prepareColumns() {
    return this.props.data;
  }

  _getDataSets() {
    return this.props.data.map((s) => s.first());
  }

  getMinOfSets() {
    return __min(
      deepCopy(this.props.data).map((t) => {
        t.shift();
        return __min(t);
      })
    );
  }
  getMaxOfSets() {
    return __max(
      deepCopy(this.props.data).map((t) => {
        t.shift();
        return __max(t);
      })
    );
  }

  _getLegendLabelData() {
    // Build array of labels for legend
    const sets = this._getDataSets();
    if (!sets || sets.length < 2) return;
    return compact(
      sets.map((name, i) => {
        const labelType = this.props.legendTypes ? this.props.legendTypes[i] : "line-point";
        return { id: i, name: this.getSetName(name, i), type: labelType };
      })
    );
  }

  _generate() {
    this.updateXAxisLabels();
    const chart = super._generate();
    this._addAdditionalUi();
    this._addLegend();
    this._updateLoadingState(false);
    this.updateLegend();
    return chart;
  }

  _onRendered(chart) {
    super._onRendered(chart, true);
    // Move axis label if axis labels are rotated
    if (this.xAxisLabelsRotated) {
      querySelectorAll(".bb-axis-x-label").forEach((l) => l.setAttribute("dy", "3.5em"));
    }
    return;
  }

  _getColors(columns) {
    const _colors = {};
    // Provide colors based on columns - subtract 1 to counter the x axis column
    columns.forEach((c, i) => {
      _colors[c[0]] = colors()[this.colorIndex(i)];
    });
    return _colors;
  }

  _buildOptions() {
    // Build basic options for line chart
    // Copy data columns array for labels and points
    const self = this;
    const columns = this._prepareColumns();
    const dataColumns = [...columns];
    // Remove x axis labels (first element in array)
    // dataColumns.shift()

    // Establish points
    // Iterate through columns and call `svgChartPoints`
    // which builds SVG points based on these parameters:
    // (index, point color, point size)
    // if point color is null
    // if hidePoints return true, update the color and size to make them
    // not visible
    const points = dataColumns.map((column, i) => {
      let pointColor = null;
      let pointSize = 7.5;
      if (this.hidePoints()) {
        pointColor = "transparent";
        pointSize = 0;
      }
      return svgChartPoints(i, pointColor, pointSize);
    });

    // Add padding to left and right of ticks to prevent clipping
    this.chartPadding = this.getPointCount() > 10 ? 5 : 0;
    const names = {};
    if (this.names) {
      this.names.forEach((n, i) => (names[`data${i + 1}`] = n));
    } else {
      columns.map((c) => c.first()).forEach((name, i) => (names[name] = this.props.set_names[i]));
    }

    const { xAxis, yAxis } = this.options;
    let { min, max } = yAxis || {};
    // If no min or max values are given in options obj,
    // determine those values by evaluating all the data sets
    if (min === null || min === undefined) {
      min = this.getMinOfSets();
    }
    if (max === null || max === undefined) {
      max = this.getMaxOfSets();
    }
    // Round the min/max values for a cleaner axis
    min = roundToMultipleOf(min, 5, "floor");
    max = roundToMultipleOf(max, 5);
    // Get colors for points and lines
    // Treat daily charts differently for higher contrast (get 5th color - magenta)
    const baseColors = colors();
    const pattern =
      this.props.granularity === "daily" ? [baseColors[0], baseColors[4]] : baseColors;

    const { y2Axis } = this.options;
    if (y2Axis && y2Axis.max !== undefined && y2Axis.min !== undefined) {
      this.options.y2Axis.tick = y2Axis.tick || {};
      this.options.y2Axis.tick.values = ticks(y2Axis.min, y2Axis.max, 10);
    }

    this._options = Object.assign(
      {
        legend: { show: false },
        color: { pattern },
        data: {
          columns,
          colors: this._getColors(columns),
          type: "line",
          types: typesAsModules(this.options.types),
          names,
          axes: this.options.axes
        },
        axis: {
          x: {
            type: "category",
            categories: this.categories,
            label: {
              text: xAxis.label || "",
              position: "outer-center"
            },
            tick: Object.assign(xAxis.tick, {
              culling: { max: 20 },
              rotate: this.hidePoints() || this.getPointCount() > 10 ? this.LABEL_ROTATION : null
            })
          },
          y: {
            min: min || 0,
            max,
            label: {
              text: yAxis.label,
              position: "outer-middle"
            },
            tick: {
              values: ticks(min, max, 10)
            }
          },
          y2: { ...this.options.y2Axis }
        },
        grid: {
          x: { show: false },
          y: { show: true }
        },
        bindto: this.containerEl,
        tooltip: { contents: this._tooltip.bind(this) },
        point: {
          show: !this.hidePoints(),
          pattern: points
        },
        onrendered() {
          self._onRendered(this);
        }
      },
      this.options
    );

    this._options.padding = {
      right: this.options.y2 ? 75 : 20,
      left: 75,
      top: 0,
      bottom: 50
    };
  }

  redraw(data, options = {}, props) {
    this.chart.destroy();
    this.props = props;
    this.options = Object.assign(this.options, options);
    this.categories = this.props.dates || this.props.categories;
    this.updateXAxisLabels();
    this.chart = this._init();
  }

  _tooltip(data) {
    const values = compact(data).map((d, i) => {
      if (d.value == null) return null;
      const date = this.categories[d.x];
      let value = `<span class='c-tooltip-chart__value c-tooltip-chart__value--${
        this.colorIndex(i) + 1
      } d-inline'>${d.value}</span>`;
      return `
          <div class='c-tooltip-chart__value-wrapper'>
            <span class='c-tooltip-chart__date'>
              ${date}
            </span>
            <h4 class='c-tooltip-chart__title'>${this.getSetName(d.name, i)}</h4>
            <span class='c-tooltip-chart__value-container'>
              ${value}
              <span class='c-tooltip-chart__value-point c-chart-legend__visual c-chart-legend__visual--${
                i + 1
              } c-chart-legend__visual--point'> </span>
            </span>
          </div>
        `;
    });
    return `
      <div class='c-tooltip-chart'>
        ${values.join("")}
      </div>
    `;
  }

  _onResize(chart) {
    super._onResize(chart);
    return;
  }

  getSetName(setName, index) {
    let name = typeof setName === "string" ? this._formatLabel(setName) : setName;
    const { set_names, names } = this.props;
    if (set_names || names) {
      name = (set_names || names)[index];
    }
    return name;
  }

  getPointCount() {
    const { data } = this.props;
    if (data.length) {
      return this.props.data.first().length;
    }

    return 0;
  }

  hidePoints() {
    const { granularity } = this.props;
    return this.getPointCount() > 100 || (granularity && granularity === "daily");
  }

  colorIndex(i) {
    if (this.props.granularity === "daily" && i > 0) {
      return 4;
    }
    return i;
  }

  updateLegend() {
    const chart = document.querySelector(".c-chart");
    const { granularity } = this.props;
    if (granularity && granularity === "daily") {
      const legendItems = [...chart.querySelectorAll(".c-chart-legend__visual")];
      if (legendItems) {
        legendItems.forEach((item, i) => {
          item.classList.add(
            "c-chart-legend__visual--line",
            `c-chart-legend__visual--line-${this.colorIndex(i) + 1}`
          );
        });
      }
    }
  }
}
