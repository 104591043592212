import React, { useState } from "react";
import TabbedComparativeMap from "./TabbedComparativeMap";
import LoadingAnimation from "javascript/LoadingAnimation";

const StoryTabbedMap = () => {
  const [data, setData] = useState(null);

  if (data === null) {
    fetch("/locations/state/choropleth_map/SUICIDE/config")
      .then((response) => {
        if (!response.ok) {
          // eslint-disable-next-line no-console
          console.error("Failed to load map config");
          setData("ERROR");
          return;
        }
        return response.json();
      })
      .then((response) => setData(response))
      .catch((error) => {
        setData("ERROR");
        // eslint-disable-next-line no-console
        console.error(error);
      });

    return <LoadingAnimation />;
  }

  if (data === "ERROR") {
    return <p>There was an error loading data for maps</p>;
  }

  return <TabbedComparativeMap {...data} />;
};

export default StoryTabbedMap;
