import React from "react";
import CardInfoWarning from "../topic/CardInfoWarning";
import SVGSymbol from "../SVGSymbol";

interface Props {
  addendum: string;
  title: string;
  date: string;
  tooltip: string;
  location: string;
  primaryDataLocation: {
    name: string;
  };
  showInfoMessage: boolean;
  path: string;
}

const MetricCardHeader: React.FC<Props> = ({
  addendum,
  title,
  date,
  tooltip,
  location,
  primaryDataLocation,
  showInfoMessage,
  path
}) => {
  const _title = (
    <div className="c-metric-card__title-wrapper">
      <h4 className="c-metric-card__title">{title}</h4>
      {date && <span className="c-metric-date">{date}</span>}
      {addendum && (
        <div id="label-metric-addendum" className="c-metric-card__addendum">
          <span className=""> *{addendum}</span>
        </div>
      )}
      {tooltip && (
        <div id="" className="c-metric-card__tooltip" title={tooltip} data-bs-toggle="tooltip">
          <SVGSymbol name="info" />
        </div>
      )}
    </div>
  );

  return (
    <header>
      <div className="c-metric-card__header">
        {path ? (
          <a href={path} className="c-metric-card__title-link">
            {_title}
          </a>
        ) : (
          <div>{_title}</div>
        )}
      </div>
      {location !== primaryDataLocation.name && showInfoMessage ? (
        <CardInfoWarning
          wrapperClassNames="c-note--in-card"
          location={location}
          primaryLocation={primaryDataLocation.name}
        />
      ) : null}
    </header>
  );
};

export default MetricCardHeader;
