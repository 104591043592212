import { Controller } from "stimulus";
import React from "react";
import { createRoot } from "react-dom/client";
import DataDownload from "components/DataDownload";

export default class extends Controller {
  connect() {
    const props = {
      options: JSON.parse(this.data.get("options") || "{}"),
      endpoint: this.data.get("endpoint") || "",
      param: this.data.get("param") || "",
      identifier: this.data.get("identifier") || "",
      identifierName: this.data.get("identifierName") || ""
    };
    const root = createRoot(this.element);
    root.render(<DataDownload {...props} />);
  }
}
