import * as d3 from "d3";
import { getPollutantIdentifiers } from "../utils/pollutants";

export default class ChartLevelReference {
  constructor({ sets, comparison }, chart, options = {}) {
    this.sets = sets;
    this.comparison = comparison;
    this.chart = chart;
    this.RENDER_DELAY = 500;
    this.levelRefColors = ["#BF1B1B", "#C24644", "#FF925C", "#FFD18A", "#80BDCE", "#30A4E3"];
    this.levelLabels = [
      "Good",
      "Moderate",
      "Unhealthy for sensitive groups",
      "Unhealthy",
      "Very unhealthy",
      "Hazardous"
    ];
    this.chartPadding = {
      left: 10
    };
    this.options = Object.assign(
      {
        showFullRange: true
      },
      options
    );
  }

  rangeDataAvailable(id) {
    return getPollutantIdentifiers().includes(id);
  }

  getLevelRefRanges() {
    const refRanges = this.sets[0].levelRefRanges;
    return refRanges;
  }

  getLevelInfo(value) {
    const _colors = [...this.levelRefColors].reverse();
    const labels = this.levelLabels;
    const ranges = this.getLevelRefRanges();
    let color = _colors[_colors.length - 1];
    let label = labels[labels.length - 1];
    let range = ranges[ranges.length - 1];
    const setValues = (i) => {
      label = labels[i];
      color = _colors[i];
      range = ranges[i];
    };
    ranges.forEach((range, i) => {
      if (range[1] !== "undefined") {
        if (value >= range[0] && value <= range[1]) {
          setValues(i);
        }
      } else if (value >= range[1]) {
        setValues(i);
      }
    });
    return {
      color,
      label,
      range
    };
  }

  getLevelRangeText(range) {
    const [bottom, top] = range;
    let values = `${bottom} - ${top}`;
    if (!top) values = `> ${bottom}`;
    return values;
  }

  getLegend(data) {
    if (this.getLevelRefRanges() && this.getLevelRefRanges(data.id))
      return `
        <div class='c-tooltip-chart__legend'>
          <div class='c-tooltip-chart__legend-swatch' style='background-color: ${
            this.getLevelInfo(data.value).color
          };'></div>
          <div class='c-tooltip-chart__legend-label'>
            ${this.getLevelInfo(data.value).label}
          </div>
        </div>
      `;
    return "";
  }

  add(chart, _chartMinMax) {
    return new Promise((resolve) => {
      const ranges = this.getLevelRefRanges();
      chart.axis.max(ranges[ranges.length - 1][0]);
      chart.regions.add(
        ranges.map((r) => ({
          axis: "y",
          start: r[0],
          end: r[1],
          class: "level-ref"
        }))
      );
      resolve();
    });
  }

  updateLineAndDots(chart) {
    if (!this.getLevelRefRanges()) return;
    setTimeout(() => {
      const chartMain = chart.main || chart.$.main;
      const identifier = this.sets[0].identifier.replace(new RegExp("_", "g"), "-");
      chartMain.selectAll(`.bb-circles-${identifier} .bb-circle`).attr("style", (d) => {
        if (d.value !== null && d.value !== undefined) return `fill: #FFFFFF`;
      });

      chartMain.selectAll(`.bb-lines-${identifier} path`).attr("style", "stroke: #FFFFFF");

      chartMain
        .selectAll(".bb-ygrid")
        .attr("style", "stroke: #FFFFFF")
        .attr("style", "stroke-opacity: .5");
    }, 500);
  }

  updateLegendItem(chartId) {
    const chart = document.getElementById(chartId);
    const legendItem = chart.querySelector(".c-chart-legend__visual");
    if (legendItem) legendItem.classList.add("c-chart-legend__visual--leveled");
  }

  addLevelRefLegend(chartId) {
    d3.select(`#${chartId}`).selectAll(".c-chart-legend--secondary").remove();
    d3.select(`#${chartId}`)
      .insert("div", ".chart")
      .attr("class", "c-chart-legend c-chart-legend--secondary")
      .attr("style", `padding-left: ${this.chartPadding.left}px`)
      .selectAll("span")
      .data(this.levelLabels)
      .enter()
      .append("div")
      .attr("class", "c-chart-legend__item c-chart-legend__item--swatch")
      .attr("data-id", (label) => label.name)
      .html((label, i) => {
        const levelColors = this.levelRefColors;
        return `
            <div class='c-chart-legend__swatch' style='background-color: ${
              levelColors[levelColors.length - 1 - i]
            }'></div>
            <div class='c-chart-legend__label c-chart-legend__label--secondary'>
              <span>${label}</span>
              <span>${this.getLevelRangeText(this.getLevelRefRanges()[i])}</span>
            </div>
          `;
      });
  }
}
