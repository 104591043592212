import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import { YAxisPlotLinesOptions, SeriesOptionsType } from "highcharts";
import { colorConfig, tickInterval, dateTimeLabelFormats } from "charts/config";
import HighchartsReact from "highcharts-react-official";
import { compact, flatten, maxBy, minBy } from "lodash";

type Series = { value: number; date: number };
type Trend = Series[];
interface TrendSeries {
  name: string;
  color: string;
  data: number[][];
}

interface Props {
  dateRange: string;
  className: string;
  trends: Trend[];
  benchmark: number;
  unit: string;
  series: TrendSeries[];
  comparisonLocResolutionChanged?: boolean;
}
interface RawTrend {
  location: { name: string };
  trend: Series[];
}

export const transformTrendsToSeries = (trends: RawTrend[]): TrendSeries[] => {
  const _trends = compact(trends.map((t) => t?.trend));
  const series = _trends.map((t: Trend, i: number) => {
    return {
      name: trends[i]?.location.name,
      color: i > 0 ? "#787878" : "#245194",
      data: t.map(({ value, date }: Series) => [date, value])
    };
  });
  return series;
};

export const Sparkline: React.FC<Props> = ({
  series,
  className,
  dateRange,
  benchmark,
  unit,
  comparisonLocResolutionChanged = false
}) => {
  const [_options, setoptions] = useState({});
  const axisLineColor = "#CCCCCC";
  useEffect(() => {
    const plotLines = (): YAxisPlotLinesOptions[] => {
      if (!benchmark) return [];
      return [
        {
          value: benchmark,
          color: colorConfig.benchmark,
          dashStyle: "Dash",
          width: 1,
          zIndex: 1
        }
      ];
    };
    const seriesDates = flatten(series.map((s) => s.data.map((d) => d[0])));
    const options = {
      credits: { enabled: false },
      title: { text: null },
      chart: {
        backgroundColor: "transparent",
        height: 100
      },
      yAxis: {
        title: {
          text: null
        },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
        gridLineWidth: 0,
        plotLines: plotLines()
      },
      xAxis: {
        crosshair: true,
        lineColor: axisLineColor,
        tickColor: axisLineColor,
        minorTickColor: axisLineColor,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        type: "datetime",
        dateTimeLabelFormats,
        minTickInterval: tickInterval("yearly"),
        min: Date.parse(minBy(seriesDates)),
        max: Date.parse(maxBy(seriesDates)),
        startOnTick: true,
        endOnTick: true
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          label: { connectorAllowed: false },
          marker: { enabled: false }
        }
      },
      series: series.map(
        ({ data, name, color }, i: number): SeriesOptionsType => {
          return {
            color: color || colorConfig.set[i],
            type: "spline",
            data: data.map(([d, v]) => [Date.parse(d.toString()), v]),
            name
          };
        }
      ),
      tooltip: {
        xDateFormat: "%Y"
      }
    };
    setoptions(options);
  }, [series, unit, benchmark]);

  return (
    <div className={className}>
      <div className="c-metric-card__group-title">
        <span id="label-metric-trend">Trend</span>
        {dateRange && <span className="c-metric-card__group-title-context"> ({dateRange})</span>}
      </div>
      {unit && <div style={{ fontSize: "12px" }}>({unit})</div>}
      <HighchartsReact highcharts={Highcharts} options={_options} />
      {comparisonLocResolutionChanged && (
        <div className="c-tooltip__info-message">
          <i className="fas fa-info-circle" />
          <span>
            Data is for {series[1].name} because there is insufficient data for the selected
            comparison location.
          </span>
        </div>
      )}
    </div>
  );
};
