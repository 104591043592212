import React, { Fragment, useState } from "react";
import { createUseStyles } from "react-jss";
import AnimatedLineChart from "../Charts/AnimatedLineChart";
import Scene from "../Scene/Scene";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import { getCreditById, getInlineCredit } from "./copy";
import { chartOptions } from "../Charts/config";

import { StepData } from "../types";
import InlineCredit from "../InlineCredit";

const useStyles = createUseStyles({
  chartTextWrapper: {
    height: "25vh"
  }
});

const reasonsCredit = getInlineCredit("sternberg");

const EarlyCaseRates = () => {
  const [sceneData, setSceneData] = useState<SceneData>({
    currentSceneIndex: 0,
    scrollPercentage: 0
  });

  // Callbacks
  const onStepEnter = ({ data }: StepData) => {
    setSceneData({ currentSceneIndex: data, scrollPercentage: sceneData.scrollPercentage });
  };

  const chartText = [
    "filler-quarter",
    <>
      <p style={{ fontWeight: 700 }}>
        At the pandemic’s outset, the portion of Hispanic residents testing positive for COVID-19
        exceeded the non-Hispanic rate almost every day.
      </p>
    </>,
    "filler-quarter",
    <>
      <p>
        Reasons were multiple and overlapping...
        {reasonsCredit && (
          <InlineCredit credit={reasonsCredit}>
            <sup>{reasonsCredit.superscript}</sup>
          </InlineCredit>
        )}
      </p>
    </>,
    "filler-quarter",
    <>
      <p>
        Many Hispanics live in dense multigenerational (or multi-family) households, prime
        conditions for a respiratory illness like COVID-19 to spread.
      </p>
    </>,
    "filler-quarter",
    <>
      <p>
        Language and cultural barriers hindered messaging around handwashing, masks, and social
        distancing.
      </p>
    </>,
    "filler-quarter",
    <>
      <p>Identification requirements at some test sites dissuaded some Hispanics from testing.</p>
    </>,
    "filler-half"
  ];
  const classes = useStyles();

  const options = chartOptions.earlyCaseRates;

  return (
    <Scene reverseContent>
      <SceneFeaturedContent>
        <AnimatedLineChart
          id=""
          currentSceneIndex={sceneData.currentSceneIndex}
          totalNumberOfScenes={chartText.length}
          options={options}
          title="Daily rate of new positive COVID-19 cases by Hispanic origin, Delaware"
          subTitle="March 2020 to October 2021"
          jsonFile="covid_case_rates_by_ethnicity"
          source={getCreditById("ddhss", "sources")}
        />
      </SceneFeaturedContent>
      <SceneScrollContainer
        acts={chartText}
        onStepEnter={onStepEnter}
        actClassName={classes.chartTextWrapper}
      />
    </Scene>
  );
};

export default EarlyCaseRates;
