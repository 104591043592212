import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "investigate"];

  connect() {
    this.element.addEventListener("ajax:success", this.refreshFromSuccessEvent);
    this.element.addEventListener("ajax:error", this.errorAlert);
    const firstOption = this.investigateTarget.querySelector("option");
    if (firstOption) {
      firstOption.disabled = true;
    }
    this.removeAuthenticityTokenInput();
  }

  filter = (_event) => {
    window.Rails.fire(this.formTarget, "submit");
  };

  refreshFromSuccessEvent = (event) => {
    this.element.outerHTML = event.detail[2].responseText;
    this.removeAuthenticityTokenInput();
  };

  errorAlert = (_response) => {
    alert("Sorry, an error occurred filtering the chart. Please refresh the page and try again.");
  };

  // HACK for some reason form_with authenticity_token: false isn't working so we're doing it the "fun" way
  removeAuthenticityTokenInput() {
    this.formTarget.querySelector('[name="authenticity_token"]')?.remove();
  }

  clearInvestigation() {
    this.investigateTarget.selectedIndex = 0;
    const event = new Event("change");
    this.formTarget.dispatchEvent(event);
  }

  disconnect() {
    this.element.removeEventListener("ajax:success", this.refreshFromSuccessEvent);
    this.element.removeEventListener("ajax:error", this.errorAlert);
  }
}
