import React, { memo } from "react";
import { createUseStyles } from "react-jss";
import LineChart from "./LineChart";
import breakpoints from "../../styles/breakpoints";

import { ChartProps } from "../types";

const useStyles = createUseStyles({
  chartWrapper: {
    bottom: 0,
    display: "flex",
    flex: [1, 1, "100%"],
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 50,
    maxHeight: "100vh"
  },
  chartContainer: {
    flex: [1, 1, "auto"],
    padding: "1.5vw",
    display: "flex",
    flexDirection: "column",
    [breakpoints.up("md")]: {
      padding: "2.5vw"
    }
  }
});

const Chart: React.FC<ChartProps> = ({
  id,
  jsonFile = "",
  title = "",
  subTitle = "",
  series = [],
  options,
  mutateSeriesCallback,
  source
}) => {
  const classes = useStyles();
  return (
    <div className={classes.chartWrapper}>
      <div className={classes.chartContainer}>
        <LineChart
          id={id}
          jsonFile={jsonFile}
          series={series}
          mutateSeries={mutateSeriesCallback}
          chartOptions={options}
          title={title}
          subTitle={subTitle}
          source={source.text}
        />
      </div>
    </div>
  );
};

const startAndEndAreSame = (prevProps: ChartProps, nextProps: ChartProps) => {
  if (!prevProps.series?.length) return false;
  return prevProps.pointsToRender === nextProps.pointsToRender;
};
const MemoizedLineChart = memo(Chart, (prevProps: ChartProps, nextProps: ChartProps) => {
  return prevProps.jsonFile === nextProps.jsonFile && startAndEndAreSame(prevProps, nextProps);
});

export default MemoizedLineChart;
