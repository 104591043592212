import Highcharts from "highcharts/highstock";
import { Controller } from "stimulus";

export default class extends Controller {
  // This component is tied to the Ruby ViewComponent Charts::DonutComponent
  // so it follows a different pattern than other controllers here.

  static values = { categories: Array, colors: Array, innerSize: String };

  connect() {
    Highcharts.chart({
      chart: {
        type: "pie",
        renderTo: this.element,
        backgroundColor: "transparent",
        margin: [20, 20, 20, 20]
      },
      title: { text: undefined },
      tooltip: {
        pointFormat: "<b>{point.percentage:.1f}%</b>"
      },
      accessibility: {
        point: {
          valueSuffix: "%"
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: { enabled: false },
          size: "100%"
        }
      },
      series: [
        {
          colorByPoint: true,
          name: this.title,
          innerSize: "80%",
          data: this.categoriesValue.map((cat, i) => {
            return {
              name: cat.label,
              y: parseFloat(cat.value),
              color: cat.color || this.colorsValue[i],
              selected: cat.selected,
              dataLabels: cat.dataLabels
            };
          })
        }
      ],
      credits: {
        enabled: false
      }
    });
  }
}
