/**
 * export default - description
 *
 * @param  {String or Number} value  Value for handlers (data-value)
 * @param  {String} label            Text label of item
 * @return {Obj}                     Object of value and label
 */
export function buildDropdownItem(value, label, sets=null, disabled=false) {
  return {
    value: value,
    name: label,
    sets: sets,
    disabled: disabled,
  }
}
