import React, { Component } from "react";
import classnames from "classnames";
import { sendGAEvent } from "../utils/google_analytics";
import { uniqeId } from "../utils/index";
import { Dropdown } from "bootstrap";

export default class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.theme = props.theme || "primary";
  }

  componentDidMount() {
    this.dropdownInstance = new Dropdown(this.buttonEl);
    const watchForClicks = (event) => {
      if (event.target != this.buttonEl) {
        this.dropdownInstance.toggle();
      }
    };
    this.buttonEl.addEventListener("shown.bs.dropdown", () => {
      const { ga } = this.props;
      ga && sendGAEvent(ga);
      this.menuEl.style.minWidth = `${this.buttonEl.offsetWidth}px`;
      document.addEventListener("click", watchForClicks);
    });
    this.buttonEl.addEventListener("hidden.bs.dropdown", () => {
      document.removeEventListener("click", watchForClicks);
    });
  }

  handleDropdownToggle() {
    this.dropdownInstance && this.dropdownInstance.toggle();
  }

  render() {
    const {
      selectedItem,
      placeholder,
      btnClassNames,
      size,
      label,
      actionLabel,
      options,
      constrained
    } = this.props;
    const _options = Object.assign(
      {
        menuWidth: null,
        position: null
      },
      options || {}
    );

    const selectedName = selectedItem ? selectedItem.name : placeholder || "Select an item";

    // Classnames
    const addSizeModifier = (base) => {
      if (!size) return "";
      return ` ${base}--${size}`;
    };
    const buttonClasses = classnames(
      "c-dropdown__button",
      btnClassNames,
      addSizeModifier("c-dropdown__button")
    );
    const menuClasses = classnames(
      "dropdown-menu",
      "c-dropdown__menu",
      addSizeModifier("c-dropdown__button"),
      { "dropdown-menu-right": _options.position === "right" }
    );

    const _constrained = constrained ? "c-dropdown--constrained" : "";

    const containerClassNames = classnames(
      "dropdown",
      "c-dropdown",
      addSizeModifier("c-dropdown"),
      _constrained,
      `c-dropdown--${this.theme}`
    );
    const id = `dropdown_${uniqeId()}`;
    return (
      <div className={containerClassNames} ref={(el) => (this.wrapperEl = el)}>
        {actionLabel ? <label className="c-dropdown__label"> {actionLabel} </label> : null}
        <button
          aria-expanded="false"
          aria-label={label}
          aria-haspopup="true"
          className={buttonClasses}
          data-boundary="viewport"
          id={id}
          type="button"
          ref={(el) => (this.buttonEl = el)}
          onClick={() => this.handleDropdownToggle()}
        >
          {selectedName}
        </button>
        <div
          aria-labelledby={id}
          className={menuClasses}
          ref={(el) => (this.menuEl = el)}
          x-placement="bottom-start"
          style={_options.menuStyle || {}}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
