import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "submit", "confirm"];

  connect() {
    this.submitTarget &&
      this.submitTarget.addEventListener("click", (event) => {
        this.userConfirmation(event);
      });
    this.modal = new Modal(this.modalTarget, {
      keyboard: false
    });
    this.modalTarget.addEventListener("hidden.bs.modal", () => {
      this.handleStickyHeader(1);
    });
    this.confirmTarget.addEventListener("click", () => {
      this.submit();
    });
  }

  userConfirmation() {
    this.handleStickyHeader(-1);
    this.modal.show();
    const data = new FormData(this.element);
    const values = {};
    for (const [name, value] of data) {
      if (value && name !== "authenticity_token") {
        values[name] = value;
      }
    }
    Object.keys(values).forEach((k) => {
      const el = this.modalTarget.querySelector(`[data-key='${k}']`);
      el.innerHTML = values[k] || "";
    });
    return false;
  }

  submit() {
    const modalContent = this.modalTarget.querySelector(".modal-content");
    if (modalContent) {
      modalContent.innerHTML = `<i class="fas fa-rocket" style="font-size: 100px; font-size: 100px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #255194"/>`;
    }
    this.element.submit();
  }

  handleStickyHeader(direction = 1) {
    const stickyHeader = document.getElementById("mhc-header");
    if (stickyHeader) {
      stickyHeader.style.zIndex = stickyHeader.style.zIndex + direction;
    }
  }
}
