export function getLevelRefRanges (pollutant=null) {
  const PM2 = [
    [ 0, 12 ],
    [ 12.1, 35.4 ],
    [ 35.5, 55.4 ],
    [ 55.5, 150.4 ],
    [ 150.5, 250.4],
    [ 250.5 ]
  ]
  const POLLUTANT_RANGES = {
    AIR_OZONE_MEAN: [
      [ 0, 0 ],
      [ 0, 124.0 ],
      [ 125.0, 164.0 ],
      [ 165.0, 204.0 ],
      [ 205.0, 504.0 ],
      [ 505.0 ]
    ],
    AIR_8_HR_OZONE_MEAN: [
      [ 0, 54.0 ],
      [ 55.0, 70.0 ],
      [ 71.0, 85.0 ],
      [ 86.0, 105.0 ],
      [ 106.0, 200.0 ],
      [ 200.0 ]
    ],
    AIR_CO_MEAN: [
      [ 0, 4.4 ],
      [ 4.5, 9.4 ],
      [ 9.5, 12.4 ],
      [ 12.5, 15.4 ],
      [ 15.5, 40.4 ],
      [ 40.5 ]
    ],
    AIR_SO2_TRACE_LEVEL_MEAN: [
      [ 0, 35 ],
      [ 36, 75 ],
      [ 76, 185 ],
      [ 186, 304 ],
      [ 305, 604],
      [ 605, 804 ]
    ],
    AIR_NO2_MEAN: [
      [ 0, 53 ],
      [ 54, 100 ],
      [ 101, 360 ],
      [ 361, 649 ],
      [ 650, 1249],
      [ 1250, 1649 ]
    ],
    AIR_PM2_5_5030I_IN_MEAN: PM2,
    AIR_PM2_5_SHARP_COLO_MEAN: PM2,
    AIR_PM_2_5_TAPI_MEAN: PM2,
  }
  if (pollutant)
    return POLLUTANT_RANGES[pollutant]
  else
    return POLLUTANT_RANGES
}

export function getPollutantIdentifiers () {
  return Object.keys(getLevelRefRanges())
}
