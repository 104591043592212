import React, { Fragment, ReactNode, useState } from "react";
import Scene from "../Scene/Scene";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { createUseStyles } from "react-jss";
import breakpoints from "../../styles/breakpoints";
import { figureTitle, figureSource } from "../styles/figures";

import { StepData, Act, AnimatedMapAct } from "../types";

const wrapperStyle = {
  flex: "1 1 100%",
  width: "100%",
  display: "flex",
  flexDirection: "column"
};
const useStyles = createUseStyles({
  mapTitle: figureTitle,
  mapSource: figureSource,
  childrenWrapper: {
    ...wrapperStyle
  },
  mapContainer: {},
  mapFrameWrapper: {
    position: "absolute",
    top: "2.5vh",
    bottom: "2.5vh",
    left: "5vw",
    right: "5vw",
    display: "flex",
    flexDirection: "column"
  },
  mapDateContainer: {
    position: "absolute",
    right: 50,
    top: 50,
    zIndex: 100
  },
  mapDate: {
    background: "white",
    position: "absolute",
    right: -50,
    top: 0,
    whiteSpace: "nowrap",
    padding: 15,
    minWidth: 200,
    fontSize: 24,
    textAlign: "right",
    "&:empty": {
      background: "none"
    },
    [breakpoints.up("md")]: {
      right: 0,
      fontSize: 32,
      textAlign: "left"
    }
  },
  mapFrameContainer: {
    flex: "1 1 100%",
    minWidth: "100%",
    position: "relative"
  },
  mapFrame: {
    "& img": {
      height: "90%",
      maxWidth: "100%",
      objectFit: "cover",
      transition: "opacity .5s ease-in-out",
      position: "absolute",
      top: "2.5%",
      left: "50%",
      right: 0,
      transform: "translateX(-50%)"
    }
  },
  legendWrapper: {
    flex: "0 1 auto",
    transition: ".2s opacity ease-in-out",
    width: "100%",
    alignSelf: "center",
    [breakpoints.up("md")]: {
      width: "75%"
    },
    [breakpoints.up("lg")]: {
      width: "50%"
    }
  },
  animatedMapContentWrapper: {
    width: "100%",
    marginTop: "-100vh",
    "& > div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& > div > p": {
      background: "rgba(255,255,255,.9)",
      width: "90%",
      [breakpoints.up("md")]: {
        width: "75%"
      },
      [breakpoints.up("lg")]: {
        width: "50%"
      },
      "&:empty": {
        display: "none"
      }
    }
  },
  mapScene: {
    display: "block",
    position: "relative"
  }
});

type SceneAnimatedMapProps = {
  textAndImages: AnimatedMapAct[];
  Legend?: React.FC | ReactNode | Element;
  source: Node | Element | ReactNode | string;
  sourceShowIndices?: number[];
};

const SceneAnimatedMap: React.FC<SceneAnimatedMapProps> = ({
  textAndImages,
  Legend,
  source,
  sourceShowIndices
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const onStepEnter = ({ data }: StepData) => setCurrentStepIndex(data);
  const classes = useStyles();
  const determineLegendStyle = (showLegend: boolean) => ({
    display: showLegend ? "block" : "none"
  });
  const visibilityForStep = (frameIndex: number) => ({
    visibility: currentStepIndex === frameIndex ? "visible" : "hidden"
  });
  return (
    <Scene reverseContent className={classes.mapScene}>
      <SceneFeaturedContent className={classes.mapContainer}>
        <div
          className={
            textAndImages[currentStepIndex].children
              ? classes.childrenWrapper
              : classes.mapContainer
          }
        >
          {textAndImages.map(
            (
              { title, image, imageClassname, date, altText, showLegend = false, children = null },
              i
            ) => (
              <div key={i} className={classes.mapFrameWrapper} style={visibilityForStep(i)}>
                {title && <h3 className={classes.mapTitle}> {title} </h3>}
                <div className={classes.mapFrameContainer}>
                  <div className={classes.mapDateContainer}>
                    {date && <p className={classes.mapDate}>{date}</p>}
                  </div>
                  {image.length > 0 && (
                    <LazyLoadImage
                      wrapperClassName={[classes.mapFrame, imageClassname].join(" ")}
                      alt={altText}
                      src={image}
                    />
                  )}
                  {children}
                </div>
                {Legend && (
                  <div className={classes.legendWrapper} style={determineLegendStyle(showLegend)}>
                    <Legend />
                  </div>
                )}
                <p className={classes.mapSource}>
                  {source && sourceShowIndices?.includes(currentStepIndex) && (
                    <>
                      <strong>Source:</strong> {source}
                    </>
                  )}
                </p>
              </div>
            )
          )}
        </div>
      </SceneFeaturedContent>
      <SceneScrollContainer
        acts={textAndImages.map(({ text }) => text) as Act[]}
        onStepEnter={onStepEnter}
        className={classes.animatedMapContentWrapper}
        actClassName={classes.context}
      />
    </Scene>
  );
};

export default SceneAnimatedMap;
