import renderChart from "charts/renderChart";
import Highcharts from "highcharts";
import { Controller } from "stimulus";
import {
  colorConfig,
  tickInterval,
  dateTimeLabelFormats as defaultDateTimeLabelFormats
} from "charts/config";
import { TimeScale } from "charts/types";

type TimeSeriesData = [string, number];

type ChartConfig = {
  title: string;
  yAxisTitleText: string;
  xAxisTitleText: string;
  timeScale: TimeScale;
  series: Array<{
    type?: "line" | "spline";
    name: string;
    color?: string;
    data: TimeSeriesData[];
  }>;
  startDate: [number, number, number];
  enableRangeSelector?: boolean;
  dateTimeLabelFormats?: {
    millisecond?: string;
    second?: string;
    minute?: string;
    hour?: string;
    day?: string;
    week?: string;
    month?: string;
    year?: string;
  };
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({
    series,
    title,
    timeScale = "daily",
    yAxisTitleText,
    xAxisTitleText,
    dateTimeLabelFormats
  }: ChartConfig) {
    const { element } = this;
    renderChart({
      title: {
        text: title
      },
      chart: {
        renderTo: element as HTMLElement,
        height: 500
      },
      yAxis: {
        title: { text: yAxisTitleText }
      },
      xAxis: {
        title: { text: xAxisTitleText },
        type: "datetime",
        dateTimeLabelFormats: {
          ...defaultDateTimeLabelFormats,
          ...dateTimeLabelFormats
        },
        minTickInterval: tickInterval(timeScale),
        plotLines: [
          {
            color: "#666666",
            dashStyle: "Dot",
            id: "current-year",
            label: {
              align: "left",
              rotation: 0,
              useHTML: true,
              text: "Vaccine open to all Delawareans 16+",
              style: {
                padding: "10px",
                background: "rgba(255,255,255,.5)",
                fontSize: "15px",
                lineHeight: "20px"
              },
              x: 5,
              y: 40
            },
            width: 2,
            value: new Date("2021-04-06").getTime(),
            zIndex: 3
          },
          {
            color: "#666666",
            dashStyle: "Dot",
            id: "current-year",
            label: {
              align: "left",
              rotation: 0,
              useHTML: true,
              text: "First vaccine authorized by FDA",
              style: {
                padding: "10px",
                background: "rgba(255,255,255,.5)",
                fontSize: "15px",
                lineHeight: "20px"
              },
              x: 5,
              y: 10
            },
            width: 2,
            value: new Date("2020-12-11").getTime(),
            zIndex: 3
          }
        ]
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 4
          }
        }
      },
      rangeSelector: {
        enabled: true,
        buttons: [
          {
            type: "month",
            count: 3,
            text: "3m",
            title: "3-month chart"
          },
          {
            type: "month",
            count: 6,
            text: "6m",
            title: "6-month chart"
          },
          {
            type: "year",
            count: 1,
            text: "1y",
            title: "1-year chart"
          }
        ]
      },
      series: series.map(
        (
          { data, name, color, type, marker = { enabled: false }, lineWidth = 2 },
          i: number
        ): Highcharts.SeriesOptionsType => {
          return {
            color: color || colorConfig.set[i],
            type: type || "line",
            data: data.map(([d, v]) => [Date.parse(d), v]),
            name,
            marker,
            lineWidth
          };
        }
      )
    });
  }
}
