import { Controller } from "stimulus";
import renderChart from "charts/renderChart";
import { ColumnData } from "charts/types";
import { barTooltipOpts } from "../../charts/utils";

type ChartConfig = {
  title: string;
  yAxisTitleText: string;
  yAxisLabelFormat: string;
  xAxisTitleText: string;
  categories: string[];
  series: Array<{
    name: string;
    data: ColumnData[];
  }>;
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;
  declare colors: string[];

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({ series, yAxisTitleText = "", xAxisTitleText = "", categories }: ChartConfig) {
    const { element } = this;
    const formatter = function () {
      return `${this.value}%`;
    };
    renderChart({
      title: { text: null },
      chart: {
        renderTo: element as HTMLElement,
        type: "column"
      },
      yAxis: {
        title: {
          text: yAxisTitleText
        },
        labels: { formatter }
      },
      xAxis: {
        title: {
          text: xAxisTitleText
        },
        categories
      },
      plotOptions: {
        series: {
          stacking: "percent"
        },
        dataLabels: { formatter }
      },
      tooltip: barTooltipOpts(
        null,
        true,
        series.map(({ data }) => data)
      ),
      series
    });
  }
}
