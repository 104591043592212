import DynamicContentCardController from "./dynamic-content-card-controller";

export default class extends DynamicContentCardController {
  static values = { url: String };
  declare urlValue: string;

  connect() {
    const { urlValue } = this;
    if (!urlValue) {
      this.handleError(new Error("missing url"));
    }
    this.fetch(urlValue, this.element as HTMLElement);
  }
}
