import React, { useState, useEffect, useRef, useMemo } from "react";
import { createUseStyles } from "react-jss";
import FuzzySearchRecents from "./FuzzySearchRecents";
import FuzzySearchResults from "./FuzzySearchResults";
import Icon from "./Icon";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import debounce from "lodash/debounce";
import LoadingAnimation from "../../javascript/LoadingAnimation";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { sendGaUserInteractionEvent } from "../../javascript/utils/google_analytics";
import { FuzzySearchResultsType } from "./FuzzySearchResultsGroup";

const useStyles = createUseStyles({
  fuzzySearch: {},
  fuzzySearchContainer: {
    position: "relative",
    "& .c-loading-animation__indicator": {
      width: "calc(100% - 2px)",
      "& svg": {
        position: "absolute",
        left: 13,
        top: "50%",
        transform: "translateY(-50%)",
        background: "white",
        width: 20
      }
    }
  },
  fuzzyInputWrapper: {
    position: "relative",
    "& > i": {
      position: "absolute",
      left: 15,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 10
    },
    "& button": {
      position: "absolute",
      right: 5,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 10,
      border: 0,
      outline: 0,
      background: "none",
      padding: [10, 15]
    },
    "& input": {
      position: "relative",
      marginBottom: 5,
      paddingLeft: 40,
      fontSize: 18
    },
    "& input:focus": {
      borderColor: "#0060f0"
    },
    "& input:not(:empty)": {
      borderRadius: 0
    }
  },
  "fuzzySearchContainer--inHeader": {
    "& input": {
      fontSize: 14,
      marginTop: 5,
      marginBottom: 5,
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  "fuzzyInputClear": {
    "&:focus": {
      outline: "2px solid #0060f0",
      outlineOffset: "-4px"
    }
  }
});

type FuzzySearchProps = {
  placeholder: string;
  showRecents: boolean;
  inHeader: boolean;
};

type FuzzySearchState = {
  searching: boolean;
  results: FuzzySearchResultsType;
  searchString: string;
  cachedSearchString?: string | null;
  resultsOpen?: boolean;
  error?: boolean;
};

const FuzzySearch: React.FC<FuzzySearchProps> = ({
  placeholder,
  showRecents = true,
  inHeader = false
}) => {
  const [searchState, setSearchState] = useState<FuzzySearchState>({
    searching: false,
    results: null,
    searchString: "",
    cachedSearchString: null,
    resultsOpen: false
  });

  // Default cleared state
  const clearedSearchState = useMemo(() => ({
    searching: false,
    cachedSearchString: null,
    searchString: "",
    results: {},
    resultsOpen: false
  }), []);

  // Refs
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Event Handlers
  const fetchSearchResults = (searchString: string) => {
    fetch(`/fuzzy-search?query=${searchString}&default_results=false`)
      .then(async (response) => response.json())
      .then((data) => {
        setSearchState({
          results: data,
          searching: false,
          searchString: "",
          cachedSearchString: searchString
        });
      })
      .catch((error = "") => {
        // eslint-disable-next-line no-console
        console.error(`Error while performing search`, error);
        setSearchState({
          results: null,
          searching: false,
          cachedSearchString: searchString,
          error: true,
          searchString: ""
        });
      });
  };

  const handleSearchChange = (event: InputEvent) => {
    const inputElement = event.target as HTMLInputElement;
    const searchString = inputElement?.value || "";
    if (searchState.searching) return;
    else if (searchString.length && searchString.length > 2) {
      setSearchState((state) => ({ ...state, searchString, resultsOpen: true }));
      fetchSearchResults(searchString);
    } else {
      setSearchState((state) => ({
        ...state,
        searchString: "",
        cachedSearchString: null,
        resultsOpen: true
      }));
    }
  };

  const handleOpenResults = () => {
    if (!searchState.resultsOpen) {
      setSearchState((state)=>({
        ...state,
        resultsOpen: true
      }));
      sendGaUserInteractionEvent({ category: "Fuzzy Search", action: "Toggle (open)" });
    }
  };

  const handleClearSearch = () => {
    setSearchState(clearedSearchState);
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
    sendGaUserInteractionEvent({ category: "Fuzzy Search", action: "Toggle (close)" });
  };

  // Hooks
  useOnClickOutside(searchState.resultsOpen, containerRef, handleClearSearch);

  useEffect(() => {
    const handleClearSearchWithKeyboard = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        handleClearSearch();
        if (inputRef?.current) {
          inputRef.current.value = "";
        }
      }
    };
    document.addEventListener("keydown", handleClearSearchWithKeyboard, false);
    return () => document.removeEventListener("keydown", handleClearSearchWithKeyboard, false);
  }, [handleClearSearch]);

  const classes = useStyles();
  const containerClasses = [classes.fuzzySearchContainer];
  if (inHeader) {
    containerClasses.push(classes["fuzzySearchContainer--inHeader"]);
  }

  return (
    <div className={classes.fuzzySearch} ref={containerRef} onBlur={(event) => {
      setTimeout(() => {
        // Check if the currently focused element is within the parent
        if (containerRef !== null && containerRef.current !== null && !containerRef.current.contains(document.activeElement)) {
          handleClearSearch()
        }
      }, 2);
    }}>
      <div className={containerClasses.join(" ")}>
        <div className={classes.fuzzyInputWrapper}>
          <Icon id="search" />
          <input
            id="fuzzy-search-input"
            ref={inputRef}
            onClick={handleOpenResults}
            onFocus={handleOpenResults}
            onChange={debounce(handleSearchChange, 500)}
            className="form-control"
            placeholder={placeholder}
            aria-label="Search bar to search for a location or topic"
          />
          {(searchState.cachedSearchString || searchState.resultsOpen) && (
            <button onClick={handleClearSearch} className={classes.fuzzyInputClear}>
              <Icon id="close" />
            </button>
          )}
        </div>
        {searchState.searching && (
          <LoadingAnimation
            showLabel={false}
            size={30}
            zIndex={300}
            style={{ minHeight: "300px" }}
          />
        )}
        {(searchState.cachedSearchString || searchState.resultsOpen) && (
          <FuzzySearchResults
            inHeader={inHeader}
            results={searchState.results}
            error={searchState.error ?? false}
            cachedSearchString={searchState.cachedSearchString as string}
            showRecents={showRecents}
            closeModal={handleClearSearch}
          />
        )}
      </div>
      {showRecents && !inHeader && <FuzzySearchRecents />}
    </div>
  );
};

export default FuzzySearch;
