import React, { Fragment } from "react";
import CountUp from "react-countup";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";

const useStyles = createUseStyles({
  title: {
    margin: [0, 0, 10, 0],
    fontWeight: 100,
    fontSize: 32,
    lineHeight: 1,
    [breakpoints.up("md")]: {
      fontSize: 42,
      lineHeight: 1
    }
  },
  number: {
    fontFamily: "open sans",
    marginBottom: 50,
    color: "white",
    textAlign: "center",
    "& span": {
      lineHeight: 1,
      fontSize: 50,
      fontWeight: 800,
      [breakpoints.up("md")]: {
        fontSize: 65
      }
    }
  }
});

type NumberProps = {
  title: string;
  end: number;
  start?: number;
  duration?: number;
  prefix?: string;
  suffix?: string;
};

type UpTickingNumbersProps = {
  numbers: NumberProps[];
};

const UpTickingNumbers: React.FC<UpTickingNumbersProps> = ({ numbers }) => {
  const classes = useStyles();
  return (
    <>
      {numbers.map(({ title, end, start = 0, duration = 1.75, prefix = "", suffix = "" }, i) => (
        <div className={classes.number} key={`number-${i}`}>
          {title && <h2 className={classes.title}>{title}</h2>}
          <CountUp
            start={start}
            end={end}
            duration={duration}
            prefix={prefix}
            suffix={suffix}
            separator=","
          />
        </div>
      ))}
    </>
  );
};

export default UpTickingNumbers;
