import React, { Fragment } from "react";
import titleImage from "images/stories/vaccination/title.jpg";
import hospitalImage from "images/stories/vaccination/hospital.jpg";
import hospitalImage2x from "images/stories/vaccination/hospital@2x.jpg";
import jabImage from "images/stories/vaccination/jab.jpg";
import jabImage2x from "images/stories/vaccination/jab@2x.jpg";
import churchImage from "images/stories/vaccination/church.jpg";
import churchImage2x from "images/stories/vaccination/church@2x.jpg";
import farmerImage from "images/stories/vaccination/man.jpg";
import farmerImage2x from "images/stories/vaccination/man@2x.jpg";
import chickenImage from "images/stories/vaccination/chicken.jpg";
import chickenImage2x from "images/stories/vaccination/chicken@2x.jpg";
import childImage from "images/stories/vaccination/mother-child.jpg";
import childImage2x from "images/stories/vaccination/mother-child@2x.jpg";
import worshipImage from "images/stories/vaccination/worship.jpg";
import worshipImage2x from "images/stories/vaccination/worship@2x.jpg";
import cdcLogoImage from "images/stories/vaccination/cdc_papers.jpg";
import mhcLogo from "images/logos/mhc.svg";
import dhssLogo from "images/logos/dhss_logo_reverse.png";

import { ImageType } from "../types";

type ImageDictionaryEntry = {
  [key: string]: ImageType;
};

const SourceLink = ({ children, href }) => (
  <a href={href} target="_blank" rel="noreferrer">
    {children}
  </a>
);

const UnsplashLink = () => <SourceLink href="https://unsplash.com/">Unsplash</SourceLink>;

const lopezCredit = (
  <>
    Photo by <SourceLink href="https://unsplash.com/@omarlopez1">Omar Lopez</SourceLink> on{" "}
    <UnsplashLink />
  </>
);

export const imageDictionary: ImageDictionaryEntry = {
  title: {
    src: titleImage,
    altText: "Grandfather holding grandchild",
    credit: (
      <>
        Photo by <SourceLink href="https://unsplash.com/@ocvisual">OC Gonzalez</SourceLink> on{" "}
        <UnsplashLink />
      </>
    )
  },
  hospital: {
    src: hospitalImage,
    src2x: hospitalImage2x,
    altText: "Patient in hospital room",
    credit: (
      <>
        Photo by{" "}
        <SourceLink href="https://unsplash.com/@illest_shinobi">Richard Catabay</SourceLink> on{" "}
        <UnsplashLink />
      </>
    )
  },
  jab: {
    src: jabImage,
    src2x: jabImage2x,
    altText: "Person getting vaccination",
    credit: (
      <>
        Photo by <SourceLink href="https://unsplash.com/@cdc">CDC</SourceLink> on <UnsplashLink />
      </>
    )
  },
  church: {
    src: churchImage,
    src2x: churchImage2x,
    altText: "Person in religious setting",
    credit: (
      <>
        Photo by{" "}
        <SourceLink href="https://unsplash.com/@gabiontheroad">Gabriella Clare Marino </SourceLink>{" "}
        on <UnsplashLink />
      </>
    )
  },
  farmer: {
    src: farmerImage,
    src2x: farmerImage2x,
    altText: "Man in blue shirt sitting",
    credit: (
      <>
        Photo by{" "}
        <SourceLink href="https://www.pexels.com/@timmossholder">Tim Mossholder</SourceLink> on{" "}
        <SourceLink href="https://www.pexels.com/photo/man-in-blue-and-white-plaid-dress-shirt-wearing-brown-fedora-hat-sitting-on-red-flower-5614533/">
          Pexels
        </SourceLink>
      </>
    )
  },
  chicken: {
    src: chickenImage,
    src2x: chickenImage2x,
    altText: "Chickens",
    credit: (
      <>
        Photo by <SourceLink href="https://www.pexels.com/@belart84">Artem Beliaikin</SourceLink> on{" "}
        <SourceLink href="https://www.pexels.com/photo/rooster-in-brown-wooden-coop-1459451/">
          Pexels
        </SourceLink>
      </>
    )
  },
  child: {
    src: childImage,
    src2x: childImage2x,
    altText: "Child with mother",
    credit: lopezCredit
  },
  mhcLogo: {
    src: mhcLogo,
    altText: "My Healthy Community — Delaware Environmental Public Health Tracking Network"
  },
  dhssLogo: {
    src: dhssLogo,
    altText: "DHSS — Delaware Health and Social Services"
  },
  cdcLogo: {
    src: cdcLogoImage,
    altText: "CDC logo and paper"
  },
  worship: {
    src: worshipImage,
    src2x: worshipImage2x,
    altText: "Women in religious setting",
    credit: (
      <>
        Photo by <SourceLink href="https://www.pexels.com/@jibarofoto">Luis Quintero</SourceLink> on{" "}
        <SourceLink href="https://www.pexels.com/photo/grayscale-photography-of-people-worshiping-2774570/">
          Pexels
        </SourceLink>
      </>
    )
  }
};

export const getImages = (ids: string[]) => {
  return Object.keys(imageDictionary)
    .filter((key) => ids.includes(key))
    .reduce((cur, key) => Object.assign(cur, { [key]: imageDictionary[key] }), {});
};
