// Compares two variables when we're trying to see if they have some kind of
// relationship. Not currently in use on the site.

import Chart from "./chart";
import { scatter, line } from "billboard.js";
import { colors } from "./options";
import { removeLeadingZero, roundToDigits } from "../utils/number";

export default class extends Chart {
  constructor(props, containerEl, options = {}) {
    super(props, containerEl, options);
  }

  _buildOptions() {
    const { title } = this.props;
    const _options = Object.assign(this.options, {
      grid: {
        x: { show: false },
        y: { show: true }
      },
      data: {
        columns: this._prepareColumns()
      },
      axis: {
        x: {
          label: {
            text: this.props.comparison.name,
            position: "outer-center"
          },
          tick: {
            format: roundToDigits
          }
        },
        y: {
          label: {
            text: this.props.primary.name,
            position: "outer-middle"
          },
          min: 0,
          padding: 20,
          tick: {
            format: removeLeadingZero
          }
        }
      }
    });
    _options.data.xs = _options.data.xs || {};
    _options.data.xs[title] = "compare_x";

    _options.data.types = _options.data.types || {};
    _options.data.types[title] = scatter();

    _options.color = { pattern: [colors()[1], colors()[0]] };

    if (this.options.showTrendLine) {
      _options.data.xs.Trend = "lineX";
      _options.data.types = { Trend: line() };
      _options.data.types[title] = scatter();
    }
    this._options = _options;
    return _options;
  }

  _prepareColumns() {
    let { x, y, line } = this.props.data;
    const _x = [...["compare_x"], ...x];
    const _y = [...[this.props.title], ...y];
    const xMax = Math.max(...x);
    const xMin = Math.min(...x);
    const columns = [_x, _y];
    if (this.options.showTrendLine && line) {
      const _line = [...line.y];
      _line[0] = "Trend";
      columns.push(_line);
      columns.push(["lineX", xMin, xMax]);
    }
    return columns;
  }

  _getLegendLabelData() {
    const labels = [
      {
        name: this.props.comparison.name,
        type: "point",
        location: ""
      }
    ];
    if (this.options.showTrendLine) {
      labels.push({
        name: "Trend",
        type: "line",
        location: ""
      });
    }
    return labels;
  }
}
