import React from "react";
import { createUseStyles } from "react-jss";
import StoryCreditsGroup from "../StoryCreditsGroup";
import Scene from "../Scene/Scene";

import breakpoints from "../../styles/breakpoints";
import { getImages } from "./config";
import { getCreditsByType } from "./copy.tsx";

const useStyles = createUseStyles({
  creditsWrapper: {
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: [0, "5vw"],
    maxWidth: "100%"
  },
  creditsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 50,
    "& h1": {
      fontSize: 32,
      lineHeight: "42px",
      margin: [0, 0, 15, 0]
    },
    "& h2": {
      margin: [0, 0, 10, 0]
    }
  },
  creditsLogoWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    background: "black",
    borderRadius: 20,
    padding: 25,
    "& img": {
      maxHeight: 40
    }
  },
  creditsLogo: {
    padding: [0, 35],
    margin: [15, 0]
  },
  titleDhssWrapper: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none !important",
    padding: [0, 50],
    "& span": {
      color: "white",
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 1,
      marginRight: 5,
      textDecoration: "none !important",
      fontFamily: "open sans"
    }
  },
  titleMhcLogo: {
    width: "100%",
    [breakpoints.up("md")]: {
      width: 400
    }
  }
});

const Credits = () => {
  const classes = useStyles();
  const { dhssLogo, mhcLogo } = getImages(["dhssLogo", "mhcLogo"]);
  return (
    <Scene id="footnotes" className={classes.creditsWrapper}>
      <div className={classes.creditsContainer}>
        <h1>Footnotes & Sources</h1>
        <StoryCreditsGroup title="Footnotes" credits={getCreditsByType("footnotes")} />
        <StoryCreditsGroup title="Sources" credits={getCreditsByType("sources")} />
        <StoryCreditsGroup title="Citation" credits={getCreditsByType("citations")} />
      </div>
      <div className={classes.creditsLogoWrapper}>
        <a
          href="https://myhealthycommunity.dhss.delaware.gov"
          target="_blank"
          rel="noreferrer"
          className={classes.creditsLogo}
        >
          <img className={classes.titleMhcLogo} src={mhcLogo.src} alt={mhcLogo.altText} />
        </a>
        <a
          href="https://dhss.delaware.gov/dhss/"
          target="_blank"
          rel="noreferrer"
          className={classes.titleDhssWrapper}
        >
          <span>DHSS</span>
          <img src={dhssLogo.src} alt={dhssLogo.altText} />
        </a>
      </div>
    </Scene>
  );
};

export default Credits;
