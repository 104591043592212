/* eslint object-shorthand: 0 */

import renderChart from "charts/renderChart";
import { YAxisPlotLinesOptions, SeriesOptionsType } from "highcharts";
import { Controller } from "stimulus";
import { colorConfig, tickInterval, dateTimeLabelFormats } from "charts/config";
import { TimeScale } from "charts/types";

type Benchmark = {
  value: number;
  name: string;
};

type ChartConfig = {
  title: string;
  benchmark?: Benchmark;
  height?: number;
  timeScale?: TimeScale;
  series: Array<{
    name: string;
    color?: string;
    data: number[];
  }>;
  tooltip?: {
    valuePrefix: string;
    valueSuffix: string;
  };
  min?: number;
  max: number;
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({ title = "", benchmark, height, timeScale = "yearly", series, tooltip, min, max }: ChartConfig) {
    const { element } = this;
    const axisLineColor = "#CCCCCC";
    const plotLines = (): YAxisPlotLinesOptions[] => {
      if (!benchmark) return [];
      return [
        {
          value: benchmark.value,
          color: colorConfig.benchmark,
          dashStyle: "Dash",
          width: 1,
          label: {
            text: benchmark.name
          },
          zIndex: 1
        }
      ];
    };
    renderChart({
      title: {
        text: title
      },
      chart: {
        renderTo: element as HTMLElement,
        backgroundColor: "transparent",
        height
      },
      yAxis: {
        title: {
          text: null
        },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
        gridLineWidth: 0,
        plotLines: plotLines(),
        min,
        max
      },
      xAxis: {
        lineColor: axisLineColor,
        tickColor: axisLineColor,
        minorTickColor: axisLineColor,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        type: "datetime",
        dateTimeLabelFormats,
        minTickInterval: tickInterval(timeScale)
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          label: { connectorAllowed: false },
          marker: { enabled: false }
        }
      },
      series: series.map(
        ({ data, name, color }, i: number): SeriesOptionsType => {
          return {
            color: color || colorConfig.set[i],
            type: "spline",
            data: data.map(([d, v]) => [Date.parse(d), v]),
            name
          };
        }
      ),
      tooltip: {
        pointFormatter: function () {
          return `<b style='font-size: 16px'>${tooltip?.valuePrefix ?? ""}${
            this.y?.toLocaleString("en-US") || "N/A"
          }${tooltip?.valueSuffix ?? ""}</b><br/>`;
        }
      }
    });
  }
}
