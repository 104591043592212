import React from "react";
import classnames from "classnames";
import SVGSymbol from "../SVGSymbol";
import compact from "lodash/compact";

interface Props {
  className: string;
  dateRange: string;
  change: boolean[];
}

export const MetricChange: React.FC<Props> = ({ className, dateRange, change }) => {
  const changeDirectionIcon = classnames(
    { "arrow-no-change": change[2] == null },
    { "arrow-down": change[2] !== null && !change[2] },
    { "arrow-up": change[2] !== null && change[2] }
  );
  const changeDirectionClassName = classnames(
    "c-metric-pair__direction",
    { "c-metric-pair__direction--neutral": change[1] == null },
    { "c-metric-pair__direction--negative": change[1] !== null && !change[1] },
    { "c-metric-pair__direction--positive": change[1] !== null && change[1] }
  );
  const changeWords = () => {
    let words = "";
    words += change[2] !== null && !change[2] ? "decrease" : "increase";
    words += " ";
    words += change[1] !== null && !change[1] ? "worsening" : "improvement";
    return words;
  };
  return (
    <div className={className}>
      <div className="c-metric-card__group-title">
        <span id="label-metric-percent-change">% Change</span>
        {dateRange && <span className="c-metric-card__group-title-context"> ({dateRange})</span>}
      </div>
      {change && compact(change).length > 0 && (
        <div className="c-metric-pair c-metric-pair--change">
          <span className={changeDirectionClassName}>
            <SVGSymbol name={changeDirectionIcon} alt={changeWords()} />
          </span>
          <div>
            <span className="c-metric-value c-metric-value--primary d-inline mr-0">
              {change[0]}%
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
