import * as d3 from 'd3'

export const getColor = (colorCalibration, value) => {
  const colors = colorCalibration.colors
  const numColors = colors.length
  const bins = colorCalibration.bins
  const low = colorCalibration.low
  const binSize = parseFloat(colorCalibration.high - low) / numColors
  let offset = parseInt((value-low) / binSize)

  if (bins) {
    // Bins have been pre-determined: no need to calculate
    for (var i = bins.length - 1; i >= 0; i--) {
      const bin = bins[i]
      if (bin.min <= value && value <= bin.max) {
        return colors[i]
      }
    }
  } else if (colorCalibration.continuous) {
    // This branch is not fully tested. Not sure we even need it. see
    // ChoroplethData class
    let otherOffset = null

    if (offset+1 == numColors) {
      otherOffset = offset
    } else {
      otherOffset = offset + 1
    }

    const interp = value - parseInt(value)
    const colorInterpolator = d3.interpolateRgb(colors[offset], colors[otherOffset])

    return(colorInterpolator(interp))
  } else {
    // bin the values into static colors

    if (offset == numColors) {
      offset = offset - 1
    }

    return colors[offset]
  }
}
