export const opacityForStep = (currentIndex: number, index: number) => {
  const condition = currentIndex === index;
  return {
    opacity: condition ? 1 : 0
    // zIndex: condition ? 100 : 1,
  };
};

export const imageSrc = (src: string, src2x = "") => {
  const srcset = [`${src} 1x`, src2x ? `${src2x} 2x` : ""].join(", ");
  return { src, srcset };
};
