import React, { Fragment } from "react";
import { SearchResult } from "./types";
import { createUseStyles } from "react-jss";
import { sendGaNavigationEvent } from "../../javascript/utils/google_analytics";

// Update this to clear users' recents if something changes
// in the search result data
export const VERSION = "1";
export const LOCAL_STORAGE_KEY = `recent_searches`;
// Limit of how many records are stored and shown
export const RECENTS_LIMIT = 5;

const useStyles = createUseStyles({
  recentsPrompt: {
    fontWeight: 600,
    whiteSpace: "nowrap",
    marginRight: 5
  }
});

export const getRecentSearches = () => {
  if (!localStorage) return [];
  // Check version, clear if new version
  const version = localStorage.getItem(`${LOCAL_STORAGE_KEY}_version`);
  if (!version || version != VERSION) {
    localStorage.setItem(LOCAL_STORAGE_KEY, "[]");
  }
  localStorage.setItem(`${LOCAL_STORAGE_KEY}_version`, VERSION);
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "[]") || [];
};


export const storeRecentSearch = (selection: SearchResult) => {
  if (!localStorage) return;
  const recents = getRecentSearches();
  const existingSelection = recents.find((r: SearchResult) => r.name === selection.name);
  if (!existingSelection) {
    recents.unshift(selection);
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(recents.slice(0, RECENTS_LIMIT)));
};

const FuzzySearchRecents = () => {
  const recents = getRecentSearches();
  const classes = useStyles();

  const pathForItem = (path?: string | null, slug?: string | null): string => {
    const locationPath = slug ? `/locations/${slug as string}` : null;
    return path || locationPath || "#";
  };

  return (
    <>
      {recents.length > 0 && (
        <div className="d-block d-md-flex">
          <span className={classes.recentsPrompt}>Recent searches: </span>
          <div>
            {recents.map(({ name, path, slug }: SearchResult, i: number) => (
              <Fragment key={i}>
                <a href={pathForItem(path, slug)} onClick={() =>
                  sendGaNavigationEvent({
                    category: "Fuzzy Search",
                    action: "Select Recent search item",
                    label: name ?? ""
                })}
                >{name}</a>
                {recents.length - 1 !== i ? ", " : ""}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FuzzySearchRecents;
