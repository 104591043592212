import React from "react";
import { createUseStyles } from "react-jss";
import { linkStyle } from "./styles/typography";

const useStyles = createUseStyles({
  storyWrapper: {
    fontFamily: "Crimson Pro",
    "& a": linkStyle
  }
});

const Story: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.storyWrapper}>{children}</div>;
};

export default Story;
