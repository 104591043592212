import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "showZone", "hideZone", "content", "trigger"];

  connect() {
    this.boundShow = this.show.bind(this);
    this.boundHide = this.hide.bind(this);

    this.showZoneTarget.addEventListener("mouseenter", this.boundShow);
    this.hideZoneTarget.addEventListener("mouseleave", this.boundHide);
  }

  disconnect() {
    this.showZoneTarget.removeEventListener("mouseenter", this.boundShow);
    this.hideZoneTarget.removeEventListener("mouseleave", this.boundHide);
  }

  show() {
    $(this.contentTarget).stop();
    $(this.contentTarget).slideDown(250);
  }

  hide() {
    $(this.contentTarget).stop();
    $(this.contentTarget).slideUp(250);
  }

  toggle(event) {
    event.preventDefault();
    const computedDisplay = getComputedStyle(this.contentTarget).getPropertyValue("display");
    if (computedDisplay === "none") {
      this.show();
    } else {
      this.hide();
    }
  }
}
