// @ts-nocheck

import DynamicContentCardController from '../dynamic-content-card-controller'
import { renderBaseMap, MAX_ZOOM, addPointsToMap } from './utils'
import { Map, marker, Marker, icon } from 'leaflet';
import { Feature, FeatureCollection } from 'geojson';
import { gaEvent } from  './utils'
import { renderLoader } from '../../charts/utils'
import 'leaflet.markercluster'

type Location = {
  name: string;
  slug: string;
  geoJson: object;
}
interface  MapData {
  list: string
  pointJson: FeatureGroup
  token: string
  location: Location
}
export default class extends DynamicContentCardController {
  static targets = [ 'map', 'list' ]

  declare map:Map
  declare mapTarget:HTMLElement
  declare listTarget:HTMLElement
  declare markers:object
  declare locationLayer:Layer

  connect() {
    this.renderLoader(this.mapTarget as HTMLElement)
    this.renderLoader(this.listTarget as HTMLElement)
  }

  initialize() {
    const endpoint = this.data.get('endpoint')
    endpoint && this.fetch(endpoint, null, 'json')
  }

  handleLoad({list, pointJson, token, location}:MapData) {
    // Init Map
    this.markers = {}
    this.mapTarget.innerHTML = ''
    this.map = renderBaseMap(this.mapTarget, token, {
      maxZoom: MAX_ZOOM,
      zoomControl: true,
      doubleClickZoom: true,
      touchZoom: true,
      scrollWheelZoom: false,
    })

    // Add current location as layer
    this.locationLayer = L.geoJSON(location.geoJson, {
      interactive: false,
      style: {
        weight: 2,
        color: '#1576D7',
        fill: '#1576D7',
        fillOpacity: .1,
      },
    }).addTo(this.map)
    this.fitToBoundsOfLocation()


    this.markers = addPointsToMap(pointJson, this.map, this.handlePointClick.bind(this))

    // Add List
    if (this.listTarget) {
      this.listTarget.innerHTML = list
    }
  }

  renderLoader(contentContainer?:HTMLElement) {
    renderLoader(contentContainer)
  }

  handleError(error?: any) {
    this.listTarget.innerHTML = `
      <div class='alert alert-danger'>We're sorry, but something went wrong. The engineering team has been notified.</div>
    `;
    if (error) {
      throw error;
    }
  }

  handlePointClick(event:Event) {
    const point = event.target
    if (!point) return
    if (this.listController) {
      this.sendGaEventForPoint(point, {actionPrefix: 'Map'})
      this.listController.scrollToItem(point.options.id)
    }
  }

  highlightPoint(id:string, identifier) {
    const marker = this.markers[identifier] && this.markers[identifier].find((m) => m.options.id === id)
    this.activeMarker && this.activeMarker.closePopup()
    this.activeMarker = marker
    if (this.activeMarker) {
      this.sendGaEventForPoint(marker, {actionPrefix: 'List'})
      this.map.flyToBounds(
        L.latLngBounds([ this.activeMarker.getLatLng() ]),
        { duration: 1, maxZoom: MAX_ZOOM }
      )
      // Delay opening opoover until above animation completes
      setTimeout(() => this.activeMarker.openPopup(), 1100)
    }
  }

  handleUpdateUiState(event:Event) {
    const button = event.currentTarget as HTMLElement
    if (button && button.classList.contains('active')) return
    const { state='' } = button.dataset
    const updateSibling = (el) => {
      (el?.previousElementSibling || el?.nextElementSibling)?.classList.remove('active')
    }
    if (!state) return
    updateSibling(button)
    button.classList.add('active')
    const containerToShow = this[`${state}Target`]
    if (containerToShow) {
      updateSibling(containerToShow)
      containerToShow.classList.add('active')
    }
    if (state === 'map') {
      // If the map was rendered in a container with `display: block`
      // tell the map to resize when we show the container
      this.map.invalidateSize()
      this.fitToBoundsOfLocation()
    }
    gaEvent({
      action: 'Mobile UI state change',
      label: state,
    })
  }

  fitToBoundsOfLocation() {
    if (this.map && this.locationLayer) {
      this.map.fitBounds(this.locationLayer.getBounds(), { maxZoom: MAX_ZOOM })
    }
  }

  private sendGaEventForPoint(point, {actionPrefix=''}) {
    const { options } = point
    const latLng = point.getLatLng().toString()
    const label = options.NAME ? `${options.NAME} (${latLng})` : latLng || ''
    const action = `${actionPrefix} Point Click: ${options.FEATURE_TYPE && options.FEATURE_TYPE.toHuman()}`.trim()
    gaEvent({ action, label })
  }

  get listController() {
    if (this.listTarget) {
      return this.application
        .getControllerForElementAndIdentifier( this.listTarget, 'maps--list' )
    }
  }

}
