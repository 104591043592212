import React from "react";
import { Controller } from "stimulus";
import renderComponent from "../../javascript/renderComponent";
import throttle from "lodash/throttle";
import flatten from "lodash/flatten";
import { ContextSwitcherController, ItemType } from "../types";
import { getMapController, gaEvent } from "./config";

const combineLocationJson = (geoJson = []) => {
  return {
    features: flatten(geoJson.map((json) => json.features)),
    type: "FeatureCollection"
  };
};
type ListHtml = {
  location: string;
  topic: string;
};

export default class extends Controller implements ContextSwitcherController {
  static targets = [
    "canvas",
    "content",
    "tab",
    "locationTab",
    "locationList",
    "topicTab",
    "topicList",
    "locationSearch",
    "topicSearch",
    "switch",
    "map",
    "accordionList",
    "footer"
  ];
  declare canvasTarget: HTMLElement;
  declare locationSearchTarget: HTMLElement;
  declare topicSearchTarget: HTMLElement;
  declare contentTargets: HTMLElement[];
  declare tabTargets: HTMLElement[];
  declare locationTabTarget: HTMLElement;
  declare locationListTarget: HTMLElement;
  declare topicTabTarget: HTMLElement;
  declare topicListTarget: HTMLElement;
  declare mapTarget: HTMLElement;
  declare accordionListTargets: HTMLElement[];
  declare location: ItemType;
  declare topic: ItemType;
  declare switchTargets: HTMLAnchorElement[];
  declare dirty: boolean;
  declare selectedTopicLink: HTMLElement;
  declare selectedLocationLink: HTMLElement;
  declare footerTarget: HTMLElement;
  declare visible: boolean;
  declare activeClassName: string;
  declare switching: boolean;
  declare listsLoaded: boolean;
  declare newUrl: string;

  initialize() {
    this.listsLoaded = false;
    this.dirty = false;
    this.visible = false;
    this.switching = false;
    const currentTopic = JSON.parse(this.data.get("currentTopic") || "{}") as ItemType;
    const currentLocation = JSON.parse(this.data.get("currentLocation") || "{}") as ItemType;
    this.topic = {
      type: "topic",
      ...currentTopic
    };
    this.location = {
      type: "location",
      ...currentLocation
    };
    this.activeClassName = "active";
    this.selectItem = throttle(this.selectItem, 500);
    // Add loading animations to list areas
    this.listTargets.forEach(([el, typeName]) => {
      renderComponent(el, "LoadingAnimation", {
        labelComponent: `Loading ${typeName}`
      });
    });
  }

  connect() {
    // Create event that can be trigger outside of the context of stimulus "applications"
    const event = new Event("trigger-context-switcher");
    document.body.addEventListener("trigger-context-switcher", () => this.toggle());
    App.events = App.events || {};
    App.events.contextSwitcherTrigger = event;
  }

  disconnect() {
    document.body.removeEventListener("event", this.toggle.bind(this));
  }

  initSearches() {
    this.searchTargets.forEach(([searchTarget, type, placeholder]) => {
      renderComponent(searchTarget, "ContextSearch", {
        handleUpdateSelectedItem: this.selectItem.bind(this),
        handleSearchResults: this.handleSearchResults.bind(this),
        type,
        placeholder
      });
    });
  }

  initLists() {
    const errMessage = "There was a problem loading the context switcher locations and topics";
    const addLists = (lists: ListHtml) => {
      // eslint-disable-next-line no-console
      if (!lists) console.error(errMessage);
      const { location, topic } = lists;
      if (this.locationListTarget) {
        this.locationListTarget.innerHTML = location;
      }
      if (this.topicListTarget) {
        this.topicListTarget.innerHTML = topic;
      }
      this.initSearches();
    };
    fetch("/context-switcher/lists")
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        // eslint-disable-next-line no-console
        console.error(errMessage);
      })
      .then(addLists)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(errMessage, e);
      });
    this.listsLoaded = true;
  }

  toggle(event?: KeyboardEvent) {
    if (!this.listsLoaded) {
      this.initLists();
    }
    const { canvasTarget, locationTabTarget } = this;
    canvasTarget.classList.toggle(this.activeClassName);
    this.visible = !this.visible;
    const escape = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && this.visible) {
        this.toggle(event);
      }
    };
    if (this.visible) {
      canvasTarget.parentElement?.setAttribute("aria-hidden", "false")
      locationTabTarget.focus()
      document.body.classList.add("with-veil");
      document.addEventListener("keyup", escape);
    } else {
      canvasTarget.parentElement?.setAttribute("aria-hidden", "true")
      document.getElementById("fuzzy-search-input")?.focus()
      document.body.classList.remove("with-veil");
      document.removeEventListener("keyup", escape);
    }
    gaEvent({
      action: "Toggle",
      label: this.visible ? "Shown" : "Hidden"
    });
  }

  handleSearchResults(results: ItemType[], type: string) {
    const { mapController } = this;
    if (type !== "location" || !mapController) return;
    mapController.clearLayers();
    mapController.addLocations({
      geoJson: combineLocationJson(results.reverse().map((r) => r.geoJson))
    });
  }

  updateItem(item: ItemType, showOnMap = true) {
    const { type, name } = item;
    const target = `${type}TabTarget`;
    this[target].querySelector("span").innerHTML = name;
    this[item.type] = item;
    if (showOnMap && type === "location") {
      this.mapController?.selectLocation(item.name);
    }
    this.animateButtons([this[target]], "highlight");
    this.animateButtons(this.switchTargets);
    this.updateGoButton();
    this.dirty = true;
  }

  selectItem(e: Event, element?: HTMLElement, item?: ItemType) {
    if (e) e.preventDefault();
    const selected: HTMLElement | null = element || (e.currentTarget as HTMLElement);
    if (!item && !selected) return; // If there's no element, noop
    const { slug, type = "", name, parents, locationDependent, path } = item || selected?.dataset;
    if (selected) {
      const selectedKey = `selected${type.charAt(0).toUpperCase()}Link`;
      if (this[selectedKey]) {
        this[selectedKey].classList.remove(this.activeClassName);
      }
      this.updateItem({
        slug,
        name,
        type,
        path,
        parents,
        locationDependent
      });
      selected.classList.add(this.activeClassName);
      this[selectedKey] = selected;
      let _type = type.capitalize();
      if (selected?.dataset.otherType) {
        _type = `${_type} (${selected?.dataset.otherType})`;
      }
      gaEvent({
        action: `Select ${_type}`,
        label: name
      });
    }
  }

  animateButtons(btns: HTMLElement[], animation = "bounce") {
    btns.forEach((btn) => {
      btn.classList.add(animation);
      setTimeout(() => {
        btn.classList.remove(animation);
      }, 1000);
    });
  }

  updateGoButton() {
    const { switchTargets } = this;
    this.footerTarget.classList.add("enabled");
    switchTargets.forEach((switchTarget) => {
      switchTarget.removeAttribute("disabled");
      switchTarget.removeAttribute("title");
      switchTarget.removeAttribute("data-bs-toggle");
      switchTarget.classList.remove("disabled");
      switchTarget.classList.add(this.activeClassName);
      switchTarget.href = this.updateUrl();
    });
  }

  updateUrl() {
    let href: string | string[] = [];
    let locationPart = "";
    let topicPart = "";
    const { location, topic } = this;
    if (topic.locationDependent === "true" || topic.locationDependent === true) {
      const locationPath = (location.path || location.slug).replace("/", "");
      locationPart = `/locations/${locationPath}`;
    }
    if (this.topic.path) topicPart = topic.path;
    if (this.externalPortals.some((p: string) => topic.path?.includes(p))) {
      href = [topicPart, locationPart];
    } else {
      href = [locationPart, topicPart];
    }
    href = href.join("") as string;
    this.newUrl = href;
    return href;
  }

  switch(event?: Event, go = false, triggerGaEvent = true) {
    if (triggerGaEvent) {
      gaEvent({
        action: "Switch",
        label: `Location: ${this.location.name} | Topic: ${this.topic.name}`
      });
    }
    if (this.switching && event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    this.enableLoadingState();
    if (go) {
      window.location = this.updateUrl();
    }
    this.switching = true;
  }

  enableLoadingState() {
    this.tabTargets.forEach(({ parentElement }) => {
      if (parentElement) {
        parentElement.style.opacity = ".3";
        parentElement.style.pointerEvents = "none";
      }
    });
    this.switchTargets.forEach((el) => {
      el.classList.remove("active");
      el.classList.add("loading");
      el.innerHTML = "Loading...";
      el.blur();
    });

    let locationText = "";
    if (this.location?.name) {
      locationText = `of ${this.location.name}`;
    }
    renderComponent(document.body, "LoadingAnimation", {
      veil: true,
      withBG: true,
      positionFixed: true,
      labelComponent: `Navigating to ${this.topic.name} ${locationText}`.trim()
    });
  }

  changeContent(event?: Event, contentType?: string) {
    let tabIndex = 0;
    let tab: HTMLElement;
    if (event) {
      tab = event.currentTarget as HTMLElement;
      tabIndex = this.tabTargets.indexOf(tab);
    } else if (contentType) {
      tabIndex = this.typeLookup[contentType];
      tab = this.tabTargets[tabIndex] as HTMLElement;
    }
    if (this.contentIndex === tabIndex) return;
    this.tabTargets.forEach((t) => {
      if (t === tab) {
        t.classList.add(this.activeClassName);
      } else {
        t.classList.remove(this.activeClassName);
      }
    });
    this.contentIndex = tabIndex;
    gaEvent({
      action: "Change Content Tab",
      label: tab ? tab.dataset.type : "Tab unknown"
    });
  }

  showArea() {
    this.contentTargets.forEach((content: HTMLElement, index: number) => {
      content.classList.remove("active");
      if (index === this.contentIndex) {
        content.classList.add("active");
      }
    });
  }

  trackEvent({ currentTarget }: Event) {
    const { gaAction = "", gaLabel = "" } = (currentTarget as HTMLElement)?.dataset;
    if (gaAction || gaLabel) {
      gaEvent({ action: gaAction, label: gaLabel });
    }
  }

  get searchTargets() {
    return [
      [this.locationSearchTarget, "location", this.locationSearchTarget.dataset.placeholder],
      [this.topicSearchTarget, "topic", this.topicSearchTarget.dataset.placeholder]
    ];
  }

  get listTargets() {
    return [
      [this.locationListTarget, "Locations"],
      [this.topicListTarget, "Topics"]
    ];
  }

  get contentIndex() {
    return +(this.data.get("contentIndex") || 0);
  }

  get externalPortals() {
    return JSON.parse(this.data.get("externalPortals")) || [];
  }

  get mapController() {
    return getMapController(this.application, this.element as HTMLElement);
  }

  get typeLookup() {
    return {
      location: 0,
      topic: 1
    };
  }

  set contentIndex(value: number) {
    this.data.set("contentIndex", `${value}`);
    this.showArea();
  }
}
