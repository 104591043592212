// This is closely related to app/assets_webpacker/controllers/context_switcher/layout-controller.ts
// but not extending it yet because some the functionality
// is different enough to require an independent controller
// We should update the controller above to accommodate only one list being rendered
import React from "react";
import { Controller } from "stimulus";
import renderComponent from "../../javascript/renderComponent";
import throttle from "lodash/throttle";
import flatten from "lodash/flatten";
import { GeoJsonObject } from "geojson";
import { ItemType } from "../types";
import { getMapController, gaEvent } from "./config";

const combineLocationJson = (geoJSON: GeoJsonObject[]) => {
  return {
    features: flatten(geoJSON.map((json) => json.features)),
    type: "FeatureCollection"
  } as GeoJsonObject;
};

type ListHtml = {
  location: string;
};

export default class extends Controller {
  [key: string]: any;
  static targets = [
    "canvas",
    "content",
    "locationList",
    "locationSearch",
    "switch",
    "map",
    "accordionList"
  ];
  declare canvasTarget: HTMLElement;
  declare locationSearchTarget: HTMLElement;
  declare contentTargets: HTMLElement[];
  declare locationListTarget: HTMLElement;
  declare mapTarget: HTMLElement;
  declare accordionListTargets: HTMLElement[];
  declare location: ItemType;
  declare switchTargets: HTMLAnchorElement[];
  declare dirty: boolean;
  declare selectedLocationLink: HTMLElement;
  declare visible: boolean;
  declare activeClassName: string;
  declare switching: boolean;
  declare listsLoaded: boolean;
  declare newUrl: string;

  initialize() {
    this.listsLoaded = false;
    this.dirty = false;
    this.visible = false;
    this.switching = false;
    const currentLocation = JSON.parse(this.data.get("currentLocation") || "{}") as ItemType;
    this.location = {
      slug: currentLocation.slug,
      name: currentLocation.human_name,
      type: "location"
    };
    this.activeClassName = "active";
    this.selectItem = throttle(this.selectItem, 500);
    // Add loading animations to list areas
    this.listTargets.forEach(([el, typeName]) => {
      renderComponent(el, "LoadingAnimation", { labelComponent: `Loading ${typeName}` });
    });
    this.initLists();
  }

  initSearches() {
    this.searchTargets.forEach(([searchTarget, type, placeholder]) => {
      renderComponent(searchTarget, "ContextSearch", {
        handleUpdateSelectedItem: this.selectItem.bind(this),
        handleSearchResults: this.handleSearchResults.bind(this),
        type,
        placeholder
      });
    });
  }

  initLists() {
    const errMessage = "There was a problem loading the context switcher locations and topics";
    const addLists = (lists: ListHtml) => {
      // eslint-disable-next-line no-console
      if (!lists) console.error(errMessage);
      const { location } = lists;
      this.locationListTarget.innerHTML = location;
      // this.locationListTarget.querySelector("[id='location__context-switcher-list']")
      this.initSearches();
    };
    fetch("/context-switcher/location-list")
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        // eslint-disable-next-line no-console
        console.error(errMessage);
      })
      .then(addLists)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(errMessage, e);
      });
    this.listsLoaded = true;
  }

  handleSearchResults(results: ItemType[], type: string) {
    if (type !== "location") return;
    const { mapController } = this;
    if (results && results.length) {
      mapController?.addLocations({
        geoJson: combineLocationJson(results.reverse().map((r) => r.geoJson))
      });
    } else {
      mapController?.clearLayers();
    }
  }

  updateItem(item) {
    this.selectItem(null, null, item);
  }

  selectItem(e: Event | null, element?: HTMLElement | null, item?: ItemType) {
    const selected = element || (e?.currentTarget as HTMLElement);
    if (e) {
      e.preventDefault();
    }
    if (!item && !selected) return; // If there's no element, noop
    const { slug, path, name } = item || selected?.dataset;
    if (path || slug) {
      this.location = { name, path, slug, type: "location" };
      window.location.href = `/locations/${path || slug}/topics`;
    }
  }

  enableLoadingState() {
    this.canvasTarget.classList.add("loading");
    let locationText = "";
    if (this.location?.name) {
      locationText = this.location.name;
    }
    renderComponent(document.body, "LoadingAnimation", {
      veil: true,
      withBG: true,
      positionFixed: true,
      labelComponent: `Navigating to ${locationText}`.trim()
    });
  }

  trackEvent({ currentTarget }: Event) {
    const { gaAction = "", gaLabel = "" } = (currentTarget as HTMLElement)?.dataset;
    if (gaAction || gaLabel) {
      gaEvent({ action: gaAction, label: gaLabel });
    }
  }

  get searchTargets() {
    return [[this.locationSearchTarget, "location", this.locationSearchTarget.dataset.placeholder]];
  }

  get listTargets() {
    return [[this.locationListTarget, "Locations"]];
  }

  get contentIndex() {
    return +(this.data.get("contentIndex") || 0);
  }

  get loadListOnInit() {
    return this.data.get("loadListOnInit");
  }

  get mapController() {
    return getMapController(this.application, this.element as HTMLElement);
  }

  set contentIndex(value: number) {
    this.data.set("contentIndex", `${value}`);
    this.showArea();
  }
}
