// There are many unknown types and it doesn't really make sense to spend the time at this point
// getting them all right. Instead, we can just use the @ts-nocheck directive to ignore the errors
// eslint-disable-next-line
// @ts-nocheck
/* eslint-disable */
import { Controller } from "stimulus";
import { FeatureGroup } from "leaflet";
import { AccordionListController, ItemType } from "../types";
import { childComponentName, getContextController, getMapController, gaEvent } from "./config";
import Tooltip from "bootstrap/js/src/tooltip";

export default class extends Controller implements AccordionListController {
  selectAll: Function;
  deselectAll: Function;
  static targets = ["item"];
  declare itemTargets: HTMLElement[];
  declare open: boolean;
  declare activeLayer: FeatureGroup;
  declare itemTooltip: object;

  initialize() {
    this.open = false;
  }

  handleMouseOver(e: Event) {
    this.addGoLink(e.currentTarget);
    this.mapController?.highlightLocation(e.currentTarget?.dataset);
  }

  handleMouseOut(event: Event) {
    const el = event.toElement || event.relatedTarget;
    if (!el || (el.nodeName === "A" && el.classList.contains("go"))) {
      return;
    }
    this.removeGoLink(event?.currentTarget as HTMLElement);
    this.mapController?.unHighlightLocation();
    if (this.type !== "location") return;
  }

  handleClick(e: Event) {
    e.preventDefault();
    e.currentTarget && this.selectItem(e.currentTarget as HTMLElement);
  }

  setOpenState(state: boolean) {
    this.open = state;
    if (!state) {
      this.mapController?.clearLayers();
    }
  }

  toggle() {
    if (this.type !== "location") return;
    if (this.open) {
      this.setOpenState(false);
      return;
    }

    // set state of all list controllers to not open
    [...this.element.parentElement.children].forEach((el: HTMLElement) => {
      if (el === this.element) return;
      const controller = this.application.getControllerForElementAndIdentifier(
        el,
        childComponentName("accordion")
      ) as AccordionListController;
      controller?.setOpenState(false);
    });

    const callbacks = {
      mouseover: (name: string) => {
        this.itemTargets.forEach((item) => {
          if (item.dataset.name?.trim() === name.trim()) {
            item.classList.add("hover");
          } else {
            item.classList.remove("hover");
          }
        });
      },
      click: (item: ItemType) => {
        if (!item) return;
        const selected = this.itemTargets.find(
          (el) => el.dataset.path && el.dataset.path.includes(item.path)
        );
        selected && this.selectItem(selected);
      },
      mouseout: () => {
        this.deSelectAll();
      }
    };
    if (this.searchableType && !this.mapController?.isDisabled) {
      this.mapController?.addLocations({
        searchableType: this.searchableType,
        clearLayers: true,
        callbacks
      });
    }
    this.open = true;
    gaEvent({
      action: `${this.type.capitalize()} Accordion List Toggle`,
      label: this.element.querySelector("span")?.innerHTML
    });
  }

  deSelectAll() {
    this.itemTargets.forEach((el) => {
      el.classList.remove("hover");
      this.removeGoLink(el);
    });
  }

  selectItem(selectedEl: HTMLElement) {
    if (!selectedEl || selectedEl.classList.contains("active")) return;
    this.deSelectAll();
    const { contextController } = this;
    contextController?.selectItem(null, selectedEl);
    selectedEl.classList.add("active");
    this.addGoLink(selectedEl);
  }

  addGoLink(selectedEl: HTMLElement) {
    if (selectedEl.querySelector("a") || this.parentController.includes("selector")) return;
    const { contextController } = this;
    if (!contextController) return;
    const { type } = selectedEl.dataset;
    const goLink = document.createElement("a");
    const location = contextController.location.name || contextController.location.human_name;
    const locationSlug = contextController.location.slug;
    const topic = contextController.topic.name;
    const topicPath = contextController.topic.path;

    let newLocation = location;
    let newTopic = topic;
    let url = contextController.updateUrl();
    if (!url) return;
    if (type === "location") {
      newLocation = selectedEl.dataset.name;
      const newLocationSlug = selectedEl.dataset.path;
      if (newLocationSlug) url = url.replace(locationSlug, newLocationSlug);
    } else if (type === "topic") {
      const { locationDependent = "true", path: newTopicPath } = selectedEl.dataset;
      newTopic = selectedEl.dataset.name;
      newTopicPath && locationDependent === "true" && (url = url.replace(topicPath, newTopicPath));
      newTopicPath && locationDependent === "false" && (url = newTopicPath);
    }

    goLink.setAttribute("title", `Go to ${newTopic} of ${newLocation}`);
    goLink.setAttribute("href", url);
    this.itemTooltip = new Tooltip(goLink);
    goLink.classList.add("go");
    const text = document.createTextNode("Go");
    goLink.appendChild(text);
    selectedEl.appendChild(goLink);
    goLink.addEventListener("click", (event: MouseEvent) => {
      const link = event.currentTarget as HTMLElement;
      link && link.parentElement && this.selectItem(link.parentElement);
      this.itemTooltip?.dispose();
      gaEvent({
        action: `Switch from item Go button (${type})`,
        label: `Location: ${newLocation} | Topic: ${newTopic}`
      });
      contextController?.switch(null, true, false);
    });
  }

  removeGoLink(el: HTMLElement) {
    const goLink = el.querySelector(".go");
    if (!goLink) return;
    this.itemTooltip?.hide();
    goLink.removeEventListener("click", () => {
      return;
    });
    goLink.remove();
  }

  get mapController() {
    return getMapController(this.application, this.element as HTMLElement);
  }

  get parentController() {
    return this.data.get("parentController");
  }

  get contextController() {
    return getContextController(this.application, {
      parentController: this.parentController
    });
  }

  get type() {
    return this.data.get("type");
  }

  get searchableType() {
    return this.data.get("searchableType");
  }
}
