import React from "react";

const Resource = ({ title, url, description }) => (
  <li className="pb-3">
    <a target="_blank" href={url} rel="noreferrer">
      {title}
    </a>
    <span className="d-block">{description}</span>
  </li>
);

const Resources = ({ data = [] }) => (
  <div className="o-topic-context__content o-container--constrained mb-6" aria-label="Resources">
    <ul className="list-unstyled">
      {data.map((resource, i) => (
        <Resource key={`resource-${i}`} {...resource} />
      ))}
    </ul>
  </div>
);

export default Resources;
