import { checkStyleAvailability } from "../utils/style";
import "./remove";

export default class objectFit {
  /**
   * constructor - Determine if object fit is supported then replace with background image if not
   *
   * @param  {String} imgParentElement  DOM element's class or id
   * @return NA
   */
  constructor(imgParentElement) {
    if (!checkStyleAvailability("objectFit")) {
      this.imgParentElement = imgParentElement;
      this.replace();
    }
  }

  /**
   * replace - Add inline styles to parent element with background image of source of child image
   *           source then remove from DOM
   *
   * @return NA
   */
  replace() {
    const parentEl = document.querySelector(this.imgParentElement);
    if (parentEl) {
      const image = parentEl.querySelector("img");
      parentEl.setAttribute(
        "style",
        `background-image: url(${image.src}); background-position: center center; background-size: cover;`
      );
      parentEl.classList.add("image-background");
      image.remove();
    }
  }
}
