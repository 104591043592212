import React, { Component } from "react";

export default class MapPointLegend extends Component {
  shouldComponentUpdate(prevProps) {
    return false;
  }

  render() {
    const { items } = this.props;
    return (
      <div className="c-map-legend" aria-label="Map legend">
        <div className="c-legend c-legend--flex-0 mr-6">
          <div className="c-legend__items">
            {items.map((i) => (
              <LegendItem {...i} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

class LegendItem extends Component {
  render() {
    const { icon, label } = this.props;
    return (
      <div className="c-legend__item">
        {icon ? <img src={icon} className="c-legend__icon" height={42} alt="" /> : null}
        <span className="c-legend__label">{label}</span>
      </div>
    );
  }
}
