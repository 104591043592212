/**
 * removeLeadingZero - Remove 0 in something like "0.4"
 *
 * @param  {Number} num number like '0.4'
 * @return {String}     string without leading "0"
 */
export function removeLeadingZero(num) {
  return num.toString().replace(/^[0|\.]/, '')
}

/**
 * roundToDigits -
 *
 * @param  {Number} num
 * @param  {Number} maxSigDigits=null
 * @param  {Number} maxFractionDigits=null
 * @param  {Number} minFractionDigits=null
 * @return {Number}
 */
export function roundToDigits(num, maxSigDigits=null, maxFractionDigits=null, minFractionDigits=null) {
  const options = {}
  if (maxSigDigits !== null && typeof(maxSigDigits) === 'number')
    options.maximumSignificantDigits = maxSigDigits
  if (maxFractionDigits !== null && typeof(maxFractionDigits) === 'number')
    options.maximumFractionDigits = maxFractionDigits
  if (minFractionDigits !== null && typeof(minFractionDigits) === 'number')
    options.minimumFractionDigits = minFractionDigits
  return Number(num).toLocaleString('en', options)
}


/**
 * getMaxMin - Get max/min numbers from array
 *
 * @param  {Array} nums
 * @return {Array}         [ min, max ]
 */
export function getMaxMin(nums=[]) {
  return [
    Math.min(...nums),
    Math.max(...nums)
  ]
}
/**
 * roundToMultipleOf - Take a number and round to a multiple of another
 *
 * @param  {Number} num .    - Number to round
 * @param  {Number} multiple - Multiple to round to
 */
export function roundToMultipleOf(num, multiple, method='ceil') {
  return Math[method](num/5)*multiple;
}

export const addTrailingZeros = (num) => {
  const stringNum = String(num)
  if (stringNum.split('.').length === 1)
    return `${stringNum}.0`
  else
    return num
}
