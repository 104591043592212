import React, { Fragment, useState, useRef, useCallback, SyntheticEvent } from "react";
import Select, { OptionType } from "./Select";
import PollingButton from "./PollingButton";
import { createUseStyles } from "react-jss";
import { sendGAEvent } from "../../javascript/utils/google_analytics";
import breakpoints from "../styles/breakpoints";

const useStyles = createUseStyles({
  createButton: {
    flex: [1, 1, "200px"],
    [breakpoints.up("sm")]: {
      maxWidth: 200
    }
  },
  notification: {
    lineHeight: "22px"
  }
});

interface DataDownloadProps {
  options: OptionType;
  endpoint: string;
  param: string;
  identifier?: string;
  identifierName?: string;
}

export interface DownloadState {
  creating: boolean;
  generated: boolean;
  selectedOption: string;
  error?: boolean;
  errorMessage?: string;
  url?: string;
  fileName?: string;
  takingTooLong?: boolean;
}

type CachedUrls = Record<string, string>;

const DataDownload: React.FC<DataDownloadProps> = ({
  options,
  endpoint,
  param,
  identifier,
  identifierName
}) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const cachedUrls = useRef<CachedUrls>({});
  const getSelectedOption = () => selectRef?.current?.value || "";
  const [downloadState, setDownloadState] = useState<DownloadState>({
    creating: false,
    generated: false,
    error: false,
    selectedOption: getSelectedOption()
  });

  const handleSelectChange = () => {
    const selectedOption = getSelectedOption();
    setDownloadState({
      generated: false,
      creating: false,
      error: false,
      url: "",
      selectedOption
    });
    sendGAEvent({
      eventType: "user_interaction",
      parameters: {
        category: "Data Download",
        action: `Option Change`,
        label: selectedOption
      }
    });
  };

  const handleDataCreation = useCallback(
    (event: SyntheticEvent) => {
      sendGAEvent({
        eventType: "user_interaction",
        parameters: {
          category: "Data Download",
          action: `Creation (${selectRef?.current?.value ?? ""})`,
          label: `${identifier as string}: ${identifierName as string}`
        }
      });
      event.preventDefault();
      const selectedOption = getSelectedOption();
      if (!selectRef?.current) {
        setDownloadState({
          generated: true,
          creating: false,
          error: true,
          selectedOption
        });
      }
      setDownloadState({
        generated: false,
        creating: true,
        error: false,
        selectedOption
      });
    },
    [identifier, identifierName]
  );

  const handleDownloadClick = () => {
    sendGAEvent({
      eventType: "file_download",
      parameters: {
        category: "Data Download",
        action: `Download (${selectRef?.current?.value ?? ""})`,
        label: `${identifier ?? ""}: ${identifierName ?? ""}`
      }
    });
  };

  const { creating, generated, error, takingTooLong, selectedOption, url = null } = downloadState;
  if (url) {
    cachedUrls.current = {
      ...cachedUrls.current,
      [selectedOption]: url
    };
  }
  const _url = cachedUrls.current[selectedOption] || url || null;
  const _endpoint = `${endpoint}?${param}=${selectedOption}`;
  const classes = useStyles();

  return (
    <>
      <Select options={options} selectRef={selectRef} handleChange={handleSelectChange} />
      <div className="d-flex align-items-center">
        {((!creating && !generated && !_url) || error) && (
          <button
            className={`btn btn-outline-primary ${classes.createButton}`}
            onClick={handleDataCreation}
          >
            Generate data
          </button>
        )}
        {error && (
          <p className={`text-danger fw-bold ml-4 mb-0 ${classes.notification}`}>
            We&apos;re having trouble generating these data. Please try again.
          </p>
        )}
        {takingTooLong && (
          <p className={`ml-4 fw-bold mb-0 ${classes.notification}`}>
            The data generation is taking longer than expected. <br />
            Please try again in a few minutes. Thanks for your patience.
          </p>
        )}
      </div>
      {creating && (
        <PollingButton
          endpoint={_endpoint}
          setDownloadState={setDownloadState}
          selectedOption={selectedOption}
        />
      )}
      {_url && (
        <a href={_url} className="btn btn-success" onClick={handleDownloadClick}>
          <i className="fas fa-download mr-2" />
          <span>Download data</span>
        </a>
      )}
    </>
  );
};

export default DataDownload;
