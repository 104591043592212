import { geoJSON } from "leaflet";
import { ChoroplethConfig } from "./types";
import ChoroplethController from "./choropleth-controller";
import { renderLoader, removeLoader } from "../../charts/utils";
import { sendGAEvent } from "../../javascript/utils/google_analytics";
  // This file exists — looks like eslint has trouble finding it
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
import { dateToEndpoint } from "../../javascript/utils/date";

export default class extends ChoroplethController {
  static targets = ["rangeSelector"];
  declare rangeSelectorTarget: HTMLElement;

  handleLoad(data: ChoroplethConfig) {
    this.containingElement && removeLoader(this.containingElement as HTMLElement);
    if (this.map) {
      this.updateMap(data);
    } else {
      this.buildMap(data);
      this.rangeSelectorTarget.innerHTML = data.dateSelectorHtml || "";
    }
  }

  updateMap(config: ChoroplethConfig) {
    if (!config) {
      // eslint-disable-next-line no-console
      console.error("Map could not be updated at this time");
    }
    const loaderEl = this.contentTarget.querySelector(".c-loading-animation");
    loaderEl?.remove();
    const { unitsAffix = "", colorCalibration = {}, identifier } = config.props;
    const { polygons } = config.geoJson;

    // If dataset has been changed
    if (this.activeIdentifier !== identifier) {
      this.colorCalibration = colorCalibration;
      this.addLegend(unitsAffix, colorCalibration);
      this.addInfoBox();
      this.rangeSelectorTarget.innerHTML = config.dateSelectorHtml || "";
    }
    // Update map polygons
    if (this.geoJson) {
      this.map.removeLayer(this.geoJson);
    }
    this.geoJson = geoJSON(polygons, {
      // Leaflet typings are tough ... not worth the effort to fix
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style: this.getStyle.bind(this),
      onEachFeature: this.onEachFeature.bind(this)
    }).addTo(this.map);

    // Leaflet typings are tough ... not worth the effort to fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.locationLayer.bringToFront();
    this.activeIdentifier = identifier;
  }

  async handleUpdateDataSet(event: Event) {
    const selectElement = event.currentTarget as HTMLSelectElement;
    const identifier = selectElement?.value;
    if (!selectElement || !this.containingElement) return
    const { endpoint } = this;
    if (endpoint && identifier) {
      renderLoader(this.containingElement as HTMLElement);
      this.identifier = identifier;
      const option = selectElement.options.namedItem(`option-${identifier}`);
      const label = option ? `${option.text} (${identifier})` : identifier;
      sendGAEvent({eventType: "user_interaction", parameters: {
        category: "Maps",
        action: "Dataset change",
        label
      }});
      return this.fetch(`${endpoint}${identifier}`, this.contentTarget, "json")
                 .catch(this.handleError);
    } else {
      this.handleError();
    }
  }

  async filterByRange(event: Event) {
    const date = (event.currentTarget as HTMLSelectElement)?.value;
    if (!date || !this.containingElement) return
    const { endpoint, identifier } = this;
    if (endpoint && date) {
      renderLoader(this.containingElement as HTMLElement);
      sendGAEvent({eventType: "user_interaction", parameters: {
        category: "Maps",
        action: "Date range change",
        label: date
      }});
      return this.fetch(`${endpoint}${identifier}${dateToEndpoint(date) as string}`, this.contentTarget, "json")
          .catch(this.handleError);
    } else {
      this.handleError();
    }
  }

  get containingElement(): HTMLElement | null {
    return this.contentTarget.parentElement;
  }
}
