import Tooltip from "bootstrap/js/src/tooltip";

export const initTooltips = (domScope = document) => {
  const tooltipTriggerList = [].slice.call(domScope.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const options = {
    delay: 200,
    boundary: "window"
  };
  tooltipTriggerList.forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, options));
};

document.addEventListener("DOMContentLoaded", () => {
  initTooltips();
});
