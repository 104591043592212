import DynamicContentCardController from './dynamic-content-card-controller'

export default class extends DynamicContentCardController {
  static targets = ['content'];
  declare contentTarget:HTMLElement;

  async filterByRange(event: Event) {
    const selectInput = event?.currentTarget as HTMLSelectElement
    if (!selectInput) return
    this.renderLoader()
    let endpoint = this.baseEndpoint
    if (!endpoint) {
      this.handleError(new Error("missing url"));
      return
    }
    const filter = selectInput.value
    if (!filter) {
      return this.fetch(endpoint)
    }
    const range = filter.split(' - ')
    if (range.length > 1) {
      endpoint += `/range/${range[0]}/${range[1]}`
    } else if (filter) {
      endpoint += `/${filter}`
    } else {
      endpoint += `/cumulative`
    }
    return this.fetch(endpoint)
  }
}
