import React, { useEffect, useRef, useState, Fragment } from "react";
import { addPointsToMap, renderBaseMap } from "../../controllers/maps/utils";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - Leaflet types are available
import { geoJSON, FeatureCollection } from "leaflet";
import LoadingAnimation from "../../components/stories/LoadingAnimation";

interface PointMapProps {
  points: string[];
  slug: string;
  title: string;
}

interface MapProps {
  mapEl?: HTMLDivElement | null;
  pointJson: string[];
  token: string;
  location: {
    geoJson: FeatureCollection;
  };
}

const renderMap = ({ mapEl, pointJson, location, token }: MapProps) => {
  if (!mapEl) return;
  const map = renderBaseMap(mapEl, token, {
    scrollWheelZoom: false
  });
  if (location && location.geoJson) {
    const locationLayer = geoJSON(location.geoJson, {
      style: {
        weight: 1,
        opacity: 0.75,
        color: "#C24644",
        dashArray: "",
        fillColor: "#147BB1",
        fillOpacity: 0.25
      }
    });
    locationLayer.addTo(map);
    map.fitBounds(locationLayer.getBounds());
  }
  addPointsToMap(pointJson, map);
};

const fetchPointData = async ({ points, slug }: Omit<PointMapProps, "title">) => {
  try {
    const response = await fetch(`/locations/${slug}/point_map/${points.join("+")}`);
    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("There was an issue with loading data for this map", error);
    return "ERROR";
  }
};

const PointMap: React.FC<PointMapProps> = ({ points, slug, title }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (!mapContainerRef?.current) return;
    async function _getMapData() {
      const { location, token, pointJson } = await fetchPointData({ points, slug });
      renderMap({
        mapEl: mapContainerRef.current,
        pointJson,
        token,
        location
      });
    }
    _getMapData()
      .then(() => setLoading(false))
      .catch((error: string) => setError(error));
  }, [points, slug, mapContainerRef]);

  return (
    <>
      <h3 className="o-map__title">{title}</h3>
      <div className="px-4 pt-4">
        <div className="row align-items-center flex-wrap">
          <div
            ref={mapContainerRef}
            className="c-map__container c-map__container--constrained"
            style={{ minHeight: "65vh", width: "100%" }}
          />
          {error && !loading && (
            <p>There was an error while loading this map. Please try reloading the page.</p>
          )}
          {loading && <LoadingAnimation />}
        </div>
      </div>
    </>
  );
};

export default PointMap;
