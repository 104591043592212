import React, { Component } from "react";
import { roundToDigits } from "../utils/number";
import { ticks } from "d3";
import isUndefined from "lodash/isUndefined";
import classnames from "classnames";

export default class MapGradientLegend extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(prevProps) {
    return false;
  }

  legendData() {
    const legend = this.props;
    // Do not show legend if no legend data
    if (!legend || !legend.colors || !legend.colors.length) return null;
    const colors = [...legend.colors];
    // Set no data color to -1000 value
    const noDataColor = colors.shift();
    // Remove -.01 value
    colors.shift();
    const gradientStyle = colors.map((color, i) => {
      return `rgb(${color.rgb}) ${(i / colors.length) * 100}%`;
    });
    var precision = legend.precision;
    if (precision == null) {
      precision = 2;
    }

    var min, max;
    if (colors.length > 0) {
      min = colors[0].value.toFixed(precision);
      max = colors[colors.length - 1].value.toFixed(precision);
    } else {
      min = 0;
      max = 0;
    }

    const gradient = `linear-gradient(to right, ${gradientStyle.join()}`;
    return {
      gradientStyle: { background: gradient },
      unit: legend.unit,
      min: min,
      max: max,
      noDataColor: { background: noDataColor ? `rgb(${noDataColor.rgb})` : "#616a6f" }
    };
  }

  label(label, value, showUnit = false) {
    if (isUndefined(value)) return null;
    return (
      <span className={`c-legend__label c-legend__label--${label}`}>
        <span className="c-legend__label-value"> {roundToDigits(value) || ""} </span>
        {showUnit ? <span className="c-legend__label-unit"> {this.props.unit || ""} </span> : null}
      </span>
    );
  }

  ticks(min, max, tickCount = 10) {
    const legendTicks = ticks(min, max, tickCount);
    return legendTicks.map((tick, i) => {
      let renderTick = true;
      renderTick = !(max - tick < (legendTicks[1] - legendTicks[0]) / 2);
      return renderTick ? tick : null;
    });
  }

  render() {
    const { min, max, gradientStyle, noDataColor } = this.legendData();
    const { bgWhite = false, unit, showNote = true } = this.props;
    const wrapperClasses = classnames("c-map-legend", { "c-map-legend--bg-white": bgWhite });
    return (
      <div>
        <div className={wrapperClasses} aria-label="Map legend">
          <div className="c-legend c-legend--flex-0 mr-6">
            <div className="c-legend__labels">
              <span className="c-legend__label">Insufficient data</span>
            </div>
            <div className="c-legend__no-data" style={noDataColor} />
          </div>
          <div className="c-legend c-legend--with-ticks">
            <div className="c-legend__labels">
              {this.label("btm", min, false)}
              <span className={`c-legend__label c-legend__label--unit`}>{unit || ""}</span>
              {this.label("top", max, false)}
            </div>
            <div className="c-legend__gradient" style={gradientStyle}>
              {this.ticks(min, max).map((tick, i) => {
                tick && (
                  <div
                    key={i}
                    className="c-legend__label-tick"
                    style={{ left: `${(tick / max) * 100}%` }}
                  >
                    <span className="c-legend__label">{tick}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {showNote && (
          <div className="c-topic-note pt-0">
            <div className="c-topic-note__wrapper">
              <div className="c-topic-note__message">
                <p className="c-topic-note__body">
                  Only counts of 5 or more are used to create maps.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
