import React from "react";
import FuzzySearchRecents from "./FuzzySearchRecents";
import FuzzySearchResultsGroup, { FuzzySearchResultsType } from "./FuzzySearchResultsGroup";
import FuzzySearchAllResults from "./FuzzySearchAllResults";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";

const useStyles = createUseStyles({
  fuzzySearchResults: {
    position: "absolute",
    top: "calc(100% - 4px)",
    left: 0,
    right: 0,
    zIndex: 100,
    maxHeight: "65vh",
    display: "flex",
    flexDirection: "column",
    background: "white",
    boxShadow: "0 15px 15px 0 rgba(0,0,0,.1)",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    border: "1px solid rgb(206, 212, 218)",
    overflow: "auto",
    "& li": {
      marginBottom: 3
    }
  },
  "fuzzySearchResults--inHeader": {
    borderTopLeftRadius: 4,
    minHeight: "auto",
    [breakpoints.up("md")]: {
      left: "auto",
      width: "95vw"
    },
    [breakpoints.up("md2")]: {
      left: "auto",
      width: "65vw",
      maxWidth: 800
    }
  },
  recentsWrapper: {
    "&:empty": {
      display: "none",
      padding: 0
    }
  }
});

type FuzzySearchResultsProps = {
  results: FuzzySearchResultsType;
  error: boolean;
  cachedSearchString?: string;
  inHeader: boolean;
  showRecents: boolean;
  closeModal: () => void;
};

const FuzzySearchResults: React.FC<FuzzySearchResultsProps> = ({
  results,
  error,
  cachedSearchString,
  inHeader,
  showRecents = true,
  closeModal
}) => {
  const noResults = !results || Object.keys(results).length === 0
  const classes = useStyles();

  const containerClasses = [classes.fuzzySearchResults];
  if (inHeader) {
    containerClasses.push(classes["fuzzySearchResults--inHeader"]);
  }

  return (
    <div className={containerClasses.join(" ")}>
      {error && (
        <span className="text-danger p-4">
          We&apos;re having trouble finding results. Please try again.
        </span>
      )}
      {!noResults && (
        <FuzzySearchResultsGroup results={results} cachedSearchString={cachedSearchString ?? ""} />
      )}
      {showRecents && (
        <div className={`p-3 border-top ${classes.recentsWrapper}`}>
          <FuzzySearchRecents />
        </div>
      )}
      <FuzzySearchAllResults closeModal={closeModal}/>
    </div>
  );
};

export default FuzzySearchResults;
