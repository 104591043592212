import { Controller } from "stimulus";
export default class extends Controller {

  static targets = [ "item", "panel" ];

  declare itemTargets: HTMLElement[];
  declare panelTargets: HTMLElement[];

  showPanel(e: Event) {
    e.preventDefault()
    const clickedItemIndex = this.itemTargets.indexOf(
      e.currentTarget as HTMLElement
    )
    // no-op if the user clicks on the current item
    if (this.itemIndex === clickedItemIndex) return;
    this.itemIndex = clickedItemIndex;
  }

  get itemIndex() {
    return +(this.data.get("itemIndex") || 0);
  }

  set itemIndex(value: number) {
    this.data.set("itemIndex", `${value}`);
    ;[this.itemTargets, this.panelTargets].forEach((targets:HTMLElement[]) => {
      targets.forEach((item: HTMLElement, index: number) => {
        const isCurrentItem = index === this.itemIndex;
        item.classList.remove("active");
        if (isCurrentItem) {
          item.classList.add("active");
        }
      })
    })
  }
}
