import React from "react";
import SVGSymbol from "./SVGSymbol";

const Tooltip = ({ title }) => (
  <span className="c-tooltip-icon" data-bs-toggle="tooltip" title={title}>
    <SVGSymbol name="info" color={"primary"} />
  </span>
);

export default Tooltip;
