import React, { Ref } from "react";

interface SelectProps {
  options: OptionType;
  selectRef: Ref<HTMLSelectElement>;
  handleChange: () => void;
}

export type OptionType = {
  [key: string]: string;
};

const Select: React.FC<SelectProps> = ({ options, selectRef, handleChange }) => (
  <div className="c-select d-block mb-3" style={{ maxWidth: 400 }}>
    <select ref={selectRef} style={{ width: "100%" }} onChange={handleChange}>
      {Object.keys(options).map((name) => (
        <option value={options[name]}>{name}</option>
      ))}
    </select>
  </div>
);

export default Select;
