import React, { CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imageSrc } from "./utils";
import isEmpty from "lodash/isEmpty";

import { ImageType } from "./types";

const useStyles = createUseStyles({
  storyImageWrapper: {
    position: "relative",
    margin: 0,
    "& .lazy-load-image-loaded": {
      display: "flex"
    }
  },
  storyImageCredit: {
    position: "absolute",
    bottom: 10,
    right: 10,
    background: "rgba(0,0,0,0.5)",
    zIndex: 100,
    fontSize: 15,
    lineHeight: 1,
    color: "rgba(255,255,255,0.75)",
    padding: 5,
    "&:hover": {
      color: "rgba(255,255,255,1)",
      background: "rgba(0,0,0,1)",
      "& a": {
        color: "rgba(255,25,255,1) !important"
      }
    },
    "& a": {
      color: "rgba(255,255,255,0.75) !important"
    }
  }
});

type StoryImageProps = {
  image: ImageType;
  style?: CSSProperties;
  className?: string;
};

const StoryImage: React.FC<StoryImageProps> = ({ image, style, className = "" }) => {
  const { src, src2x = "", altText = "", credit = null } = image;
  const classes = useStyles();
  const imageSource = imageSrc(src, src2x);
  const _credit = typeof credit === "object" && isEmpty(credit) ? null : credit;
  return (
    <figure className={[classes.storyImageWrapper, className].join(" ")} style={style}>
      <LazyLoadImage src={imageSource.src} srcSet={imageSource.srcset} alt={altText} />
      {credit && <span className={classes.storyImageCredit}>{_credit}</span>}
    </figure>
  );
};

export default StoryImage;
