import React, { Component } from "react";
import MapGradientLegend from "./MapGradientLegend";
import Tooltip from "../Tooltip";

export default class StaticMap extends Component {
  render() {
    const { title, legend, legendLabel, path, tooltip } = this.props;
    return (
      <div>
        <h3 className="o-map__title">
          {title}
          {tooltip ? <Tooltip title={tooltip} /> : null}
        </h3>
        <img src={path} style={{ width: "100%" }} alt="Map of incidences" />
        {legendLabel ? (
          <div className="c-map-legend c-chart-legend c-chart-legend--left" aria-label="Map Legend">
            <div className="c-chart-legend__swatch c-chart-legend__swatch--map"> </div>
            <div className="">{legendLabel}</div>
          </div>
        ) : null}
        {legend ? <MapGradientLegend {...legend} /> : null}
      </div>
    );
  }
}
