import DynamicContentCardController from "./dynamic-content-card-controller";

export default class extends DynamicContentCardController {
  static targets = ["content", "filter", "form"];
  declare contentTarget: HTMLElement;
  declare filterTargets: HTMLElement[];
  declare formTarget: HTMLElement;
  declare activeFilters: object;

  initialize() {
    this.activeFilters = {};
  }

  updateVisibleContent(filterGroupId: string, filterId: string) {
    const showTableEl = (el: HTMLTableRowElement | HTMLTableCellElement, addClass = false) => {
      let classAction = "remove";
      if (addClass) classAction = "add";
      const elType = el.tagName === "TR" ? "row" : "cell";
      el.style.display = `table-${elType}`;
      el.classList[classAction]("visible");
    };
    const allRows = Array.from(
      this.contentTarget.querySelectorAll(`[class*='${filterGroupId}']`)
    ) as HTMLTableRowElement[];
    if (filterId.includes("all")) {
      allRows.forEach((els) => showTableEl(els));
    } else {
      allRows.forEach((row: HTMLTableRowElement) => {
        row.style.display = "none";
        row.classList.remove("visible");
      });
      const rowsToShow = Array.from(
        this.contentTarget.querySelectorAll(`.${filterGroupId}-content_${filterId}`)
      ) as HTMLTableRowElement[];
      rowsToShow.forEach((els) => showTableEl(els, true));
    }
  }

  filterChange(event: Event) {
    const filterEl = event.currentTarget as HTMLElement;
    if (!this.contentTarget || filterEl.classList.contains("active")) return;
    const filterGroupEl = filterEl.parentElement;
    if (!filterGroupEl || !this.contentTarget || filterEl.classList.contains("active")) {
      return;
    }
    const filterGroupId = filterGroupEl.id;
    const filterId = filterEl?.id;
    const filterButtons = Array.from(filterGroupEl.children) as HTMLButtonElement[];
    filterButtons.forEach((btn) => btn.classList.remove("active"));
    filterEl?.classList.add("active");
    this.updateVisibleContent(filterGroupId, filterId);
    this.activeFilters[filterGroupId] = filterId;
  }

  filterByRange(event: Event) {
    this.renderLoader();
    let endpoint = this.baseEndpoint;
    if (!endpoint) {
      this.handleError(new Error("missing url"));
      return;
    }
    const date = event.currentTarget.value;
    endpoint += `/${date}`;
    this.fetch(endpoint).then(() => {
      Object.entries(this.activeFilters).forEach(([filterGroupId, filterId]) => {
        this.updateVisibleContent(filterGroupId, filterId);
      });
    });
  }
}
