import React from "react";

interface Props {
  path: string;
}

export const MetricFooter: React.FC<Props> = ({ path = null }) => (
  <div className="c-metric-card__footer">
    {path && (
      <a href={path} className="c-metric-card__footer-link">
        <span className="mr-1">View full report</span>
        <i className="fas fa-angle-double-right" />
      </a>
    )}
  </div>
);
