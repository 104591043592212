import React from "react";
import { estDateTimeFromDateString, isItPastTime } from "javascript/utils/date";

interface TimeBasedNoteProps {
  note?: string;
  level?: string;
  triggerTimeDate?: string;
  triggerTimeHour?: number;
  triggerTimeMinute?: number;
}

const TimeBasedNote: React.FC<TimeBasedNoteProps> = ({
  note,
  level = "danger",
  triggerTimeDate,
  triggerTimeHour,
  triggerTimeMinute
}) => {
  const triggerDate = estDateTimeFromDateString(triggerTimeDate);
  const noteShouldShow = isItPastTime(triggerDate, triggerTimeHour, triggerTimeMinute);

  if (noteShouldShow) {
    return (
      <div className={`c-note c-note--${level}`}>
        <div>{note}</div>
      </div>
    );
  }
  return null;
};

export default TimeBasedNote;
