import React, { Component } from "react";
import LoadingAnimation from "./LoadingAnimation";
import debounce from "lodash/debounce";
import { fetch } from "whatwg-fetch";
import classnames from "classnames";
import { gaEvent } from "../controllers/context_switcher/config";
export default class ContextSearch extends Component {
  constructor(props) {
    super(props);
    this.LOCAL_STORAGE_KEY = `recent_${this.props.type}_searches`;
    this.RECENTS_LIMIT = 5;
    this.state = {
      results: [],
      searching: false,
      query: ""
    };
  }

  /**
   * handleSearch - Respond to input - fetch locations matching query and
   *                update state with results
   *
   * @param  {type} event Mouse event
   * @return NA
   */
  handleSearch(event) {
    event.preventDefault();
    const value = event.target.value.trim();
    const { type = "" } = this.props;
    let typeParam = "";
    if (type) {
      typeParam = `&type=${type}`;
    }
    if (value.length === 0) {
      this.setState({ results: [], searching: false, query: "" });
      return;
    }
    if (value.length > 0 && value.length <= 2) {
      return;
    }
    this.setState({ searching: true });
    fetch(`/search.json?query=${value}${typeParam}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ results: data, searching: false, query: value });
        this.props.handleSearchResults(data, type);
        gaEvent({
          action: `${type.capitalize()} Search`,
          label: value
        });
      })
      .catch((error = "") => {
        // eslint-disable-next-line no-console
        console.error(`Error while performing ${this.props.type} search`, error);
        this.setState({ results: [], searching: false, query: value });
        this.props.handleSearchResults([], type);
      });
  }

  /**
   * handleSearchSelection - Handler for search selection
   *
   * @param  {Object} selection Name and id(slug) of selection
   * @param  {Object} event     Mouse event
   * @return NA
   */
  handleSearchSelection(selection, event) {
    event.preventDefault();
    const { type } = this.props;
    const _selection = {
      name: selection.human_name || selection.name,
      id: selection.slug,
      type,
      ...selection
    };
    if (type === "location" && selection.geoJson) {
      _selection["geoJson"] = selection.geoJson || "";
    }
    this.storeRecentSearch(_selection);
    this.props.handleUpdateSelectedItem(event, event.currentTarget, _selection);
  }

  /**
   * results - Returns formated nodes for results from search
   *
   * @return {Nodes}
   */
  results() {
    const { query, results } = this.state;
    if (!query && !results.length && !this.props.handleViewMoreResults) {
      return null;
    }

    if (query && !results.length) {
      return (
        <div>
          <li className="mx-4 my-2">We're having trouble finding "{query}".</li>
          {this.resultsAction(false)}
        </div>
      );
    }

    const _results = this.formatResults(results);
    this.props.handleViewMoreResults && _results.push(this.resultsAction(!_results.length));
    return _results;
  }

  resultsAction(isOnlyResult = false) {
    const { type } = this.props;
    const liClass = classnames(
      "c-context-switcher__list-child",
      "c-context-switcher__list-child--search-result",
      { "c-context-switcher__list-child--all": !isOnlyResult }
    );

    return (
      <li className={liClass}>
        <a
          className="c-context-switcher__list-explore-all"
          data-type={type}
          onClick={this.props.handleViewMoreResults}
          href="#"
        >
          Explore all {type}s
        </a>
      </li>
    );
  }

  /**
   * formatResults - Create Nodes for each result
   *
   * @param  {Array} results=[]  Array of result objects
   * @return {Nodes}
   */
  formatResults(results = [], asSearchResults = true) {
    if (!results.length) return [];
    const classNames = classnames("c-context-switcher__list-child", {
      "c-context-switcher__list-child--search-result": asSearchResults
    });
    return results.map((result) => {
      const parent = result.parent;
      return (
        <li className={classNames} key={result.human_name || result.name}>
          <a
            className="c-context-switcher__list-link"
            onClick={(event) => this.handleSearchSelection(result, event)}
            data-other-type={`search result`}
            href="#"
          >
            <span className="c-context-switcher__list-link-name">
              {result.human_name || result.name}
            </span>
            {parent ? (
              <span className="c-context-switcher__list-link-parent-list">
                <span>{parent}</span>
              </span>
            ) : null}
          </a>
        </li>
      );
    });
  }

  /**
   * storeRecentSearch - Store select search item in local storage
   *                     only if not currently in array
   *
   * @param  {Object} selection Selected search item
   * @return NA
   */
  storeRecentSearch(selection) {
    if (!localStorage) return;
    const recents = this.getRecentSearches();
    if (!recents.find((r) => r.id === selection.id)) {
      recents.unshift(selection);
    }
    localStorage.setItem(
      this.LOCAL_STORAGE_KEY,
      JSON.stringify(recents.slice(0, this.RECENTS_LIMIT))
    );
  }

  /**
   * getRecentSearches - Get recent searches from local storage
   *
   * @return {Array}
   */
  getRecentSearches() {
    if (!localStorage) return [];
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY)) || [];
  }

  render(props = {}, state = {}) {
    const { type = "", placeholder = "", showRecents = true } = props;
    const { searching = false, query = "", _results = [] } = state;
    const inputClassNames = classnames(
      "c-search__input",
      "form-control",
      { "with-results": query && query.length },
      { searching }
    );
    return (
      <div aria-label="Search Results">
        <div className="c-search__input-wrapper">
          <input
            onInput={debounce(this.handleSearch.bind(this), 500)}
            placeholder={placeholder}
            className={inputClassNames}
          />
          {searching ? (
            <LoadingAnimation
              size={30}
              veil={true}
              padded={false}
              fontSize={11}
              showLabel={false}
              withBG={false}
            />
          ) : null}
        </div>

        <ul className="c-search__results">{this.results()}</ul>

        {showRecents && this.getRecentSearches().length ? (
          <ul className="c-context-switcher__list">
            <li className="c-context-switcher__list-group">
              <a
                aria-controls="group-parent-recent-searches"
                className="c-context-switcher__list-toggle"
                data-bs-target="#group-parent-recent-searches"
                data-bs-toggle="collapse"
                href="#"
              >
                Your recent {type} searches
              </a>
              <ul
                className="collapse mb-4"
                aria-labelledby="group-parent-recent-searches"
                id="group-parent-recent-searches"
              >
                {this.formatResults(this.getRecentSearches(), false)}
              </ul>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}
