import React from "react";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";
import FuzzySearchResultsListItem from "./FuzzySearchResultsListItem";
import { SearchResult } from "./types";
import { resultsStyle } from "./style";

const useStyles = createUseStyles({
  fuzzySearchList: {
    marginBottom: 15,
    flex: "1 1 100%",
    minWidth: "100%",
    [breakpoints.up("sm")]: {
      flex: "1 1 50%",
      minWidth: "50%"
    },
    "& h3": {
      fontSize: 20,
      lineHeight: "32px",
      marginBottom: 0,
      [breakpoints.up("sm")]: {
        fontSize: "1.5rem",
        lineHeight: "1.22",
        marginBottom: ".5rem"
      }
    }
  },
  fuzzySearchListNoResults: resultsStyle
});

interface FuzzySearchResultsListProps {
  name: string;
  items: SearchResult[];
  searchString: string;
}

const FuzzySearchResultsList: React.FC<FuzzySearchResultsListProps> = ({
  name,
  items,
  searchString
}) => {
  const classes = useStyles();
  return (
    <div className={classes.fuzzySearchList}>
      {/* capitalize is added as prototype method */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <h3>{name.capitalize()}</h3>
      {items.length > 0 && (
        <ul className="list-unstyled m-0">
          {items.map((item) => (
            <FuzzySearchResultsListItem key={item.slug} item={item} />
          ))}
        </ul>
      )}
      {items.length === 0 && (
        <span className={classes.fuzzySearchListNoResults}>
          We couldn&apos;t find any {name} matching &quot;{searchString}&quot;
        </span>
      )}
    </div>
  );
};

export default FuzzySearchResultsList;
