import React from "react";
import FuzzySearchResultsList from "./FuzzySearchResultsList";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";
import { SearchResult } from "./types";

const useStyles = createUseStyles({
  fuzzySearchListsWrapper: {
    flex: 1,
    display: "flex",
    padding: 15,
    flexDirection: "column",
    "& > div": {
      marginBottom: 20,
      "&:last-child": {
        marginBottom: 0
      }
    },
    [breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  }
});

export type FuzzySearchResultsType = Record<string, SearchResult[]> | null;
interface FuzzySearchResultsGroupProps {
  results: FuzzySearchResultsType;
  cachedSearchString: string;
}

const FuzzySearchResultsGroup: React.FC<FuzzySearchResultsGroupProps> = ({
  results,
  cachedSearchString
}) => {
  const classes = useStyles();
  if (!results || !Object.keys(results).length) return null
  return (
    <div className={classes.fuzzySearchListsWrapper}>
      {Object.keys(results).map((groupName: string) => (
        <FuzzySearchResultsList
          key={groupName}
          items={results[groupName] as SearchResult[]}
          name={groupName}
          searchString={cachedSearchString}
        />
      ))}
    </div>
  );
};

export default FuzzySearchResultsGroup;
