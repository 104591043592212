import moment from "moment";
import flatten from "lodash/flatten";

/**
 * getDatesInRange - description
 *
 * @param  {String} increment='year'
 * @param  {Array}  yearRange=[]     Max and min dates
 * @return {Array}                   All dates in range based on increment
 */
export function getDatesInRange(increment = "year", yearRange = [], quarterly = false) {
  const dates = [];
  const minYear = yearRange[0];
  const maxYear = yearRange[1];
  for (let y = minYear; y <= maxYear; y++) {
    if (increment === "year") {
      dates.push(moment().year(y).startOf("year").toDate());
    } else if (increment === "month") {
      if (quarterly) {
        for (let m = 0; m <= 11; m = m + 3) {
          dates.push(moment().year(y).month(m).startOf("month").toDate());
        }
      } else {
        for (let m = 0; m <= 11; m++) {
          dates.push(moment().year(y).month(m).startOf("month").toDate());
        }
      }
    }
  }
  return dates;
}

/**
 * getMaxMinDatesOfSets - description
 *
 * @param  {Array} sets Data sets for charts
 * @return {Array}      Range - max and min
 */
export function getMaxMinDatesOfSets(sets) {
  if (!sets || !sets.length) return [];
  const _dates = flatten(
    sets.map((s) => {
      if (s.data) return Object.keys(s.data).map((d) => moment(d).toDate().getFullYear());
    })
  );
  const range = [Math.min(..._dates), Math.max(..._dates)];
  return range;
}

/**
 * getStartAndEndDatesFromYears -
 *
 * @param  {Array} dates Start and end years as strings eg '2018'
 * @return {Array}       Start and end dates
 */
export function getStartAndEndDatesFromYears(dates = []) {
  return [
    moment(`${dates[0]}-01-01`).startOf("year").toDate(),
    moment(`${dates[1]}-01-01`).endOf("year").toDate()
  ];
}

/**
 * getYearRange - Build Array of years between start and end year
 *
 * @param  {Array} years=null Dates in range (as years)
 * @return {Array} yearRange  Array of years
 */
export function getYearRange(years = [2010, new Date().getFullYear()]) {
  const [minDate, maxDate] = years;
  const yearRange = [];
  for (let i = 0; i < maxDate - minDate; i++) {
    yearRange.push(minDate + i);
  }
  yearRange.push(maxDate);
  return yearRange;
}

/**
 * dateToEndpoint - Build Array of years between start and end year
 *
 * @param  {String} date='' Year or Date (`2021-10-01`)
 * @return {String} dateEndpoint  part of endpoint relating to dates
 */
export const dateToEndpoint = (date = "") => {
  let dateEndpoint = "";
  if (!date) return "";
  // Selected value is a date range (not a year)
  if (date.length > 4 && date.includes("-")) {
    dateEndpoint = `/date/${date.split(" - ").last()}`;
  }
  // Selected value is a year
  else if (date) {
    dateEndpoint = `/year/${date}`;
  }
  return dateEndpoint;
};

/**
 * estDateTime - return date/time that's in EST timezone
 * source: https://bobbyhadz.com/blog/javascript-convert-local-time-to-est
 *
 * @param  {Object} date=null Date object
 * @return {Date} date in est time zone as Date object
 */
export const estDateTime = (date = null) => {
  const _date = date || new Date();
  const timeZone = "America/New_York";

  return new Date(
    new Date(_date).toLocaleString("en-US", {
      timeZone
    })
  );
};

/**
 * estDateTime - return date/time that's in EST timezone
 * source: https://bobbyhadz.com/blog/javascript-convert-local-time-to-est
 *
 * @param  {Object} dateString string Date in ISO format: YYYY-MM-DD
 * @return {Date} date in EST time zone as Date object
 */
export const estDateTimeFromDateString = (dateString) => {
  const parts = dateString.split("-");
  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

/**
 * Check if a date is past some time
 * @param {Date} date Date to use as comparison
 * @param {number} hour 24h based hours to use as comparison
 * @param {number | undefined} [minute] (optional) 0-59 number used for comparison
 * @param {Date} referenceDateTime DateTime to use as reference
 * @returns {boolean} if date given is past time
 */
export const isItPastTime = (date, hour, minute, referenceDateTime = estDateTime()) => {
  if (referenceDateTime.getDate() === date.getDate()) {
    if (minute !== undefined && referenceDateTime.getHours() === hour) {
      return referenceDateTime.getMinutes() > minute;
    }
    return referenceDateTime.getHours() > hour;
  }
  return referenceDateTime > date;
};
