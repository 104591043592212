import React from "react";

const DummyAnimation = () => {
  return (
    <div className="c-video c-video--dummy">
      <h3 className="o-map__title" />
      <div className="c-video__wrapper o-map__video-wrapper">
        <div className="c-video__load-error-message">
          We're sorry, this animation is not loading right now.
        </div>
      </div>
      <div className="c-animation-controls">
        <div className="c-animation-controls__primary">
          <div className="c-animation-controls__playback">
            <div className="c-animation-controls__playback-buttons">
              <a className="c-animation-controls__playback-button" href="#">
                <i className="fa fa-play" />
              </a>
              <a className="disabled c-animation-controls__playback-button" href="#">
                <i className="fa fa-pause" />
              </a>
            </div>
          </div>
          <div className="c-animation-controls__slider">
            <div className="d-flex justify-content-around">
              <a className="c-animation-controls__date" href="#" index="0" />
              <a className="c-animation-controls__date" href="#" index="1" />
              <a className="c-animation-controls__date" href="#" index="2" />
              <a className="c-animation-controls__date" href="#" index="3" />
              <a className="c-animation-controls__date" href="#" index="4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DummyAnimation;
