import React from "react";
import MemoizedLineChart from "../Charts/MemoizedLineChart";
import Scene from "../Scene/Scene";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import { createUseStyles } from "react-jss";

import { textContainer } from "../styles/typography";
import { chartOptions, labels } from "../Charts/config";
import { getCreditById } from "./copy";
import cloneDeep from "lodash/cloneDeep";
import { Act } from "../types";

const useStyles = createUseStyles({
  textContainer
});

const StaticEarlyCaseRates = () => {
  const classes = useStyles();
  const acts: Act[] = [
    <div className={classes.textContainer}>
      <p>
        And, Delaware-wide, going into 2021, Hispanic persons continued to test positive for
        COVID-19 at rates higher than non-Hispanics.
      </p>
    </div>
  ];
  const options = cloneDeep(chartOptions.earlyCaseRates);
  options.xAxis.plotBands = [
    {
      color: "rgba(30, 144, 255, .1)",
      from: Date.parse("2021-01-01"),
      to: Date.parse("2021-02-01"),
      label: {
        text: "January 2021",
        align: "left",
        x: 5,
        y: 25,
        ...labels
      }
    }
  ];

  return (
    <Scene reverseContent>
      <SceneFeaturedContent>
        <MemoizedLineChart
          id="StaticEarlyCaseRates"
          options={options}
          title="Daily rate of new positive COVID-19 cases by Hispanic origin, Delaware"
          subTitle="March 2020 to October 2021"
          jsonFile="covid_case_rates_by_ethnicity"
          source={getCreditById("ddhss", "sources")}
        />
      </SceneFeaturedContent>
      <SceneScrollContainer acts={acts} />
    </Scene>
  );
};

export default StaticEarlyCaseRates;
