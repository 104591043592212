import React from "react";
import MetricCard from "./MetricCard";
import MetricCardHeader from "./MetricCardHeader.tsx";
import MetricCardMetric from "./MetricCardMetric";
import TrendChart from "../topic/TrendChart";
import SVGSymbol from "../SVGSymbol";
import { deepCopy } from "../utils/index";

export default class MetricCardAverageTrend extends MetricCard {
  trendProps() {
    return Object.assign(deepCopy(this.props.data), {
      unit: "%",
      options: {
        showXAxis: true,
        showYAxis: true,
        height: 125
      }
    });
  }

  trendTooltip() {
    const tooltip = this.props.data.trend_tooltip;
    return `
      <div>
        <span>${tooltip.text ? tooltip.text : ""}</span>
        <span class='ml-auto mt-2 d-block'>
          (<a href=${tooltip.source}> ${tooltip.source} </a>)
        </span>
      </div>
    `;
  }

  render() {
    const { data, location } = this.props;
    const { primary } = data;
    const base = `c-metric-card__group`;
    return (
      <div className="col-sm-6 mb-4">
        <MetricCardHeader
          date={data.date_range.join(" - ")}
          title={data.title}
          tooltip={data.description}
          location={location}
          primaryDataLocation={primary.location}
          bordered={true}
        />
        <div className="c-metric-card__content c-metric-card__row c-metric-card__content--spaced">
          <MetricCardMetric
            title="Average"
            unit={data.unit || ""}
            baseClasses={`${base} c-metric-card__group--auto`}
            metricValue={this._getMetricValues("average")}
            locations={this.locations()}
          />
          <div className={`${base} c-metric-card__group--auto`}>
            <div className="c-metric-card__group-title">
              <span id="label-metric-trend">% of months with values in exceedance</span>
              {data.trend_tooltip ? (
                <span
                  className="c-tooltip-icon"
                  title={this.trendTooltip()}
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                >
                  <SVGSymbol name="info" />
                </span>
              ) : null}
            </div>
            {primary.trend && primary.trend.length ? <TrendChart {...this.trendProps()} /> : null}
          </div>
        </div>
      </div>
    );
  }
}
