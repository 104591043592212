import React from "react";
import { createRoot } from "react-dom/client";
import { Controller } from "stimulus";
import LineChart from "components/stories/Charts/LineChart";

export default class extends Controller {
  connect() {
    const jsonFile = this.data.get("jsonFile");
    const props = {
      chartOptions: {
        title: { text: null },
        tooltip: {
          pointFormatter() {
            return this.y
              ? `<span>&#9726;</span> ${this.series.name}: <strong>${this.y.toFixed(1)}</strong>`
              : "";
          }
        }
      },
      jsonFile,
      mutateSeries: (series) => series,
      loadingAnimationHeight: 100
    };
    const root = createRoot(this.element);
    root.render(<LineChart {...props} />);
  }
}
