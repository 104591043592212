import "core-js/es/symbol";
import "core-js/es/object";

export function querySelectorAll(selector, root = null) {
  let _root = root ? root : document;
  if (typeof _root === "string") {
    _root = document.querySelector(_root);
  }
  return [..._root.querySelectorAll(selector)];
}
