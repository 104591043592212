// @ts-nocheck
import { control, DomUtil, geoJSON, icon, map, MapOptions, marker, tileLayer } from "leaflet";
import { sendGAEvent, googleEvent } from "../../javascript/utils/google_analytics";

export const MAX_ZOOM = 12;
export const mapBaseLayers = {
  simple: {
    mapLayer: "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}{r}.png",
    labelLayer:
      "https://{s}.basemaps.cartocdn.com/rastertiles/light_only_labels/{z}/{x}/{y}{r}.png",
    attribution: "©CartoDB"
  },
  contextual: {
    mapLayer: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
    labelLayer: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}.png",
    attribution: "©CartoDB"
  }
};

export const polygonStyles = {
  default: {
    fillColor: "#147BB1",
    fillOpacity: 0.2,
    color: "#147BB1",
    weight: 2
  },
  selected: {
    fillColor: "orange",
    fillOpacity: 0.2,
    color: "orange",
    weight: 2
  },
  active: {
    fillColor: "#8B0505",
    fillOpacity: 0.5,
    color: "#8B0505"
  }
};

interface BaseMapOptions extends MapOptions {
  scrollWheelZoom?: boolean;
  initialZoom?: number;
  addScale?: boolean;
  baseLayer?: "simple" | "contextual";
  tabindex?: number;
}

export const renderBaseMap = (
  element: HTMLElement,
  token: string,
  options?: BaseMapOptions = {}
) => {
  const { initialZoom = 9, addScale = true, baseLayer = "simple", tabindex } = options;
  const _map = map(element, options).setView([38.8172, -75.5639], initialZoom);
  const { labelLayer, mapLayer, attribution } = mapBaseLayers[baseLayer];
  if (tabindex) {
    element.setAttribute("tabindex", tabindex)
  }

  // Add Attribution pane
  tileLayer(mapLayer, {
    attribution,
    minZoom: 4,
    maxZoom: 12,
    accessToken: token,
    opacity: 0.75
  }).addTo(_map);
  if (labelLayer) {
    _map.createPane("labels");
    _map.getPane("labels").style.zIndex = 650;
    tileLayer(labelLayer, { attribution, pane: "labels" }).addTo(_map);
  }

  // Add Distance scale pane
  if (addScale) {
    control.scale().addTo(_map);
  }

  return _map;
};

export const currentLocationGeoJson = (locationGeoJson) => {
  return geoJSON(locationGeoJson, {
    interactive: false,
    style: {
      weight: 3,
      color: "black",
      fillOpacity: 0
    }
  });
};

export const gaEvent = ({ action, label }: googleEvent) => {
  sendGAEvent({
    eventType: "user_interaction", parameters: {
      category: "Maps",
      action,
      label
    }
  });
};

export const markerHtml = (props: object) => {
  let phones = (props.PHONES || []).filter((p) => p != 0); // Check both 0 and '0'
  phones = phones.length
    ? `
      <p class='mb-1 d-flex'>
        <span class='font-weight-bold mr-1'>Phone:</span>
        <span>${phones.join(", ")}</span>
      </p>
    `
    : "";
  const website = props.WEBSITE
    ? `<a class="d-block" target='_BLANK' href="${props.WEBSITE}">${props.WEBSITE}</a>`
    : "";
  return `
    <div class='p-2'>
      <h5 class="c-list-card__title">${props.NAME}</h5>
      ${phones}
      ${website}
    </div>
  `;
};

export const addPointsToMap = (pointJson: object, map: object, handlePointClick = () => { }) => {
  const markers = {};
  const clusterPane = "clusterPane";
  map.createPane(clusterPane).style.zIndex = 1000;
  const clusterGroup = L.markerClusterGroup({
    clusterPane,
    disableClusteringAtZoom: MAX_ZOOM
  });
  Object.keys(pointJson).map((identifier) => {
    markers[identifier] = pointJson[identifier].features.map((feature: Feature, i) => {
      if (feature.geometry.coordinates) {
        const _icon = icon({
          iconUrl: feature.properties.ICON,
          iconSize: [64, 64],
          iconAnchor: [32, 64]
        });
        const _marker = marker(feature.geometry.coordinates.reverse(), {
          icon: _icon,
          ...feature.properties,
          id: `${identifier}-${i}`
        });
        _marker.bindPopup(markerHtml(feature.properties));
        _marker.on("click", handlePointClick);
        clusterGroup.addLayer(_marker);
        return _marker;
      }
    });
  });
  map.addLayer(clusterGroup);
  return markers;
};

export const choroplethLegend = (colorCalibration, unitsAffix = "") => {
  const { bins, colors, continuous, low, high, precision } = colorCalibration;

  // Do not render a legend if config is incomplete
  if (!colors) {
    return;
  }

  const legend = control({ position: "bottomright" });

  const legendColors = [...colors];
  const numColors = colors.length;
  // interpolate between colors or bin the values into ranges?
  const binSize = parseFloat(high - low) / numColors;
  const swatches = legendColors.map((c, i) => {
    const startingVal = low + i * binSize;
    const startingValRounded = Math.round(startingVal);
    const _unitsAffix = unitsAffix && unitsAffix.length < 2 ? unitsAffix : "";

    const format = (value) => {
      return `${value.toFixed(precision)}${_unitsAffix}`;
    };

    if (bins) {
      const bin = bins[i];
      if (bin.max == 10 ** 10) {
        return { color: c, value: `${format(bin.min)}+` };
      }
      return { color: c, value: `${format(bin.min)} - ${format(bin.max)}` };
    } else if (continuous) {
      // when you interpolate colors, a particular color is for a
      // particular value (not a range of values)
      return { color: c, value: `${format(startingValRounded)}` };
    }
    const endingValRounded = Math.round(startingVal + binSize);
    return {
      color: c,
      value: `${format(startingValRounded)} - ${format(endingValRounded)}`
    };
  });

  legend.onAdd = function () {
    const html = null;
    const additionalClasses = "";
    const div = DomUtil.create("div", `c-map-info c-map-info__legend ${additionalClasses}`);

    if (html) {
      div.innerHTML += html;
    } else {
      // Loop through our density intervals and generate a label with a colored square for each interval
      swatches.forEach((swatch) => {
        div.innerHTML += `
          <div class='c-map-info__legend-item'>
            <i class='c-map-info__legend-swatch' style="background: ${swatch.color}"></i>
            <span class='c-map-info__legend-label'>${swatch.value}</span>
          </div>
        `;
      });
    }
    return div;
  };

  return legend;
};
