import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = [ 'pointIcon' ]

  handlePointIconClick(event:Event) {
    const itemEl = event.currentTarget?.closest('li')
    if (!itemEl) return
    const listEl = itemEl.parentElement
    listEl && this.mapController?.highlightPoint(itemEl.id, listEl.dataset.identifier || '')
  }

  scrollToItem(id:string) {
    const highlightedClassName = 'highlighted'
    const currentItem = this.element.querySelector(`.${highlightedClassName}`)
    currentItem && currentItem.classList.remove(highlightedClassName)
    const item = this.element.querySelector(`#${id}`) as HTMLElement
    if(item) {
      this.element.scroll({
        top: item.offsetTop,
        behavior: "smooth",
      });
      item.classList.add(highlightedClassName)
    }
  }

  get mapController() {
    const mapId = this.data.get('mapId')
    const mapEl = document.getElementById(mapId)
    if (mapEl) {
      return this.application
        .getControllerForElementAndIdentifier( mapEl, 'maps--point-map' )
    }
  }

}
