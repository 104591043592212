import { SeriesLineOptions } from "highcharts";
import { CHART_COLORS } from "charts/config";

type ChartPayload = {
  series: SeriesLineOptions[];
  categories: string[] | number[];
  description: string;
  notes: string;
  source: string;
  sourceLink: string;
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
};

export const fetchChartData = async (
  identifiers?: string[] | null,
  jsonFile?: string,
  dateRange?: string[]
) => {
  let endpoint = "/stories/chart_data";
  if (identifiers?.length) {
    endpoint += `/identifiers/${identifiers}`;
  } else if (jsonFile) {
    endpoint += `/file/${jsonFile}`;
  }
  if (identifiers && dateRange && dateRange[0]) {
    endpoint += `/${dateRange[0]}`;
  }

  try {
    const response = await fetch(endpoint);
    const payload: ChartPayload = await response.json();
    let mutatedSeries = payload.series;
    if (!payload.categories) {
      mutatedSeries = payload.series.map((s) => {
        s.data = s.data.map(([d = "", v = null]) => [Date.parse(d), v]);
        return s;
      });
    }
    payload.series = mutatedSeries;
    return payload;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("There was an issue with loading data for this chart", error);
    return "ERROR";
  }
};

//
/**
 * @param  {number[]} originalArray
 * @param  {number} percent
 * @return {number[]} truncatedArray
 * Takes an array and percentage and returns a new array truncated array based on the percentage
 * based on https://www.tutorialspoint.com/taking-part-from-originalArrayay-of-numbers-by-percent-javascript
 */
export const truncatedArrayBasedOnPercentage = (originalArray: number[], percent: number) => {
  const sum = originalArray.reduce((acc: number, val: number) => acc + val);
  const truncatedArray: number[] = [];
  let current = 0;
  originalArray.forEach((i) => {
    current += i;
    const currentPercent = (sum * percent) / 100;
    current <= currentPercent && truncatedArray.push(current);
  });
  return truncatedArray;
};

export const addSMASeries = (series: SeriesLineOptions) => {
  const idsAndNames: [string, string][] = [];
  const mutatedSeries = series.map((s, i: number) => {
    const defaultName = `series-${i}`;
    const { id = defaultName, name = defaultName } = s;
    idsAndNames.push([id, name]);
    s.data = (s.data || []).map(([d, v]) => [Date.parse(d), v]);
    return {
      ...s,
      id,
      showInLegend: false,
      opacity: 0,
      states: { hover: { enabled: false, halo: { opacity: 0 } } }
    };
  });
  return [
    ...mutatedSeries,
    ...idsAndNames.map(([id, name], i) => ({
      type: "sma",
      linkedTo: id,
      name,
      color: CHART_COLORS[i]
    }))
  ];
};
