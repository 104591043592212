import { Controller } from "stimulus";
import { initScrollLinks } from '../javascript/link'
import { renderLoader } from '../charts/utils'
import { initTooltips } from 'javascript/_init/ui/tooltips'

export default class DynamicContentCardController extends Controller {
  static targets = [ 'content' ];
  declare contentTarget:HTMLElement;
  declare fetchController:AbortController;

  disconnect() {
    this.fetchController?.abort()
  }

  fetch(endpoint:string, contentContainer?:HTMLElement|null, responseType='text') {
    const fetchOptions = {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'text/html; charset=UTF-8'
      },
    }
    if (AbortController !== undefined) {
      this.fetchController = new AbortController()
      fetchOptions.signal = this.fetchController.signal
    }
    return fetch(endpoint, fetchOptions)
      .then((response) => {
        if (!response.ok) {
          this.handleError();
          return
        }
        return response[responseType]();
      })
      .then((response) => {
        if (response) {
          this.handleLoad(response, contentContainer)
          return response
        }
      })
      .catch((error: any) => {
        this.handleError(error, contentContainer)
      });
  }

  handleLoad(html:string, contentContainer?:HTMLElement) {
    const container:HTMLElement = contentContainer || this.contentTarget
    if (container) {
      container.innerHTML = html;
      initScrollLinks(container)
      initTooltips(container)
    }
  }

  renderLoader(contentContainer?:HTMLElement) {
    renderLoader(contentContainer || this.contentTarget)
  }

  handleError(error?: any, contentContainer?:HTMLElement) {
    (contentContainer || this.contentTarget).innerHTML = `
      <div class='alert alert-danger'>We're sorry, but something went wrong. The engineering team has been notified.</div>
    `;
    if (error) {
      throw error;
    }
  }

  get baseEndpoint() {
    return this.data.get('urlValue')
  }
}
