import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    // add a "stuck" class when element is sticking
    // from https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("stuck", e.intersectionRatio < 1),
      { threshold: [1] }
    );
    observer.observe(this.element);
  }

}
