import { useEffect, RefObject } from "react";

type HandlerFunction = (event: Event) => void;

// https://usehooks.com/useOnClickOutside/
const useOnClickOutside = (isOpen=false, ref: RefObject<HTMLElement>, handler: HandlerFunction) => {
  useEffect(() => {
    if (!isOpen) return
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref?.current || ref?.current.contains(event.target as HTMLElement)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, isOpen]);
};

export default useOnClickOutside;
