// Superclass for some types of charts (trend.js, plot.js, and line.js)

import React from "react";
import bb from "billboard.js";
import { svgChartPoints, colors } from "./options";
import * as d3 from "d3";
import classnames from "classnames";
import max from "lodash/max";
import compact from "lodash/compact";
import isUndefined from "lodash/isUndefined";
import mathFn from "../utils/math_fn";

export default class Chart {
  constructor(props, containerElements, options = {}) {
    this.props = props;
    this.LOAD_DELAY = 250;
    this.wrapperEl = containerElements[0];
    this.id = this.wrapperEl.id;
    this.chart = null;
    this.containerEl = containerElements[1];
    const self = this;
    this.options = Object.assign(
      {
        size: { height: this.props.height || 450 },
        legend: { show: false },
        padding: this._getChartPadding(),
        color: { pattern: colors() },
        bindto: this.containerEl,
        tooltip: { contents: this._tooltip.bind(this) },
        onrendered() {
          self._onRendered(this);
        },
        onresize() {
          self._onResize(this);
        },
        addLocationToLegendLabel: false,
        showLegend: true,
        showLoader: true,
        defaultAxisMin: null
      },
      options
    );
    this.yLabels = [];
    this.unit = null;
  }

  _init() {
    // Generate chart
    this._buildOptions();
    return this._generate();
  }

  _generate() {
    this._updateLoadingState(true);
    this.chart = bb.generate(this._options);
    // Add Max level line
    this._addAdditionalUi();
    // Add Custom legend to chart container with D3
    this._addLegend();
    return this.chart;
  }

  _onRendered(chart, loading = false) {
    this._removeAxisAndTickLines(chart);
    this._removeClipPaths(chart);
    if (!loading) this._updateLoadingState(false);
  }

  _onResize() {
    return;
  }

  _buildOptions() {
    this._options = this.options;
  }

  _updateAxisMaxMin(withLevelRef = false) {
    let max = this._getMaxPoint();
    let min = this._getMinPoint();
    if (withLevelRef && this.minMaxPoints) {
      max = Math.max(...[this.minMaxPoints[1], max]);
      min = Math.min(...[this.minMaxPoints[0], min]);
    }
    this.chart.axis.range({ min: { y: min }, max: { y: max } });
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // "Getters"
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  _getChart() {
    return this.chart;
  }

  _getDataSets(index = null) {
    const sets = this.props.data.sets;
    if (index !== null) {
      if (!sets[index]) console.warn("Data set for this index does not exist");
      else return sets[index];
    } else {
      return sets;
    }
  }

  _getDates() {
    return this.props.dates;
  }

  _getMaxMinPoint(point = null, setIndex = null) {
    // Determine maximum value across all sets
    if (point) {
      if (setIndex !== null) {
        const set = this._getDataSets(setIndex);
        if (set && set.data) {
          return mathFn[point](set.data.map((d) => parseFloat(d.value)));
        }
      } else {
        return mathFn[point](
          this._getDataSets().map((s) => {
            return mathFn[point](s.data.map((d) => parseFloat(d.value)));
          })
        );
      }
    } else {
      return [this._getMaxMinPoint("min", setIndex), this._getMaxMinPoint("max", setIndex)];
    }
  }

  _getMaxPoint(setIndex = null) {
    // Return 1 if _getMaxMinPoint returns 0 or null
    let maxValue = 0;
    if (setIndex !== null) {
      const set = this._getDataSets(setIndex);
      maxValue = set ? +set.maxValue : null;
    }
    return max([maxValue, this._getMaxMinPoint("max", setIndex)]) || 1;
  }

  _getMinPoint(setIndex = null) {
    if (this.options.defaultAxisMin && this.options.defaultAxisMin !== undefined) {
      return this.options.defaultAxisMin;
    }
    return this._getMaxMinPoint("min", setIndex);
  }

  _getChartPadding(hasY2 = false) {
    let right = 20;
    const left = 75;
    const { comparison, padding } = this.props;
    if (comparison || hasY2) {
      right = left;
    }
    return Object.assign(
      {
        top: 20,
        right,
        bottom: 20,
        left
      },
      padding || {}
    );
  }

  /**
   * _getYAxisLabel - Build y axis label string based on label name
   *                  Add addendum marker if one exists
   *
   * @return {String} Label
   */
  _getYAxisLabel() {
    const { labels, data } = this.props;
    const baseLabel = labels.y || this.yLabels[0];
    const sets = data.sets;
    // Add indicator that an addendum exists
    let addendumMarker = "";
    if (sets && sets.length && sets[0].nameAddendum) {
      addendumMarker = "*";
    }
    return (baseLabel + addendumMarker || "").removeHtmlTags();
  }

  _getColumnNames() {
    let result = {};
    this._getDataSets().map((s) => (result[s.identifier] = s.name));
    return result;
  }

  _getColors(columns) {
    const _colors = {};
    // Provide colors based on columns - subtract 1 to counter the x axis column
    columns.forEach((c, i) => (_colors[c[0]] = colors()[i - 1]));
    return _colors;
  }

  _getUnit() {
    return this.unit ? this.unit : "";
  }

  _getLegendLabelData() {
    // Build array of labels for legend
    const sets = this._getDataSets();
    if (!sets) return;
    return compact(
      sets.map((s, i) => {
        // Give name depending on the comparison type
        // If 'location' most likely the set name is identical so we should
        // show the location name
        if (!compact(s.data.map((d) => d.value)).length) return null;
        let name = s.name;
        if (this.props.comparisonType === "location" && !this.props.options.stratification)
          name = s.location;
        // If more than one comparative set, change name of primary to all
        // FIXME - This is pretty fragile
        // if (sets.length > 2 && i === 0) {
        //   name = 'All'
        // }
        return {
          id: i,
          name: typeof name === "string" ? name.capitalize(["for"]) : name,
          location: s.location || ""
        };
      })
    );
  }

  _getVisualPoints(columns = []) {
    return columns.map((column, i) => {
      let size = 7;
      if (this.granularity === "month") size = 5.5;
      // Decrease size of point ot data set has over 75 data points
      if (columns[0].filter((d) => d !== "NaN" && d !== null).length > 75) size = 3.5;
      return svgChartPoints(i, null, size);
    });
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Data
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  _prepareColumns() {
    this.yLabels = [];
    const columns = this._getDataSets().map((set) => {
      let values = set.data.map((d) => {
        const value = isUndefined(d.value) ? null : Number(d.value).toFixed(2);
        const { date } = d;
        const { confidence } = set;
        const getConfidence = (key) => (confidence[date] ? confidence[date][key] : null);
        if (this.props.showConfidence && confidence) {
          return [getConfidence("upper_confidence"), value, getConfidence("lower_confidence")];
        }
        return value;
      });
      values.unshift((set.identifier || set.name).replace(/[\])}.[{(]/g, ""));
      let yLabel = set.name;
      this.unit = set.unit;
      if (set.unit) yLabel += ` (${set.unit})`;
      this.yLabels.push(yLabel);
      return values;
    });

    // Add Dates to show lables in x axis
    columns.unshift(["x", ...this._getDates()]);

    return columns;
  }

  _dataAreSubsets() {
    return compact(this._getDataSets().map((s) => s.subset)).length;
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // UI
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  _tooltip(data) {
    const values = compact(data).map((d, i) => {
      if (d.value === null) return "";
      return `
          <div class='c-tooltip-chart__value-wrapper'>
            <h4 class='c-tooltip-chart__title'>${d.name}</h4>
            <span class='c-tooltip-chart__value c-tooltip-chart__value--${i + 1}'>${Number(
        d.value
      ).toFixed(2)}</span>
            <span class='c-tooltip-chart__unit'>${this._getUnit()}</span>
          </div>
        `;
    });
    return `
      <div class='c-tooltip-chart'>
        ${values.join("")}
      </div>
    `;
  }

  _addLegend() {
    if (!this.options.showLegend) return;
    const labels = this._getLegendLabelData();
    this._removeLegend();
    // if only one label or set do not show legend but allow adding items
    // with _additionalLegendUpdates
    if (!labels || labels.length <= 1) {
      this._additionalLegendUpdates();
      return;
    }

    d3.select(`#${this.id}`)
      .insert("div", ".c-topic-note")
      .attr("class", "c-chart-legend")
      .attr("aria-label", "Chart Legend")
      .attr("style", `padding-left: ${this._getChartPadding().left}px`)
      .selectAll("span")
      .data(labels)
      .enter()
      .append("div")
      .attr("data-id", (label) => label.name)
      .html((label) => {
        const visualClassNames = classnames(
          "c-chart-legend__visual",
          `c-chart-legend__visual--${label.id + 1}`,
          `c-chart-legend__visual--${label.type}`
        );
        return `
            <div class='${visualClassNames}'> </div>
            <div class='c-chart-legend__label'>
              ${
                this.options.addLocationToLegendLabel ? label.location || "" : ""
              } ${label.name.capitalize()}
            </div>
          `;
      })
      .each(function () {
        d3.select(this).attr("class", `c-chart-legend__item active`);
      })
      .on("mouseover", (label, id) => {
        if (this.chart && id) {
          this.chart.data()[id] && this.chart.focus(this.chart.data()[id].id);
        }
      })
      .on("mouseout", () => this.chart && this.chart.revert())
      .on("click", (label, id, elements) => {
        if (!this.chart || !id || !elements) return;
        const element = elements[label.id];
        const elementClasses = element.classList;
        if (elementClasses.contains("active")) {
          elementClasses.remove("active");
        } else {
          elementClasses.add("active");
        }
        this.chart.data()[id] && this.chart.toggle(this.chart.data()[id].id);
      });

    this._additionalLegendUpdates();
  }

  _additionalLegendUpdates() {
    return;
  }

  _removeLegend() {
    d3.select(`#${this.id}`).selectAll(".c-chart-legend").remove();
  }

  _removeAxisAndTickLines(chart) {
    const chartEl = chart.$.chart;
    // Remove axes tick lines to reduce visual clutter
    chartEl.selectAll(".tick line").remove();
    // Remove axes lines to reduce visual clutter
    // chartEl.selectAll('path.domain').remove()
  }

  _addAdditionalUi() {
    return;
  }

  _removeClipPaths(chart) {
    chart.$.chart.selectAll(".bb-grid, .bb-chart, .bb-axis").attr("clip-path", null);
  }

  _updateLoadingState(loadingState = false) {
    if (!this.options.showLoader || !this.loader) return;
    let actionMethod = "add";
    if (loadingState) {
      actionMethod = "remove";
    }
    this.loader.classList[actionMethod]("hidden");
  }

  _formatLabel(label) {
    return label.replace(/_/g, " ").capitalize(["for"]);
  }
}
