import defaultsDeep from "lodash/defaultsDeep";
import Highcharts from "highcharts/highstock";
import { defaultConfig } from "charts/config";
import { setGlobalOptions } from "charts/utils";

// Highchart global options
setGlobalOptions();

const renderChart = (config: Highcharts.Options) => {
  return Highcharts.chart(defaultsDeep(config, defaultConfig));
};

export default renderChart;
