import React, { Fragment } from "react";
import "leaflet";
import TabbedMap from "./TabbedMap";
import classnames from "classnames";
import { getColor } from "../utils/color";
import { addTrailingZeros } from "../utils/number";

const TabbedMapValue = ({ label, value, color, unit }) => {
  return (
    <>
      <h4 className="mb-1">{label}</h4>
      <div className="d-flex align-items-end">
        <span className="c-metric mr-2 mb-0 d-flex align-items-baseline">
          <span className="c-swatch c-swatch--lg mr-2" style={{ backgroundColor: color }} />
          <span className="c-metric__value">{addTrailingZeros(value)}</span>
        </span>
      </div>
      {unit && <span className="c-metric__unit">{unit}</span>}
    </>
  );
};

export default class TabbedComparativeMap extends TabbedMap {
  mapDetails() {
    const { maps, activeMap } = this.state;
    if (maps.length && !maps.last().data) return null;
    const locationDetails = (locationProps) => {
      const { values = [] } = locationProps;
      if (!values.length) return;
      const value = values.first();
      return (
        <div
          className="c-tabbed-map__detail-list-sub-item"
          onMouseOver={(event) => this.highlightFeature(locationProps, event)}
          onMouseOut={(event) => this.resetFeature(locationProps, event)}
        >
          <TabbedMapValue
            label={locationProps.locationName}
            value={value.valuePresented}
            color={getColor(this.colorCalibration, value.value)}
            unit="rate per 100,000 population"
          />
        </div>
      );
    };
    return maps.map((m) => {
      const { year, label, data } = m;
      const className = classnames("c-tabbed-map__detail-list-link ", {
        active: (label || year) === this.state.activeMap
      });
      const { stateValue } = data;
      return (
        <div className="c-tabbed-map__detail-list-item" key={`map-${label}`}>
          <a
            href="#"
            className={className}
            onClick={(event) => this.handleDateChange(label || year, event)}
          >
            <span className="mb-0"> {label || year} </span>
          </a>
          {activeMap === (label || year) && data ? (
            <div className="c-tabbed-map__detail-list-details d-flex">
              {stateValue && (
                <div className="c-tabbed-map__detail-list-sub-item">
                  <TabbedMapValue
                    label="Delaware"
                    value={stateValue}
                    color={getColor(this.colorCalibration, stateValue)}
                    unit="rate per 100,000 population"
                  />
                </div>
              )}
              <div>
                {data.polygons.features.map(({ properties }, i) => (
                  <Fragment key={`location-detail-${i}`}>
                    {locationDetails(properties, year)}
                  </Fragment>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      );
    });
  }
}
