import React, { Component } from "react";

export default class Video extends Component {
  constructor(props) {
    super(props);
  }

  setVideo(el) {
    this.video = el;
    this.props.setVideo(el);
  }

  render() {
    const {
      data,
      played,
      handlePlay,
      handleTime,
      playable,
      posterImage = "",
      showControls = false
    } = this.props;
    return (
      <div className="c-video">
        {!played && playable && !showControls ? (
          <div className="c-video__play" onClick={handlePlay}>
            <i className="fa fa-play c-video__play-icon" />
          </div>
        ) : null}
        <video
          ref={this.setVideo.bind(this)}
          onTimeUpdate={handleTime.bind(this)}
          playsInline
          preload="metadata"
          muted
          onClick={handlePlay}
          controls={showControls}
          poster={posterImage}
        >
          <source src={data.url || data.file_url} type={data.mime_type} />
          Sorry, your browser does not support this type of video.
        </video>
      </div>
    );
  }
}
