import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Scene from "../Scene/Scene";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import breakpoints from "../../styles/breakpoints";
import { imageDictionary } from "./config";

import { textStyle } from "../styles/typography";
import StoryImage from "../StoryImage";

const useStyles = createUseStyles({
  triptych: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    height: "75%",
    width: "100%",
    flex: "1 1 150%",
    [breakpoints.up("md")]: {
      marginLeft: "5vw",
      marginRight: "5vw"
    }
  },
  triptychFeaturedContent: {
    alignItems: "center",
    background: "black",
    color: "white",
    flexBasis: "100%",
    flexDirection: "column",
    height: "100vh",
    fallbacks: {
      height: "-webkit-fill-available"
    },
    justifyContent: "center",
    marginLeft: "auto",
    position: "sticky",
    top: 0
  },
  triptychImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.35,
    transition: "opacity .5s ease-in-out",
    overflow: "hidden",
    [breakpoints.up("md")]: {
      flex: `1 1 ${(1 / 3) * 100}%`,
      maxWidth: `${(1 / 3) * 100}%`,
      position: "relative"
    },
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "100%",
      transition: "opacity .5s ease-in-out",
      opacity: 1
    },
    "&.active, &.active img": {
      opacity: 1,
      zIndex: 100
    }
  },
  triptychText: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "85vw",
    ...textStyle,
    flex: "0 1 auto",
    position: "absolute",
    bottom: 20,
    zIndex: 100,
    background: "rgba(0,0,0,.5)",
    [breakpoints.up("md")]: {
      width: "75vw",
      padding: 15,
      position: "static"
    },
    "& p": {
      marginBottom: 0
    }
  },
  triptychStepContent: {
    "> div": {
      height: "25vh"
    }
  }
});

const Triptych = () => {
  const classes = useStyles();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const onStepEnter = ({ data }) => setCurrentStepIndex(data);
  const opacityForStep = (currentIndex: number, index: number) => ({
    opacity: currentIndex >= index ? 1 : 0.25,
    zIndex: currentIndex >= index ? 100 : 10
  });
  const acts = [...Array(4).keys()].map((i) => (i === 3 ? "filler-half" : "filler-3-quarter"));

  const determineImgClass = (currentStepIndex: number, imageIndex: number) => {
    const classNames = [classes.triptychImage];
    if (currentStepIndex === imageIndex) {
      classNames.push("active");
    }
    return classNames.join(" ");
  };

  const { farmer, chicken, child } = imageDictionary;
  return (
    <Scene background="black">
      <SceneFeaturedContent className={classes.triptychFeaturedContent}>
        <div className={classes.triptych}>
          {[farmer, chicken, child].map((image, i) => (
            <StoryImage key={i} image={image} className={determineImgClass(currentStepIndex, i)} />
          ))}
        </div>
        <p className={classes.triptychText}>
          <span style={opacityForStep(currentStepIndex, 0)}>
            The answer appears to be a uniquely Delaware story blending immigration trends
          </span>
          <span style={opacityForStep(currentStepIndex, 1)}>{", the poultry industry"}</span>
          <span style={opacityForStep(currentStepIndex, 2)}>
            {", and grassroots efforts of community leaders and organizations."}
          </span>
        </p>
      </SceneFeaturedContent>
      <div>
        <SceneScrollContainer
          acts={acts}
          onStepEnter={onStepEnter}
          className={classes.triptychStepContent}
        />
      </div>
    </Scene>
  );
};

export default Triptych;
