import { Controller } from "stimulus";
import { LegendController } from "./types";

interface Value {
  value: number;
  label: string;
  unit: string;
}

interface Properties {
  indicatorPosition: number[];
  centroid: number[];
  color: string;
  dataType: string;
  featureType: string;
  humanDate: number;
  locationName: string;
  locationSlug: string;
  path: string;
  values: Value[];
}
interface Feature {
  properties: Properties;
}

interface Layer {
  feature: Feature;
}

export default class extends Controller implements LegendController {
  static targets = ["cell"];
  declare cellTargets: HTMLElement[];
  declare activeCell?: HTMLElement;

  showIndicator(layer: Layer) {
    this.clearIndicator();
    const { properties } = layer.feature;
    if (!properties) return;

    const { indicatorPosition = [] } = properties;
    if (!this.cellTargets || !this.cellTargets.length) return;
    if (!indicatorPosition.length) {
      this.clearIndicator();
      return;
    }
    this.activeCell = this.cellTargets.find((el) => {
      return el.dataset.position?.match(indicatorPosition);
    });

    if (this.activeCell && this.indicatorContainer) {
      this.indicatorContainer.innerHTML = `
        <div class='c-indicator-pog'>
          <div class='c-indicator-pog__left'></div>
          <div class='c-indicator-pog__right'></div>
        </div>
      `;
    }
  }

  clearIndicator() {
    if (this.indicatorContainer) {
      this.indicatorContainer.innerHTML = "";
    }
  }

  get indicatorContainer() {
    return this.activeCell?.querySelector("div:not(.cell-context)") || this.activeCell;
  }
}
