import isNil from "lodash/isNil";
import compact from "lodash/compact";

export const GaEventTypes = {
  Click: "click", // clicks of *outbound* links - https://support.google.com/analytics/answer/9234069?sjid=10768985373875242647-NA#click
  UserInteraction: "user_interaction", // Non-navigational interactions
  FileDownload: "file_download", //  https://support.google.com/analytics/answer/9234069?sjid=10768985373875242647-NA#file_download
  Navigation: "navigation", // custom
  Search: "search" // custom
} as const;
const gaEventTypesValues = Object.values(GaEventTypes);
export type GaEventType = typeof gaEventTypesValues[number];

export const GaEventCategories = {
  Accordions: "Accordions",
  Charts: "Charts",
  ContextSwitcher: "Context Switcher",
  DataDownload: "Data Download",
  Embed: "Embed",
  FuzzySearch: "Fuzzy Search",
  Headers: "Headers",
  Indicator: "Indicator",
  LocationPicker: "Location Picker",
  LocationSwitcher: "Location Switcher",
  Maps: "Maps",
  Modals: "Modals",
  PageTabs: "Page Tabs",
  PdfDownload: "PDF Download",
  Popovers: "Popovers",
  SideNav: "Side Nav",
  Tables: "Tables",
  Tooltips: "Tooltips"
} as const;
const gaEventCategoryValues = Object.values(GaEventCategories);
export type GaEventCategoryType = typeof gaEventCategoryValues[number];

export interface GaClickEventParameters {
  category: GaEventCategoryType;
  action: string;
  label?: string;
  value?: string|number;
  link_path?: string;
}
export type googleEvent = GaClickEventParameters

export const gaMapActions = {
  polygonClick: "Location polygon click",
  polygonMouseOver: "Location polygon mouseover"
};

export const gaTableActions = {
  rowClick: "Row click"
};

export const gaActions: Record<string, Record<string, string>> = {
  [GaEventCategories.Maps]: gaMapActions
};

interface SendGaEventParams {
  eventType: GaEventType;
  parameters: GaClickEventParameters;
}

export const sendGAEvent = ({ eventType="click", parameters }: SendGaEventParams) => {
  if (typeof(gtag) === "undefined" ||  isNil(gtag) || !parameters) return;
  const aggregate = compact([
    parameters.category,
    parameters.action,
    parameters.label,
    parameters.value,
  ]).join(" | ")
  const _params = {
    ...parameters,
    aggregate,
  }
  gtag("event", eventType, _params);
};

export const sendGaEvent = sendGAEvent

export const sendGaSearchEvent = (parameters: GaClickEventParameters) =>
  sendGAEvent({ eventType: GaEventTypes.Search, parameters });

export const sendGaUserInteractionEvent = (parameters: GaClickEventParameters) =>
  sendGAEvent({ eventType: GaEventTypes.UserInteraction, parameters });

export const sendGaNavigationEvent = (parameters: GaClickEventParameters) =>
  sendGAEvent({ eventType: GaEventTypes.Navigation, parameters });
