import React, { Component } from "react";
import Trend from "../charts/trend";
import classnames from "classnames";
import { getDatesInRange } from "../utils/date";
import { colors } from "../charts/options";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";

export default class TrendChart extends Component {
  componentDidMount() {
    this.chart = new Trend(this.getChartData(), [this.trendChartWrapperEl, this.trendChartEl], {
      showXAxis: this.props.options.showXAxis,
      showYAxis: this.props.options.showYAxis,
      height: this.props.options.height || 50,
      colors: colors("comparison"),
      isComparison: true,
      tooltipAddendum:
        this.props.comparison && this.props.comparisonLocResolutionChanged
          ? `
          <div class='c-tooltip__info-message'>
            <i class='fas fa-info-circle'></i>
            <span>
              Data is for ${this.props.comparison.location.name} because there is insufficient
              data for the selected comparison location
            </span>
          </div>
        `
          : null,
      rotate: 0,
      type: "time"
    });
  }

  componentDidUpdate() {
    if (this.chart && this.chart.update) {
      this.chart.update(this.getChartData(), {
        tooltipAddendum:
          this.props.comparison && this.props.comparisonLocResolutionChanged
            ? `
            <div class='c-tooltip__info-message'>
              <i class='fas fa-info-circle'></i>
              <span>
                Data is for ${this.props.comparison.location.name} because there is insufficient
                data for the selected comparison location
              </span>
            </div>
          `
            : null
      });
    }
  }

  getChartData() {
    const data = this.props;
    const dates = this.props.dates || getDatesInRange("year", data.date_range);
    const columns = [];

    ["primary", "comparison"].forEach((s) => {
      // trend data is {date: YYYY, value: number_as_string}
      // `date`` is always a year, `value` can be a String since
      // it's a Decimal in ruby
      if (data[s] && data[s].trend) {
        let trendByYear = keyBy(data[s].trend, "date");
        let column = [s];

        dates.forEach((d) => {
          const year = d.getFullYear();
          column.push(trendByYear[year] ? +trendByYear[year].value : null);
        });
        columns.push(column);
      }
    });

    return {
      names: compact([
        data.primary.location.name,
        data.comparison ? data.comparison.location.name : ""
      ]),
      baseline: data.trend_baseline,
      columns,
      dates,
      unit: this.props.unit || ""
    };
  }

  render() {
    const { classNames } = this.props;
    const _classNames = classnames(classNames, "c-chart-trend");
    return (
      <div
        className="o-chart"
        ref={(el) => (this.trendChartWrapperEl = el)}
        aria-label="Sparkline chart which is not yet screen reader friendly"
      >
        <div className={_classNames} ref={(el) => (this.trendChartEl = el)} />
      </div>
    );
  }
}
