import React, { Component } from "react";
import MetricCardHeader from "./MetricCardHeader.tsx";
import MetricCardMetric from "./MetricCardMetric";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import classnames from "classnames";
import { MetricChange } from "./MetricChange";
import { MetricFooter } from "./MetricFooter";
import { Sparkline, transformTrendsToSeries } from "./Sparkline";

export default class MetricCard extends Component {
  componentWillReceiveProps(nextProps) {
    const { comparison } = this.props.data;
    if (
      comparison &&
      nextProps.data.comparison &&
      comparison.location.slug === nextProps.data.comparison.nextComparisonSlug
    )
      return false;
  }

  _getMetricValues(key, giveComparison = true) {
    const { primary, comparison } = this.props.data;
    return {
      primary: primary ? primary[key] : null,
      comparison: comparison && giveComparison ? comparison[key] : null
    };
  }

  locations() {
    const { primary, comparison } = this.props.data;
    return {
      primaryLocation: primary.location,
      comparisonLocation: comparison ? comparison.location : {}
    };
  }

  rates(base, comparisonLocResolutionChanged) {
    const rates = this._getMetricValues("rates");

    // This prevents the entire page from crashing when there's no data.
    if (rates.primary.length == 0) {
      return "";
    }

    // This is a little complex and probably needs to be re-imagined/re-factored
    // Transform rates into arrays from obj of format:
    // { primary: [], comparison: [] }
    // to new format:
    // [ { title: '', value: { primary: {}, comparison: {} } },...]
    const metrics = [];
    let tooltipAddendum = "";
    if (this.props.data.primary.granularity === "five-year-window")
      tooltipAddendum = " Average rates for the selected time period";
    Object.keys(rates).forEach((k) => {
      if (!rates[k]) return;
      rates[k].forEach((r, i) => {
        metrics[i] = metrics[i] || {};
        metrics[i].title = r.title;
        metrics[i].value = metrics[i].value || {};
        metrics[i].value[k] = r.value;
        metrics[i].timeframe = metrics[i].timeframe || [];
        metrics[i].timeframe[k] = r.year;
        metrics[i].tooltip = (r.tooltip || "") + tooltipAddendum;
        metrics[i].unit = r.unit || "";
      });
    });
    const { data } = this.props;

    // "* Age-Adjusted Rate..." in a card only applies to one of the two rates if there are two.
    // Assuming the first one will remain the correct one. And you know what assuming makes, right?
    let seenRateUnitWordsCount = 0;

    const buildProps = (rate) => {
      seenRateUnitWordsCount += 1;
      return {
        title: rate.title,
        timeframe: rate.timeframe,
        tooltip: rate.tooltip || "",
        unit: rate.unit || "",
        titleAddendum: `${data.rate_unit_words && seenRateUnitWordsCount < 2 ? "*" : ""}`,
        metricValue: rate.value,
        comparisonLocResolutionChanged
      };
    };

    const baseProps = {
      baseClasses: base,
      locations: this.locations()
    };
    let finalProps = {};
    if (metrics.length > 1) {
      finalProps = Object.assign({ metrics: metrics.map(buildProps) }, baseProps);
    } else {
      finalProps = Object.assign(buildProps(metrics[0]), baseProps);
    }

    return <MetricCardMetric {...finalProps} />;
  }

  render() {
    const { data = {}, location, comparisonLocationId, showInfoMessage } = this.props;
    const {
      comparison,
      date_range,
      path,
      primary,
      rate_title = "",
      rate_unit_words,
      show = false,
      target_tooltip,
      title_tooltip,
      title,
      trend_baseline
    } = data;

    if (!primary) {
      return (
        <div className="c-metric-card c-metric-card--suppressed">
          {title && (
            <MetricCardHeader
              title={title}
              date={""}
              addendum={""}
              location={location}
              primaryDataLocation={location}
            />
          )}
          <div className="c-metric-card__content c-metric-card__row">
            <p className="my-3">There is insufficient data to support this indicator</p>
          </div>
        </div>
      );
    }

    let comparisonLocResolutionChanged = false;
    if (comparison)
      comparisonLocResolutionChanged = comparisonLocationId !== comparison.location.slug;
    let dateRange = compact(uniq(date_range));
    dateRange = dateRange.length ? dateRange.join("-") : "";
    const base = `c-metric-card__group c-metric-card__group--sized`;
    const groupClasses = {
      change: classnames(base, {
        "c-metric-card__group--disabled": !compact(primary.change).length
      }),
      trend: classnames(base, {
        "c-metric-card__group--disabled": !primary.trend || !primary.trend.length
      })
    };
    const unit = rate_title === "Percent" ? "%" : rate_unit_words;
    const targetUnit = unit == "%" ? "%" : "";

    if (!show) {
      return <div className="supressed-slat" hiddenReason={`${title} doesn't have enough data.`} />;
    }

    return (
      <div className="c-metric-card">
        <MetricCardHeader
          title={title}
          tooltip={title_tooltip}
          date={dateRange}
          addendum={rate_unit_words}
          location={location}
          primaryDataLocation={primary.location}
          showInfoMessage={showInfoMessage}
          path={path}
        />

        <div className="c-metric-card__content c-metric-card__row">
          <MetricCardMetric
            title={`${primary.granularity === "five-year-window" ? "5-year " : ""}Count`}
            baseClasses={base}
            metricValue={this._getMetricValues("count")}
            locations={this.locations()}
            comparisonLocResolutionChanged={comparisonLocResolutionChanged}
          />

          {this.rates(base, comparisonLocResolutionChanged)}

          <MetricCardMetric
            title="Benchmark"
            baseClasses={base}
            tooltip={target_tooltip}
            metricValue={this._getMetricValues("rate_target", false)}
            unit={targetUnit}
            locations={this.locations()}
            comparisonLocResolutionChanged={comparisonLocResolutionChanged}
            showPrimaryTooltip={false}
          />

          <MetricChange
            className={groupClasses.change}
            dateRange={dateRange}
            change={primary.change}
          />
          {primary.show_trend && (
            <Sparkline
              className={groupClasses.trend}
              dateRange={dateRange}
              series={transformTrendsToSeries([primary, comparison])}
              benchmark={trend_baseline}
              unit={unit}
              comparisonLocResolutionChanged={comparisonLocResolutionChanged}
            />
          )}
        </div>
        {path && <MetricFooter path={path} />}
      </div>
    );
  }
}
