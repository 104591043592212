import React, { Component } from "react";
import LoadingAnimation from "../LoadingAnimation";
import PlotChart from "../charts/plot";
import ChartDataDetail from "./ChartDataDetail";
import { uniqeId } from "../utils/index";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

export default class extends Component {
  constructor(props) {
    super(props);
    this.chartInstance = null;
    this.id = uniqeId();
    this.state = {
      loading: true,
      data: {}
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (this.props.comparison.identifier !== nextProps.comparison.identifier) {
      this.setState({ loading: true });
      if (this.chartInstance) this.chartInstance._removeLegend();
      this.fetchData(this.getProps(nextProps));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.loading === nextState.loading &&
      this.props.comparison.identifier === nextProps.comparison.identifier
    ) {
      return false;
    }
  }

  componentDidUpdate() {
    if (!this.state.loading && this.state.data) {
      if (this.chartInstance) {
        this.chartInstance._getChart().destroy();
      }
      this.chartInstance = new PlotChart(this.getProps(), [this.wrapperEl, this.containerEl], {
        showTrendLine: true
      });
      this.chartInstance._init();
    }
  }

  /**
   * fetchData - Request data with fetch (polyfill)
   *
   * @param  {Object} props=null Props to build out endpoint
   * @return                   State is updated with response
   */
  fetchData(props = null) {
    const { comparison, primary, location } = props || this.getProps();
    const endPoint = `/locations/${location}/stat-comparison.json?identifier1=${comparison.identifier}&identifier2=${primary.identifier}`;
    const setStateWithData = (data, error = "") => {
      this.setState({ loading: false, data: data, error: error });
    };
    fetch(endPoint)
      .then((response) => response.json())
      .then((data) => {
        if (!data.stat_identifier1 || !data.stat_identifier2) data = null;
        setStateWithData(data);
      })
      .catch((error) => setStateWithData(data, error));
  }

  /**
   * parseData - Use rate_multiplier to update data
   *
   * @param  {Object} data Raw data payload
   * @return {Object}      Data with new values considering rate_multiplier(s)
   */
  parseData(data) {
    if (!data || !data.results) return {};
    else return data.results;
  }

  /**
   * getProps - Get parsed props for chart after update
   *
   * @param  {Object} props=null New or old props depending on state
   * @return {Object}            Updated props with data from state
   */
  getProps(props = null) {
    const { comparison, primary, data, location } = props || this.props;
    return {
      data: this.parseData(this.state.data),
      primary: primary,
      comparison: comparison,
      location: data.location,
      title: data.title
    };
  }

  /**
   * title - Get title with comparison
   *
   * @return {Node}
   */
  title() {
    return (
      <div className="c-chart__header">
        <h3 className="c-chart__title" id="label-chart-title">
          {this.props.data.title} compared with {this.props.comparison.name}
        </h3>
      </div>
    );
  }

  /**
   * getDetailProps - Build props for chart supplemental
   *
   * @return {Object}  Props to give to Detail Component
   */
  getDetailProps() {
    const { results } = this.state.data;
    if (!results) return { items: [] };
    return {
      items: [
        {
          title: "Pearson Correlation",
          value: results.pearson_correlation,
          units: null
        },
        {
          title: "Spearman Correlation",
          value: results.spearman_correlation,
          units: null
        }
      ]
    };
  }

  /**
   * chart - Show chart elements or no data message
   *
   * @return {Node}  Chart elements or no data UI
   */
  chart() {
    if (!this.state.data) {
      return (
        <div>
          {this.title()}
          <div className="px-5 ">
            <p>We're having trouble finding the data for this investigation.</p>
          </div>
        </div>
      );
    }
    return (
      <div>
        {this.title()}
        <div className="d-flex align-items-start">
          <div
            className="c-chart__container"
            aria-labelledby="label-chart-title"
            aria-label="Charts are not yet screen reader friendly."
            style={{ flex: "0 0 75%" }}
            ref={(el) => (this.containerEl = el)}
          />
          <ChartDataDetail {...this.getDetailProps()} />
        </div>
      </div>
    );
  }

  render() {
    const { title } = this.props;
    const { loading, data } = this.state;
    const chartClassnames = classnames(
      "c-chart",
      { "c-chart--flex-height": !data && !loading },
      { "c-chart--loading": loading }
    );
    return (
      <div className={chartClassnames} ref={(el) => (this.wrapperEl = el)} id={this.id}>
        {!loading ? this.chart() : <LoadingAnimation veil={true} />}
      </div>
    );
  }
}
