import React, { Fragment, useState, useRef, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import { SeriesLineOptions } from "highcharts";
import Indicators from "highcharts/indicators/indicators";
import HighchartsReact from "highcharts-react-official";
import { defaultConfig } from "charts/config";
import { setGlobalOptions } from "charts/utils";
import defaultsDeep from "lodash/defaultsDeep";
import LoadingAnimation from "../LoadingAnimation";
import { fetchChartData } from "./utils";
import { figureTitle, figureSource } from "../styles/figures";
import { createUseStyles } from "react-jss";

Indicators(Highcharts);
setGlobalOptions && setGlobalOptions();

const useStyles = createUseStyles({
  chartTitle: figureTitle,
  chartSubTitle: {
    fontFamily: "open sans",
    display: "block",
    "& strong": {
      fontWeight: 700
    },
    "& span": {
      fontSize: 16,
      fontWeight: "normal"
    }
  },
  chartContainer: {
    flex: "1 1 100%",
    display: "flex",
    "& > div": {
      width: "100%",
      flex: "1 1 100%"
    }
  },
  chartContext: {
    flex: "0 1 auto !important",
    marginTop: 10,
    "&:empty": {
      margin: 0,
      display: "none"
    }
  },
  chartSource: figureSource
});

type LineChartProps = {
  title: string;
  subTitle?: string;
  chartOptions: object;
  series?: Series | "ERROR" | null;
  identifiers?: string[];
  dateRange?: string[];
  jsonFile?: string;
  mutateSeries?: Function;
  loadingAnimationHeight?: number;
  source?: string;
  note?: string;
};

type LineChartState = {
  renderedSeries: SeriesLineOptions[];
  categories?: number[] | string[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  title?: string;
  source?: string;
  note?: string;
};

const LineChart: React.FC<LineChartProps> = ({
  title = "",
  subTitle = "",
  chartOptions,
  series = null,
  identifiers = [],
  dateRange = [],
  jsonFile = "",
  source = "",
  note = "",
  mutateSeries,
  loadingAnimationHeight
}) => {
  const [renderedState, setChartState] = useState<LineChartState | "ERROR" | null>(null);
  const chartComponentRef = useRef<HTMLElement | null>(null);
  const classes = useStyles();

  useEffect(() => {
    async function _setChartState() {
      if (renderedState === null && (identifiers.length || jsonFile)) {
        const payload = await fetchChartData(identifiers, jsonFile, dateRange);
        setChartState({
          renderedSeries: payload.series,
          ...payload
        });
      }
    }
    _setChartState();
  }, [series, renderedState, setChartState, identifiers, jsonFile, dateRange]);

  if (series === "ERROR") {
    return <p>There was an error loading data for maps</p>;
  } else if (renderedState === null) {
    return (
      <>
        <LoadingAnimation height={loadingAnimationHeight} />
      </>
    );
  }

  const {
    renderedSeries = [],
    categories,
    xAxisLabel,
    yAxisLabel
  } = renderedState as LineChartState;

  let _series = renderedSeries;
  if (series && series.length) {
    _series = series;
  }
  const _source = source || renderedState.source || "";
  const _note = note || renderedState.note || "";
  const _title = title || renderedState.title || "";
  const _subTitle = subTitle || renderedState.subTitle || "";

  // Modify options to accommodate categorical data
  chartOptions.xAxis = chartOptions.xAxis || {};
  chartOptions.yAxis = chartOptions.yAxis || {};
  if (categories) {
    chartOptions.xAxis.categories = categories;
    chartOptions.xAxis.type = "category";
    chartOptions.xAxis.tickmarkPlacement = "on";
  }

  if (xAxisLabel) {
    chartOptions.xAxis.title = { text: xAxisLabel };
  }
  if (yAxisLabel) {
    chartOptions.yAxis.title = { text: yAxisLabel };
  }

  const options = defaultsDeep(
    {
      ...chartOptions,
      series: mutateSeries ? mutateSeries(_series) : _series
    },
    defaultConfig
  );
  return (
    <>
      {(_subTitle || _title) && (
        <h3 className={classes.chartTitle}>
          {_title}
          {_subTitle && (
            <span className={classes.chartSubTitle}>
              <span>{_subTitle}</span>
            </span>
          )}
        </h3>
      )}
      <div className={classes.chartContainer}>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
      </div>
      <div className={classes.chartContext}>
        {_source && (
          <p className={classes.chartSource}>
            <strong>Source:</strong> {_source}
          </p>
        )}
        {_note && (
          <p className={classes.chartSource}>
            <strong>Note:</strong> {_note}
          </p>
        )}
      </div>
    </>
  );
};

export default LineChart;
