import React from "react";
import { storeRecentSearch } from "./FuzzySearchRecents";
import { createUseStyles } from "react-jss";
import { SearchResult } from "./types";
import { resultsStyle } from "./style";
import { sendGaNavigationEvent } from "../../javascript/utils/google_analytics";

const useStyles = createUseStyles({
  fuzzySearchListParent: resultsStyle
});

interface FuzzySearchResultsListItemProps {
  item: SearchResult;
}

const FuzzySearchResultsListItem: React.FC<FuzzySearchResultsListItemProps> = ({ item }) => {
  const classes = useStyles();
  const { path, name, parent = null } = item;
  return (
    <li>
      <a href={path} onClick={() => {
          sendGaNavigationEvent({
            category: "Fuzzy Search",
            action: "Select",
            label: name ?? ""
          });
          storeRecentSearch(item)
        }}>
        {name}
      </a>
      {parent && <span className={classes.fuzzySearchListParent}>{parent}</span>}
    </li>
  );
};

export default FuzzySearchResultsListItem;
