// @ts-nocheck

import DynamicContentCardController from "../dynamic-content-card-controller";
import { geoJSON, GeometryUtil } from "leaflet";
import { renderBaseMap } from "./utils";

export default class extends DynamicContentCardController {
  initialize() {
    const { locationSlug = null } = this;
    if (locationSlug) {
      this.fetch(
        `/context-switcher/locations-geo-json/slugs/${locationSlug}?include_deprecated=1`,
        this.contentTarget,
        "json"
      );
    } else {
      this.handleError();
    }
  }

  handleLoad(data: any, _contentContainer?: HTMLElement) {
    this.buildMap(data);
  }

  buildMap({ geoJson, token }) {
    let _geoJson = geoJson;
    if (typeof geoJson === "object" && geoJson.length) {
      _geoJson = geoJson[0];
    }

    this.map = renderBaseMap(this.contentTarget, token, {
      addScale: false,
      initialZoom: 10,
      zoomControl: false,
      doubleClickZoom: false,
      touchZoom: false,
      scrollWheelZoom: false,
      dragging: false,
      baseLayer: "simple",
      tabindex: "-1"
    });

    if (_geoJson) {
      this.locationLayer = geoJSON(_geoJson, {
        interactive: false,
        style: {
          weight: 1,
          opacity: 0.75,
          color: "#C24644",
          dashArray: "",
          fillColor: "#147BB1",
          fillOpacity: 0.5
        }
      });
      this.map.fitBounds(this.locationLayer.getBounds(), {
        padding: [15, 15],
        maxZoom: 12
      });
      this.locationLayer.addTo(this.map);
    }
  }

  get locationSlug() {
    return this.data.get("locationSlug");
  }

  get token() {
    return this.data.get("token");
  }
}
