import React from "react";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";
import { sendGaNavigationEvent } from "../../javascript/utils/google_analytics";

const useStyles = createUseStyles({
  fuzzySearchExploreAll: {
    display: "block",
    background: "#e9e9e9",
    padding: [10, 15],
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    textAlign: "left",
    all: "unset",
    [breakpoints.up("sm")]: {
      padding: 15
    },
    "&:hover, &:focus": {
      background: "#245194",
      color: "white"
    }
  }
});

const handleTriggerContextSwitcher = () => {
  sendGaNavigationEvent({category: "Fuzzy Search", action: "Explore all Locations and Topics"})
  // App is added to the globals
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (App.events?.contextSwitcherTrigger) {
    // App is added to the globals
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.body.dispatchEvent(App.events.contextSwitcherTrigger);
  }
};

const FuzzySearchAllResults = ({ closeModal }: {closeModal: () => void}) => {
  const classes = useStyles();
  return (
    <button onClick={handleTriggerContextSwitcher} className={classes.fuzzySearchExploreAll} onBlur={(event) => closeModal()}>
      Explore all Locations and Topics
    </button>
  );
};

export default FuzzySearchAllResults;
