import React from "react";
import Dropdown from "../Dropdowns/Dropdown";
import DropdownMenu from "../Dropdowns/DropdownMenu";

export default class extends Dropdown {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null
    };
  }

  handleClear() {
    this.setState({ selectedItem: null });
    this.props.handleClearComparison();
  }

  componentDidMount() {
    document.addEventListener("chart:clear-investigation", this.handleClear.bind(this));
  }

  render() {
    const { selectedItem } = this.state;
    const dropdownLabel = "Select data set for investigation";
    return (
      <div className="c-filters__filter-wrapper">
        <DropdownMenu
          selectedItem={selectedItem}
          placeholder="Investigate"
          label={dropdownLabel}
          ga={{
            category: "Maps and Charts",
            action: "Investigation",
            label: "Investigation Dropdown"
          }}
          actionLabel={dropdownLabel}
          constrained={true}
        >
          {this._menuItems()}
          {selectedItem && (
            <div className="c-dropdown__menu-container c-dropdown__menu-container--border-top">
              <button
                className="btn btn-outline-primary d-flex align-items-center"
                onClick={this.handleClear.bind(this)}
              >
                <span className="far fa-times-circle mr-2" />
                <span>Clear current investigation</span>
              </button>
            </div>
          )}
        </DropdownMenu>
        {selectedItem && (
          <div
            className="c-filters__clear far fa-times-circle"
            onClick={this.handleClear.bind(this)}
          />
        )}
      </div>
    );
  }
}
