// Based on MUI's breakpoint logic (very primitively)
// https://github.com/mui-org/material-ui
// material-ui/packages/mui-system/src/breakpoints.js

// The breakpoint **start** at this value.
// For instance with the first breakpoint xs: [xs, sm[.
export const values = {
  xs: 0, // phone
  sm: 576, // tablet
  md2: 768, // small laptop
  md: 864, // small laptop
  lg: 1200, // desktop
  xl: 1440 // large screen
};

const defaultBreakpoints = {
  // Sorted ASC by size. That's important.
  // It can't be configured as it's used statically for propTypes.
  keys: ["xs", "sm", "md", "md2", "lg", "xl"],
  up: (key: string) => `@media (min-width:${values[key]}px)`
};

const breakpoints = defaultBreakpoints;

export default breakpoints;
