import React from "react";
import { QuoteProps } from "../types";
import { createQuoteStyle } from "./styles";
import InlineCredit from "../InlineCredit";

const Quote = ({ credit, size = "md" }: QuoteProps) => {
  const classes = createQuoteStyle();
  const { text, textShort, originalCopy } = credit;
  return (
    <div className={classes.quoteWrapper}>
      <p className={classes[`quoteText-${size}`]}>"{originalCopy}"</p>
      {(text || textShort) && (
        <p className={classes.quoteCredit}>
          {textShort || text}
          <InlineCredit credit={credit} />
        </p>
      )}
    </div>
  );
};

export default Quote;
