import React, { Fragment, useState } from "react";
import Quote from "./Quote";
import Scene from "../Scene/Scene";
import SceneScrollContainer from "../Scene/SceneScrollContainer";
import SceneFeaturedContent from "../Scene/SceneFeaturedContent";
import { getCreditById } from "./copy.tsx";
import { createQuoteWrapperStyles } from "./styles";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { getImages } from "./config";

import { QuoteProps, Act, StepData, Credit } from "../types";

const quotes: Credit[] = [
  null,
  getCreditById("waltenburg", "footnotes"),
  getCreditById("dyal-1", "footnotes"),
  getCreditById("dyal-2", "footnotes")
];
const lastQuoteCredit = getCreditById("rosa-working", "footnotes");

const Quotes = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const onStepEnter = ({ data }: StepData) => setCurrentStepIndex(data);
  const classes = createQuoteWrapperStyles();
  const { cdcLogo } = getImages(["cdcLogo"]);

  const quoteActs: Act[] = quotes.map((quote) => (
    <div className={classes.quotesWrapper}>{quote && <Quote credit={quote} />}</div>
  ));

  const lastQuote: QuoteProps = {
    size: "lg",
    credit: lastQuoteCredit
  };

  const determineIntroClass = () => {
    const classNames = [classes.quotesIntro];
    if (currentStepIndex === 0) {
      classNames.push(classes.shifted);
    }
    return classNames.join(" ");
  };

  return (
    <>
      <Scene>
        <SceneFeaturedContent className={classes.quotesFeatured}>
          <div className={classes.quotesIntroWrapper}>
            <div className={determineIntroClass()}>
              <p>
                Upon COVID-19's arrival in the U.S., public health authorities recognized the
                heightened vulnerability of poultry and meat facility workers nationwide—who often
                could not afford time off unpaid—and observed a disproportionate impact on
                minorities.
              </p>
            </div>
            {currentStepIndex > 0 && (
              <div className={classes.quotesImageWrapper}>
                <LazyLoadImage
                  wrapperClassName={classes.quotesImage}
                  src={cdcLogo.src}
                  alt={cdcLogo.altText}
                />
              </div>
            )}
          </div>
        </SceneFeaturedContent>
        <SceneScrollContainer
          acts={quoteActs}
          onStepEnter={onStepEnter}
          className={classes.quotesContentWrapper}
        />
      </Scene>
      <div className={classes.quotesWrapper}>
        <Quote {...lastQuote} />
      </div>
    </>
  );
};

export default Quotes;
