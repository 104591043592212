import { tickInterval, dateTimeLabelFormats } from "charts/config";
import defaultsDeep from "lodash/defaultsDeep";

export const chartTextStyle = {
  fontSize: "15px",
  lineHeight: "20px"
};

export const labels = {
  style: chartTextStyle
};

export const defaultConfigs = {
  line: {
    xAxis: {
      type: "datetime",
      minTickInterval: tickInterval("daily"),
      labels,
      dateTimeLabelFormats,
      title: {
        style: chartTextStyle
      }
    },
    yAxis: {
      labels,
      title: {
        style: chartTextStyle
      }
    },
    plotOptions: {
      series: {
        marker: { enabled: false },
        showInLegend: true,
        lineWidth: 3
      }
    },
    legend: {
      title: {
        style: chartTextStyle
      }
    }
  }
};

export const chartOptions = {
  earlyCaseRates: defaultsDeep(
    {
      title: { text: null },
      yAxis: {
        title: { text: "Rate per 10,000 population (14-day moving average)" },
        max: 20
      },
      xAxis: {
        title: { text: "Date" }
      }
    },
    defaultConfigs.line
  )
};
