import breakpoints from "../../styles/breakpoints";

const textContainerBG = "rgba(255,255,255,.9)";

export const baseFontStyles = {
  fontSize: 24,
  lineHeight: "32px"
};

export const baseFontStylesLarge = {
  fontSize: 32,
  lineHeight: "44px"
};

export const textStyle = {
  padding: [50, "5vw"],
  "& p": {
    ...baseFontStyles,
    marginBottom: 10,
    background: textContainerBG,
    color: "black",
    padding: 20,
    "& sup": {
      fontSize: 18,
      lineHeight: "16px"
    },
    "&.lead": {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "34px"
    }
  },
  [breakpoints.up("md")]: {
    ...baseFontStylesLarge,
    textAlign: "center",
    "& p": {
      padding: 0,
      "&.lead": {
        fontSize: 32,
        lineHeight: "40px"
      }
    }
  }
};

export const textContainer = {
  background: textContainerBG,
  padding: [20, "5vw"],
  "& p": {
    ...baseFontStyles,
    background: "none",
    paddingTop: 0,
    paddingBottom: 0,
    margin: [0, 0, 20, 0],
    "&:last-of-type": {
      marginBottom: 0
    },
    "& sup": {
      fontSize: 18,
      lineHeight: "18px"
    },
    [breakpoints.up("md")]: {
      ...baseFontStylesLarge
    }
  }
};

export const linkStyle = {
  color: "#1576D7",
  textDecoration: "underline",
  "&:hover, &:focus": {
    color: "#2669AA",
    textDecoration: "none"
  }
};

export const chartTitleHighlighted = {
  color: "#1576D7"
};

export const leadText = (multiplier = 1) => ({
  fontWeight: 600,
  fontSize: multiplier * 0.75 * 48,
  lineHeight: `${multiplier * 0.75 * 52}px`,
  [breakpoints.up("md")]: {
    fontSize: multiplier * 48,
    lineHeight: `${multiplier * 52}px`
  }
});
