import renderChart from "charts/renderChart";
import Highcharts from "highcharts";
import { Controller } from "stimulus";
import { colorConfig, tickInterval, dateTimeLabelFormats } from "charts/config";
import { TimeScale } from "charts/types";

type ChartConfig = {
  title: string;
  yAxisTitleText: string;
  timeScale: TimeScale;
  enableRangeSelector: boolean;
  series: Array<{
    type?: "line" | "spline";
    name: string;
    color?: string;
    data: number[];
  }>;
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({
    series,
    title,
    yAxisTitleText,
    enableRangeSelector,
    timeScale = "yearly"
  }: ChartConfig) {
    const { element } = this;
    // const renderFn = useDateRange ? renderStockChart : renderChart
    renderChart({
      title: {
        text: title
      },
      chart: {
        renderTo: element as HTMLElement
      },
      yAxis: {
        title: { text: yAxisTitleText }
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats,
        minTickInterval: tickInterval(timeScale)
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 4
          }
        }
      },
      rangeSelector: {
        enabled: !!enableRangeSelector
      },
      series: series.map(
        ({ data, name, color, type }, i: number): Highcharts.SeriesOptionsType => {
          return {
            color: color || colorConfig.set[i],
            type: type || "line",
            data: data.map(([d, v]) => [Date.parse(d), v]),
            name,
            pointInterval: 1
          };
        }
      )
    });
  }
}
