import React from "react";
import classnames from "classnames";

const CardInfoWarning = ({
  message,
  primaryLocation,
  location,
  inCard = true,
  wrapperClassNames = ""
}) => {
  const classNames = classnames(
    "c-note",
    "c-note--danger",
    { "rounded-0": inCard },
    "m-0",
    wrapperClassNames
  );
  if (!message) {
    message = `Statistics shown are calculated from data for <b> ${primaryLocation} </b> because there are insufficient data for <b> ${location}</b>`;
  }
  return (
    <div className={classNames}>
      <i className="fas fa-info-circle mr-2" />
      <p className="mb-0" dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
};

export default CardInfoWarning;
