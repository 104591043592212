import React from "react";
import { createUseStyles } from "react-jss";

type IconProps = {
  id: string;
};

const useIconStyles = createUseStyles({
  faIcon: {
    "&::before": {
      display: "block",
      fontFamily: "'Font Awesome 6 Free'",
      fontWeight: 700,
      fontStyle: "normal",
      color: "#0060f0"
    }
  },
  search: {
    "&::before": {
      content: "'\\f002'"
    }
  },
  close: {
    "&::before": {
      content: "'\\f00d'"
    }
  }
});

const Icon: React.FC<IconProps> = ({ id }) => {
  const classes = useIconStyles();
  return <i className={[classes.faIcon, classes[id]].join(" ")} />;
};

export default Icon;
