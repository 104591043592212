import Highcharts from "highcharts/highstock";
import { ChartColorConfig, TimeScale } from "./types";

export const CHART_COLORS = [
  "#1E90FF",
  "#E5B303",
  "#85C390",
  "#A0DEE7",
  "#B4AFFF",
  "#F7C390",
  "#296069",
  "#4E1199",
  "#9C6F4C",
  "#FF6584"
];
export const colorConfig = {
  primary: CHART_COLORS[0],
  secondary: CHART_COLORS[1],
  average: "#b5daff",
  trend: "#888888",
  bars: [
    // We should add these for each color in set...and generate the border/text
    // programmatically with a package or a function
    { fill: CHART_COLORS[0], border: "#007AF5", text: "#70B8FF" },
    { fill: CHART_COLORS[1], border: "#C99E03", text: "#FCD64A" }
  ],
  benchmark: "#CCCCCC",
  set: CHART_COLORS
} as ChartColorConfig;

export const tickInterval = (scale: TimeScale) => {
  switch (scale) {
    case "daily":
      return 1000 * 3600 * 24;
    case "weekly":
      return 1000 * 3600 * 24 * 7;
    case "monthly":
      return 1000 * 3600 * 24 * 28;
    default:
      return Date.UTC(2010, 0, 1) - Date.UTC(2009, 0, 1);
  }
};

export const dateTimeLabelFormats = {
  millisecond: "%H:%M:%S.%L",
  second: "%H:%M:%S",
  minute: "%H:%M",
  hour: "%H:%M",
  day: "%e. %b",
  week: "%e. %b",
  month: "%b %Y",
  year: "'%y"
};

const colors = colorConfig.set;
export const axisConfig = {
  crosshair: true,
  gridLineWidth: 1,
  lineWidth: 1,
  lineColor: "#000000",
  tickColor: "#000000",
  tickWidth: 1,
  tickLength: 5
};
export const barColumnChartPlotOpts = {
  maxPointWidth: 200,
  borderRadius: 2,
  opacity: 0.8,
  borderWidth: 1
};

export const defaultConfig = {
  title: {
    align: "left",
    style: {
      color: colorConfig.primary,
      letterSpacing: ".72px",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
      fontFamily: "Open Sans"
    }
  },
  plotOptions: {
    column: barColumnChartPlotOpts,
    bar: barColumnChartPlotOpts
  },
  tooltip: {
    borderWidth: 2,
    borderRadius: 2,
    backgroundColor: "#FFFFFF",
    padding: 10
  },
  credits: {
    enabled: false
  },
  yAxis: {
    min: 0,
    labels: {
      formatter(): string {
        const { value } = this;
        return Highcharts.numberFormat(value, 0);
      }
    },
    opposite: false,
    ...axisConfig
  },
  xAxis: {
    ...axisConfig,
    crosshair: false
  },
  navigator: {
    enabled: false
  },
  scrollbar: {
    enabled: false
  },
  rangeSelector: {
    buttons: [
      {
        type: "day",
        count: 14,
        text: "14d",
        title: "14-day chart"
      },
      {
        type: "day",
        count: 30,
        text: "30d",
        title: "30-day chart"
      },
      {
        type: "day",
        count: 90,
        text: "90d",
        title: "90-day chart"
      },
      {
        type: "all",
        text: "All",
        title: "All available data"
      }
    ]
  },
  colors
};
