/* eslint object-shorthand: 0 */

import renderChart from "charts/renderChart";
import {
  dateFormat,
  YAxisPlotLinesOptions,
  SeriesOptionsType,
  TooltipOptions,
  SeriesTooltipOptionsObject,
  XAxisOptions
} from "highcharts";
import { Controller } from "stimulus";
import { colorConfig, tickInterval, dateTimeLabelFormats } from "charts/config";
import { TimeScale } from "charts/types";

type Benchmark = {
  value: number;
  name: string;
};

type YAxis = {
  title?: string;
};

type CustomSeriesOptionsType = SeriesOptionsType & {
  tooltip: SeriesTooltipOptionsObject;
  lineWidth: number;
  data: [string, number][];
};

type ChartConfig = {
  title: string;
  benchmark?: Benchmark;
  height?: number;
  timeScale?: TimeScale;
  yAxis?: YAxis;
  xAxis?: object;
  series: CustomSeriesOptionsType[];
  markersOn?: boolean;
  useRangeSelector?: boolean;
  useRangeSelectorButtons?: boolean;
  useNavigator?: boolean;
  useScrollbar?: boolean;
  overrideTooltip?: TooltipOptions;
  percent?: boolean;
  xAxisLabelOverride?: string;
  maxPercentYAxisOverride?: number;
  tooltip?: {
    valuePrefix: string;
    valueSuffix: string;
  };
};

export default class extends Controller {
  static values = { config: Object };
  declare configValue: ChartConfig;

  connect() {
    const { configValue } = this;
    this.render(configValue);
  }

  render({
    title = "",
    benchmark,
    height,
    timeScale = "yearly",
    series,
    yAxis = {},
    markersOn = false,
    useRangeSelector = false,
    useRangeSelectorButtons = false,
    useNavigator = false,
    useScrollbar = false,
    overrideTooltip,
    percent = false,
    xAxisLabelOverride,
    maxPercentYAxisOverride,
    tooltip
  }: ChartConfig) {
    const { element } = this;
    const axisLineColor = "#CCCCCC";
    const plotLines = (): YAxisPlotLinesOptions[] => {
      if (!benchmark) return [];
      return [
        {
          value: benchmark.value,
          color: colorConfig.benchmark,
          dashStyle: "Dash",
          width: 1,
          label: {
            text: benchmark.name
          },
          zIndex: 1
        }
      ];
    };
    const optionalXAxisProperties: XAxisOptions = {};
    if (xAxisLabelOverride !== undefined && xAxisLabelOverride !== "") {
      optionalXAxisProperties["labels"] = {
        formatter: function () {
          return dateFormat(xAxisLabelOverride, this.value);
        }
      };
    }
    let _tooltip: TooltipOptions =
      overrideTooltip !== undefined
        ? {
            xDateFormat: overrideTooltip?.xDateFormat,
            pointFormat: overrideTooltip?.pointFormat,
            shared: overrideTooltip?.shared,
            backgroundColor: overrideTooltip?.backgroundColor
          }
        : {};
    if (tooltip) {
      _tooltip = {
        formatter: function () {
          return `${tooltip.valuePrefix}${this.y}${tooltip.valueSuffix}`;
        }
      };
    }
    renderChart({
      title: {
        text: title
      },
      chart: {
        renderTo: element as HTMLElement,
        backgroundColor: "transparent",
        height
      },
      yAxis: {
        title: { text: yAxis.title },
        plotLines: plotLines(),
        tickInterval: percent === true ? 5 : undefined,
        labels:
          percent === true
            ? {
                enabled: true,
                formatter: function () {
                  return `<span>${this.value}%</span>`;
                },
                step: 2,
                style: {
                  color: "black"
                }
              }
            : undefined,
        max:
          percent === true
            ? maxPercentYAxisOverride !== undefined
              ? maxPercentYAxisOverride
              : 100
            : undefined
      },
      xAxis: {
        lineColor: axisLineColor,
        tickColor: axisLineColor,
        minorTickColor: axisLineColor,
        type: "datetime",
        dateTimeLabelFormats,
        minTickInterval: tickInterval(timeScale),
        ...optionalXAxisProperties
      },
      rangeSelector: {
        enabled: useRangeSelector === true ? `true` : undefined,
        inputEnabled: true,
        inputDateFormat: "%b %e, %Y",
        buttons:
          useRangeSelectorButtons === true
            ? [
                { type: "month", count: 2, text: "2m" },
                { type: "month", count: 6, text: "6m" },
                { type: "month", count: 12, text: "12m" },
                { type: "all", text: "All" }
              ]
            : undefined,
        selected: 3,
        allButtonsEnabled: useRangeSelectorButtons === true
      },
      navigator: {
        enabled: useNavigator ?? false
      },
      scrollbar: {
        enabled: useScrollbar ?? false
      },
      plotOptions: {
        series: {
          label: { connectorAllowed: false },
          marker: { enabled: false }
        }
      },
      tooltip: _tooltip,
      series: series.map(
        ({ data, name, color, tooltip = {}, lineWidth = 2 }, i: number): SeriesOptionsType => {
          return {
            color: color || colorConfig.set[i],
            type: "spline",
            data: data.map(([d, v]) => [Date.parse(d), v]),
            name,
            tooltip,
            lineWidth,
            marker: {
              enabled: markersOn ?? false,
              radius: 5,
              symbol: "circle"
            }
          };
        }
      )
    });
  }
}
