import React, { CSSProperties } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  featuredContentWrapper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    fallbacks: {
      minHeight: "-webkit-fill-available",
      maxHeight: "-webkit-fill-available"
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    top: 0,
    marginLeft: "auto",
    flexBasis: "50%",
    fontSize: 30,
    zIndex: 0
  }
});

type SceneFeaturedContentProps = {
  style?: CSSProperties;
  className?: string;
};

const SceneFeaturedContent: React.FC<SceneFeaturedContentProps> = ({
  children,
  style = {},
  className = ""
}) => {
  const { featuredContentWrapper } = useStyles();
  const wrapperClass = [featuredContentWrapper, className];
  return (
    <div className={wrapperClass.join(" ")} style={style}>
      {children}
    </div>
  );
};

export default SceneFeaturedContent;
