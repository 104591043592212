import { createUseStyles } from "react-jss";
import breakpoints from "../../styles/breakpoints";
import { textStyle, baseFontStyles, baseFontStylesLarge } from "../styles/typography";

const defaultQuoteHeight = "75vh";
export const createQuoteWrapperStyles = createUseStyles({
  quotesFeatured: {
    justifyContent: "center"
  },
  quotesIntroWrapper: {
    maxHeight: "100vh",
    fallbacks: {
      maxHeight: "-webkit-fill-available"
    },
    display: "flex",
    flexDirection: "column"
  },
  quotesImageWrapper: {
    maxHeight: "30vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.up("md")]: {
      maxHeight: "40vh"
    }
  },
  quotesIntro: {
    ...textStyle,
    paddingTop: "2vh",
    paddingBottom: "2vh",
    minWidth: "auto",
    transition: ".15s ease-in-out",
    "& p": {
      ...baseFontStyles,
      textAlign: "center"
    },
    [breakpoints.up("md")]: {
      textAlign: "left",
      "& p": {
        textAlign: "left",
        ...baseFontStylesLarge
      }
    }
  },
  quotesWrapper: {
    minHeight: defaultQuoteHeight,
    fallbacks: {
      minHeight: "-webkit-fill-available"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  quotesImage: {
    maxHeight: "100%",
    flex: "1 1 100%",
    display: "flex !important",
    justifyContent: "center",
    "& img": {
      objectFit: "contain",
      maxHeight: "20vh",
      maxWidth: "80vw",
      [breakpoints.up("md")]: {
        maxHeight: "100%",
        maxWidth: "100%"
      }
    }
  },
  filler: {
    minHeight: "100vh",
    fallbacks: {
      minHeight: "-webkit-fill-available"
    }
  },
  shifted: {
    textAlign: "center",
    [breakpoints.up("md")]: {
      textAlign: "center",
      transform: "translate(50%)",
      padding: 0,
      "& p": {
        fontSize: 38,
        maxWidth: "85ch",
        lineHeight: "48px",
        textAlign: "center"
      }
    }
  },
  quotesContentWrapper: {
    "& > div": {
      height: defaultQuoteHeight
    }
  }
});

const quoteText = {
  margin: [0, 0, 10, 0],
  fontWeight: 600,
  textAlign: "center",
  padding: [0, "5vw"],
  "&, & p": {
    display: "inline !important",
    margin: 0,
    background: "none"
  }
};

export const createQuoteStyle = createUseStyles({
  quoteWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: [20, 0],
    background: "rgba(255,255,255,.9)",
    zIndex: 100
  },
  quoteCredit: {
    display: "inline",
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 300,
    margin: 0,
    color: "#646464",
    maxWidth: "60ch",
    padding: [0, "2.5vw"]
  },
  "quoteText-sm": {
    ...quoteText,
    "&, & p": {
      display: "inline",
      fontSize: 20,
      lineHeight: "30px"
    },
    "& sup": {
      fontSize: 18,
      lineHeight: "18px"
    },
    [breakpoints.up("md")]: {
      "&, & p": {
        fontSize: 24,
        lineHeight: "26px"
      }
    }
  },
  "quoteText-md": {
    ...quoteText,
    "&, & p": {
      display: "inline",
      fontSize: 32,
      lineHeight: "38px"
    },
    "& sup": {
      fontSize: 22,
      lineHeight: "22px"
    },
    [breakpoints.up("md")]: {
      "&, & p": {
        fontSize: 42,
        maxWidth: "35ch",
        lineHeight: "48px"
      }
    }
  },
  "quoteText-lg": {
    ...quoteText,
    "&, & p": {
      display: "inline",
      fontSize: 56,
      lineHeight: "62px"
    },
    "& sup": {
      fontSize: 40,
      lineHeight: "40px"
    },
    [breakpoints.up("md")]: {
      "&, & p": {
        fontSize: 96,
        maxWidth: "20ch",
        lineHeight: "108px"
      }
    }
  }
});
