import React from "react";
import StoryImage from "./StoryImage";
import { createUseStyles } from "react-jss";
import breakpoints from "../styles/breakpoints";
import { ImageType } from "./types";
import mhcLogo from "images/logos/mhc.svg";
import dhssLogo from "images/logos/dhss_clr_logo.png";

const useStyles = createUseStyles({
  titleHeader: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    padding: [10, 20],
    background: "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.65) 100%)",
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: 25,
    [breakpoints.up("md")]: {
      justifyContent: "space-between",
      padding: [10, 50]
    },
    "& img": {
      maxHeight: 40
    }
  },
  titleDhssWrapper: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none !important",
    background: "rgba(255,255,255,.8)",
    padding: [5, 10],
    borderRadius: 4,
    "&:hover, &:focus": {
      background: "white"
    }
  },
  titleMhcLogo: {
    // width: "80vw",
    marginBottom: 10,
    [breakpoints.up("md")]: {
      width: 400,
      marginBottom: 0
    }
  },
  titleWrapper: {
    position: "relative",
    height: "100vh",
    maxHeight: "100vh",
    fallbacks: {
      height: "-webkit-fill-available"
    },
    overflow: "hidden",
    background: "black",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "rgba(0,0,0,.4)",
      zIndex: 2
    },
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: 0,
      left: 0,
      height: "100%",
      width: "100%",
      zIndex: 2,
      background: "linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%)",
      [breakpoints.up("md")]: {
        background: "linear-gradient(14deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 41%)"
      }
    }
  },
  titleText: {
    position: "absolute",
    bottom: "15vh",
    left: "5vw",
    color: "white",
    zIndex: 3,
    "& h1": {
      fontSize: 46,
      lineHeight: "52px",
      fontWeight: 500,
      margin: [0, 0, 5, 0],
      [breakpoints.up("md")]: {
        fontSize: 100,
        lineHeight: "110px",
        margin: [0, 0, 15, 0]
      }
    },
    "& h2": {
      fontSize: 24,
      lineHeight: "34px",
      fontWeight: 300,
      margin: 0,
      [breakpoints.up("md")]: {
        fontSize: 48,
        lineHeight: "54px"
      }
    }
  },
  titleImage: {
    height: "100%",
    width: "100%",
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "100%"
    }
  }
});

type StoryTitleProps = {
  image: ImageType;
  title: string;
  subTitle?: string;
};

const StoryTitle = ({ image, title, subTitle = "" }: StoryTitleProps) => {
  const classes = useStyles();
  return (
    <div className={classes.titleWrapper}>
      <header className={classes.titleHeader}>
        <a href="https://myhealthycommunity.dhss.delaware.gov" target="_blank" rel="noreferrer">
          <img
            className={classes.titleMhcLogo}
            src={mhcLogo}
            alt="My Healthy Community — Delaware Environmental Public Health Tracking Network"
          />
        </a>
        <a
          href="https://dhss.delaware.gov/dhss/"
          target="_blank"
          rel="noreferrer"
          className={classes.titleDhssWrapper}
        >
          <img src={dhssLogo} alt="DHSS - Delaware Health and Social Services" />
        </a>
      </header>
      <StoryImage image={image} className={classes.titleImage} />
      <div className={classes.titleText}>
        <h1>{title}</h1>
        {subTitle && <h2>{subTitle}</h2>}
      </div>
    </div>
  );
};

export default StoryTitle;
