import React, { Component } from "react";
import classnames from "classnames";
import Video from "./Video";
import MapGradientLegend from "../maps/MapGradientLegend";
import DummyAnimation from "./DummyAnimation";
import Tooltip from "../Tooltip";
import ReactSlider from "react-slider";
import { convertPlayTime } from "../utils/time";
import moment from "moment";
import LoadingAnimation from "../LoadingAnimation";
import { browserIsMobileSafari, browserIs } from "../utils/browser";

const getDateRange = (startDateStr, endDateStr, granularity) => {
  if (granularity !== "year" && granularity !== "day") {
    throw new Error(`unknown granularity '${granularity}'`);
  }
  const currentDate = moment(startDateStr).startOf(granularity);
  const endDate = moment(endDateStr).startOf(granularity);
  const momentUnit = granularity == "year" ? "years" : "days";

  const maxButtons = 10;
  const estimatedIncrement = Math.floor(endDate.diff(currentDate, momentUnit) / maxButtons);

  // Getting a nice range for each scrubber button
  const increment = (function () {
    if (estimatedIncrement <= 1) {
      return 1;
    } else if (granularity == "year" && estimatedIncrement <= 365) {
      return 1;
    } else if (granularity == "year" && estimatedIncrement > 365) {
      return 2;
    } else if (granularity == "day" && estimatedIncrement <= 7) {
      return 7;
    } else if (granularity == "day" && estimatedIncrement <= 14) {
      return 14;
    } else if (granularity == "day" && estimatedIncrement > 14) {
      return 30;
    } else {
      throw new Error("cannot figure out size of each date range");
    }
  })();

  const values = [];
  let i = 0;
  while (true) {
    i += 1;
    if (i > 20) {
      throw new Error(`too many values ${startDateStr} ${endDateStr} ${granularity}`);
    }
    if (currentDate.isSameOrBefore(endDateStr)) {
      values.push(currentDate.toDate());
    } else {
      break;
    }
    currentDate.add(increment, granularity); // mutates currentDate
  }
  return values;
};

export default class Animation extends Component {
  constructor(props) {
    super(props);
    this.options = Object.assign({}, this.props.options);

    const { start_date, end_date, granularity } = this.props;
    this.dates = getDateRange(start_date, end_date, granularity);

    const loadAsVanillaVideo = browserIsMobileSafari() || browserIs("safari");
    this.state = {
      played: false,
      srcLoaded: loadAsVanillaVideo,
      loading: !loadAsVanillaVideo,
      loadFailure: false,
      playing: false,
      time: 0,
      duration: 0,
      playbackRate: granularity === "day" ? 2 : 1,
      loadAsVanillaVideo
    };
  }

  componentDidMount() {
    const video = this.video;
    const onVideoLoad = () => {
      if (video.readyState >= 2) {
        const duration = video.duration;
        this.setState({
          srcLoaded: true,
          loading: false,
          duration,
          frameDuration: duration / this.dates.length
        });
        if (!this.props.playable) {
          this.handleDateChange(this.dates[this.dates.length - 1], null);
        }
      }
    };
    video.addEventListener("timeupdate", () => this.setState({ loading: false }));
    video.addEventListener("loadedmetadata", onVideoLoad);
    video.addEventListener("error", () => {
      this.setState({
        srcLoaded: true,
        loadFailure: true,
        loading: false
      });
    });
    video.addEventListener("ended", () => this.setState({ playing: false }));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.loading !== nextState.loading ||
      this.state.time !== nextState.time ||
      this.state.playbackRate !== nextState.playbackRate ||
      this.state.playing !== nextState.playing
    );
  }

  componentDidUpdate(prevProps) {
    const newUrl = this.props.url || this.props.file_url;
    const oldUrl = prevProps.url || prevProps.file_url;
    if (newUrl !== oldUrl) {
      this.video.load();
    }
  }

  handleTime() {
    this.setState({ time: this.video.currentTime });
  }

  updateCurrentTime(time) {
    this.video.currentTime = time;
  }

  handleDateChange(date, event) {
    this.setState({ loading: true });
    if (event) {
      event.preventDefault();
      event.target.blur();
    }
    let { min } = this.getRangeOfFrame(this.dates.indexOf(date));
    min += this.state.frameDuration / 2;
    this.updateCurrentTime(min);
  }

  handlePlay(play = null, event) {
    const _play = play || !this.state.playing;
    if (!this.props.playable) return false;
    if (event) {
      event.preventDefault();
      event.target.blur();
    }
    if (_play && this.video.paused) {
      this.video.play();
      if (typeof ga !== "undefined") {
        // Google Analytics video play event indicator
        ga("send", "event", {
          eventCategory: "Maps and Charts",
          eventAction: "Play",
          eventLabel: "Map Timeline"
        });
      }
    } else if (!this.video.paused) {
      this.video.pause();
    }
    this.setState({ playing: _play, played: true });
  }

  getRangeOfFrame(index) {
    const { frameDuration } = this.state;
    const min = index * frameDuration;
    return {
      min,
      max: min + frameDuration
    };
  }

  dateIsActive(index) {
    const { min, max } = this.getRangeOfFrame(index);
    const time = this.calculateCurrentTime();
    return time >= min && time <= max;
  }

  dateSelectors() {
    const { granularity } = this.props;
    const label = granularity === "year" ? (d) => d.getFullYear() : (d) => moment(d).format("M/D");
    return this.dates.map((d, i) => {
      const classList = classnames("c-animation-controls__date", { active: this.dateIsActive(i) });
      return (
        <a
          key={`date-${i}`}
          href="#"
          onClick={this.handleDateChange.bind(this, d)}
          index={i}
          className={classList}
        >
          <span>{label(d)}</span>
        </a>
      );
    });
  }

  handleScrub(updateVideo = true, value) {
    this.handlePlay(false, null);
    this.setState({ loading: true });
    if (updateVideo) this.updateCurrentTime(value);
    else this.setState({ time: value });
  }

  handlePlayRateChange(rate, event) {
    event.preventDefault();
    this.video.playbackRate = rate;
    this.setState({
      playbackRate: rate,
      displayDuration: this.video.duration / rate
    });
    event.target.blur();
  }

  setVideo(el) {
    this.video = el;
  }

  calculateCurrentTime() {
    return this.state.time;
  }

  rateControl() {
    const rates = [1, 2, 4];
    return (
      <div className="d-flex">
        {rates.map((r, i) => {
          const classes = classnames("c-animation-controls__rate", {
            active: this.state.playbackRate === r
          });
          return (
            <a
              key={`rate-${i}`}
              href="#"
              onClick={this.handlePlayRateChange.bind(this, r)}
              className={classes}
            >
              {r}x
            </a>
          );
        })}
      </div>
    );
  }

  getCurrentPlaybackTime() {
    const { time, playbackRate } = this.state;
    return convertPlayTime((time / playbackRate).toFixed(2));
  }

  getTotalPlaybackTime() {
    const { displayDuration, duration } = this.state;
    return convertPlayTime((displayDuration || duration).toFixed(2));
  }

  render() {
    const {
      title,
      tooltip,
      legend,
      showFrameRateControls = false,
      playable,
      single_frame_url
    } = this.props;
    const {
      loadAsVanillaVideo,
      playing,
      played,
      time,
      duration,
      loading,
      srcLoaded,
      loadFailure
    } = this.state;
    const playButtonClasses = classnames("fa", { "fa-pause": playing }, { "fa-play": !playing });
    return (
      <div>
        <h3 className="o-map__title">
          {title}
          {tooltip ? <Tooltip title={tooltip} /> : null}
        </h3>
        {legend ? <MapGradientLegend {...legend} /> : null}

        <div className="o-map__video-wrapper">
          {(loading || (!srcLoaded && !loadAsVanillaVideo)) && (
            <LoadingAnimation veil={true} withBG={false} />
          )}
          {loadFailure && !loading ? (
            <DummyAnimation />
          ) : (
            <Video
              data={this.props}
              playable={playable}
              played={played}
              showControls={loadAsVanillaVideo}
              handlePlay={this.handlePlay.bind(this, true)}
              handleTime={this.handleTime.bind(this)}
              setVideo={this.setVideo.bind(this)}
              posterImage={single_frame_url}
            />
          )}
        </div>
        {!loadFailure && srcLoaded && playable && !loadAsVanillaVideo ? (
          <div className="c-animation-controls">
            <div className="c-animation-controls__primary">
              <div className="c-animation-controls__playback">
                <div className="c-animation-controls__playback-buttons">
                  <a
                    href="#"
                    className="c-animation-controls__playback-button"
                    onClick={this.handlePlay.bind(this, null)}
                  >
                    <i className={playButtonClasses} />
                  </a>
                </div>
              </div>
              <div className="c-animation-controls__slider">
                <div className="d-flex justify-content-around">{this.dateSelectors()}</div>
                <ReactSlider
                  step={0.1}
                  min={0}
                  value={time}
                  max={duration}
                  onAfterChange={(value) => this.handleScrub(true, value)}
                  className="c-range-slider"
                  thumbClassName="c-range-slider__handle"
                  trackClassName="c-range-slider__track"
                  // classesPrefix="c-range-slider__"
                />
              </div>
            </div>
            {showFrameRateControls ? (
              <div className="c-animation-controls__secondary">
                <span className="c-animation-controls__time">
                  {this.getCurrentPlaybackTime()} / {this.getTotalPlaybackTime()}
                </span>
                <div className="ml-auto d-flex">
                  <span className="c-video-controls__rate-title">Playback speed:</span>
                  {this.rateControl()}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
