import { Controller } from "stimulus";
import { getContextController, gaEvent } from "./config";
import { TriggerController } from "../types";
export default class extends Controller implements TriggerController {
  toggle(event: MouseEvent) {
    event.preventDefault();
    this.contextController?.toggle();

    // KLUDGE there is probably a cleaner way to activate the topic tab
    const htmlElement = this.element as HTMLElement;
    let type = "location";
    if (htmlElement.dataset.activateTopic && htmlElement.dataset.activateTopic == "true") {
      type = "topic";
    }
    const tabElement: HTMLElement | null = document.querySelector(
      `[data-context-switcher--layout-target*="${type}Tab"]`
    );
    tabElement?.click();

    gaEvent({
      action: `Trigger`,
      label: this.data.get("location")
    });
  }

  get contextController() {
    return getContextController(this.application);
  }
}
