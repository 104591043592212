import React from "react";
import Conclusion from "./Conclusion";
import Credits from "./Credits";
import EarlyCaseRates from "./EarlyCaseRates";
import EarlyCaseRatesStatic from "./EarlyCaseRatesStatic";
import EarlyMonthsMap from "./EarlyMonthsMap";
import HispanicPopulationMap from "./HispanicPopulationMap";
import Introduction from "./Introduction";
import Poultry from "./Poultry";
import Quotes from "./Quotes";
import Story from "../Story";
import StoryTitle from "../StoryTitle";
import Triptych from "./Triptych";
import VaccinationRateChange from "./VaccinationRateChange";
import { imageDictionary } from "./config";

const VaccinationStory = () => {
  return (
    <Story>
      <StoryTitle
        image={imageDictionary.title}
        title="Flipping the Stats"
        subTitle="A COVID-19 success story"
      />
      <Introduction />
      <Triptych />
      <EarlyCaseRates />
      <Poultry />
      <HispanicPopulationMap />
      <Quotes />
      <EarlyMonthsMap />
      <EarlyCaseRatesStatic />
      <VaccinationRateChange />
      <Conclusion />
      <Credits />
    </Story>
  );
};

export default VaccinationStory;
