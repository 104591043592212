import React, { Component } from "react";
import MapPointLegend from "../maps/MapPointLegend";
import { renderBaseMap, addPointsToMap } from "../../controllers/maps/utils";
import { uniqeId } from "../utils";
import { geoJSON } from "leaflet";
import { fetch } from "whatwg-fetch";
import Tooltip from "../Tooltip";

export default class LeafletMap extends Component {
  componentDidMount() {
    this.fetchPointData();
  }

  render() {
    const { title, tooltip, legend } = this.props;
    return (
      <div className="c-map">
        <h3 className="c-map__title">
          {title}
          {tooltip ? <Tooltip title={tooltip} /> : null}
        </h3>
        <div
          id={uniqeId()}
          ref={(el) => (this.mapEl = el)}
          className="c-map__container c-map__container--constrained"
          aria-label={title}
        />
        {legend && legend.items ? <MapPointLegend {...legend} /> : null}
      </div>
    );
  }

  renderMap({ pointJson, token, location }) {
    this.map = renderBaseMap(this.mapEl, token, {
      scrollWheelZoom: false
    });

    if (location && location.geoJson) {
      this.locationLayer = geoJSON(location.geoJson, {
        interactive: false,
        style: {
          weight: 1,
          opacity: 0.75,
          color: "#C24644",
          dashArray: "",
          fillColor: "#147BB1",
          fillOpacity: 0.25
        }
      });
      this.locationLayer.addTo(this.map);
      this.map.fitBounds(this.locationLayer.getBounds());
    }

    this.markers = addPointsToMap(pointJson, this.map);
  }

  fetchPointData() {
    const { points, slug } = this.props;
    fetch(`/locations/${slug}/point_map/${points.join("+")}`)
      .then((response) => response.json())
      .then((data) => {
        this.renderMap(data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line no-console
        console.error(`Point files for ${slug} failed to load.`);
      });
  }
}
