import ChoroplethController from "./choropleth-controller";
import { renderLoader } from "../../charts/utils";
import { LegendController, ChoroplethConfig } from "./types";

export default class extends ChoroplethController {
  handleLoad(payload: ChoroplethConfig) {
    payload.callbacks = {
      mouseover: this.legendController?.showIndicator.bind(
        this.legendController
      ),
      mouseout: this.legendController?.clearIndicator.bind(
        this.legendController
      ),
    };
    const { props, geoJson } = payload;
    if (geoJson && geoJson.polygons.features.length) {
      const currentLocation = geoJson.polygons.features.find(
        (feature) => props.locationSlug === feature?.properties?.locationSlug
      );
      if (currentLocation) {
        this.legendController?.showIndicator(
          { feature: currentLocation },
          true
        );
      }
    }
    this.buildMap(payload);
  }

  get legendController() {
    const element = document.getElementById("choropleth-legend");
    if (element) {
      return this.application.getControllerForElementAndIdentifier(
        element,
        "maps--svi-legend"
      ) as LegendController;
    }
  }
}
