import React, { Component } from "react";
import LineChart from "../charts/line";
import CardInfoWarning from "./CardInfoWarning";
import { getLevelRefRanges } from "../utils/pollutants";
import { getDatesInRange } from "../utils/date";
import { uniqeId } from "../utils/index";
import moment from "moment";
import { info } from "../symbols";
import uniq from "lodash/uniq";

export default class TimeSeriesChart extends Component {
  constructor(props) {
    super(props);
    this.wrapperEl = null;
    this.containerEl = null;
    this.title = props.primary ? props.primary.name : "";
    this.id = uniqeId();
  }

  componentDidMount() {
    const props = this.getProps();
    this.chart = new LineChart(
      props,
      [this.wrapperEl, this.containerEl],
      Object.assign(this.props.data.options || {}, props.options || {})
    );
    this.chart._init();
  }

  shouldComponentUpdate(nextProps) {
    const {
      comparison,
      primary,
      data,
      dates,
      investigationIdentifier,
      showConfidence
    } = this.props;

    // TODO: Rethink this...I think there are too many conditions.
    //       Some may be repetitive
    const oldComparison = comparison;
    const newComparison = nextProps.comparison;
    const oldPrimary = primary || {};
    const newPrimary = nextProps.primary || {};

    const currentSets = data.sets || [];
    const nextSets = nextProps.data.sets || [];
    const identifierChanged = oldPrimary.identifier !== newPrimary.identifier;
    const investigationChanged = investigationIdentifier !== nextProps.investigationIdentifier;
    const setChanged =
      currentSets[0] && nextSets[0] && currentSets[0].identifier !== nextSets[0].identifier;
    const showConfidenceChanged = showConfidence !== nextProps.showConfidence;

    if (identifierChanged || setChanged) {
      return true;
    } else if (dates[0] !== nextProps.dates[0] || dates[1] !== nextProps.dates[1]) {
      return true;
    } else if (investigationChanged) {
      return true;
    } else if (showConfidenceChanged) {
      return true;
    } else if (
      oldComparison &&
      newComparison &&
      oldComparison.identifier === newComparison.identifier
    ) {
      return false;
    } else if (!newComparison) {
      return true;
    }
  }

  componentDidUpdate() {
    this.chart && this.chart.update(this.getProps());
  }

  getChartData() {
    const { dates } = this.props;
    const chartProps = JSON.parse(JSON.stringify(this.props));
    const chartData = chartProps.data;
    const currentPrimary = chartProps.primary;
    const sets = { primary: [], geographicComparison: [], comparison: [] };
    const datesToShow = getDatesInRange(chartData.granularity || "month", dates);
    let _comparison = this.props.comparison || null;
    const getSetData = (set, identifierPrefix = "") => {
      if (!set) return null;

      return {
        name: set.name,
        nameAddendum: set.title_addendum,
        grouping: set.grouping,
        annualized: set.annualized,
        identifier: identifierPrefix + set.identifier,
        maxValue: set.mcl,
        unit: set.unit,
        slug: set.slug,
        location: set.resolved_location || set.location || "",
        levelRefRanges: getLevelRefRanges(set.identifier),
        subset: set.subset,
        relatedData: set.related_data,
        granularity: set.granularity,
        confidence: set.confidence_info,
        data: datesToShow.map((d) => {
          let value = null;
          const formatedDate = moment(d).format("YYYY-MM-DD");
          // Sets with only one data point - repeat that point for all dates
          if (Object.values(set.data).length === 1) {
            value = Object.values(set.data)[0];
          } else {
            value = set.data[formatedDate];
          }
          return { date: formatedDate, value: value };
        })
      };
    };

    const getSelectedSet = (
      setName,
      newSetName = null,
      identifierToMatch,
      prefixIdentifier = false
    ) => {
      let identifierPrefix = "";
      const selectedSets = chartData.sets[setName].filter((set) => {
        return set.identifier === identifierToMatch;
      });

      if (!selectedSets.length) {
        sets[newSetName || setName] = [];
        return;
      }

      // build a prefix string based on location so the identifier given to charting library
      // is unique
      if (prefixIdentifier) {
        identifierPrefix = selectedSets[0].location.replace(/\s|\-/g, "_").toUpperCase() + "_";
      }
      sets[newSetName || setName] = selectedSets.map((s) => getSetData(s, identifierPrefix));
    };

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // If sets is an array (simple chart with multiple sets rendered at once)
    // pass sets
    const { sets: setsData } = chartData;
    if (setsData && setsData.length) {
      chartData.sets = setsData.map((set) => {
        return getSetData(set);
      });
      return { data: chartData, dates: datesToShow, stratification: true };
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // If current primary has multiple sets
    const { sets: primarySets = [] } = currentPrimary || {};
    if (primarySets && primarySets.length) {
      let _comparisonSet = null;
      chartData.sets = currentPrimary.sets.map((set) => {
        return getSetData(set);
      });
      // Add comparison to sets if exists
      if (_comparison && _comparison.data) {
        _comparisonSet = getSetData(_comparison);
        chartData.sets.push(_comparisonSet);
      }
      return {
        data: chartData,
        dates: datesToShow,
        stratification: true,
        comparison: _comparisonSet
      };
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Select current primary set or create array with primary sets if
    //  chartData.primarySetSelection is false
    const { sets: dataSets = {} } = chartData;
    if (dataSets.primary && dataSets.primary.length) {
      getSelectedSet("primary", null, this.props.primary.identifier);
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // If comparison is active only show first primary set
    if (_comparison && _comparison.data) {
      sets.primary = [sets.primary[0]];
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Select current comaprison
    if (_comparison && _comparison.data) {
      if (_comparison.data.length) sets.comparison = _comparison.data.map((s) => getSetData(s));
      else sets.comparison = [getSetData(_comparison)];
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Select geographic comparison set if exists based on the selected primary
    // identifier and if there are no active metric comparisons
    const geographicComparisonSet = dataSets.geographic_comparison || [];

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Determine if the resolved location matches the location of the primary set
    // If it does, do not build the geographic comparison data set
    const primarySet = sets.primary[0];
    const resolvedLocationMatchesBase =
      this.props.geographicComparison &&
      primarySet &&
      primarySet.location === this.props.geographicComparison.location;

    if (!sets.comparison.length && geographicComparisonSet.length && !resolvedLocationMatchesBase) {
      getSelectedSet(
        "geographic_comparison",
        "geographicComparison",
        this.props.primary.identifier,
        true
      );
    }

    // Add filtered sets to chartData obj
    chartData.sets = [...sets.primary, ...sets.geographicComparison, ...sets.comparison];
    return {
      data: chartData,
      primary: this.props.primary,
      comparison: _comparison,
      dates: datesToShow
    };
  }

  getProps() {
    const { dates, primary = {}, comparison = {}, data, showConfidence } = this.props;
    const chartData = this.getChartData();
    const title = this.getTitle();
    const { sets = [] } = data;
    const primarySet = primary.name ? primary : (sets.length && sets[0]) || {};
    const unit = primarySet.unit || "";
    const yAxisLabel =
      (primarySet.y_axis_label || primarySet.name) +
      (unit ? ` ${unit}` : "") +
      (data.yAxisLabel ? " " + data.yAxisLabel : "");
    const props = {
      title: title,
      titleAddendum: this.getAddendum(),
      data: chartData.data,
      dates: chartData.dates,
      dateRange: getDatesInRange(data.granularity, dates, true),
      dateFormat: data.granularity === "month" ? "month_year" : "year",
      maxLevel: data.maxValue,
      comparison: chartData.comparison,
      comparisonType: comparison && comparison.data ? "stat" : "location",
      identifier: primarySet.identifier,
      showConfidence: showConfidence,
      options: {
        rotate: data.granularity === "month" ? 35 : 0,
        granularity: data.granularity,
        connectNull: chartData.granularity === "year",
        stratification: chartData.stratification !== undefined ? chartData.stratification : false,
        data_unit: chartData.primary && chartData.primary.rate_is_a_percent ? "percent" : "num"
      },
      labels: {
        x: "Date",
        y: yAxisLabel,
        title: title
      }
    };
    return props;
  }

  getTitle() {
    // Build chart title based on dates and comparison
    const { dates, primary = {}, comparison = {}, geographicComparison, data } = this.props;
    const { sets = [] } = data;
    const set = primary.name ? primary : (sets.lenght && sets[0]) || {};
    const { name, title_addendum, location } = set;
    const resolved_location = this.getResolvedLocationOfSets(set);
    const _location = resolved_location || location;

    let _title = name || "";
    if (title_addendum) {
      _title += "*";
    }
    if (location) {
      _title += ` in ${_location}`;
    }
    if (comparison && comparison.data) {
      _title += ` compared with ${comparison.name}${comparison.title_addendum ? "*" : ""}`;
    } else if (geographicComparison && data.geographicComparison) {
      const resolvedLocation =
        geographicComparison.resolved_location || geographicComparison.location;
      const resolvedLocationIsDifferent =
        data.geographicComparison.name && resolvedLocation !== data.geographicComparison.name;
      const comparisonLocation = resolvedLocationIsDifferent
        ? resolvedLocation
        : data.geographicComparison.name;
      const comparisonIsSameAsPrimary = comparisonLocation === _location;
      const { data: geographicComparisonData = {} } = geographicComparison;
      const geoCompareHasData = Object.getOwnPropertyNames(geographicComparisonData).length > 0;
      if (comparisonLocation && !comparisonIsSameAsPrimary && geoCompareHasData) {
        _title += ` compared with ${comparisonLocation}`;
        if (resolvedLocationIsDifferent) {
          _title += `
            <span data-bs-toggle='tooltip' data-bs-html='true' title='Comparison statistics shown are calculated from data for <b> ${comparisonLocation} </b> because there are insufficient data for <b> ${data.geographicComparison.name.capitalize()}</b>'>${info(
            { h: 12, w: 12 }
          )}</span>
          `;
        }
      }
    }
    if (dates) {
      // const format = props.dateFormat === 'month_year' ? 'MMM YYYY' : 'YYYY'
      _title += ` (${dates[0]} - ${dates[1]})`;
    }
    return _title;
  }

  getAddendum() {
    const { primary = {}, comparison = {}, data = {} } = this.props;
    const { sets = [] } = data;
    const primarySet = primary.name ? primary : (sets.lenght && sets[0]) || {};
    const addendums = [];
    if (data.titleAddendum) {
      addendums.push(<span key="addendum-1"> {"*" + data.titleAddendum} </span>);
    }

    if (primarySet.title_addendum) {
      addendums.push(<span key="addendum-2"> {"*" + primarySet.title_addendum} </span>);
    }

    if (comparison && (comparison || comparison[0]).title_addendum) {
      addendums.push(
        <span key="addendum-3"> {"*" + (comparison || comparison[0]).title_addendum} </span>
      );
    }

    return addendums.length ? addendums : false;
  }

  getResolvedLocationOfSets(set) {
    if (set.sets && set.sets.length) {
      const resolvedLocations = uniq(set.sets.map((s) => s.resolved_location));
      return resolvedLocations.length ? resolvedLocations[0] : "";
    } else {
      return set.resolved_location;
    }
  }

  render() {
    const { primary = {}, investigationIdentifier } = this.props || {};
    const { location, note="" } = primary;
    // Handle case where primary has multiple sets and potentially different
    // resolved locations
    const resolved_location = this.getResolvedLocationOfSets(primary);
    return (
      <div className="c-chart d-flex flex-column" style={{gap: "24px"}} ref={(el) => (this.wrapperEl = el)} id={this.id}>
        {resolved_location && location !== resolved_location && (
          <CardInfoWarning
            wrapperClassNames="mb-4 border-start-0 border-end-0"
            message={`
              Data in chart is for
              <strong> ${resolved_location} </strong>
              because there is insufficient data for <b> ${location}</b>`}
          />
        )}
        <div className="c-chart__header">
          <div className="c-chart__title-wrapper">
            <h3 className="c-chart__title" dangerouslySetInnerHTML={{ __html: this.getTitle() }} />
            <span className="c-chart__title-addendum" id="label-chart-addendum">
              {" "}
              {this.getAddendum()}{" "}
            </span>
          </div>
        </div>
        <div
          className="c-chart__container"
          aria-labelledby="label-chart-title"
          aria-label="Charts are not yet screen reader friendly."
          ref={(el) => (this.containerEl = el)}
        />
        <div className="d-flex flex-column px-4" style={{gap: "10px"}}>
          {note && <div className="c-note c-note--info mb-0">{note}</div>}
          <div className="c-note c-note--info mb-0">
            Counts of less than 11 are suppressed. If the population is sufficiently large, 0 counts
            may be shown. Rates based on counts less than 20 may be unstable.
          </div>
          {investigationIdentifier && investigationIdentifier.match(/racial|race/) && (
            <div className="c-note c-note--info mb-0">
              Race and ethnicity categories are not mutually exclusive so counts will not add up to
              100%. Counts of less than 11 are suppressed.
            </div>
          )}
        </div>
      </div>
    );
  }
}
