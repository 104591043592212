import React, { Fragment } from "react";
import { Credit } from "../types";
import InlineCredit from "../InlineCredit";

const dyalSource = {
  text: (
    <>
      Dyal JW, Grant MP, Broadwater K, et al.{" "}
      <a href="http://dx.doi.org/10.15585/mmwr.mm6918e3" target="_blank" rel="noreferrer">
        &quot;COVID-19 Among Workers in Meat and Poultry Processing Facilities ― 19 States, April
        2020.&quot;
      </a>{" "}
      MMWR Morb Mortal Wkly Rep 2020;69:557–561.
    </>
  ),
  textShort: (
    <>
      Dyal JW, et al., <em>CDC Morbidity and Mortality Weekly Report</em>, May 8, 2020
    </>
  ),
  type: "footnote"
};

const rosaSource = {
  text: "Rosa Rivera, COO, La Red Health Center, Georgetown, Del., 15 September 2021",
  textShort: "Rosa Rivera, COO, La Red Health Center, Georgetown, Del.",
  type: "footnote"
};

const citations: Credit[] = [
  {
    id: "story-citation",
    text: (
      <>
        Delaware Department of Health and Social Services, Division of Public Health,{" "}
        <em>My Healthy Community</em>, &quot;Flipping the Stats: A COVID-19 success story,&quot;{" "}
        <a
          href="https://myhealthycommunity.dhss.delaware.gov/stories/vaccination"
          target="_blank"
          rel="noreferrer"
        >
          https://myhealthycommunity.dhss.delaware.gov/stories/vaccination
        </a>
        .
      </>
    ),
    type: "citation"
  }
];

const footnotes: Credit[] = [
  {
    id: "poultry-figures",
    text: (
      <>
        Figure sources: U.S. Census Bureau,{" "}
        <a
          href="https://www.census.gov/programs-surveys/acs/data.html"
          target="_blank"
          rel="noreferrer"
        >
          ACS 5-Year Data Profiles, 2019;
        </a>{" "}
        Sternberg, S.,{" "}
        <a
          href="https://www.census.gov/programs-surveys/acs/data.html"
          target="_blank"
          rel="noreferrer"
        >
          &quot;Chicken Plants – and the Food Supply – Take Center Stage in Delaware&apos;s COVID-19
          Fight,&quot;
        </a>{" "}
        U.S. News & World Report, May 5, 2020; U.S. Poultry & Egg Association,{" "}
        <a
          href="https://www.dcachicken.com/facts/docs/DE%201%20Congress%20Impact%20Report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          &quot;The Chicken Industry Creates Jobs in Delaware,&quot; 2020
        </a>
      </>
    ),
    type: "footnote"
  },
  {
    id: "waltenburg",
    text: (
      <>
        Waltenburg MA, et al., <em>CDC Morbidity and Mortality Weekly Report</em>, July 10, 2020
      </>
    ),
    textShort: (
      <>
        Waltenburg MA, et al., <em>CDC Morbidity and Mortality Weekly Report</em>, July 10, 2020
      </>
    ),
    originalCopy:
      "Among [U.S. facility] cases with race/ethnicity reported, 87% occurred among racial or ethnic minorities",
    source: "http://dx.doi.org/10.15585/mmwr.mm6927e2",
    type: "footnote"
  },
  {
    ...dyalSource,
    id: "dyal-1",
    originalCopy: "...socioeconomic challenges might contribute to working while feeling ill..."
  },
  {
    ...dyalSource,
    id: "dyal-2",
    originalCopy:
      "...difficult to maintain a 6-foot distance while working, especially on production lines..."
  },
  {
    ...rosaSource,
    id: "rosa-working",
    originalCopy: "Not going to work was not an option."
  },
  {
    id: "lisa-henry",
    text: "Henry, L. and Saperstein, L. Personal interview. 3 September 2021.",
    textShort:
      "Lisa Henry, Director of Community Health Services, Delaware Department of Health and Social Services, Delaware Division of Public Health",
    originalCopy: "The Latino community banded together.",
    type: "footnote"
  },
  {
    id: "hall-long",
    text: (
      <>
        &quot;Wilmington&apos;s Latin Community Vaccinated at Family Fun Day,&quot; Delaware
        Newsline, June 13, 2021 (
        <a
          href="https://www.delawarenewsline.com/2021/06/13/wilmingtons-latin-community-vaccinated-at-family-fun-day/"
          target="_blank"
          rel="noreferrer"
        >
          source
        </a>
        )
      </>
    ),
    textShort: "Bethany Hall-Long, Lieutenant Governor and registered nurse",
    originalCopy:
      "Working with community partners is critical to distributing the vaccine as far and wide and equitably as we can",
    type: "footnote"
  },
  {
    ...rosaSource,
    id: "rosa-people",
    originalCopy: "You have to meet people where they are."
  }
];

export const sources: Credit[] = [
  {
    id: "alonzo",
    text: (
      <>
        Alonzo, A.,{" "}
        <a
          href="https://www.wattagnet.com/blogs/47-us-poultry-industry-insights/post/43689-poultry-industry-faces-likely-federal-vaccine-mandate"
          target="_blank"
          rel="noreferrer"
        >
          Poultry industry faces likely federal vaccine mandate (WATTPoultry.com)
        </a>
        , October 1, 2021
      </>
    ),
    source:
      "https://www.wattagnet.com/blogs/47-us-poultry-industry-insights/post/43689-poultry-industry-faces-likely-federal-vaccine-mandate",
    type: "source"
  },
  {
    id: "ddhss",
    text: (
      <a href="https://myhealthycommunity.dhss.delaware.gov" target="_blank" rel="noreferrer">
        Delaware Department of Health and Social Services
      </a>
    ),
    source: "https://myhealthycommunity.dhss.delaware.gov/",
    type: "source"
  },
  {
    id: "ddph-covid-response",
    text: "Delaware Division of Public Health, Coronavirus Reponse, Tools for Community Groups",
    source: "https://coronavirus.delaware.gov/vaccine/tools-for-community-groups/",
    type: "source"
  },
  {
    id: "aspira-academy",
    text:
      "Las Américas ASPIRA Academy, COVID-19 Information, Questions & Answers (Q&A) from the Lt. Governor Bethany Hall-Long Latino Leaders Meetings",
    source:
      "https://www.aspiraacademy.org/apps/pages/index.jsp?uREC_ID=1523761&type=d&pREC_ID=2052384",
    type: "source"
  },
  {
    id: "lytle",
    text:
      'Lytle, E., "Efforts to vaccinate Delaware\'s poultry workers, hit hard by COVID-19, stalled by limited supply," Delaware News Journal, February 8, 2021',
    source:
      "https://www.delawareonline.com/story/news/local/2021/02/08/efforts-vaccinate-delaware-poultry-workers-stalled-lean-supply/6672741002/",
    type: "source"
  },
  {
    id: "gov-office",
    text:
      'Office of the Governor, "Governor Carney Announces Updates to COVID-19 Vaccination Program," March 5, 2021 [Press Release]',
    source:
      "https://news.delaware.gov/2021/03/05/governor-carney-announces-updates-to-covid-19-vaccination-program/",
    type: "source"
  },
  {
    id: "owens",
    text:
      'Owens, J., "Despite FDA approval, few Del. employers mandate vaccine," Delaware Business Times, September 1, 2021',
    source: "https://delawarebusinesstimes.com/news/vaccine-mandate-astrazeneca/",
    type: "source"
  },
  {
    id: "rolfe",
    text:
      'Rolfe, G., "Poultry companies set for COVID vaccines when supply is available," Bay to Bay News, February 12, 2021',
    source:
      "https://baytobaynews.com/stories/poultry-companies-set-for-covid-vaccines-when-supply-is-available,40451",
    type: "source"
  },
  {
    id: "sternberg",
    text: (
      <>
        Sternberg, S.,{" "}
        <a
          href="https://www.usnews.com/news/healthiest-communities/articles/2020-05-05/chicken-plants-take-center-stage-in-delaware-coronavirus-fight"
          target="_blank"
          rel="noreferrer"
        >
          &quot;Chicken Plants – and the Food Supply – Take Center Stage in Delaware&apos;s COVID-19
          Fight,&quot;
        </a>{" "}
        U.S. News & World Report, May 5, 2020; Henry, L. and Saperstein, L. Personal interview. 3
        September 2021
      </>
    ),
    type: "source"
  },
  {
    id: "vaccine-mandates",
    text:
      '"Vaccine mandates: Perdue Farms leaving decisions to employees; Hogan presses nursing homes on COVID shots," My Eastern Shore, Maryland, August 6, 2021',
    source:
      "https://www.myeasternshoremd.com/emergency_notice/vaccine-mandates-perdue-farms-leaving-decisions-to-employees-hogan-presses-nursing-homes-on-covid-shots/article_c0ac38b7-02ec-532e-9b6f-e2ea70a83434.html",
    type: "source"
  },
  {
    id: "de-newsline-family-fun-day",
    text:
      '"Wilmington\'s Latin Community Vaccinated at Family Fun Day," Delaware Newsline, June 13, 2021',
    source:
      "https://www.delawarenewsline.com/2021/06/13/wilmingtons-latin-community-vaccinated-at-family-fun-day/",
    type: "source"
  },
  {
    id: "census-short",
    text: (
      <>
        U.S. Census Bureau, ACS{" "}
        <a
          href="https://www.census.gov/programs-surveys/acs/data.html"
          target="_blank"
          rel="noreferrer"
        >
          5-Year Data Profiles, 2019
        </a>
      </>
    ),
    type: "source"
  }
];

export const credits = {
  citations,
  footnotes,
  sources
};

export const creditsAsList = [...citations, ...footnotes, ...sources];

export const getCreditsByType = (type: string) => {
  const _credits = [...credits[type]];
  if (type === "footnotes") {
    _credits.map((c, i) => {
      c.superscript = i + 1;
      return c;
    });
  }
  return _credits;
};

export const getCreditById = (id: string, type = "footnotes") => {
  let superscript = null;
  const availableCredits = type ? getCreditsByType(type) : creditsAsList;
  const credit = availableCredits.find((c, i) => {
    superscript = type === "footnotes" ? i + 1 : null;
    return c.id === id;
  });
  return {
    ...credit,
    superscript
  };
};

export const getInlineCredit = (id: string) => {
  const credit = getCreditById(id);
  if (!credit) {
    return null;
  }
  const { superscript } = credit;
  return <InlineCredit credit={credit}>{superscript && <sup>{superscript}</sup>}</InlineCredit>;
};
