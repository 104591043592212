export const cullingMaxBasedOnWidth = (
  elWidth = window.innerWidth,
  _max = 20,
  range = [576, 780]
) => {
  let cullingMax = _max;
  if (elWidth <= range.first()) {
    cullingMax = 5;
  } else if (elWidth > range.first() && elWidth <= range.last()) {
    cullingMax = 10;
  }
  return cullingMax;
};
