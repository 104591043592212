/**
 * @param  {Number} index        Index of data set
 * @param  {String} type         Type or shape of point
 * @param  {String} size         Size of point
 * @param  {String} strokeColor  Stroke color
 * @param  {String} fillColor    Fill color
 * @return {String} point        SVG string with colors and settings
 */
export function svgChartPoints(
  index = 0,
  type = null,
  size = "10",
  strokeColor = "#CCCCCC",
  fillColor = "#FFFFFF"
) {
  let _type = null;
  if (type) _type = type;
  else _type = index;
  const strokeWidth = size < 6 ? 1 : 2;
  switch (_type) {
    case 0:
    case "dot":
      return `<g><circle cx='${size}' cy='${size}' r='${size}' stroke-width='${strokeWidth}' stroke='#fcfaf7'/></g>`;
    case 1:
      return `<g><rect width="${size * 1.75}" height="${
        size * 1.75
      }" stroke-width='${strokeWidth}' stroke='#fcfaf7'/></g>`;
    case 2:
      return `<g transform="translate(0 -2.5)"><polygon points="${size} ${size * 0.33}, ${
        size * 2
      } ${size * 2}, 0 ${size * 2}" class='chart-return' /></g>`;
    case 3:
      return `<g><circle cx='${size / 1.33}' cy='${size / 1.33}' r='${
        size / 1.33
      }' fill='white' stroke-width='3' stroke='inherit' stroke-linecap='round'/></g>`;
    case 4:
      return `<g><rect width="${size * 1.25}" height="${
        size * 1.25
      }" fill='white' stroke-width='3' stroke='inherit' stroke-linecap='round'/></g>`;
    case 5:
      return `<g transform="translate(0 -2.5)"><polygon points="${size} ${size * 0.33}, ${
        size * 2
      } ${size * 2}, 0 ${
        size * 2
      }" stroke-alignment='middle' fill='white' stroke-width='3' stroke='inherit' /></g>`;
    case "circle-stroked":
      return `<g><circle cx='${size}' cy='${size}' r='${size}' stroke-width='${strokeWidth}' stroke='${strokeColor}' fill='${fillColor}'/></g>`;
    case "transparent":
      return "<g><circle cx='0' cy='0' r='0' stroke-width='0' stroke='transparent' fill='transparent'/></g>";
    default:
      return `<g><circle cx='${size}' cy='${size}' r='${size}' stroke-width='${strokeWidth}' stroke='#fcfaf7'/></g>`;
  }
}

/**
 * Returns array of colors used in charts
 */
export function colors(chartType = null) {
  switch (chartType) {
    case "comparison":
      return [
        "#147BB1", // Blue
        "#787878" // Gray
      ];
    case "single_trend":
      return ["#404040"]; // Dark Gray
    case "double_trend":
      return colors().slice(3, 5);
    case "county_comparison":
      return colors().slice(0, 3);
    default:
      return [
        "#147BB1", // Blue
        "#E3733B", // Orange
        "#4C4282", // Light Violet
        "#66A082", // Light Green
        "#AC005E", // Magenta
        "#80BDCE", // Light Blue
        "#CE9A6B", // Light Orange
        "#2E284F", // Dark Violet
        "#425936", // Green
        "#D2D2D2" // Gray
      ];
  }
}
