import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import AnimatedMap from "../Maps/AnimatedMap";
import MapGradientLegend from "javascript/maps/MapGradientLegend";
import mapFrame1 from "images/stories/vaccination/maps/map-2_1.jpg";
import mapFrame2 from "images/stories/vaccination/maps/map-2_2.jpg";
import mapFrame3 from "images/stories/vaccination/maps/map-2_3.jpg";
import mapFrame4 from "images/stories/vaccination/maps/map-2_4.jpg";
import mapFrame5 from "images/stories/vaccination/maps/map-2_5.jpg";
import mapFrame6 from "images/stories/vaccination/maps/map-2_6.jpg";
import mapFrame7 from "images/stories/vaccination/maps/map-2_7.jpg";
import mapFrame8 from "images/stories/vaccination/maps/map-2_8.jpg";
import mapFrame9 from "images/stories/vaccination/maps/map-2_9.jpg";
import timeline1 from "images/stories/vaccination/maps/timeline_1.jpg";
import timeline2 from "images/stories/vaccination/maps/timeline_2.jpg";

import { textContainer, leadText } from "../styles/typography";
import { getCreditById } from "./copy";

import { AnimationFrame } from "../types";

const useLegendStyles = createUseStyles({
  legend: {
    zIndex: 50,
    width: "100%",
    marginBottom: 20
  }
});

const legend = {
  showNote: false,
  colors: [
    {
      value: -10000.0,
      rgb: "97,106,111",
      red: 97,
      green: 106,
      blue: 111
    },
    {
      value: -0.01,
      rgb: "97,106,111",
      red: 97,
      green: 106,
      blue: 111
    },
    {
      value: 0.0,
      rgb: "255,255,255",
      red: 255,
      green: 255,
      blue: 255
    },
    {
      value: 3.38,
      rgb: "135,202,237",
      red: 135,
      green: 202,
      blue: 237
    },
    {
      value: 12.53,
      rgb: "158,198,209",
      red: 158,
      green: 198,
      blue: 209
    },
    {
      value: 18.78,
      rgb: "182,194,181",
      red: 182,
      green: 194,
      blue: 181
    },
    {
      value: 26.08,
      rgb: "205,191,154",
      red: 205,
      green: 191,
      blue: 154
    },
    {
      value: 35.55,
      rgb: "229,187,126",
      red: 229,
      green: 187,
      blue: 126
    },
    {
      value: 48.65,
      rgb: "253,184,99",
      red: 253,
      green: 184,
      blue: 99
    },
    {
      value: 66.24,
      rgb: "248,166,79",
      red: 248,
      green: 166,
      blue: 79
    },
    {
      value: 91.3,
      rgb: "243,149,59",
      red: 243,
      green: 149,
      blue: 59
    },
    {
      value: 130.28,
      rgb: "239,131,40",
      red: 239,
      green: 131,
      blue: 40
    },
    {
      value: 199.78,
      rgb: "234,114,20",
      red: 234,
      green: 114,
      blue: 20
    },
    {
      value: 450.59,
      rgb: "230,97,1",
      red: 230,
      green: 97,
      blue: 1
    }
  ],
  unit: "Rate per 10,000 people (7-day average)",
  precision: 2
};

const Legend: React.FC = () => {
  const classes = useLegendStyles();
  return (
    <div className={classes.legend}>
      <MapGradientLegend {...legend} />
    </div>
  );
};

const mapFiller = "filler-half";
const firstMapTitle = "Cumulative rate of positive COVID-19 cases, Delaware,";
const secondMapTitle = "Rate of new positive COVID-19 cases, Delaware,";
const figureTitles = {
  early: <>{firstMapTitle} April 2020 to June 2020</>,
  phases: <>COVID-19 vaccine eligibility, Delaware</>,
  late: <>{secondMapTitle} January 1, 2021</>
};

const textAndImages: AnimationFrame[] = [
  {
    text: (
      <p style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
        A closer look shows the impact in Sussex County.
      </p>
    ),
    image: mapFrame1,
    date: "April 7, 2020",
    altText: `${firstMapTitle} April 7, 2020`,
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: mapFiller,
    image: mapFrame2,
    date: "April 10, 2020",
    altText: `${firstMapTitle} April 10, 2020`,
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: (
      <p>
        In the pandemic’s early months, case hotspots flared around poultry facilities and across
        the adjacent census tracts where the percentage of Hispanic residents is higher than the
        state and county percentage.
      </p>
    ),
    image: mapFrame3,
    date: "April 15, 2020",
    altText: `${firstMapTitle} April 15, 2020`,
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: mapFiller,
    image: mapFrame4,
    altText: `${firstMapTitle} April 19, 2020`,
    date: "April 19, 2020",
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: mapFiller,
    image: mapFrame5,
    altText: `${firstMapTitle} April 24, 2020`,
    date: "April 24, 2020",
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: mapFiller,
    image: mapFrame6,
    date: "May 2, 2020",
    altText: `${firstMapTitle} May 2, 2020`,
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: mapFiller,
    image: mapFrame7,
    date: "May 16, 2020",
    altText: `${firstMapTitle} May 16, 2020`,
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: mapFiller,
    image: mapFrame8,
    date: "June 7, 2020",
    altText: `${firstMapTitle} June 7, 2020`,
    title: figureTitles.early,
    showLegend: true
  },
  {
    text: <p style={{ fontWeight: "bold" }}>Fast forward to vaccination roll-out.</p>,
    image: timeline1,
    imageClassname: "hidden-frame",
    date: null,
    altText: "Phase timeline",
    title: figureTitles.phases,
    showLegend: false
  },
  {
    text: (
      <p>
        Delaware, following Centers for Disease Control and Prevention guidance, classified poultry
        and other food processing employees as "frontline essential workers" - a priority group for
        COVID-19 vaccination in early 2021.
      </p>
    ),
    image: timeline2,
    imageClassname: "hidden-frame", //classes.hiddenFrame,
    date: null,
    altText: "Phase timeline with phase highlighted",
    title: figureTitles.phases,
    showLegend: false
  },
  {
    text: (
      <div style={textContainer}>
        <p style={{ ...leadText(1) }}>
          By then the virus was widespread, <br /> afflicting all corners of the state.
        </p>
      </div>
    ),
    image: mapFrame9,
    date: "January 1, 2021",
    altText: `${secondMapTitle} January 1, 2021`,
    title: figureTitles.late,
    showLegend: true
  },
  {
    text: "filler-half",
    date: "January 1, 2021",
    altText: `${secondMapTitle} January 1, 2021`,
    image: mapFrame9,
    title: figureTitles.late,
    showLegend: true
  }
];
const EarlyMonthsMap = () => {
  return (
    <AnimatedMap
      Legend={Legend}
      textAndImages={textAndImages}
      sourceShowIndices={textAndImages.map((_, i) => i)}
      source={getCreditById("ddhss", "sources")?.text}
    />
  );
};

export default EarlyMonthsMap;
