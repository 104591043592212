import React, { Component, Fragment } from "react";
import MetricCards from "../MetricCard/MetricCards";
import TopicContext from "./TopicContext";
import CardInfoWarning from "./CardInfoWarning";

export default class Topic extends Component {
  constructor(props) {
    super(props);
    // get comparison slug from URL and set it as current state
    this.HEADER_INTRO_TEXT_LIMIT = 100;
    this.options = Object.assign(
      {
        cardType: "multi-metric",
        headerBordered: true,
        showInfoMessagePerCard: true,
        showGlobalInfoWarning: false
      },
      props.options || {}
    );
    const { primaryLocation, comparisonLocation } = props.comparison;
    let id = null;
    let name = "";
    if (comparisonLocation && comparisonLocation.slug !== primaryLocation.slug) {
      id = comparisonLocation.slug || null;
      name = comparisonLocation.human_name || "";
    }
    this.state = { geographicComparison: { id, name } };
  }

  addNavItems() {
    const item = document.querySelector(".c-list__item--child.active");
    return $(item).append(`
      <ul class='active c-list__children c-list__children nav-bar j-topic-nav'>
        <li class='nav-item c-list__item c-list__item--child nav-item'>
          <a class='c-list__link j-smooth-scroll' href='#summary-metrics'>
            <span>Summary Metrics</span>
          </a>
        </li>
        <li class='nav-item c-list__item c-list__item--child nav-item'>
          <a class='c-list__link j-smooth-scroll' href='#maps'>
            <span>Maps</span>
          </a>
        </li>
        <li class='nav-item c-list__item c-list__item--child nav-item'>
          <a class='c-list__link j-smooth-scroll' href='#charts'>
            <span>Charts</span>
          </a>
        </li>
      </ul>
    `);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.geographicComparison.id === nextState.geographicComparison.id) {
      return false;
    }
  }

  handleComparisonChange(location) {
    this.setState({ geographicComparison: location });
    // Update browser history
    window.history.pushState({}, document.title, `?comparison_slug=${location.id}`);
  }

  contextProps() {
    return Object.assign(
      {
        location: this.props.location || "",
        geographicComparison: this.state.geographicComparison
      },
      this.props.context
    );
  }

  geoWarning() {
    return (
      <div className="o-topic__note c-topic-note c-topic-note--warning c-topic-note--full-bleed">
        <div className="c-topic-note__wrapper">
          <div className="c-topic-note__message">
            <p className="c-topic-note__lead" />
            <p className="c-topic-note__body" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { geographicComparison } = this.state;
    const {
      context,
      comparison,
      location,
      topicLanding,
      showHigherGeoWarning = false
    } = this.props;
    return (
      <>
        <div id="lead-content" className="o-topic__lead-content mx-4">
          {showHigherGeoWarning && (
            <div className="mt-6 mb-4">
              <CardInfoWarning
                inCard={false}
                message="<strong>Please note:</strong> Your chosen geography is shown when possible.
                  A larger geography may be shown to protect health data privacy
                  in the case of small numbers or data availability."
              />
            </div>
          )}
        </div>
        <div className="o-topic__content px-4">
          {comparison && comparison.summaryData && comparison.summaryData.length > 0 && (
            <div id="summary-metrics">
              {!topicLanding && (
                <div className="o-topic-context__title-wrapper">
                  <div className="c-dashboard-title c-dashboard-title--h2 c-dashboard-title--line mb-4">
                    <h2>Summary Metrics</h2>
                  </div>
                </div>
              )}
              <MetricCards
                cardType={this.options.cardType}
                data={comparison.summaryData}
                location={location}
                showInfoMessagePerCard={this.options.showInfoMessagePerCard}
                showGlobalInfoWarning={this.options.showGlobalInfoWarning}
                geographicComparison={geographicComparison}
              />
            </div>
          )}
          {context && <TopicContext {...this.contextProps()} />}
        </div>
      </>
    );
  }
}
