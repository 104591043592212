import React, { Component } from "react";
import DropdownMenu from "./DropdownMenu";
import classnames from "classnames";
import { sendGAEvent } from "../utils/google_analytics";

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    // const { constrained } = this.props;
    // this.props.constrained = constrained === undefined ? true : constrained;
    this.state = {
      selectedItem: props.selectedItem ? props.selectedItem : props.items[0]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedItem &&
      nextProps.selectedItem &&
      this.props.selectedItem.value !== nextProps.selectedItem.value
    ) {
      this.setState({ selectedItem: nextProps.selectedItem });
    }
  }

  _menuItems() {
    const items = this.props.items.map((choice, i) => {
      const { name, sets, value, disabled, active } = choice;
      const classNames = classnames(
        "d-flex",
        "dropdown-item",
        "c-dropdown__item",
        { disabled: disabled },
        { active: active }
      );
      if (value || sets) {
        return (
          <a
            className={classNames}
            href="#"
            onClick={this._handleItemClick.bind(this, value)}
            key={`menu-item-${i}`}
          >
            {typeof name === "string" ? name.capitalize() : name}
            {disabled && <span className="ml-auto pl-2">(insufficient data)</span>}
          </a>
        );
      } else {
        return (
          <h4 key={`menu-item-${i}`} className="c-dropdown__menu-heading dropdown-header">
            {choice.name || choice.title}
          </h4>
        );
      }
    });

    if (items.length && items[items.length - 1].nodeName == "h4") {
      items.pop();
    }

    return items;
  }

  _handleItemClick(value, event) {
    event.preventDefault();
    this.setState({
      selectedItem: this.props.items.find((i) => i.value == value)
    });
    this.props.handler(event, value);
    const { ga } = this.props;
    if (ga) {
      const { category, action, value } = ga;
      sendGAEvent({eventType: "user_interaction", parameters: { category, action, label: event.target.innerHTML || value || "" }});
    }
  }

  render() {
    const { label, size, actionLabel, ga, constrained } = this.props;
    const { selectedItem } = this.state;
    return (
      <DropdownMenu
        selectedItem={selectedItem}
        label={label}
        size={size}
        ga={ga ? ga : null}
        actionLabel={actionLabel}
        constrained={constrained}
      >
        {this._menuItems()}
      </DropdownMenu>
    );
  }
}
