import React, { Component } from "react";
import Animation from "./Animation";
import StaticMap from "../maps/StaticMap";
import LeafletMap from "../maps/LeafletMap";
import TabbedMap from "../maps/TabbedMap";
import PointMap from "../maps/PointMap";
import DisabledTreatmentMap from "../maps/DisabledTreatmentMap";

export default class extends Component {
  maps() {
    return this.props.maps.map((map) => {
      const { type } = map;
      switch (type) {
        case "static":
          return <StaticMap {...map} />;
        case "leaflet":
          return <LeafletMap {...map} />;
        case "animated":
          return <Animation {...map} />;
        case "tabbed":
          return <TabbedMap {...map} />;
        case "point":
          return <PointMap {...map} />;
        case "disabledTreatment":
          return <DisabledTreatmentMap {...map} />;
        default:
          return null;
      }
    });
  }

  render() {
    const { maps = [] } = this.props;
    const hasRenderableMaps = maps.filter(
      (m) =>
        m && (m.url || m.path || (m.maps && m.maps.length) || (m && m.points && m.points.length))
    );
    return (
      <div aria-label="Maps">
        {hasRenderableMaps.length > 0 && (
          <div>
            <div className="c-dashboard-title c-dashboard-title--h2 c-dashboard-title--line mb-4">
              <h2>Maps</h2>
            </div>
            {this.maps().map((m, i) => (
              <div key={`map-${i}`} className="o-map card mb-4">
                {m}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
