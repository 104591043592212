import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  legendContainer: {
    fontFamily: "open sans",
    display: "flex",
    flexDirection: "column"
  },
  legendTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
    display: "block"
  },
  legendSubTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 5,
    display: "block"
  },
  legendSwatchWrapper: {},
  swatchContainer: {
    marginBottom: 5,
    fontSize: 16,
    display: "flex",
    alignItems: "center"
  },
  swatch: {
    height: 25,
    width: 25,
    margin: [0, 5],
    border: "1px solid #707070",
    borderRadius: 4
  }
});

type LegendItem = {
  range: number[] | string[];
  label?: string;
  color: string;
  suffix: string;
  isLast: boolean;
  openEnded: boolean;
};

type LegendProps = {
  items: LegendItem[];
  title?: string;
  subTitle?: string;
  suffix: string;
  openEnded: boolean;
};

const Swatch: React.FC<LegendItem> = ({ range, color, label, suffix, isLast, openEnded }) => {
  const classes = useStyles();
  let rangeLabel = range.join(" - ");
  if (suffix) {
    rangeLabel += suffix;
  }
  if (openEnded && isLast) {
    rangeLabel += "+";
  }
  return (
    <div className={classes.swatchContainer}>
      {color && <div style={{ background: color }} className={classes.swatch} />}
      {label && <span>{label}</span>}
      {rangeLabel && <span>{rangeLabel}</span>}
    </div>
  );
};

const SwatchLegend: React.FC<LegendProps> = ({ items, title, subTitle, suffix, openEnded }) => {
  const classes = useStyles();
  return (
    <div className={classes.legendContainer}>
      {title && <span className={classes.legendTitle}>{title}</span>}
      {subTitle && <span className={classes.legendSubTitle}>{subTitle}</span>}
      <div className={classes.legendSwatches}>
        {items.map((item, i) => {
          const props = {
            ...item,
            suffix,
            isLast: items.length - 1 === i,
            openEnded
          };
          return <Swatch key={i} {...props} />;
        })}
      </div>
    </div>
  );
};

export default SwatchLegend;
