import { Controller } from "stimulus";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["item", "panel", "canvas", "panelContainer", "month"];

  declare THROTTLE_DELAY: number;
  declare itemTargets: HTMLElement[];
  declare panelTargets: HTMLElement[];
  declare monthTargets: HTMLElement[];
  declare canvasTarget: HTMLElement;
  declare panelContainerTarget: HTMLElement;

  connect() {
    this.THROTTLE_DELAY = 400;
    window.onresize = debounce(() => {
      this.scrollPanel();
      this.adjustHeight();
    }, this.THROTTLE_DELAY);
  }

  disconnect() {
    window.onresize = null;
  }

  initialize() {
    // Throttle mouse events
    this.change = throttle(this.change, this.THROTTLE_DELAY).bind(this);
    this.next = throttle(this.next, this.THROTTLE_DELAY).bind(this);
    this.prev = throttle(this.prev, this.THROTTLE_DELAY).bind(this);
    // If the size of the container changes, scroll to accommodate
    // Adjust height of content
    const activePhases = this.panelTargets.filter((el) => el.dataset.active == "true").length;
    this.itemIndex = activePhases - 1;
    this.adjustHeight();
  }

  adjustHeight() {
    if (this.panelTargets.length) {
      let maxHeight = 0;
      this.panelTargets.forEach((el) => {
        if (el.scrollHeight > maxHeight) {
          maxHeight = el.scrollHeight;
        }
      });
      this.panelTargets.forEach((el) => {
        el.style.height = `${maxHeight}px`;
        const parentEl: HTMLElement | null = el.parentElement;
        if (parentEl) parentEl.style.height = `${maxHeight}px`;
      });
    }
  }

  change(e: Event) {
    // no-op if the user clicks on the current item
    const clickedItemIndex = this.itemTargets.indexOf(e.currentTarget as HTMLElement);
    if (this.itemIndex === clickedItemIndex) return;
    this.itemIndex = clickedItemIndex;
  }

  prev() {
    if (this.itemIndex >= 0) {
      this.itemIndex--;
    }
  }

  next() {
    this.itemIndex++;
  }

  scrollPanel(element?: HTMLElement, container?: HTMLElement, topOffset = 0) {
    (container || this.panelContainerTarget).scroll({
      top: topOffset,
      left: (element || this.panelTargets[this.itemIndex]).offsetLeft,
      behavior: "smooth"
    });
  }

  showItem() {
    this.itemTargets.forEach((item: HTMLElement, index: number) => {
      const isCurrentItem = index === this.itemIndex;
      item.classList.remove("selected");
      if (isCurrentItem) {
        item.classList.add("selected");
        this.canvasTarget.scroll({
          top: 0,
          left: item.offsetLeft - 10,
          behavior: "smooth"
        });
        this.scrollPanel();
      }
    });
  }

  get itemIndex() {
    return +(this.data.get("itemIndex") || 0);
  }

  set itemIndex(value: number) {
    this.data.set("itemIndex", `${value}`);
    this.showItem();
  }
}
