// Sparklines in slats

import Chart from "./chart";
import { spline } from "billboard.js";
import classnames from "classnames";
import moment from "moment";
import { scaleTime } from "d3";
import { roundToDigits } from "../utils/number";
import { deepCopy } from "../utils/index";
import { colors } from "./options";
import { cullingMaxBasedOnWidth } from "./utils";
import * as __max from "lodash/max";
import * as __min from "lodash/min";
import isNaN from "lodash/isNaN";
import sum from "lodash/sum";
import compact from "lodash/compact";
import { typesAsModules } from "./simple_line";

export default class Trend extends Chart {
  constructor(props, containerElements, options = {}) {
    const _options = Object.assign(
      {
        showLegend: false,
        showLoader: false,
        tooltipAddendum: "",
        hideTooltipValues: false,
        colors: colors(),
        lineClasses: ["bb-line", "bb-line--dashed"],
        granularity: "yearly",
        dateFormat: "'%y",
        showXAxis: false,
        showYAxis: false,
        cullXAxisLabels: false,
        height: props.height || 200,
        type: "time",
        defaultMinZero: true,
        xAxis: {},
        yAxis: {},
        tooltipPrecision: 1,
        padAxis: true,
        showGrid: false,
        groupLines: false,
        lineThickness: 2,
        labelRotation: 0,
        explicitMinMax: false,
        hiddenSeries: []
      },
      options
    );
    super(props, containerElements, _options);
    this.options = _options;
    this.lineOptions = {
      connectNull: true,
      classes: this.options.lineClasses
    };
    this._init(false);
  }

  _onRendered() {
    return;
  }

  getLineNames() {
    const names = {};
    this.props.columns.forEach((c, i) => {
      const name = typeof c === "string" ? c : c[0];
      names[name] = this.props.names[i];
    });
    return names;
  }

  getMinMax() {
    const { unit, defaultMinZero } = this.options;
    let _columns = deepCopy(this.props.columns);
    let columnNumericValues = _columns.map((trend) => {
      trend.shift();
      return trend.map(Number);
    });
    let max = __max(columnNumericValues.flat());
    if (unit && unit.toLowerCase().includes("percent") && max <= 100 && max >= 50) {
      max = 100;
    }
    let min = __min(columnNumericValues.flat());
    if (defaultMinZero && min > 0) {
      min = 0;
    }
    if (this.options.groupLines) {
      const sumOfColumnMaxes = sum(columnNumericValues.map(__max));
      return { min, max: sumOfColumnMaxes };
    }
    const _max = Math.round(max);
    const _min = Math.round(min);
    return {
      min: isNaN(_min) ? 0 : _min,
      max: isNaN(_max) ? 100 : _max
    };
  }

  _buildOptions() {
    const { dates, columns, baseline, categories } = this.props;
    const {
      cullXAxisLabels,
      showYAxis,
      showXAxis,
      yAxis = {},
      xAxis = {},
      labelRotation,
      explicitMinMax,
      height,
      dateFormat,
      showGrid,
      groupLines,
      types,
      hiddenSeries
    } = this.options;
    let _columns = [];
    if (this.isOverTime()) {
      _columns = [["x", ...dates], ...columns];
    } else {
      _columns = columns;
    }

    let yAxisOpts = {};

    let values = null;
    if (showYAxis) {
      let minMax = { min: 0, max: 100 };
      if (typeof yAxis.min === "number" && typeof yAxis.max === "number") {
        minMax = { min: yAxis.min || 0, max: yAxis.max || 100 };
      } else {
        minMax = this.getMinMax();
      }
      values = Object.values(minMax);

      // This hack allows the y-axis origin to be -1 but still look like 0.
      // Keeps 0 values on the chart so they are not hidden by the axis line.
      if (this.options.padAxis) values[0] = 0;

      // Add a tick that's the a mid-point between the min/max
      values.splice(1, 0, Math.round(values.last() / 2));
      yAxisOpts = {
        label: {
          text: yAxis.label,
          position: "outer-middle"
        },
        tick: {
          values
        }
      };
      if (explicitMinMax) {
        yAxisOpts = { ...minMax, ...yAxisOpts };
      }
    }
    // Create array of values to show in the x axis
    // Ensure that the first and last values are added
    let xAxisValues = null;
    if (cullXAxisLabels && dates && dates.length > 10) {
      xAxisValues = dates;
      const timeScale = scaleTime().domain([moment(dates.first()), moment(dates.last())]);
      xAxisValues = timeScale.ticks(
        cullingMaxBasedOnWidth(this.containerEl.clientWidth, 10, [420, 620])
      );
      xAxisValues[xAxisValues.length - 1] = dates.last();
    }
    const dataClasses = {};

    const seriesColors = {};
    columns.forEach((c, i) => {
      const name = c.first();
      dataClasses[name] = this.options.lineClasses[i];
      if (hiddenSeries.includes(name)) {
        seriesColors[name] = "transparent";
        return;
      }
      seriesColors[name] = this.options.colors[i];
    });
    const onrendered = (context) => {
      this._onRendered(context);
    }
    this._options = {
      size: { height },
      padding: {
        left: showYAxis || yAxis.label ? 60 : 0,
        right: showYAxis || yAxis.label ? 20 : 0,
        top: 0,
        bottom: labelRotation ? 50 : 0
      },
      axis: {
        x: {
          show: true,
          type: this.isOverTime() ? "timeseries" : "category",
          padding: this.isOverTime() ? 1000 * 60 * 60 * 50 : 0,
          label: {
            text: xAxis.label,
            position: "outer-center"
          },
          tick: {
            format: this.isOverTime() ? dateFormat : null,
            text: { show: showXAxis },
            culling: false,
            count: dates.length < 10 ? dates.length : null,
            fit: false,
            values: xAxisValues,
            rotate: labelRotation,
            multiline: false
          }
        },
        y: Object.assign({ show: showYAxis }, yAxisOpts)
      },
      legend: { show: false },
      point: { show: false },
      data: {
        columns: _columns,
        type: spline(),
        names: this.getLineNames(),
        classes: dataClasses,
        colors: seriesColors
      },
      // color: { pattern: this.options.colors },
      line: this.lineOptions,
      bindto: this.containerEl,
      onrendered: function () {
        onrendered(this);
      },
      tooltip: { contents: this._tooltip.bind(this) }
    };
    if (types) {
      this._options.data.types = typesAsModules(types);
    }
    if (this.isOverTime()) {
      this._options.data.x = "x";
    } else {
      this._options.axis.x.categories = categories || dates.map((d) => moment(d).format("'YY"));
    }
    if (showGrid) {
      this._options.grid = {
        y: { show: true }
      };
    }
    if (groupLines) {
      this._options.data.groups = [columns.map((c) => c.first())];
    }
    if (baseline) {
      this._options.grid = {
        y: {
          lines: [
            {
              value: baseline,
              text: "",
              class: "bb-grid-line--dotted"
            }
          ]
        }
      };
    }
  }

  _tooltip(data) {
    const values = compact(data).map((d, i) => {
      if (d.value === null) return null;
      const {
        tooltipAddendum,
        tooltipPrecision,
        colors,
        isComparison,
        excludedSetsFromTooltip = []
      } = this.options;

      if (excludedSetsFromTooltip.length) {
        if (excludedSetsFromTooltip[i]) {
          return null;
        }
      }

      const dateFormat = this.options.granularity === "yearly" ? "YYYY" : "MMM D";
      const categories = this.props.categories || this._options.axis.x.categories;
      const date = this.isOverTime()
        ? moment(d.x).format(dateFormat)
        : categories && categories[d.x];
      const metricClassNames = classnames(
        "c-tooltip-chart__value",
        { "c-tooltip-chart__value--1": isComparison && i === 0 },
        {
          "c-metric-value c-metric-value--comparison mt-1 d-inline-flex pt-1 pb-1":
            isComparison && i === 1
        }
      );
      let name = this.props.name || d.name;
      let value = "";
      let unitWords = "";
      let colorSwatch = "";

      if (this.props.unit) {
        unitWords = `
          <span class="c-tooltip-chart__unit">${this.props.unit}</span>
        `;
      }
      if (!this.options.hideTooltipValues) {
        value = `
          <span class='${metricClassNames} d-inline'>
            ${roundToDigits(d.value, null, null, tooltipPrecision)}
          </span>
        `;
      }
      if (colors[i]) {
        colorSwatch = `<span class='c-swatch c-swatch--lg ml-1' style='background: ${colors[i]}'> </span>`;
      }
      // Formally we colored the title and value in the tooltip with the color
      // from the chart but recent changes introduced contrast issues.
      // Added the swatch instead
      return `
        <div class='c-tooltip-chart__value-wrapper'>
          <span class='c-tooltip-chart__date'>
            ${date}
          </span>
          <h4 class='c-tooltip-chart__title'>${name}</h4>
          <div class='d-flex align-items-center'>
            ${value}
            ${colorSwatch}
          </div>
          ${unitWords}
          ${tooltipAddendum && i > 0 && data[1] ? tooltipAddendum : ""}
        </div>
      `;
    });
    return `<div class='c-tooltip-chart c-tooltip-chart--horizontal'> ${values.join(" ")} </div>`;
  }

  isOverTime() {
    return this.options.type === "time" && this.options.granularity !== "yearly";
  }

  update(props, options = {}) {
    this.props = props;
    const colors = {};
    props.columns.forEach((c, i) => {
      colors[c[0]] = this.options.colors[i];
    });
    this.options = Object.assign(this.options, options);
    this.chart.load({
      columns: props.columns,
      colors,
      names: this.getLineNames(),
      unload: [this.chart.data.shown()[1] ? this.chart.data.shown()[1].id : ""]
    });
  }

  _addLegend() {
    // noop
    return null;
  }
}
